import { Component, ChangeDetectorRef,ElementRef, HostListener, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
// import { NgbDateCustomParserFormatter } from './date-formatter/date-formatter';
import { AREAS_CFG, ODOO_IDS } from '../../models/deal'
import { TrelloCardEntry2 } from 'src/app/models/trello-card';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { first } from 'rxjs/operators';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { Contact } from 'src/app/models/contact.model';
import { orderBy } from 'lodash';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { MrpProduction } from 'src/app/models/mrp-production';
import { DriveFolder } from 'src/app/models/drive.folder';
import { CalendarEvent } from 'src/app/models/calendar-event.model';
import { StockMove } from 'src/app/models/stock-move';
import { firstValueFrom } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CrmTag } from 'src/app/models/crm.tag.model';
import { CrmLeadPart } from 'src/app/models/crm.lead.part.model';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { MailActivity } from 'src/app/models/mail.message';
import { ProcurementGroup } from 'src/app/models/procurement.group.model';


const DEAL_TABLE: string = "crm.lead"

declare var Trello: any;


// @Pipe({ name: 'sortByGaOrder' })
// export class SortByPipe implements PipeTransform {
//   transform(value: any[], order = '', column: string = ''): any[] {
//     return orderBy(value, ['name'], ['asc']);
//   }
// }



@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(value: any[], order ): any[] {
    return orderBy(value, [order], ['asc']);
  }
}


@Component({
  selector: 'app-deal-detail',
  templateUrl: './deal-detail.component.html',
  // providers: [
  //   { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  // ]
})
export class DealDetailComponent implements OnInit {

  purchaseOrdersIds: any[];
  contact: Contact;
  purchaseOrders: PurchaseOrder[];
  newSaleName: string = ""
  newProductionName: string = ""
  newPurchaseName: string;
  newGroupName:string = ""

  calendarEventModal: false
  newCalendarEventName: string;
  newCalendarEventDate: string;
  newCalendarEventNote: string;
  updateCalendarList: boolean = false

  contactForPurchase: Contact
  productions: MrpProduction[];

  existingsale: SaleOrder;
  arrayC: CalendarEvent[]
  arrayCategories: any[];
  sales: SaleOrder[];
  // saleGroups: SaleOrderGroup[];
  salesGroup: any;
  parts: CrmLeadPart[];
  partIds: string[];
  importableLeads: Lead[];
  activities: MailActivity[];
  offersPart: CrmLeadPart;


  showCosts: boolean = false
  
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    return !this.loading
  }

  purchases: PurchaseOrder[];
  id: any
  stages: CrmStage[];
  commentBody: string = ''
  loading: boolean = false
  selectStageId: any
  selectFields: Object[]
  // expectedDeliveryDate: NgbDate
  offers: SaleOrder[] = [];
  cfg: any
  trelloCards: TrelloCardEntry2[] = []
  lead: Lead;
  emails: string[] = []

  @ViewChild('map') mapElement: any;

  busyMessage: unknown;

  constructor(
    private odooEm: OdooEntityManager,
    private router: Router,
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private odooRPCService: OdoorpcService
  ) {
  }

  async ngOnInit() {

    this.stages = await this.odooEm.search<CrmStage>(new CrmStage()).toPromise()

    this.route.params.subscribe(async params => {
      this.id = params['id'];
      this.loading = true;
      await this.loadDeal()
      await this.loadParts()
      await this.toggleResolvePart(this.offersPart)
      this.loading = false
    })
    
    this.importableLeads = await this.odooEm.search<Lead>(new Lead(),[["tag_ids","in", [ODOO_IDS.tag_template_id]]]).toPromise()
  }

  async loadParts() {

    let allparts =await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [['lead_id', '=', this.lead.id]]))
    console.log("ALL PARTS ", allparts)
    this.parts =allparts.filter(p => p.name != "Preventivi")
    this.offersPart = allparts.find(p => p.name == "Preventivi")
    console.log("ALL PARTS2", this.offersPart)
    this.partIds = this.parts.map(p => "part-" + p.id)
    

  }



  async resolvePart(p) {
    this.loading = true




    console.log("RESOLVE PART ", p) 
    var res = await firstValueFrom(this.odooEm.search<CrmLeadPart>(new CrmLeadPart(), [['id', '=', p.id]]))
    // TODO update the relationship
    if (!res) return alert("Error in resolvepart")
    
      await firstValueFrom(this.odooEm.resolve(res[0].sale_order_ids))
      await firstValueFrom(this.odooEm.resolveArray(new MailActivity(), res[0].sale_order_ids.values, "activity_ids"))
      
      // do not wait
      firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new DriveFolder(), res[0].sale_order_ids.values, "drive_folder_ids"))
      firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new TrelloCardEntry2(), res[0].sale_order_ids.values, "trello_card_ids"))
      


     


      // //carico procurement group per ogni sale order della parte e stock moves per ogni procurement group
  
      firstValueFrom(this.odooEm.resolveArrayOfSingle(new ProcurementGroup(), res[0].sale_order_ids.values, "procurement_group_id"))

      let procs = []
      res[0].sale_order_ids.values.forEach(s => { 
        if (s.procurement_group_id.value && s.procurement_group_id.value.stock_move_ids)
          procs.push(s.procurement_group_id.value)
      })
      console.log("PROCS ", procs)

      if (procs.length > 0) {
      await firstValueFrom(this.odooEm.resolveArray(new StockMove(), procs , "stock_move_ids"))
      }

      // //Per ogni sale, risolvi il suo stato di avanzamento
      
      this.odooEm.call2('sale.order', 'ga_compute_order_state', [ null, res[0].sale_order_ids.values.map(r => r.id)]).then(x => {
      // ga_compute_order_state returns an ordered array of strings in the result key
       res[0].sale_order_ids.values.forEach((r, i) => {
        r._delivery_state = x.result[i]
      })
    })

      p.sale_order_ids.ids = res[0].sale_order_ids.ids
      p.sale_order_ids.values = res[0].sale_order_ids.values

      
      
    // p.sale_order_ids.values = []
    this.loading = false

  } 

  getActivity(s:SaleOrder) {
    return this.activities.filter(a => a.res_id == s.id)
  }

  async expandAll() {
    this.loading = true
    let ps = []
    this.parts.forEach(async p => {
      ps.push(this.toggleResolvePart(p))
    })

    await Promise.all(ps)

    this.loading = false
  }


  async importLead(l:Lead) {
    if (!confirm("Stai per importare nuovi gruppi di ordini nella commessa. Confermi ?"))
      return

    this.loading = true
    await this.odooEm.call2("crm.lead","importParts",[this.lead.id, l.id])
    this.loadDeal()
    this.loadParts()
    this.loading = false
  }

  async drop(el:CdkDragDrop<CrmLeadPart, CrmLeadPart>) {
    this.loading = true
    // var group = el.container.data
    var position = el.currentIndex
    console.log("DROP so", el.item.data.name, el.container.data.name)
    
    
    var dragged = el.item.data
    var sourcePart:CrmLeadPart = el.previousContainer.data
    var targetPart:CrmLeadPart = el.container.data

    if (sourcePart != targetPart) {
      // await this.odooEm.update(targetPart, {sale: [[4, v.id]]}).toPromise()
      await firstValueFrom(this.odooEm.update(sourcePart, {sale_order_ids: [[3, dragged.id]]}))
      await firstValueFrom(this.odooEm.update(targetPart, {sale_order_ids: [[4, dragged.id]]}))
      await this.resolvePart(sourcePart)
    }
    await this.odooEm.call2(new CrmLeadPart().ODOO_MODEL,"reorder_sale_orders",[[el.container.data.id]  ,el.item.data.id,position])

    // await firstValueFrom(this.odooEm.update(el.item.data, {
    //   order_group_id: el.container.data.id
    // }))
    // await this.loadDeal()
    await this.resolvePart(targetPart)
    this.loading = false
  } 


  // getSalesOfGroup(g:SaleOrderGroup) {
  //   return this.sales.filter(s => s.order_group_id.id == g.id)
  // }

  async loadDeal() {
    this.loading = true
    const filters = [['id', '=', this.id]]
    var lead:Lead= (await this.odooEm.search<Lead>(new Lead(), filters).toPromise())[0]

    await this.odooEm.resolve(lead.trello_card_ids).pipe(first()).toPromise()
    await this.odooEm.resolve(lead.drive_folder_ids).pipe(first()).toPromise()
    await firstValueFrom(this.odooEm.resolveSingle<CrmStage>(new CrmStage(), lead.stage_id))
    await firstValueFrom(this.odooEm.resolve<DriveFolder>(lead.drive_folder_ids))
    await firstValueFrom(this.odooEm.resolve<CrmTag>(lead.tag_ids))

    //var sales = await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', lead.id]]).toPromise()
    //console.log("sales", sales)
    this.lead = lead
    // TODO M3 - lead non ha il field area
    this.cfg = AREAS_CFG.filter(a => a.name == this.lead.area)[0]
    this.loading = false
  }


  async loadOffers() {
    this.offers = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', this.lead.id], ['part_id', '=', false]]))
  }

  filterDriveFolders(settore: string) {
    if (this.lead.drive_folder_ids && this.lead.drive_folder_ids.values)
      return this.lead.drive_folder_ids.values.filter(x => x.name == settore)
  }


  filterTrelloCards(settore: string) {
    if (this.lead.trello_card_ids && this.lead.trello_card_ids.values)
      return this.lead.trello_card_ids.values.filter(x => x.name == settore)
  }

  filterProductionDriveFolders(p: SaleOrder) {
    // todo fix name/display_name/P+id confusing usage
    var l = p.drive_folder_ids?.values?.find(x => x.name == "Produzione" && x.origin == p.name)
    if (l)
      return [l]
    return 
    // if (this.lead.drive_ids && this.lead.drive_ids.values) {
    //   var r = this.lead.drive_ids.values.filter(x => x.name == "Produzione" && x.origin == p.name)
    //   return r
    // }
    // return []
  }

  filterProductionTrelloFolders(p: SaleOrder) {
    if (p.trello_card_ids && p.trello_card_ids.values) {
      return p.trello_card_ids.values.filter(x => x.name == "Produzione" && x.origin == p.name)
    }
    return []
  }

  beforeunloadHandler(event) {
    return false;
    //I have used return false but you can your other functions or any query or condition
  }

  async delete() {
    if (confirm("Sei sicuro di voler eliminare il lead ?")) {
      this.loading = true
      await this.odooEm.delete(new Lead(), [this.lead.id]).pipe(first()).toPromise()
      this.loading = false
      this.router.navigate(["deals"])
    }
  }

  async onAddressChange($event) {
    for (var x = 0; x < $event.address_components.length; x++) {
      var e = $event.address_components[x]
      if (e.types[0] == "locality") {
        this.lead.city = e.long_name.replaceAll("'", " ")
        break
      }
      if (e.types[0] == "administrative_area_level_3") {
        this.lead.city = e.long_name.replaceAll("'", " ")
        break
      }
    }
    await this.updateDealToOdoo('city')

    //TODO M3
    // this.lead.cordinates = $event.geometry.location.lat() + "," + $event.geometry.location.lng()
    await this.updateDealToOdoo('cordinates')

    this.lead.street = $event.formatted_address.replaceAll("'", " ");
    console.log("STREE ", this.lead.street)
    await this.updateDealToOdoo('street')
  }


  async updatePart(part:CrmLeadPart, field, value) {
    this.loading = true
    var p = {}
    p[field] = value
    await this.odooEm.update<CrmLeadPart>(part, p).toPromise()
    
// alert("kk")
    // part[field] = value
    var id = part.id
    await this.loadParts()
    this.toggleResolvePart(this.parts.find(x => x.id == id))
    // await this.resolvePart(part)

    // var i = this.parts.indexOf(part)

    // this.parts = [...this.parts]
    // var pp = [...this.parts]
    // this.parts = []

    // setTimeout(x => {

    //   this.parts = pp
    // },100)
    this.loading = false
  }


  getNextStage(): CrmStage {
    var ind
    this.stages.forEach((x, i) => {
      if (Number(x.id) == this.lead.stage_id.id)
        ind = i
    })

    if (this.stages.length > ind)
      return this.stages[ind + 1]
    return null
  }

  getStageOrder() {
    return this.stages.map(s => s.id).indexOf(this.lead.stage_id.id)
    // var xx = this.stages.filter(x =>  == o.id)
    // return xx[0]
  }

  getStage() {
    var xx = this.stages.filter(x => this.lead?.stage_id?.id == Number(x.id))
    return xx[0]
  }

  async toStatus(s) {
    
    // check prima di progetto 
    // TODO bad 
    if (s.sequence > 2 ) {
      console.log("this.lead.tag_ids.ids.length", this.lead.tag_ids.ids.length)
      if (this.lead.tag_ids.ids.length == 0)
        return alert("Non hai compilato tutti i campi")
    }

    if (!confirm('Sei sicuro di spostare il fascicolo in ' + s.name + ' ?'))
      return

    this.loading = true
    try {
      // get the id from the sequence position
      this.lead.stage_id.id = s.id
      await this.updateDealToOdoo('stage_id')
    } catch (e) {
      alert("Errore " + JSON.stringify(e))
    } finally {
      this.loading = false
    }
  }

 

  async attachCalendarEvent() {
    if (!this.newCalendarEventName)
      return
    var calendarEventTmpl = {
      name: this.newCalendarEventName,
      partner_id: [this.lead.partner_id.id, this.lead.partner_id.name],
      start: this.newCalendarEventDate,
      stop: this.newCalendarEventDate,
      description: this.newCalendarEventNote,
      res_id: this.lead.id,
      opportunity_id: this.lead.id
    }
    var c = await this.odooEm.create<CalendarEvent>(new CalendarEvent(), calendarEventTmpl).toPromise()
    this.elRef.nativeElement.querySelectorAll('#dm3')[0].classList.remove("show")
    this.newCalendarEventName = this.newCalendarEventDate = this.newCalendarEventNote = ""
    this.updateCalendarList = true

  }

  async renameGroup(g, name) {
    this.loading = true
    await firstValueFrom(this.odooEm.update(g, {'name': name}))
    this.loading = false
  }


  async updateCommitmentDate(s:SaleOrder, value:any) {

    console.log("updateCommitmentDate", value, s)
    this.loading = true
    
    console.log("VALUE ", value)

    await this.odooEm.check(firstValueFrom(this.odooEm.update(s, 
      { 
        'tag_ids': [[6, 0, [ODOO_IDS.tag_to_prepare]]],
        'commitment_date': value.toString().split("T")[0] 
      }))
    )
    // await firstValueFrom(this.odooEm.update(s, { 'commitment_date': value }))
    s.commitment_date = value
    this.loading = false
  }

  async renameSale(s:SaleOrder, name) {
    this.loading = true
    await firstValueFrom(this.odooEm.update(s, {'ga_title': name}))
    this.loading = false
  }

  async attachNewPart() {
    if (confirm("Verrà aggiunta una nuova sotto-commessa (gruppo di ordini). Confermi?")) {
    this.loading = true
    var p = {
      lead_id: this.lead.id,
      name: '',
    }
    var s = await this.odooEm.create<CrmLeadPart>(new CrmLeadPart(), p).toPromise()
    await this.loadParts()
    this.loading = false
    }
  }

  async deletePart(p:CrmLeadPart) {
    if (confirm("Sei sicuro di voler eliminare la sotto-commessa? Non potrai eliminarla se ci sono ordini di lavoro al suo interno")) {
      this.loading = true
      if (!p.sale_order_ids.ids || (p.sale_order_ids.ids && p.sale_order_ids.ids.length == 0) ) {
      await firstValueFrom(this.odooEm.delete(new CrmLeadPart(), [p.id]))
      this.loading = false
      await this.loadParts()
      } else {
        this.loading = false
        return alert("Impossibile eliminare, ci sono ordini di lavoro al suo interno. Prima cancellali o spostali in altre sotto-commesse")    
      }
    }
  }

  hasTag(tag:string) {
    return this.lead.tag_ids.values?.find(t => t.name == tag) ? true : false
  }

  async toggleTag(tag) {
    this.loading = true
    var ts = await firstValueFrom(this.odooEm.search<CrmTag>(new CrmTag(), [['name', '=', tag]]))
    if (!ts.length) {
      return alert("Il tag " + tag + " non esiste")
    }
    await firstValueFrom(this.odooEm.update(this.lead, {tag_ids: [ts[0].id]}))

    await this.loadDeal()
    this.loading = false
  }

  async attachNewOffer() {
    if (this.offersPart){
      await this.attachNewSale(this.offersPart)
    }
    else {
      this.loading = true
      var p = {
        lead_id: this.lead.id,
        name: 'Preventivi',
      }
      this.offersPart = await firstValueFrom(this.odooEm.create<CrmLeadPart>(new CrmLeadPart(), p))
    
      await this.attachNewSale(this.offersPart)
      this.loading = false
    }
     // Mantieni la sezione dei preventivi aperta
     this.toggleResolvePart(this.offersPart);
  }


  async attachNewSale(p:CrmLeadPart) {
    let ss = await this.odooRPCService.getSessionInfo()
    

    this.loading = true
    var sale = {
      opportunity_id: this.lead.id,
      partner_id: this.lead.partner_id.id,
      picking_policy : "direct",
      ga_address: this.lead.street,
      pricelist_id: ODOO_IDS.pricelist_commessa,
      user_id: ss.result.user_id[0]
    }
    var s = await this.odooEm.create<SaleOrder>(new SaleOrder(), sale).toPromise()

    // TODO lead_part_id in order ? 
    var ids = p.sale_order_ids.ids.concat(s.id)
    await this.updatePart(p, "sale_order_ids", ids)
    
    this.loading = false
  }


  async toggleResolvePart(p:CrmLeadPart) {
    if (!p)
      return
    this.loading = true
    
    if (p.sale_order_ids?.values) {
      p.sale_order_ids.values = null
    } else {
      await this.resolvePart(p)
      // let c = {...p}
      // await firstValueFrom( this.odooEm.resolve(c.sale_order_ids))
      // await firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new MailActivity(), c.sale_order_ids.values, "activity_ids"))
      // await firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new DriveFolder(), c.sale_order_ids.values, "drive_folder_ids"))
      // await firstValueFrom(this.odooEm.resolveArray<SaleOrder>(new TrelloCardEntry2(), c.sale_order_ids.values, "trello_card_ids"))
      // p.sale_order_ids.values = c.sale_order_ids.values
      // console.log("searrrr")
      // this.changeDetector.detectChanges()
      // this.odooEm.search<MailActivity>(
      //   new MailActivity(), 
      //   [['res_id', "in", p.sale_order_ids.ids], ['res_model', '=', 'sale.order']]
      // ).subscribe(x => {
        
      //   console.log("ACTIVITIES ", x)
      //   this.activities = x
      //   this.activities.forEach(a => {
      //     // find sale order
      //     console.log("RES ID ", a.res_id)
      //     var s = p.sale_order_ids.values.find(s => s.id == a.res_id)
      //     if (s)
      //       s._activity = a
      //     else 
      //       s._activity = null
      //   })
      // })

    }
    this.loading = false
    
  }

  async attachProduzione() {
    if (!this.cfg.production_product_id) {
      alert("Nessun prodotto da produrre")
      return
    }
    // hack to force close
    document.querySelectorAll(".dropdown-menu.show").forEach(s => {
      s.classList.remove("show")
    })

    this.loading = true
    // if (this.lead.stage_id)

    var existingsale: SaleOrder = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', this.lead.id]]).toPromise())[0]
    if (!existingsale) {
      var saletmpl = {
        // name: "F" + this.lead.name ,
        opportunity_id: this.lead.id,
        partner_id: this.lead.partner_id.id,
        pricelist_id: 1
      }
      existingsale = await this.odooEm.create<SaleOrder>(new SaleOrder(), saletmpl).toPromise()
      existingsale = (await this.odooEm.search<SaleOrder>(new SaleOrder(), [['opportunity_id', '=', this.lead.id]]).toPromise())[0]
    }

    var stockmove ={
      "name": "P" + this.lead.tracking_code,
      "product_id": this.cfg.production_product_id,
      "product_uom_qty": 1,
      "product_uom": 1,
      "location_id": 15,
      "location_dest_id": 8,
      "origin": "P" + this.lead.tracking_code,
      "production_id" : null
    }

    var productiontmpl = {
      product_id: this.cfg.production_product_id,
      product_qty: 1,
      product_uom_id: 1,
      bom_id: false,
      picking_type_id: 21,
      location_src_id: 8,
      location_dest_id: 8,
      origin: existingsale.name,
      title: this.newProductionName
    }

    
    var p = await this.odooEm.create<MrpProduction>(new MrpProduction(), productiontmpl).pipe(first()).toPromise()

    stockmove.production_id = p.id
    var s = await this.odooEm.create<StockMove>(new StockMove(), stockmove).pipe(first()).toPromise()
    await this.loadDeal()
    this.loading = false
  }

  close() {
    this.router.navigate(["leads"])
  }

  updateDealToOdoo(field) {

    return new Promise<void>(async (res, rej) => {
      this.loading = true;

      var value = this.lead[field];

      // Set value ad number for field stage_id and stato_lavoro otherwhise the field value would be a string
      if (field == 'stage_id')
        value = parseInt(this.lead[field].id)

      if (Array.isArray(value) && field != 'settori')
        value = value[0]

      var fields = {}
      fields[field] = value

      await this.odooEm.update(this.lead, fields).toPromise()
      this.loading = false;
      res()
    })
  }

  async attachPurchase() {
    this.loading = true
    if (this.lead.stage_id)

      var o = await this.odooEm.create<PurchaseOrder>(new PurchaseOrder(), { name: this.newPurchaseName ? this.newPurchaseName : "", opportunity_id: this.lead.id, partner_id: this.contactForPurchase.id }).pipe(first()).toPromise()
    // move to production stage
    // await this.odooEm.update(this.lead, {stage_id: 12})
    this.loadDeal()
    this.loading = false
  }


  async loadPurchases() {
    return
    // hotfix
    // var f = this.lead.order_ids.ids.filter(x => x != null)
    // var criteria = [
    //   [ 'order_id', 'in', f]
    // ]
    // var plines:SaleOrderLine[] = await this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), criteria).pipe(first()).toPromise()
    // this.purchaseOrdersIds = []
    // plines.forEach(l => {
    //   if (l.purchase_id && !this.purchaseOrdersIds.includes(l.purchase_id.id))
    //     this.purchaseOrdersIds.push(l.purchase_id.id)
    // })

    // try {
    //   var pids = this.purchaseOrdersIds.filter(x => x != null)

    //   var criteria2 = [
    //     ['opportunity_id',"=", this.lead.id.toString(),"integer"],
    //     ['state',"!=", "cancel"]
    //   ]
    //   this.purchaseOrders = await this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), criteria2).toPromise()
    // } catch(e) {
    //   alert("Errore -" + e)
    // }

  }



  // Ricerca preventivi associati al deal
  // async searchAssociatedOffers() {
  //   this.offers = await this.restapi.search2("sale.order", [{column: "opportunity_id", operator : "=", value : this.deal.id}])
  //   console.log("OFFERS ", this.offers)
  // }
  redirectOffer(id) {
    window.open('/offers/' + id, '_blank')
  }

  async onContact(c: Contact) {
    this.loading = true
    await this.odooEm.update(this.lead, { 'partner_id': c.id }).toPromise()
    this.lead.partner_id.id = c.id
    this.lead.partner_id.name = c.name
    this.loading = false
  }

  pickContact(field) {
    var w = window.open('/contact?mode=embedded', '_blank', 'height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes');

    var h = event => {
      var d = JSON.parse(event.data)
      this.lead[field] = [d.id, d.name]
      this.updateDealToOdoo(field)
      w.close()
    }
    window.addEventListener("message", h, false);

    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

  pickedOut(s:SaleOrder) {
    //cerca se ci sono trasferimenti in uscita completati
  let picked = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => m.location_dest_id.id == ODOO_IDS.stock_location_sent && m.state == 'done')
   console.log("PICKED ", picked)  
   if (picked)
    return true
   else 
    return false
  }

  orderArrived(s:SaleOrder) {
    // controlla tra i trasferimenti se ce n'è uno di tipo ricevuto completato
    let arrived = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => m.location_id.id == ODOO_IDS.stock_location_vendor && m.state == 'done')
    console.log("ARRIVED ", arrived)
    if (arrived)
      return true
    else
      return false
}

orderPrepared(s:SaleOrder) {
  // controlla tra i trasferimenti se ce ne sono completati che non vengono da customer e non vanno a vendor
  let prepared = s.procurement_group_id.value?.stock_move_ids?.values?.find(m => (m.state == 'done' && m.location_id.id != ODOO_IDS.stock_location_vendor) && (m.state == 'done' && m.location_dest_id.id != ODOO_IDS.stock_location_customer))
  console.log("PREPARED ", prepared)  
  if (prepared)
    return true
  else
    return false
}

getOrderState(s:SaleOrder) {
  //determino lo stato dell'ordine con appoggio alle funzioni sopra

  let c = ""
  console.log("CHECKING STATE ORDER ", s.name)

  if (s.state == 'sale' && (s.delivery_status == 'partial' || s.delivery_status == 'pending') && this.pickedOut(s)==false && this.orderArrived(s)==false && this.orderPrepared(s)==false)
    c = "Confermato"
  if ((s.state == 'sale' && s.delivery_status == 'full') || (s.state == 'sale' && !s.delivery_status))
    c = "Spedito interamente"
  


  if (s.state == 'sale' && s.delivery_status == 'partial' && !this.pickedOut(s) && this.orderArrived(s) && !this.orderPrepared(s))
    c = "Confermato - acquisti arrivati"
  if (s.state == 'sale' && s.delivery_status == 'partial' && !this.pickedOut(s) && this.orderPrepared(s))
    c = "Preparato - non spedito"
  if (s.state == 'sale' && s.delivery_status == 'partial' && this.pickedOut(s))
    c = "Spedito parzialmente"
  if (s.state == 'draft')
    c = "Bozza"
  if (s.state == 'cancel')
    c = "Annullato"
  console.log("DELI STATE ",s.name, c)



  return c
  
}

  // computeChildrenSales() {
    
  //   var ffs = this.getFirstSales()
  //   ffs.forEach(fs => {  
  //     fs._sales = this.sales.filter(s => {        
  //       return (s.origin == fs.name) || (!fs.id && !this.isFirstSale(s) && !s.origin)
  //     })
  //   })
    
    
  // }



  // PRIMANOTA
  // isFirstSale(s:SaleOrder) {
  //   return s.tag_ids.values?.filter(t => t.name == 'PRIMANOTA').length
  // } 


  // getFirstSales() {
  //   return this.sales.filter(x => !x.origin)
  // }

  getDirectSales() {
    return this.sales.filter(x => x.origin)
  }


  groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      if (!x[key]) {
        x[key] = x.name
      }
      (rv[x[key]] = rv[x[key]] || []).push(x);
      
      return rv;
    }, {});
  };
  
}
