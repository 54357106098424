import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-scanner-zxing',
  templateUrl: './scanner-zxing.component.html',
  styleUrls: ['./scanner-zxing.component.scss']
})
export class ScannerZxingComponent implements OnInit, AfterViewInit, OnDestroy{
 

  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  result:any
  width: number;
  height: number;
  @Input() format = "DataMatrix"
  @Output() onCode:EventEmitter<string|null> = new EventEmitter<string|null>()
  zxing: any;
  stream: MediaStream;
  private _destroyed: boolean = false;
  persistTimeout: any;

  async ngOnInit(): Promise<void> {
    // this.onCode.emit("123456")
  }

  async ngAfterViewInit(): Promise<void> {

      
    const ctx = this.canvas.nativeElement.getContext("2d", { willReadFrequently: true });
    // const video = document.createElement("video");
    
    // video.setAttribute("id", "video");
    //     video.setAttribute("width", this.canvas.nativeElement.width);
    //     video.setAttribute("height", this.canvas.nativeElement.height);
    //     video.setAttribute("autoplay", "");
    
    
         const readBarcodeFromCanvas = (canvas, format, mode) => {
          // if (this._destroyed)
          // return
          var imgWidth = this.width;
          var imgHeight = this.height;
          var imageData = canvas.nativeElement.getContext('2d').getImageData(0, 0, this.width, this.height);
          var sourceBuffer = imageData.data;
    
          if (this.zxing != null) {
            var buffer = this.zxing._malloc(sourceBuffer.byteLength);
            this.zxing.HEAPU8.set(sourceBuffer, buffer);
    
            var result = this.zxing.readBarcodeFromPixmap(buffer, imgWidth, imgHeight, mode, format);
            this.zxing._free(buffer);
            return result;
          } else {
            return { error: "ZXing not yet initialized" };
          }
        }
    
        var constraints = {
          audio: false,
          video: {
             facingMode: "environment",
              width: { ideal: 1280 },
              height: { ideal: 1024 },
          }
      };
    
        // // enumerate devices and select the first camera (mostly the back one)
        // navigator.mediaDevices.enumerateDevices().then(function(devices) {
        //   for (var i = 0; i !== devices.length; ++i) {
        //       if (devices[i].kind === 'videoinput') {
        //           console.log('Camera found: ', devices[i].label || 'label not found', devices[i].deviceId || 'id no found');
        //           // constraints.deviceId = { exact: devices[i].deviceId }
        //       }
        //   }
        // });

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then( (stream) => {
              this.stream = stream
              let settings = stream.getVideoTracks()[0].getSettings();

              this.width = settings.width;
              this.height = settings.height;


              this.video.nativeElement.srcObject = stream;
              this.video.nativeElement.setAttribute("playsinline", "true"); // required to tell iOS safari we don't want fullscreen
              this.video.nativeElement.play();
              processFrame();
            })
            .catch(function (error) {
              console.error("Error accessing camera:", error);
            });
    
    
    
            const processFrame = () => {

                ctx.drawImage(this.video.nativeElement, 0, 0, this.width,this.height);

              
                const code = readBarcodeFromCanvas(this.canvas, this.format, true);
                console.log("PROCESS FR" , code)


                ctx.beginPath();
                ctx.moveTo(this.width / 2, (this.height / 2) - 10);
                ctx.lineTo(this.width / 2, (this.height / 2) + 10);
                ctx.moveTo(this.width / 2 - 10, this.height / 2);
                ctx.lineTo(this.width / 2 + 10, this.height / 2);
                ctx.strokeStyle = "red";
                ctx.lineWidth = 5;
                ctx.stroke();

                if (code.text) {
                  console.log(code.format + ": " + code.text)
                  this.result = code
                  if (this.persistTimeout)
                    clearTimeout(this.persistTimeout)
                  this.persistTimeout = window.setTimeout(x => {
                    this.result = null
                  },3 * 1000)


                  // const { position } = results.get(i);
                  // Draw outline square

                  console.log("path", code)

                  ctx.beginPath();
                  ctx.moveTo(code.position.topLeft.x, code.position.topLeft.y);
                  ctx.lineTo(code.position.topRight.x, code.position.topRight.y);
                  ctx.lineTo(code.position.bottomRight.x, code.position.bottomRight.y);
                  ctx.lineTo(code.position.bottomLeft.x, code.position.bottomLeft.y);
                  ctx.closePath();
                  ctx.strokeStyle = "red";
                  ctx.lineWidth = 5;
                  ctx.stroke();
            
                  console.log("pathend", code)
                } else {
                  // this.result = null
                  // resultElement.innerText = "No barcode found";
                }
                // console.log("format",code)
                if (!this._destroyed)
                  setTimeout(x => {
                    
                    requestAnimationFrame(processFrame);
                  },50)
            };
    
    
    
    // this.video.nativeElement.addEventListener( "loadedmetadata", (e:any) => {
    //   console.log("DATA ",e.videoWidth)
    //   var width = e.videoWidth;
    //   //     height = this.video.nativeElement.width;
    // }, false );
    
        // import zxing from ''
        // var zxing;
          await (window as any).ZXing().then((instance) => {
            this.zxing = instance
            // zxing = instance; // this line is supposedly not required but with current emsdk it is :-/
            processFrame();
          });
    
          // var c = 
          // try {
          //     window['BarcodeDetector'].getSupportedFormats()
          // } catch {
          //     window['BarcodeDetector'] = barcodeDetectorPolyfill.BarcodeDetectorPolyfill
          // }
      

  }

  async selectCode(c:string) {
    this.onCode.emit(c)

  }

  ngOnDestroy(): void {
    this._destroyed = true
    this.stream.getTracks().forEach(function(track) {
      track.stop();
    });
  }


}
