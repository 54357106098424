<style>
  .face {
    backface-visibility: hidden;
  }

  .back {
    transform: rotateY(0deg);
  }

  .front {
    transform: rotateY(180deg);
  }

  #tableResult {
    max-height: 100%;
    /* adesso dovrebbe riferirsi all'altezza di .card-body */
    overflow-y: auto;
  }

  .card {
    height: 100%;
    /* o un'altezza fissa, ad es. 500px, a seconda delle tue esigenze */
    display: flex;
    flex-direction: column;
    /* permette ai figli di riempire lo spazio disponibile verticalmente */
  }

  .card-body {
    flex-grow: 1;
    /* permette al .card-body di riempire lo spazio disponibile */
    overflow: auto;
  }
</style>

<div class="card" #myCard [cdkDragBoundary]="parentRef" mwlResizable cdkDrag (cdkDragEnded)="onDragEnd($event)"
  (resizeEnd)="onResizeEnd($event, divCard)" #divCard>


  <div class="card-header cdkDragHandle d-flex justify-content-end">


    <!-- <svg cdkDragHandle width="24px" class="me-auto" fill="currentColor" viewBox="0 0 24 24">
      <path
        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
      </path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
 -->

<!-- 

    <i class="fa fa-edit me-2 mt-1" (click)="flip()"></i>
    <i class="fa fa-trash me-2 mt-1" (click)="delete()"></i> -->



    <div class="resize-handle-top" mwlResizeHandle [resizeEdges]="{ top: true }"></div>
    <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
    <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
    <div class="resize-handle-bottom" mwlResizeHandle [resizeEdges]="{ bottom: true }"></div>

    <!-- Corner handles -->
    <div class="resize-handle-top-left" mwlResizeHandle [resizeEdges]="{ top: true, left: true }"></div>
    <div class="resize-handle-top-right" mwlResizeHandle [resizeEdges]="{ top: true, right: true }"></div>
    <div class="resize-handle-bottom-left" mwlResizeHandle [resizeEdges]="{ bottom: true, left: true }"></div>
    <div class="resize-handle-bottom-right" mwlResizeHandle [resizeEdges]="{ bottom: true, right: true }"></div>


  </div>

  <div class="card-body align-middle">

    <div class="back face" [@cardFlip]="data.state">

      <app-action-search *ngIf="action.type == 'search'" [state]="'canvas'" (onDelete)="delete(a)"
        [action]="action"></app-action-search>
      <app-action-plot *ngIf="action.type == 'plot'" [state]="'canvas'" (onDelete)="delete(a)"
        [action]="action"></app-action-plot>
        
    </div>
        <!-- <div class="card-footer d-flex justify-content-end p-3"> -->
      <!-- <div>
          <span class="badge bg-black">{{action.parsed?.model}} </span>
          <span class="badge bg-black">{{action.parsed?.domain}} </span>
          <span class="badge bg-black">{{action.parsed?.fields}} </span>
        </div> -->
      <!-- <button class="btn btn-sm" (click)="refresh()"><i class="fa fa-refresh"></i></button>
      </div>

      <div class="card-body p-0"> -->


      <!-- *ngIf="a.type == 'search'"  -->

      <!-- 
        <div *ngIf="action.error">
          Error {{action.error}}
        </div>
        <div *ngIf="action.error">
          {{a.debugQuery}}

        </div>
        <div *ngIf="action.error">
          {{a.debug}}

 
        </div> -->
      <!-- <table *ngIf="action.results" id="tableResult" class="table flush mb-0">
          <thead class="">
            <tr>
              <th *ngFor="let item of action?.results[0] | keyvalue">
                {{item.key}}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of action.results">
              <td *ngFor="let item of r | keyvalue">
                {{item.value}}
              </td>
            </tr>
          </tbody>
        </table> -->
    </div>
  </div>


  <!-- <div class="front face" [@cardFlip]="data.state == 'default' ? 'flipped' : 'default'">


      <div class="card-footer d-flex justify-content-end p-3">

        <button class="btn btn-success" [disabled]="action.busy" (click)="execute()">
          <i class="fa fa-table text-white"></i>
        </button>
      </div>

      <form class="w-100">
        <div class="row">
          <div class="col-4">
            <label class="form-label text-muted">Modello</label>
            <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="action.params[0].value">
          </div>
          <div class="col-8">
            <label class="form-label text-muted">Criteri</label>
            <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="action.params[1].value">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label text-muted">Colonne</label>
            <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="action.params[2].value">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <label class="form-label text-muted">Raggruppa per</label>
            <input class="form-control" [ngModelOptions]="{standalone: true}" [(ngModel)]="action.params[3].value">
          </div>
        </div>
      </form>
    </div> -->
