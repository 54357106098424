<div class="h-100 d-flex align-items-center justify-content-center">

  <img 
    class="img-fluid" 
    src="/assets/Galimberti-social-logo.png"
    style="max-height: 250px;"
  >

</div>


<div class="navbar bg-dark d-flex text-white justify-content-center py-4 px-2">
  <p *ngIf="result && result.error">{{result.error.message}}</p>
  <p *ngIf="result && !result.error" class="lead mb-0  text-white">Avvicina la carta al lettore</p>


  <i class="fa fa-user ms-auto" routerLink="/badge/admin"  ></i>
</div>