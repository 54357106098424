import { Component, OnInit } from '@angular/core';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
// import * as joint from 'jointjs';

@Component({
  selector: 'app-explorer',
  templateUrl: './explorer.component.html',
  styleUrls: ['./explorer.component.scss']
})
export class ExplorerComponent implements OnInit {

  constructor(private em: OdooEntityManager) { }

  ngOnInit() {

    // let graph = new joint.dia.Graph;

    // this.em.getSalesOrder(1).then(salesOrder => {
    //   let graph = new joint.dia.Graph;
    //   let paper = new joint.dia.Paper({
    //     el: document.getElementById('myholder'),
    //     model: graph,
    //     width: 1000,
    //     height: 600,
    //     gridSize: 1
    //   });

      // let orderElement = new joint.shapes.standard.Rectangle();
      // orderElement.position(100, 30);
      // orderElement.resize(100, 40);
      // orderElement.attr({
      //   body: {
      //     fill: 'blue'
      //   },
      //   label: {
      //     text: "salesOrder['name']",
      //     fill: 'white'
      //   }
      // });
      // orderElement.addTo(graph);
    // });
  }
}


