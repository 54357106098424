import { AfterViewInit, Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { ProductPackaging } from 'src/app/models/product.packaging.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockMoveLine } from 'src/app/models/stock-move-line';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import Decimal from 'decimal.js';
import { StockPickingType } from 'src/app/models/stock-picking-type.model';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { StockBackorderConfirmation, StockBackorderConfirmationLine } from 'src/app/models/stock.backorder.confirmation.model';
import { StockQuant } from 'src/app/models/stock-quant';
import { StockPackageLevel } from 'src/app/models/stock-package-level';
import { SaleOrder } from 'src/app/models/sale-order.model';



@Pipe({ name: 'sortBy2' })
export class SortByPipe2 implements PipeTransform {
  transform(value: StockMoveLine[], order ): any[] {
    console.log("ORDER222 ", order, value)
    return value.sort((a, b) => {
      return a.product_id.id - b.product_id.id
    })
    .sort((a, b) => {
      // if (a.product_id.value.product_template_variant_value_ids.values)
      //   return Number(a.product_id.value.product_template_variant_value_ids.values[2].name) - Number(b.product_id.value.product_template_variant_value_ids.values[2].name)
      return 1
    })
    // return orderBy(value, ['product_id', 'product_id.value.product_template_variant_value_ids.values[0].name'], ['asc']);
  }
}

@Component({
  selector: 'app-picking-internal',
  templateUrl: './picking-internal.component.html'
})
export class PickingInternalComponent implements OnInit, AfterViewInit {

  picking: StockPicking;
  id: any;
  loading: boolean;
  groups: any;
  activePackage:StockQuantPackage
  // moveLines: StockMoveLine[];
  packagesGroup: {};
  scanningBarcode:boolean = false
  masterLine: StockMoveLine;
  descriptiveArray: any[];
  original: any;
  addingToPackage:any;
  movesGroup: {};
  packages: StockQuantPackage[];
  loadingMove: StockMove;
  offCanvas: any;
  @ViewChild('offcanvas') offCanvasElement: ElementRef<HTMLDivElement>;
  pickingTypes: StockPickingType[];
  scanningTo: StockMoveLine[];
  scanningToShouldCreate: boolean = true;
  scanningFrom: StockMoveLine[];
  addingFrom: StockMoveLine;
  addingTo: StockMoveLine;
  backroute: any = "../.." ;

  constructor(
    private odooEM:OdooEntityManager,
    private route:ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    // this.offCanvas = new bootstrap.Offcanvas(this.offCanvasElement.nativeElement)
  }

  async ngOnInit(): Promise<void> {
    // load available picking types
    this.pickingTypes = await firstValueFrom(this.odooEM.search<StockPickingType>(new StockPickingType()))
    //load the sale order originating the picking using the field sale_id

   



    this.route.params.subscribe(async params => {
      this.loading = true;
      this.id = params['picking_id'];
      if (this.route.snapshot.queryParamMap.get('back')) {
        this.backroute = this.route.snapshot.queryParamMap.get('back')
      }
      await this.load()
      this.loading = false
    })
  }

  async addToExistingPackByName(packName:string) {
    await this.addToExistingPack(this.packages.find(p => p?.name == packName))
  
  }

  async addToExistingPack(pack:StockQuantPackage, ) {

    this.addingToPackage = pack
    this.loading = false
    // this.showOffcanvas()   

  }

  async addLineTo(move:StockMove) {
    // new bootstrap.Offcanvas('#offc').hide()
    // this.loadingMove = move 
    this.offCanvas.hide()
    if (!this.addingToPackage)
      return
    this.loading = true
    // create if new
    
    // todo late
    // if (!this.addingToPackage.id) {
    //  let r = await firstValueFrom(this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
    //     name: this.addingToPackage.name
    //   }))
    //   this.addingToPackage.id = r.id
    // }

    
    if (move.product_uom_qty - move.quantity_done > 0) {

      // crate new temp move line
      let ml = new StockMoveLine() 
      ml.id = -1
      ml.product_id.id = move.product_id.id
      ml.product_id.value = move.product_id.value
      ml.qty_done= move.product_uom_qty - move.quantity_done
      ml.move_id.id = move.id
      ml.move_id.value = move
      ml.result_package_id = this.addingToPackage
      ml.result_package_id.id = this.addingToPackage.id
      ml.result_package_id.value = this.addingToPackage
      ml.picking_id.id = move.picking_id.id

      // product_id: move.product_id.id,
        // qty_done: move.product_uom_qty - move.quantity_done,
        // move_id: move.id,
        // result_package_id: this.addingToPackage.id, 
        // picking_id: move.picking_id.id,
      
      // await firstValueFrom (this.odooEM.create(new StockMoveLine(), {
      //   product_id: move.product_id.id,
      //   qty_done: move.product_uom_qty - move.quantity_done,
      //   move_id: move.id,
      //   result_package_id: this.addingToPackage.id, 
      //   picking_id: move.picking_id.id,
      // }))
      this.onLine(ml)


    } else {
      alert("Riga gia' completata")
    }

    // await this.load()

    // this.addToExistingPack(this.addingToPackage)
    // this.loadingMove = null
    this.loading = false
  }


  isMultiLine(move:StockMove, moveLine:StockMoveLine) {
    console.log("moe", move, moveLine)
    let r = move.move_line_ids.ids.length

    if (move.move_line_ids.ids.length > 1) {
      let i = move.move_line_ids.values.indexOf(moveLine)
      move.move_line_ids.values.forEach((m, j) => {
        if (m.product_id.id == moveLine.product_id.id && i > j) {
          console.log("IIIII ", move.move_line_ids.values,i,j)
          r = 0
        }
      })
    }
    return r
  }

  setSelectedAllLines(v:boolean) {
    let r  = []
    if (!this.picking)
      return []
    this.picking.move_ids.values.forEach(m => {
      m.move_line_ids.values.forEach(l => {
        l._checked = v
      })
    })
    return r
  }

  getSelectedLines(): any {
    let r  = []
    if (!this.picking)
      return []
    this.picking.move_ids.values.forEach(m => {
      m.move_line_ids.values.forEach(l => {
        if (l._checked) {
          r.push(l)
        }
      })
    })
    return r
  } 

  async searchPackage(code):Promise<StockQuantPackage | null> {
    let p = await firstValueFrom(this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [['name', '=like',  code + '%']]))
    if (p.length == 0) {
      // if not found create with id -1. we'll save it later
      // let p = new StockQuantPackage() 
      // p.id = -1
      // p.name = code
      return null
    }
    return p[0]
  }


  // async addLine(m:StockMove) {

  //   console.log("ADD LINE", m)

  //   await firstValueFrom(this.odooEM.create(new StockMoveLine(), {
  //     'move_id' : m.id,
  //     'product_id': m.product_id.id,
  //     'reserved_uom_qty': 0,
  //     'qty_done': 0,
  //     'picking_id': this.picking.id,
  //   }))

  //   this.load()
  // }
  

  addFrom(ms:StockMoveLine) {
    this.addingFrom = ms
  }

  addTo(ms:StockMoveLine) {
    this.addingTo = ms
  }

  changeFrom(ms:StockMoveLine) {
    this.scanningFrom = [ms]
  }

  changeTo(ms:StockMoveLine) {
    this.scanningToShouldCreate = true
    this.scanningTo = [ms]
  }

  async removeTo(ms:StockMoveLine) {
    if (!confirm("Confermi eliminazione ?"))
      return
  
    this.loading = true

  // check for multi line selection
    let toRemove = this.getSelectedLines()
    if (toRemove.length == 0) 
      toRemove = [ms]

    await firstValueFrom(this.odooEM.updateMulti(new StockMoveLine(), toRemove, {"result_package_id": null}))
    await this.load()
    this.loading = false
  }

  changeToMulti(ms:StockMoveLine[]) {
    this.scanningToShouldCreate = true
    this.scanningTo = ms
  }


  newFrom(m:StockMove) {
    let ml = new StockMoveLine()
    ml.id = -1
    ml.qty_done = 0
    ml.product_id.id = m.product_id.id
    ml.product_id.value = m.product_id.value
    ml.move_id.id = m.id
    ml.move_id.value = m
    ml.picking_id.id = m.picking_id.id
    this.addingFrom = ml
  }


  async onAddFrom(code:string) {
    let from = this.addingFrom

    // this.addingFrom = null

    if (!code)
      return this.addingFrom = null

    this.loading = true

    if (code) {
      let p = await this.searchPackage(code)
      
      if (p && p.id) {
        let m = await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
          'move_id': from.move_id.id,
          'product_id': from.product_id.id,
          'package_id': p.id,
          'location_id': p.location_id.id,
          'picking_id': from.picking_id.id
        }))
        m.product_id.id = from.product_id.id
        m.product_id.value = from.product_id.value
        m.package_id.id = p.id
        m.package_id.value = p
        m.package_id.name = p.name
        m.move_id.id = from.move_id.id
        m.move_id.value = from.move_id.value

        await this.updateQtyDoneWithCheck(m, p)

        this.onLine(m)

      } else {
        alert("Collo non trovato")
      }

      this.addingFrom = null
      await this.load()
      this.loading = false
    }
  } 

  async onFrom(code:string) {

    if (!code)
      return this.scanningFrom = null

    this.loading = true

    if (code) {
      let p = await this.searchPackage(code)
      if (p && p.id) {
        // await firstValueFrom(this.odooEM.update(this.scanningFrom[0], {
        //   package_id:p.id
        // }))
        this.scanningFrom[0].package_id.id = p.id
        this.scanningFrom[0].package_id.name = p.name
        this.scanningFrom[0].package_id.value = p

        await this.updateQtyDoneWithCheck(this.scanningFrom[0], p)
        
        this.onLine(this.scanningFrom[0])



      } else {
        alert("Collo non trovato")
      }


      this.scanningFrom = null
      // await this.load()
      this.loading = false
    }
  }


  async updateQtyDoneWithCheck(s:StockMoveLine, p:StockQuantPackage) {

    if (!p) return s.qty_done = s.move_id.value.product_uom_qty
    
    await firstValueFrom(this.odooEM.resolve(p.quant_ids))

    // check the qty we have in the package
    let qty = 0
    p.quant_ids.values.forEach(q => {
      if (q.product_id.id == s.product_id.id) {
        qty = qty + q.available_quantity
      }
    })

    if (s.reserved_uom_qty)
      qty = qty + s.reserved_uom_qty

    let q = Math.min(s.move_id.value.product_uom_qty, qty)
    s.qty_done = q
  }






  async onAddTo(code:string) {
    let to:StockMoveLine = this.addingTo

    // this.addingFrom = null

    if (!code)
      return this.addingTo = null

    this.loading = true

    if (code) {
      let p = await this.searchPackage(code)
      
      if (!p) {
        p = await firstValueFrom(this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
          name: code
        }))
      }

      
      if (p && p.id) {

        await firstValueFrom(this.odooEM.create(new StockMoveLine(), {
          'move_id': to.move_id.id,
          'product_id': to.product_id.id,
          'package_id': to.package_id.id,
          'result_package_id': p.id,
          'location_id': p.location_id.id,
          'picking_id': to.picking_id.id
        }))

      } else {
        alert("Collo non trovato")
      }

      this.addingTo = null
      await this.load()
      this.loading = false
    }
  } 

  async onTo(code:string) {

    if (!code)
      return this.scanningTo = null

    this.loading = true
    
    if (code) {
      // check for multi line selection
      let sl = this.getSelectedLines()
      if (sl.length > 0) 
        this.scanningTo = sl

      console.log("xxx",this.scanningTo)

      let p = await this.searchPackage(code);

      if (!p && this.scanningToShouldCreate) {
        p = await firstValueFrom(this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
          name: code,
        }));
      }

      // Update package for all non-consumable products
      for (let line of this.scanningTo) {
        if (this.getProductType(line.move_id.value) !== 'consu') {
          if (p) {
            await this.odooEM.update<StockMoveLine>(line, {result_package_id: p.id});
          } else {
            // If no package was found or created, just update the name
            if (line.result_package_id.value) {
              await this.odooEM.update<StockQuantPackage>(line.result_package_id.value, {name: code});
            } else {
              // If there's no result_package_id, create a new one
              const newPackage = await firstValueFrom(this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
                name: code,
              }));
              await this.odooEM.update<StockMoveLine>(line, {result_package_id: newPackage.id});
            }
          }
        }
      }

      this.scanningTo = null;
      await this.load();
    }
    this.loading = false;
  }



  async togglePack(p:StockPackageLevel) {
    this.loading = true
    await firstValueFrom(this.odooEM.update<StockPackageLevel>(p, {
      is_done: !p.is_done
    }))

    await this.load()
    this.loading = false
  }


  async updateDescriptiveOr(master, d, value) {
    d[0] = value
    let uom_qty = value * d[1].qty 

    console.log("d",d, uom_qty)
    await this.updateMaster(master, "qty_done", uom_qty)
  }

  async load() {
    var ps = await this.odooEM.search<StockPicking>(new StockPicking(),[["id","=",this.id]]).toPromise()
    await firstValueFrom(this.odooEM.resolve(ps[0].package_level_ids))
  

    if (ps.length == 0)
      throw "Error"

    await firstValueFrom(this.odooEM.resolve(ps[0].move_ids))

    // resolve sale_id
    await firstValueFrom(this.odooEM.resolveSingle(new SaleOrder(), ps[0].sale_id))
    console.log("SALE", ps[0].sale_id.value)
    


    await this.odooEM.resolveArrayOfSingle(new ProductPackaging(),ps[0].move_ids.values,"product_packaging_id").toPromise();
    await firstValueFrom(this.odooEM.resolveArray(new StockMoveLine(), ps[0].move_ids.values, "move_line_ids"))
    await this.odooEM.resolveArrayOfSingle(new Product(),ps[0].move_ids.values,"product_id").toPromise();
    
    let prods = []
    ps[0].move_ids.values.forEach(m => {
      prods.push(m.product_id.value)
    })
    
    await firstValueFrom(this.odooEM.resolveArray(new ProductPackaging(), prods, "packaging_ids"))
    await firstValueFrom(this.odooEM.resolveArray(new ProductTemplateAttributeValue(), prods, "product_template_variant_value_ids"))
    
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new ProductTemplate(),prods,"product_tmpl_id"));
    
    // this.moves = moves
    let moveLines = []
    ps[0].move_ids.values.forEach(m => {
      m.move_line_ids.values.forEach(l => {
        if (l.package_id.id) 
            moveLines.push(l)
        l.move_id.id = m.id
        l.move_id.value = m
        l.product_id.id = m.product_id.id
        l.product_id.value = m.product_id.value
      })
    })
    
     
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new StockQuantPackage(),moveLines,"package_id"))
    let packages = moveLines.map(l => {
        return l.package_id.value
    })
    await firstValueFrom(this.odooEM.resolveArray(new StockQuant(), packages, "quant_ids"))
   
    this.packagesGroup = this.groupByProductTemplate(ps[0].move_ids.values)

    // this.moveLines = movelines
    this.picking = ps[0];
  }

  groupByPackage(xs, key) {
    return xs.reduce(function(rv, x) {
      if (!x[key]) {
        x[key] = x.name
      }
      (rv[x[key]] = rv[x[key]] || []).push(x);
      
      return rv;
    }, {});
  }

  
  public groupItemBy = function(array, property) {
    var hash = {},
        props = property.split('.');
    for (var i = 0; i < array.length; i++) { 
        var key = props.reduce(function(acc, prop) {
            return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
    }

    Object.keys(hash).forEach((k,v) => {
      hash[k] = hash[k].sort((a,b) => {
        return b.name - a.id
      })
    })

    return hash;
  } 

  getReservedForOthersQtyInPackage(line:StockMoveLine) {
    let qty = 0
    
    line.package_id.value.quant_ids.values.forEach((q) => {
      if (q.product_id.id == line.product_id.id) {
        qty = qty + q.reserved_quantity
      }
    })
    return this.getDescriptive(line.move_id.value, qty)
  }

  getAvailableQtyInPackage(line:StockMoveLine) {
    let qty = 0
    
    line.package_id.value.quant_ids.values.forEach((q) => {
      if (q.product_id.id == line.product_id.id) {
        qty = qty + q.available_quantity
      }
    })

    if (line.reserved_uom_qty) 
      qty = qty + line.reserved_uom_qty
    
    return this.getDescriptive(line.move_id.value, qty)
  }

  getTotalQtyInPackage(line:StockMoveLine) {
    let qty = 0
    
    line.package_id.value.quant_ids.values.forEach((q) => {
      if (q.product_id.id == line.product_id.id) {
        qty = qty + q.quantity
      }
    })
    return this.getDescriptive(line.move_id.value, qty)
  }

  getIconsForLine(l:StockMoveLine) {
    if (!l.package_id.value || !l.package_id.value.quant_ids.values) return

    let qty = 0

    l.package_id.value.quant_ids.values.forEach((q) => {
      if (q.product_id.id == l.product_id.id) {
        qty = qty + q.quantity
      }
    })


    // if (new Decimal(l.qty_done).toPrecision(5) <= new Decimal(qty).toPrecision(5)) {


    if (this.picking.state == 'done' || Number(new Decimal(l.qty_done).toPrecision(5)) <= Number(new Decimal(qty).toPrecision(5))) {
      return 
    } else {
      return ["fa fa-exclamation-triangle fa-lg text-danger"]
    }
  }


  public groupItemsByTwoKeys = function(array, property1, property2) {
    var hash = {};
    for (var i = 0; i < array.length; i++) { 
        // Generate a key by accessing the two properties and concatenating them
        // with a delimiter to ensure uniqueness.
        var key1 = property1.split('.').reduce((acc, prop) => acc && acc[prop], array[i]);
        var key2 = property2.split('.').reduce((acc, prop) => acc && acc[prop], array[i]);
        var compositeKey = `${key1}|${key2}`; // '|' used as a delimiter

        if (!hash[compositeKey]) hash[compositeKey] = [];
        hash[compositeKey].push(array[i]);
    }
    return hash;
}


  getDestinations(ls:StockMoveLine[]) {
    return ls.reduce((a, b) => {
      if (a.indexOf(b.result_package_id.name) === -1) {
        a.push(b.result_package_id.name)
      }
      return a
    }, [])
  }

  getFrom(key) {
    let r = key.split("|")[0]
    
    return r == "undefined" ? "Nessun imballo" : r
  }

  getTo(key) {
    let r = key.split("|")[1]
    return r == "undefined" ? "Nessun imballo" : r
  }

  getNoPackage() {
    if (this.packagesGroup)
      return this.packagesGroup["undefined"]
  }

  groupByProductTemplate = function(xs:StockMove[]) {
    return xs.reduce(function(rv, x) {
      // if (!x.product_tmpl_id.name) {
      //   x[x.product_tmpl_id.name] = x.product_tmpl_id.name 
      // }
        (rv[x.product_id.value.product_tmpl_id.name] = rv[x.product_id.value.product_tmpl_id.name] || []).push(x);
      
      return rv;
    }, {});
  };

  updateMaster(master,prop ,val) {
    this.original = {...master}
    master[prop] = val

    this.descriptiveArray = this.getDescriptiveArrayOr(master)
    this.loading = false
  }


  getDescriptiveTodo(move, line) {
    return this.getDescriptive(move, move.product_uom_qty)
  }

  async assignMoveLine() {

    // console.log("XX",this.masterLine.package_id.value)
    this.original = {...this.masterLine}
    await this.updateQtyDoneWithCheck(this.masterLine, this.masterLine.package_id.value)
    this.descriptiveArray = this.getDescriptiveArrayOr(this.masterLine)

    // await this.updateMaster(this.masterLine, "qty_done", this.masterLine.qty_done)
  }

  getProductType(move:StockMove) {
    if (move.product_id.value.detailed_type)
      return move.product_id.value.detailed_type
  }

  getDescriptive(move:StockMove, q) {

    console.log("MOVE", move)
    if (q == 0) return 0
    if (!move.product_id.value) return
    if (!move.product_id.value.packaging_ids.values.length)    // dont want to show 
      return q +  " " + move.product_id.value.uom_id.name 
    
    console.log("move", move)
    
    var ps = move.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    // var q = line.move_id.value.product_uom_qty
    console.log("PS", ps)
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    var totale = new Decimal(q)

    ps.forEach((p, i) => {

      if (!p.sales || p.name.includes("netti")) 
        return

      if (totale.toNumber() <= 0)
        return 

      let quo;
      //if last
      if (i == ps.length - 1)
        quo = totale.div(p.qty)
      else
        quo = totale.divToInt(p.qty)
      totale = totale.minus(quo.mul(p.qty))
      
      if (quo.toNumber() > 0) {
        if (d && !d.endsWith("\n")) {
          d = d + "\n"
        }
        d = d + "" + Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name 
      }
    })
    
    return d
  }


  cc(cc) {
    console.log("CC",cc)
  }

  getDescriptiveDone(line:StockMoveLine) {
    if (!line)
      return "ww"

    console.log("LINE", line)
    console.log("LINE2", line.move_id.value, line.qty_done)
    return this.getDescriptive(line.move_id.value, line.qty_done)
  }

  getDescriptiveReserved(line:StockMoveLine) {
    if (line.move_id.value.product_packaging_id.id) {
        var t = new Decimal(line.move_id.value.product_uom_qty).div( new Decimal(line.move_id.value.product_packaging_id.value.qty))
        return t + " " + line.move_id.value.product_packaging_id.name
    }
    return line.move_id.value.product_uom_qty +  line.product_id.value.uom_id.name
  }


  async closeWithBackorder() {

    var o = {
      pick_ids : [this.picking.id]
    }
    var bo = await firstValueFrom(this.odooEM.create<StockBackorderConfirmation>(new StockBackorderConfirmation(), o))

    var ol = {
      to_backorder: true,
      backorder_confirmation_id: bo.id,
      picking_id: this.picking.id
    }
    await firstValueFrom(this.odooEM.create(new StockBackorderConfirmationLine(),ol))
    await firstValueFrom(this.odooEM.call(new StockBackorderConfirmation(), 'process', [bo.id], {
      'button_validate_picking_ids': [this.picking.id]
    }))

    this.load()
  }


  
  async confirm() {
    if (!confirm("Confermi il completamento del trasferimento?"))
      
    this.loading = true

    // check every line has no alert
    this.picking.move_ids.values.forEach(m => {
      m.move_line_ids.values.forEach(l => {
        if (this.getIconsForLine(l)?.toString().includes("fa-exclamation-triangle")) {
          alert("Il collo " + l.package_id.value.name + " non contiene la quantità richiesta, \r\n")
          this.loading = false
          return  
        }
      })
    }) 

    let r = await this.check(
      this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[this.picking.id]])
    )
    if (r.result?.res_model == "stock.backorder.confirmation") {
      if (confirm("Vuoi chiudere il trasferimento e creare un backorder per i prodotti mancanti ?") )
        await this.closeWithBackorder()
    }
    await this.load()
    this.loading = false
  }

  async deleteLine(line:StockMoveLine) {
    if (!confirm("Confermi eliminazione ?"))
      return 

    this.loading = true    
    await firstValueFrom(this.odooEM.delete(new StockMoveLine(), [line.id])) 
    this.masterLine = null
    await this.load()
    this.loading = false
  }


  onLine(line:StockMoveLine) {
    this.masterLine = line
    this.descriptiveArray = this.getDescriptiveArrayOr(line)
    console.log("MASTERLINE", this.masterLine)
  }

  // scanBarcode() {
  //   this.scanningBarcode = true
  // }

  // showOffcanvas() {
  //   this.offCanvas.show()
  // }

  // async onScanBarcode(c) {
  //   this.scanningBarcode = false
    
  //   if (!c || this.packagesGroup[c] ) {
  //     return 
  //   }
  
  //   this.loading = true

  //   let r = await firstValueFrom(this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [['name','=',c]]))
  //   console.log("xx1",r )
  //   if (r.length == 0) {
  //     // this.packagesGroup[c] = []
  //     let p =  new StockQuantPackage();
  //     p.id = -1
  //     p.name = c
  //     // this.packages.push(this.addingTo)
  //     this.addToExistingPack(p)
  //   } else {
  //     // this.packagesGroup[c] = []
  //     this.addToExistingPack(r[0])
  //   }
  //   this.loading = false
  // }

  back() {
    if (this.original)
      this.masterLine.qty_done = this.original.qty_done
    this.original = null
    this.masterLine = null

    // reopen offcanvas if we are in the middle of adding to package
    // if (this.addingToPackage) {
    //   this.showOffcanvas()
    // }
  }

  getDescriptiveArrayOr(line:StockMoveLine):any[] {
    if (!line.move_id.value.product_id.value)return []
    
    if (!line.move_id.value.product_id.value.packaging_ids.values) {
      return []
    }
      
    var ps = line.move_id.value.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.qty_done
    
    var a = []

    ps = ps.filter(x => x.sales)
    
    ps.forEach((p, i) => {
        let x = Number.parseFloat(
          new Decimal(q).div(p.qty).toPrecision(5).toString()
          ).toString()
        a.push([x ,p,0])
    })
    return a
   }


   async check(f) {
    let r = await f
    if (r.error) {
      this.loading = false
      alert(r.error.data.message)
      throw r
    }
    return r
  }


   async save() {
    // we need to check for -1 id .. since are temp object to persist
    this.loading = true
    if (this.masterLine.result_package_id.id == -1) {
      let r = await firstValueFrom(this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
        name: this.masterLine.result_package_id.value.name
      }))
      this.masterLine.result_package_id.id = r.id
    }
    
    // now we can save the line
    if (this.masterLine.id == -1) {
      let r = await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
        'qty_done': this.masterLine.qty_done,
        'move_id': this.masterLine.move_id.id,
        'product_id': this.masterLine.product_id.id,
        'location_id': this.masterLine.package_id.value.location_id.id,
        'result_package_id': this.masterLine.result_package_id.id,
        'picking_id': this.masterLine.picking_id.id
      }))
      this.masterLine.id = r.id
    } else {
      await firstValueFrom(this.odooEM.update<StockMoveLine>(this.masterLine, {
        'qty_done': this.masterLine.qty_done,
        'package_id': this.masterLine.package_id.id,
        'location_id': this.masterLine.package_id.value?.location_id.id,
        'result_package_id': this.masterLine.result_package_id.id,

        // 'price_unit' : this.masterLine.price_subtotal / this.masterLine.product_uom_qty
      }))
      this.masterLine = null
    }

    await this.load()
    
    // reopen offcanvas if we are in the middle of adding to package
    // if (this.addingToPackage) {
    //   this.showOffcanvas()
    // }
    this.masterLine = null
    this.loading = false
   }

}
