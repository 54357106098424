import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
@Component({
  selector: 'app-milvus',
  templateUrl: './milvus.component.html',
})
export class MilvusComponent {
  answer: any;

  public loading : boolean  = false
  constructor(private http: HttpClient
  ) { }

  ngOnInit() { }
  inputSearch : string = "Come faccio a ordinare materiale?"

  async search() {

    this.loading = true
    this.answer = ""
    const response: any = await this.http.post('https://o3.galimberti.eu/milvus/search', this.inputSearch).toPromise();
    this.answer = this.textFix(response['response'])
    this.loading = false

  }

  textFix(text) {
    function generateLinks(match, p1, p2) {

      var name = p1.trim();
      var url = p2.trim();
      if (url.indexOf("https://drive.google.com/") == -1) {
        return `<a href="${url}" target="_blank">${name}</a>`;
      } else {
        return `<a href="${url}" target="_blank">${url}</a>`;
      }
    }

    // Trova e sostituisci le ripetizioni di testo con i link generati
    var formattedText = text.replace(/\[(.+?)[:|-]\s+(https?:\/\/\S+)\]/g, generateLinks);
    formattedText = formattedText.split("\n").join("<br/>")
    return formattedText
  }
}
