import { HttpClient } from "@angular/common/http";
import { OdoorpcService } from "../shared/services/odoorpc.service";
import { Action, ActionParam } from "./dashboard.component";


export class ActionOdooSearch implements Action {

  constructor(private http: HttpClient, private odooRPC: OdoorpcService) {}
  busy: boolean = false
  public params = [
    new ActionParam("modello", "clienti"),
    new ActionParam("criteri", "di milano"),
    new ActionParam("campi", "id, nome e quelli principali"),
    new ActionParam("raggruppa", "")
  ]
  name = ""
  parsed: any;

  debug: string;
  results: any[];
  error: any;
  debugQuery: string;


  type: string = 'search';
  previous: Action;
  next: Action;
}

export class ActionPlot implements Action {

  parsed: any;
  debug: any;
  error: string;
  busy: boolean;
  type: string = 'plot';
  results: any[];
  public params = [
    new ActionParam("modello", "clienti"),
    new ActionParam("criteri", "di milano"),
    new ActionParam("campi", "id, nome e quelli principali"),
    new ActionParam("raggruppa", "")
  ]
}
