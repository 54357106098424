import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { Action } from '../dashboard.component';


export interface CardData {
  state: "default" | "flipped" | "matched";
}


@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  animations: [trigger('cardFlip', [
    state('default', style({
      transform: 'rotateY(0deg)'
    })),
    state('flipped', style({
      transform: 'rotateY(180deg)',
      display: 'none'
    })),
    transition('* => *', [
      animate('400ms')
    ])
  ])
  ]
})

export class ActionCardComponent implements OnInit {

  @Output() onDelete: EventEmitter<Action> = new EventEmitter<Action>()
  @Input() parentRef: HTMLElement;
  @Input() action: Action
  @Input() style: any;

  @Output() styleChange = new EventEmitter<any>();
  @ViewChild('myCard') myCard: ElementRef;

  debugQuery: string;
  parsed: any;
  error: any;
  busy: boolean;
  results: any[];
  data: CardData = {
    state: "default",
  };

  constructor(private renderer: Renderer2, private odooRPC: OdoorpcService, private http: HttpClient) { }

  ngOnInit(): void { }

  async ngAfterViewInit() {

    for (let style in this.style) {
      this.renderer.setStyle(this.myCard.nativeElement, style, this.style[style]);
    }
    // await this.refresh()
    // // se risultati vuoti, flippo per invitare a rifare la query
    // if (!this.action.results)
    //   this.flip()

  }

  delete() {
    this.onDelete.next(null)
  }

  flip() {
    if (this.data.state === "default") {
      this.data.state = "flipped";
    } else {
      this.data.state = "default";
    }
  }

  onDragEnd(event: CdkDragEnd) {
    const element = event.source.element.nativeElement;
    var style = element.getBoundingClientRect();
    const outputStyle = {
      position: 'fixed',
      left: `${style.left}px`,
      top: `${style.top}px`,
      width: `${style.width}px`,
      height: `${style.height}px`,
    };
    this.styleChange.emit(outputStyle);
  }

  onResizeEnd(event: ResizeEvent, element: HTMLElement): void {

    element.style.position = 'fixed';
    element.style.left = `${event.rectangle.left}px`;
    element.style.top = `${event.rectangle.top}px`;
    element.style.width = `${event.rectangle.width}px`;
    element.style.height = `${event.rectangle.height}px`;
    this.emitStyle(element);
  }

  emitStyle(element: HTMLElement): void {
    const style = getComputedStyle(element);

    const outputStyle = {
      position: 'fixed',
      left: `${element.offsetLeft}px`,
      top: `${element.offsetTop}px`,
      width: style.width,
      height: style.height
    };

    console.log("outputStyle", outputStyle)
    this.styleChange.emit(outputStyle);
  }


  // async refresh() {

  //   if (this.action.params[3] && this.action.params[3].value != '' ) {
  //     var x = await this.odooRPC.groupByRead({
  //       table: this.action.parsed.model,
  //       criteria: this.action.parsed.domain,
  //       fields: this.action.parsed.fields,
  //       limit: 10
  //     }, this.action.params[3].value )
  //   } else {
  //     var x = await this.odooRPC.searchRead({
  //       table: this.action.parsed.model,
  //       criteria: this.action.parsed.domain,
  //       fields: this.action.parsed.fields,
  //       limit: 20
  //     })
  //   }

  //   console.log("XXXX ", x)

  //   this.action.results = x

  // }

  // async execute() {
  //   this.action.busy = true
  //   let pr = ''
  //   // if (previous) {
  //   //   console.log("prev", previous.results)
  //   //   pr = pr + "il risultato della ricerca precedente sono i seguenti id " + previous.results.map(x => x.id).join(",")
  //   // }

  //   // pr = pr + 'var x = { "model": "purchase.order", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]], "fields": ["id","name"]}'
  //   // pr = pr + 'var x = { "model": "crm.lead", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]] }'

  //   // var x = {
  //   //   "model": "",
  //   //   "domain": [],
  //   //   "fields": [],
  //   //   "group_by": ""
  //   // }              
  //   // modificando le proprietà 'model' e 'domain' e 'fields' e 'group_by' .
  //   // per una query su odoo che usa il modello che rappresenta ${this.action.params[0].value} con questo criteri dominio  ${this.action.params[1].value}.
  //   // il campo "fields" e' un array delle properità degli oggetti ${this.action.params[0].value}  di questo tipo ${this.action.params[2].value}
  //   // rispondi solo con il codice. quando cerchi stringhe usa sempre l'operatore ilike.
  //   // quando si deve inserire un criterio chiamato o od oppure bisogna usare l'operatore "|"
  //   // se si chiede di raggruppare usa la colonna group_by
  //   // l'operatore "|" nella query del dominio va messo prima dei due criteri tra cui scegliere  quindi  "domain": ["|", criterio 1, criterio2]





  //   // pr = pr + 'i principali campi di un res.partner sono id, name, city,street'
  //   pr = pr + `riscrivi questo script json 
  //   var x = {
  //     "model": "",
  //     "domain": [],
  //     "fields": [],
  //     "group_by": ""
  //   }              
  //   con il campo model che il nome della classe che rappresenta ${this.action.params[0].value} in odoo
  //   con il campo "domain" che specifica i criteri di ricerca come nell'interfaccia xmlrpc di odoo secondo questi criteri ${this.action.params[1].value}
  //   il campo "fields" e' un array delle properità degli oggetti ${this.action.params[0].value}  di questo tipo ${this.action.params[2].value} ordinati secondo quanto specificato se specificato    
  //   rispondi solo con il codice. 
  //   quando cerchi stringhe usa sempre l'operatore ilike.
  //   quando si deve inserire un criterio chiamato o od oppure bisogna usare l'operatore "|"
  //   se si chiede di raggruppare usa la colonna group_by
  //   l'operatore "|" nella query del dominio va messo prima dei due criteri tra cui scegliere  quindi  "domain": ["|", criterio 1, criterio2]
  //   `

  //   this.debugQuery = pr
  //   // pr = 'write hello in an heading in an html file'

  //   var response = await this.http.post<any>(
  //     'https://api.openai.com/v1/completions',
  //     {
  //       prompt: [
  //         pr
  //       ],
  //       max_tokens: 150,
  //       n: 1,
  //       stop: null,
  //       temperature: 0.1,
  //       model: "text-davinci-003"
  //       // model: "curie:ft-personal-2023-05-09-19-32-34"
  //     },
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ` + FlowComponent.k
  //       }
  //     }
  //   ).toPromise();
  //   response = response.choices[0].text.trim();
  //   // this.action.debug = response

  //   var q = response.substring(response.indexOf('=') + 1)
  //   if (q.endsWith(";"))
  //     q = q.slice(0, -1)
  //   try {
  //     this.parsed = JSON.parse(q)

  //     console.log("actions ",this.action )

  //     this.action.parsed = this.parsed


  //     // this.parsed.domain.forEach(p => {
  //     //   if (p[1] == '>')
  //     //     p[1] = 'gt;'
  //     // });

  //     // handle | or in odoo
  //     if (this.parsed.domain) {


  //       if (this.action.params[3] && this.action.params[3].value != '' ) {
  //         var x = await this.odooRPC.groupByRead({
  //           table: this.parsed.model,
  //           criteria: this.parsed.domain,
  //           fields: this.parsed.fields,
  //           limit: 10
  //         }, this.action.params[3].value )
  //       } else {
  //         var x = await this.odooRPC.searchRead({
  //           table: this.parsed.model,
  //           criteria: this.parsed.domain,
  //           fields: this.parsed.fields,
  //           limit: 20
  //         })
  //       }

  //       this.action.results = x
  //     }


  //   } catch (e) {
  //     this.action.error = e
  //     this.action.busy = false
  //   } finally {
  //     this.action.busy = false
  //     this.flip()
  //   }
  // }





}
