<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
    <span>
      Cerca
    </span>
  </a>
</app-navbar>





<app-scanner *ngIf="!package && !product" (onCode)="onCode($event)"></app-scanner>

<!-- <app-picking-package-location-picker *ngIf="!package"  (onPackage)="onPackageOrig($event)"></app-picking-package-location-picker> -->


<div class="h-100" *ngIf="product">

  <app-product-quantity-editor *ngIf="showQuantityEditor" [product]="product" (onSave)="onQuantity($event)"
    (onCancel)="showQuantityEditor = false">
  </app-product-quantity-editor>

  <div class="bg-light d-flex align-items-center ps-2 py-3 text-primary">
    <h4>{{product.display_name}}</h4>
  </div>

  <ng-container *ngFor="let l of quants">
    <ng-container *ngIf="l.location_id.name.includes('Giacenza')">
      <div class="d-flex align-items-center w-100 py-3 pe-3">
        <h4 class="ms-2 text-muted mb-0">{{l.location_id.name}}</h4>
        <button class="ms-auto btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false"> Consuma
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" (click)="onConsume(l, O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume)">Case</a></li>
                  <li><a class="dropdown-item" (click)="onConsume(l, O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume)">Massello</a></li>
                  <li><a class="dropdown-item" (click)="onConsume(l, O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume)">Montaggio</a></li>
                  <li><a class="dropdown-item" (click)="onConsume(l, O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenzioni_consume)">Lavori interni</a></li>
                </ul>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <td class="text-center" >Quantità</td>
              <td class="text-center" >Disponibile</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <!-- non riesco a inserire la descriptive qty -->
              <td>{{l.quantity}} {{product.uom_id.name}} <br> {{getDescriptiveBarcode(product,l.quantity)}}</td>
              <td>{{l.available_quantity}} {{product.uom_id.name}} <br>{{getDescriptiveBarcode(product,l.available_quantity)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-container>
  
  <!-- <div class="navbar-footer  bg-dark fixed-bottom d-flex p-2">
      <button class="btn btn-primary w-100 text-white" (click)="scanBarcode()">
        Nuovo pacco
      </button>
    </div> -->

</div>


<div class="h-100" *ngIf="package">

  <div class="bg-light d-flex align-items-center ps-2 py-3 text-primary">
    <h1>{{package.name}}</h1> - {{package.location_id.name}}

    <div class="dropdown ms-auto me-2">
      <button (click)="loadLocations()" class="ms-auto btn btn-dark dropdown-toggle" type="button"
        data-bs-toggle="dropdown" aria-expanded="false">
        Stocca
      </button>

      <div class="dropdown-menu" style="max-height: 80vh;overflow: scroll;">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" *ngFor="let g of locationGroups | keyvalue">
            <h2 class="accordion-header">
              <button (click)="$event.stopPropagation()" class="accordion-button" type="button"
                data-bs-toggle="collapse" [attr.data-bs-target]="'#loc' + g.key.replaceAll(' ', '')">
                {{g.key}}
              </button>
            </h2>
            <div id="loc{{g.key.replaceAll(' ', '')}}" class="accordion-collapse collapse"
              data-bs-parent="#accordionExample">
              <div class="accordion-body p-0">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let v of g.value" (click)="moveTo2(v)">
                    {{v.display_name}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <ul class="dropdown-menu">
            <li *ngFor="let o of locationGroups">
              {{o.key}}
            </li>
          </ul> -->
    </div>

    <!-- <button class="ms-auto btn btn-primary me-2 text-white" (click)="loadLocations()">
          Stocca
        </button> -->




  </div>


  <div class="">

    <h4 class="ms-2 text-muted mt-3">Contenuto</h4>

    <div class="table-responsive">
      <table class="table table-bordered mw-100">
        <thead>
          <tr>
            <td class="">Prodotto e quantità</td>
            <td class="text-center">Disponibile</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let q of package?.quant_ids.values">
            <td class="text-wrap align-middle">
              <strong>{{q.product_id.name.replaceAll(", -","")}}</strong><br>
              {{q.quantity | number : '1.0-2':'it-IT' }} {{q.product_uom_id.name}} - {{getDescriptive(q, q.quantity)}}
            </td>
            <td class="text-wrap align-middle text-center dropdown">
              <div class="d-inline" type="button" id="dropdownMenuButton{{q.id}}"
                data-bs-toggle="dropdown" aria-expanded="false" style="cursor: pointer;">
                {{getDescriptive(q, q.available_quantity)}}
              </div>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton{{q.id}}">
                <li class="dropdown-header">Consuma</li>
                <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_case_id, O_IDS.location_case_consume, package)">Case</a></li>
                <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_massello_id, O_IDS.location_massello_consume, package)">Massello</a></li>
                <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_montaggio_id, O_IDS.location_montaggio_consume, package)">Montaggio</a></li>
                <li><a class="dropdown-item" (click)="onConsume(q, O_IDS.picking_type_consume_manutenzioni_id, O_IDS.location_manutenzioni_consume, package)">Lavori interni</a></li>
              </ul>
            </td>
          </tr>
        </tbody>
        <br><br><br><br><br>
      </table>

    </div>
  
<!-- DA QUI IN POI I DATI DEI TRASFERIMENTI CHE NASCONDO -->
<!-- 
    <br><br><br>

    <h4 class="ms-2 text-muted">Trasferimenti</h4>
    <div class="table-responsive">

      <table class="table table-bordered">
        <thead>
          <tr>
            <td>Trasferimento</td>
            <td>Quantità</td>
            <td>Info</td>
            
          </tr>
        </thead>
        <tbody>
          <tr [routerLink]="['/pickings/incoming/p/',ml.move_id.value?.picking_id.id]" style="cursor: pointer"
            *ngFor="let ml of moveLines">
            <td>
              {{ml.move_id.value?.picking_type_id.name.replace('LOMAGNA: ', '')}} -> {{ml.move_id.value?.location_dest_id.name}}
              <br>
              {{ml.move_id.value?.picking_id.value?.partner_id?.name}}
              <br>
              {{ml.product_id.name.replaceAll(", -","")}}
            </td>
            <td>{{ml.qty_done | number : '1.0-2':'it-IT' }} {{ml.product_uom_id.name}}
              <br> -->
              <!-- {{getDescriptive(ml.move_id ,ml.qty_done )}} -->
            <!-- </td>
            <td class="text-wrap">  -->
              <!-- se completato, date done, altrimenti date deadline -->
              <!-- <ng-container>
                <span *ngIf="ml.move_id.value?.picking_id.value?.state == 'done'; else deadline">
                  {{ml.move_id.value?.picking_id.name}} {{ml.move_id.value?.picking_id.value?.date_done | date: 'dd/MM/yyyy'}}  {{ml.move_id.value?.state}}
                </span>
                <ng-template #deadline>
                  {{ml.move_id.value?.picking_id.name}} {{ml.move_id.value?.picking_id.value?.date_deadline | date: 'dd/MM/yyyy'}}  {{ml.move_id.value?.state}}
                </ng-template>
              </ng-container>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->



  <!-- </div>/ -->
  <!-- 
  <div class="navbar-footer  bg-dark fixed-bottom d-flex p-2">
    <div class="button" class="btn btn-dark" (click)="addToExistingPack(null)">
      <i class="fa fa-list"></i>
    </div>
    <button class="btn w-100 btn-primary text-white" (click)="scanBarcode()">
      Nuovo pacco
    </button>
  </div> -->


</div>

<div class="navbar-footer  bg-dark fixed-bottom d-flex p-2">
  <button class="btn btn-primary w-100 text-white" (click)="package = null;product = null">
    Scansiona ancora
  </button>
</div>