

<div class="card" *ngIf="state == 'canvas'" >
    <div class="card-header d-flex align-items-center ">
        Plot
        <i class="fa  ms-auto fa-trash" (click)="delete()"></i>
    </div>
    <div class="card-body">

        <canvas #canvas class=" h-100">

        </canvas>
    </div>
</div>


<div class="d-flex card text-white flex-column" *ngIf="state == 'inspector' && action  ">
    <div class=" ">
        <form class="p-3 w-100 ">
            <div class="row">
                <div class="col-4">
                    <label class="form-label text-muted">Modello</label>
                    <input class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="action.params[0].value">
                </div>
                <div class="col-8">
                    <label class="form-label text-muted">Criteri</label>
                    <input class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="action.params[1].value">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label class="form-label text-muted">Colonne</label>
                    <input class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="action.params[2].value">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <label class="form-label text-muted">Raggruppa per</label>
                    <input class="form-control" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="action.params[3].value">
                </div>

            </div>
        </form>

    </div>

    <div class="d-flex p-3">
        <button class="btn btn-success w-100 text-white" [disabled]="action.busy" (click)="execute()">
            EXECUTE
        </button>
    </div>

</div>