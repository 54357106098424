

<div
  id="info"
  class="card side-card w-25 h-100 rounded-0 d-none"
  *ngIf="order"
  [hidden]="infoSideClosed"
>
  <div class="card-body" data-print-row-header>
    <div class="form-group d-none">
      <input data-print-col value="{{ order.name }}" />
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{ order.partner_id.name }}" />
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{ order.partner_ref }}" />
    </div>

    <!-- <div class="form-group d-none">
      <input data-print-col value="{{order.currency_id.name}}">
    </div> -->

    <div class="form-group d-none">
      <input data-print-col type="text" value="{{ order.create_date }}" />
    </div>

    <div class="form-group">
      <input data-print-col type="text" value="{{ order.date_planned }}" />
    </div>

    <div class="form-group">
      <!-- <label><b></b></label> -->
      <input
        data-print-col
        class="form-control"
        readonly
        value="{{ order.picking_type_id.name }}"
      />
    </div>

    <div class="form-group">
      <textarea data-print-col class="  form-control" [ngModel]="order.notes ? innerText(order.notes) : ''" placeholder="Note"></textarea>
    </div>

    <div class="form-group">
      <input data-print-col value="{{ order.user_id.name }}" />
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{ order.incoterm_id.name }}" />
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{ order.payment_term_id.name }}" />
    </div>

    <div class="form-group d-none">
      <input data-print-col value="{{ order.dest_address_id.name }}" />
    </div>
  </div>
</div>

<div class="table-container h-100 d-none">
  <table
    class="table table-bordered tableFixHead table-hover"
    id="order"
    style="margin-top: 62px"
    *ngIf="order"
  >
    <thead>
      <tr>
        <th class="table-info">Codice</th>
        <th class="table-info">Prodotto</th>
        <th class="table-info">Descrizione</th>
        <th class="table-light">Q Ordinata</th>

        <!-- <th class="table-info">Descrizione Acquisti</th> -->
        <!-- <th class="table-info">Quantità</th> -->

        <!-- <th class="table-info">Fatturata</th> -->
        <th class="table-info">Unità di misura</th>
        <th class="table-info">qtà imballo</th>
        <th class="table-info">Imballo</th>
        <th class="table-info">Prezzo</th>
        <th class="table-info">Totale parziale</th>
      </tr>
    </thead>
    

    <tbody>
      <tr
        style="vertical-align: middle"
        *ngFor="let line of order?.order_line?.values"
        data-print-row
      >
        <ng-container *ngIf="!line.display_type">
          <td class="text-right">
            <input
              *ngIf="line.product_id.value.variant_seller_ids.values.length > 0"
              data-print-col
              value="{{line.product_id.value.variant_seller_ids.values[0].product_code}}"
            />
            <input
              *ngIf="
                line.product_id.value.variant_seller_ids.values.length == 0
              "
              data-print-col
              value=""
            />
          </td>

          <td class="text-right">
            <input data-print-col value="{{ line.product_id.name }}" />
          </td>
          
          <div data-print-col>
            <span *ngIf="line.product_id.value.description_purchase">{{line.product_id.value.description_purchase}}</span>
          </div>

          <!-- <td class="text-right">
            <input data-print-col value="{{line.product_id.value.description_purchase}}">
          </td> -->

          
          <!-- <app-input-double-um 
          [ngModel]="line.product_qty"
          [product]="line.product_id.value"
          style="vertical-align: middle;"
        ></app-input-double-um> -->

          <!-- <input data-print-col type="number" value="{{ line.product_qty }}" /> -->
          <!-- 
          <td class="text-right">
            <input data-print-col value="{{line.qty_received}}">
          </td>

          <td class="text-right">
            gffgfg  <input data-print-col value="{{line.qty_invoiced}}">
          </td> -->


          <input data-print-col type="number" value="{{ line.product_qty }}" />
          <input data-print-col value="{{ line.product_uom.name }}" />

          <input data-print-col value="{{ getPz(line) }}" />
          <input data-print-col value="Pz" />

          <input data-print-col type="number" value="{{ line.product_packaging_qty }}" />
          <input data-print-col value="{{ line.product_packaging_id.name }}" />

          <!-- <input data-print-col value="{{ line.product_uom.name }}" />

          <input data-print-col type="number"  value="{{ line.product_packaging_qty }}" />

          <input
            data-print-col
            value="{{ line.product_packaging_id.name }}"
          /> -->

          <input type="number" type="number"  data-print-col value="{{ line.price_unit }}" />

          <input
            type="number"
            data-print-col
            value="{{ line.price_subtotal }}"
          />

          <input data-print-col type="number" value="{{getVariantAttribute(line,'Altezza')?.name}}">

          <input data-print-col type="number" value="{{getVariantAttribute(line,'Larghezza')?.name}}">

          <input data-print-col type="number" value="{{getVariantAttribute(line,'Lunghezza')?.name}}">

          <input data-print-col value="{{ line.product_id.value.categ_id.name }}" />

        </ng-container>

        <ng-container *ngIf="line.display_type">
          <td col="1" data-print-col>
            <span>{{ line.display_type }}</span>
          </td>
          <td col="12" data-print-col>
            <span>{{ line.name }}</span>
          </td>
        </ng-container>


      </tr>
    </tbody>
  </table>
</div>

<div class="container">
  <div class="row align-items-center text-center" style="min-height: 100vh">
    <div class="offset-md-2 col-md-8">
      <div class="fa-3x">
        <i class="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse ms-3"></i>
      </div>
    </div>
  </div>
</div>

<!--(actionFromHeaderEmitter)="handleHeaderEvent($event)"-->

<!-- <app-order-inventory 
[inventoryClosed]="inventoryClosed"
(toggleInventory)="inventoryClosed = !inventoryClosed"
(addOrderLine)="addOrderLine($event)"
>
</app-order-inventory> -->
