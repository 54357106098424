import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { Contact } from '../../models/contact.model';
import { EMPTY, firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { SaleOrder } from '../../models/sale-order.model';
import { PurchaseOrder } from '../../models/purchase-order.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ContactPickerComponent } from '../contact-picker/contact-picker.component';
import { parseXMLVAT } from '../contact-search.component';



@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html'
})
export class ContactDetailComponent implements OnInit {
  id: number;
  contact: Contact;
  sales: SaleOrder[];
  purchases: any[];
  loading = false;
  isViewingChild: boolean;
  @ViewChild('picker') picker: ContactPickerComponent;

  constructor(
    private route: ActivatedRoute,
    public restapi: RestapiService,
    private router: Router,
    private odooEm: OdooEntityManager,
  ) {
  }

  ngOnInit() {
    
    this.route.params.subscribe(async params => {
      this.loading = true
      this.id = params.id
      await this.resolveContact()
      this.loading = false
    })
  }


  async resolveContact() {

  
    this.isViewingChild = false
    this.loading = true;
    // odooEM.search non trova gli archiviati di default con il solo ID, quindi ...
    var cs = new Contact()
    // let arrayArchived = (await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [
    //   ["active", "=", false]
    // ])))
    // var find = arrayArchived.find((i) => i.id == this.id)
    // if (find)
    //   cs = find
    // else {
      cs = (await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [
        ['id', '=', this.id]
      ])))[0]
    // }
    this.contact = cs
    console.log("xxcon ", cs, this.contact)
    this.loading = false;

  } 

  newPerson() {
    this.picker.newLink()
    return
  }

  confirm() {
    // this.changeState('quotation');
  }

  async restore() {

    if (confirm("Vuoi annullare l'archivazione?")) {
      await firstValueFrom(this.odooEm.call(
        new Contact(),
        "action_unarchive",
        Number(this.id),
        null
      ))
      this.contact.active = true
    }
  }

  async obsolete() {
    await firstValueFrom(this.odooEm.call(
      new Contact(),
      "action_archive",
      [Number(this.id)],
      null,
    ))
    this.contact.active = false
  }

  async onDelete() {
    if (!confirm('Sei sicuro di voler archiviare il contatto ?'))
      return
    this.loading = true
    await this.obsolete()
    this.loading = false
  }

  async update(prop: string) {
    this.loading = true
    var p = {}
    p[prop] = this.contact[prop]

    await this.odooEm.update(this.contact, p).pipe(first()).toPromise()

    console.log("HERE ", p)
    this.loading = false
  }



  onVAT(vat) {
    this.loading = true
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', 'checkvat/taxation_customs/vies/services/checkVatService', true);

    // build SOAP request
    var sr =
      "<s11:Envelope xmlns:s11='http://schemas.xmlsoap.org/soap/envelope/'>" +
      "<s11:Body>" +
      "<tns1:checkVat xmlns:tns1='urn:ec.europa.eu:taxud:vies:services:checkVat:types'>" +
      "<tns1:countryCode>IT</tns1:countryCode>" +
      "<tns1:vatNumber>" + vat + "</tns1:vatNumber>" +
      "</tns1:checkVat>" +
      "</s11:Body>" +
      "</s11:Envelope>"

    xmlhttp.onreadystatechange = () => {
      this.loading = false
      if (xmlhttp.readyState == 4) {
        if (xmlhttp.status == 200) {
          var r = parseXMLVAT(xmlhttp.responseText)

            // this.contact.name = r.name
            // this.contact.city = r.city
            // this.contact.street = r.street
            // this.contact.zip = r.postalcode

            this.bulkUpdate(r)
        }
      }
    }
    // Send the POST request
    xmlhttp.setRequestHeader('Content-Type', 'text/xml');
    xmlhttp.send(sr);
  }

  async bulkUpdate(fields) {
    this.loading = true
    await this.odooEm.update<Contact>(this.contact, fields).pipe(first()).toPromise()
    await this.resolveContact()
    this.route
    this.loading = false
  }
}

enum OrderState {
  ASSIGNED = 'assigned',
  DRAFT = 'preventivo',
  PRODUCTION = 'in_produzione',
  PLANNING = 'in_progettazione',
  SALE = 'sale'
}
