import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { Contact } from 'src/app/models/contact.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-contact-picker',
  templateUrl: './contact-picker.component.html'
})
export class ContactPickerComponent implements AfterViewInit {
  @Output() loading:EventEmitter<boolean> = new EventEmitter()
  @Input() contact:Contact
  openContact: Contact;
  editingContact: boolean;
  // recents:RecentCalls = new RecentCalls()

  constructor(
    private odooEm: OdooEntityManager
  ) { }


  async ngAfterViewInit(): Promise<void> {
    console.log("init",this.contact)
    if (!this.contact || !this.contact.child_ids.values) {
      await this.loadChild()
    }

    // console.log("LINK load", await this.odooEm.search(new ContactLink()).toPromise())
  }

  async loadChild() {
    this.loading.next(true)
    // if (this.contact.child_ids)
    console.log("load", this.contact)
    await firstValueFrom(this.odooEm.resolve(this.contact.child_ids).pipe(first()))
    // await this.odooEm.resolveArray<ContactLink,Contact>(new ContactLink(), this.contact.child_ids.values, 'link_ids').toPromise()

    // console.log("RESOLVEXXX ", this.contact)
    this.loading.next(false)
  }

  public async  newLink() {
    const fields = {
      name: "Nuovo",
      parent_id: this.contact.id
    }
    
    this.odooEm.create<Contact>(new Contact(),fields).pipe(first()).subscribe(c => {
      this.contact.child_ids.values.push(c)
      this.openContact = c;
      this.editingContact = true
    }) 


    return
  }

  async deleteChild(c:Contact) {
    if (!confirm('Confermi di voler eliminare il contatto ?')) {
      return;
    }

    this.loading.next(true)
    await this.odooEm.deleteMulti(c.child_ids,[c.id]).pipe(first()).toPromise()
    
    this.contact.child_ids.ids = this.contact.child_ids.ids.filter(i => i != c.id)
    this.contact.child_ids.values = this.contact.child_ids.values.filter(i => i != c)

    this.loading.next(false)
  }

  async update(c:Contact, prop:string ) {
    this.loading.next(true)
    let u = {}
    u[prop] = c[prop]
    await firstValueFrom(this.odooEm.update(c, u)) 
    this.loading.next(false)
  }



  // getTypeForLink(l:ContactLink):'mailto'|'href'|'tel' {
  //   if (l.value && l.value.indexOf('@') > -1)
  //     return 'mailto'
  //   else if (l.value &&  l.value.match(/[a-zA-Z]\.[a-zA-Z]/g)) 
  //     return 'href'
  //   else 
  //     return 'tel'
  // }


  // onChangeContact(subContact:Contact, prop:string) {
  //   this.loading.next(true)
  //   var p = {}
  //   p[prop] = subContact[prop]
  //   return this.odooEm.update(subContact,p).pipe(first()).toPromise()
  // }

  async onDelete(contact:Contact) {
    
    if (!confirm('Sei sicuro di volerlo eliminare ?')) 
      return
    await this.odooEm.delete(new Contact(),[contact.id]).pipe(first()).toPromise()

    this.contact.child_ids.ids = this.contact.child_ids.ids.filter(i => i != contact.id)
    this.contact.child_ids.values = this.contact.child_ids.values.filter(i => i != contact)
  }

  // onClickLink(ev, c:Contact, l:ContactLink) {
  //   // this.recents.addToRecents(this.contact)
  // }

  // onChangeLink(link:ContactLink, field:string) {

    
  //   console.log("ON CHANEG LINK ", link, field)
  //   var x = {}
  //   x[field] = link[field]
  //   return this.odooEm.update<ContactLink>(link, x);
  // }

  // async onDeleteLink(c:Contact, l:ContactLink) {

  //   return;


  //   // if (!confirm('Sei sicuro di volerlo eliminare ?')) 
  //   //   return
    
  //   // await this.odooEm.delete(new ContactLink(),[l.id]).pipe(first()).toPromise()

  //   // c.link_ids.ids = c.link_ids.ids.filter(i => i != l.id) 
  //   // c.link_ids.values = c.link_ids.values.filter(i => i != l) 
  // }
}


