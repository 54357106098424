import { Component, OnInit } from '@angular/core';
import { SaleOrder } from '../../models/sale-order.model';
import { catchError, switchMap } from 'rxjs/operators';
import { SaleOrderLine } from '../../models/sale-order-line.model';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import * as bootstrap from 'bootstrap';


@Component({
  selector: 'app-sale-order-detail',
  templateUrl: './sale-order-detail.component.html',
})
export class SaleOrderDetailComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
    
  }

  getOrder = (orderDetailComponent: OrderDetailComponent) => {
    // return orderDetailComponent.odooEm.get<SaleOrder>(new SaleOrder(), orderDetailComponent.id).pipe(
    //   switchMap(odooOrder => {
    //     // orderDetailComponent.order = odooOrder;
    //     // return orderDetailComponent.odooEm.resolve<SaleOrderLine>(orderDetailComponent.order.order_line);
    //   })
    // );
  };

  changeOrderState = (orderDetailComponent: OrderDetailComponent, method: string) => {
    orderDetailComponent.loading = true;
    orderDetailComponent.odooEm.call<SaleOrder>(new SaleOrder(), method, orderDetailComponent.order.id).subscribe(() => {
      orderDetailComponent.loading = false;
      if (method === 'action_confirm') {
        orderDetailComponent.order.state = 'sale';
      } else if (method === 'cambia_in_progettazione') {
        orderDetailComponent.order.state = 'in_progettazione';
      } else if (method === 'cambia_in_produzione') {
        orderDetailComponent.order.state = 'in_produzione';
      }
    });
  };

}
