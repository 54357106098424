import { Component, ElementRef, OnInit } from '@angular/core';
import { SearchComponent } from '../search/search.component';
import { OrderInventoryComponent } from '../components/order-inventory/order-inventory.component';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { OdoorpcService } from '../shared/services/odoorpc.service';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent extends OrderInventoryComponent implements OnInit {


  loading2 = false;

  

  constructor(
     odooEm: OdooEntityManager,
     elRef:ElementRef,
     odoorpcService: OdoorpcService
  ) {
    super(
       odooEm,
      elRef,
     odoorpcService
    )

    // this hack is because we extend badly order inventory 
    this.loading.subscribe((loading) => { 
      this.loading2 = loading;
    })
  }


}
