import { Component, OnInit } from '@angular/core';
import { GapiService } from './shared/services/g-api.service';
import { OdoorpcService } from './shared/services/odoorpc.service';
import { QuerySearchOptions } from './shared/services/rest-api.service';
const Dynamsoft = (window as any).Dynamsoft;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  
  title = 'gali-erp3';
  busyMessage: unknown;
  constructor(
    private gs:GapiService,
    private rpc:OdoorpcService
    ) {

  }

  async ngOnInit(): Promise<void> {
    
    
    Dynamsoft.DBR.BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMzY3OTI4LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjM2NzkyOCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOjIxMjk2NzYyNDV9';



    // var r = await this.rpc.searchRead({
    //   table : "res.partner",
    //   criteria : [],
    //   limit:1
    // })

    // console.log("XX ", r)

    // if (window.origin != "https://m3.galimberti.eu")
    //   document.body.classList.add("dev")

      // attach to drive service for logging during copy (loooong process -  batch?)
    this.gs.driveBusyMessage.subscribe(x => {
      this.busyMessage = x
    })

  }


  
}
