import { Component, OnInit } from '@angular/core';
import { Product } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import { ODOO_IDS } from '../models/deal';
import { ProductTemplate } from '../models/product.template.model';
import { StockPicking } from '../models/stock-picking';
import { StockMove } from '../models/stock-move';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import { ProductAttributeValue } from '../models/product.attribute.value';
import { StockMoveLine } from '../models/stock-move-line';
import { SaleOrder } from '../models/sale-order.model';
import { StockQuant } from '../models/stock-quant';
import { StockQuantPackage } from '../models/stock-quant-package';
import { ProductPackaging } from '../models/product.packaging.model';

@Component({
  selector: 'app-oddments',
  templateUrl: './oddments.component.html'
})
export class OddmentsComponent implements OnInit {

  loading: boolean = false;
  products: Product[] = null;
  activeTemplate: ProductTemplate;
  activePicking: StockPicking;
  saleNumber:string;
  activeProduct: Product;
  pieces: { length: number; package: string; }[];
  sale: SaleOrder;

  constructor(private odooEm:OdooEntityManager) { 

  }

  async ngOnInit(): Promise<void> {
    // this.saleNumber = "02703"
    // this.loadPicking()
    // this.products = await firstValueFrom(this.odooEm.search<Product>(new ProductTemplate(),  [['product_tag_ids', 'in', [ODOO_IDS.tag_variant_search]]]))
  }


  newOddment(p:ProductTemplate) {
    this.activeTemplate = p
  }

  onProduct(p:Product) {
    this.activeProduct = p

    this.pieces = [{
      length:0,
      package:"",
    }]
  }

  addPiece() {
    this.pieces.push({
      length:0,
      package:"",
    })
  }

  async loadPicking() {

    // load the sale to have procurement group
    let sales = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['name', '=', 'V'+ this.saleNumber]]))
    if (sales.length == 0) {
      alert("Ordine non trovato")
      return
    } else 
      this.sale = sales[0]


    let pickings = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['origin', '=', 'V'+ this.saleNumber]]))
    let moves = await firstValueFrom(this.odooEm.search<StockMove>(new StockMove(), [
      ['picking_id', 'in', pickings.map(p => p.id)],
      ['product_id.product_tmpl_id.product_tag_ids', 'in', [ODOO_IDS.tag_variant_search]] //prendo gli articolo con ricerca estesa.....
    ]))
    
    // reduce the moves to the available products
    let productIds = []
    moves.forEach(m => {
      if (productIds.indexOf(m.product_id.id) == -1) {
        productIds.push(m.product_id.id)
      }
    })

    let products = await firstValueFrom(this.odooEm.search<Product>(new Product(), [['id', 'in', productIds]]))
    // this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    this.products = products
    // console.log("PICKings ", moves,  pickings)
  }


  back() {

    this.activeProduct = null
  }


  async createProduct(product, e, attributeName):Promise<Product> {

  await firstValueFrom(this.odooEm.resolve(product.product_template_attribute_value_ids)) 
  this.loading = true
  var l = product.product_template_attribute_value_ids.values.find(v => v.attribute_id.name.startsWith(attributeName))
  var li = product.product_template_attribute_value_ids.values.indexOf(l)
  await this.odooEm.resolveSingle(new ProductTemplate(), product.product_tmpl_id).toPromise()

  var tmpl = product.product_tmpl_id.value

  let v
  let oldv:ProductAttributeValue[] = await this.odooEm.search<ProductAttributeValue>(new ProductAttributeValue(), 
      [["name","=", e],
      ["attribute_id","=", l.attribute_id.id]]
    ).toPromise()
    
  if (oldv.length > 0) 
    v = oldv[0]
  else {
    // new attribute value
    v = await this.odooEm.create<ProductAttributeValue>(new ProductAttributeValue(), {
      "name": e,
      "attribute_id": l.attribute_id.id
    }).toPromise()
  }

  await this.odooEm.resolve(tmpl.attribute_line_ids).toPromise()
  var tal = tmpl.attribute_line_ids.values[li]
  var ids = tal.value_ids.ids
  ids.splice(ids.length,0,v.id)
  
  // faster than
  // PATCH ODOO
  // await this.odooEm.update(tmpl, {
  //   attribute_line_ids:[[1, tal.id, {value_ids: [[4, v.id]]}]]
  //     //  attribute_line_ids:[[1, tal.id, {value_ids: [[6, false, ids]]}]]
  // }).toPromise()
  await this.odooEm.update(tal, {value_ids: [[4, v.id]]}).toPromise()
  
  // create product template
  await firstValueFrom( this.odooEm.create(new ProductTemplateAttributeValue(), {
    "product_attribute_value_id" : v.id,
    "attribute_line_id": tal.id,
    "ptav_active": true
  }))
  

  let ptav:ProductTemplateAttributeValue[] = await this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), 
    [
      ["attribute_id","=", l.attribute_id.id],
      ["product_attribute_value_id", "=", v.id],
      ["product_tmpl_id","=", l.product_tmpl_id.id],
      ["ptav_active", "=", true]
    ]
  ).toPromise()
  
  var ids2 = product.product_template_attribute_value_ids.values.map(v => v.id)
  ids2[li] = ptav[0].id

  var r:any = await this.odooEm.odoorpcService.sendRequest('/api/sale/create_product_variant', {
    "product_template_attribute_value_ids": JSON.stringify(ids2),
    "product_template_id": product.product_tmpl_id.id
  });
  
  // todo PEZZO PACK
  await this.odooEm.run(666, r.result, "product.product" )

  var freshP = await firstValueFrom(this.odooEm.search<Product>(new Product(), [['id', '=', r.result]]))
  
  
  if (freshP) {
    console.log("FRE ", freshP)
  } else {
    alert("Errore nella creazione della variante")
  }
  return freshP[0]

}

  async save() {

    let startProduct = this.activeProduct
    this.loading = true
    this.activeProduct = null

    // search if we already have open picking 
    // let ps = await firstValueFrom(this.odooEm.search(new StockPicking(), [['picking_type_id', '=', ODOO_IDS.picking_type_restolegno], ['origin', '=', 'V'+ this.saleNumber], ['state', 'in', ['draft', 'confirmed']]]))
    // if (ps.length == 0) {
    let pack, destination
    // find the package and create if not exists
    let ps = await firstValueFrom(this.odooEm.search<StockQuantPackage>(new StockQuantPackage(), [['name', '=', this.pieces[0].package]]))
    if (ps.length == 0) {
      pack = await firstValueFrom(this.odooEm.create<StockQuantPackage>(new StockQuantPackage(), 
      {
        "name": this.pieces[0].package,
        "location_id": ODOO_IDS.stock_location_stock
      }))
      destination = ODOO_IDS.stock_location_stock
    } else {
      pack = ps[0]
      destination = pack.location_id.id
    }

    


    let p:any = {}
    p.picking_type_id = ODOO_IDS.picking_type_restolegno
    p.origin = 'V'+ this.saleNumber
    p.location_id = ODOO_IDS.stock_location_customer
    p.location_dest_id = ODOO_IDS.stock_location_stock
    p.group_id = this.sale.procurement_group_id.id
    let newpick = await firstValueFrom(this.odooEm.create<StockPicking>(new StockPicking(), p))

    let product = await this.createProduct(startProduct, this.pieces[0].length, "Lunghezza")
    await firstValueFrom(this.odooEm.resolve(product.packaging_ids))
    // find pz package
    let pz = product.packaging_ids.values.find(p => p.name.toLowerCase() == "pz")

    // create the moves
    let moves = []
    this.pieces.forEach(p => {
      let m:any = {}
      m.name = startProduct.name
      m.location_id = ODOO_IDS.stock_location_customer
      m.location_dest_id = destination
      m.product_id = product.id
      m.product_uom_qty = p.length
      m.picking_id = newpick.id
      m.product_uom_qty = pz.qty
      m.group_id = this.sale.procurement_group_id.id
      moves.push(m)
    })

    await firstValueFrom(this.odooEm.create<StockMove>(new StockMove(), moves[0]))


    
    await firstValueFrom(this.odooEm.create<StockMoveLine>(new StockMoveLine(), {
      product_id : product.id,
      picking_id: newpick.id,
      qty_done: pz.qty,
      result_package_id: pack.id,
      location_id : ODOO_IDS.stock_location_customer,
      location_dest_id : destination,
      move_id: moves[0].id
    }))

    let r = await this.check(
      this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [[newpick.id]])
    )

    this.activeProduct = null
    this.loading = false
  }
  

  async check(f) {
    let r = await f
    if (r.error) {
      this.loading = false
      alert(r.error.data.message)
      throw r
    }
    return r
  }

}
