
<app-navbar 
    class="sticky-top "
    style="z-index: 1000;"
  [loading]="loading" backroute="..">
  <a class="navbar-brand">
    <span>
      Scheduler
    </span>
  </a>

  <form class="ms-auto">
    <select class="form-control" [(ngModel)]="filterUser" (ngModelChange)="onUserChange($event)" [ngModelOptions]="{standalone: true}">
        <option *ngFor="let u of users" [ngValue]="u">{{u.name}}</option>
    </select>
  </form>


</app-navbar>

<div class="d-flex p-3 w-100 bg-light border-bottom">

    <div class="dropdown">
        <button class="btn btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Commesse
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let l of leads">
            <a class="dropdown-item" (click)="setFilterLead(l)">{{l?.name}}</a>
        </li>
        </ul>
      </div>


        
        <select class="form-control ms-3 w-auto"> 
            <option >Prossimi 60 giorni</option>
        </select>
</div>



<div class=" container-fluid" >
    
    <div class="row">


    
    <!-- <div class="col-3 border-end list-group">

        <div *ngFor="let p of shipments" class="d-flex flex-fill p-4 list-group-item">

            <div class=" d-flex">
                <ng-container *ngIf="p.sale_id.value?.opportunity_id.value">
                    <div class="d-flex flex-column ms-2">
                        {{p.sale_id.value?.opportunity_id.value?.partner_id.name}}
                        <br>
                        {{p.sale_id.value?.opportunity_id.value?.name}}
                    </div>
                </ng-container>
            </div>
        </div>

    </div> -->

    <diV class="col" >



    <div *ngFor="let p of shipments" class="d-flex flex-fill p-4">


        <!-- lead --> 
        <!-- <div class=" d-flex">
            <ng-container *ngIf="p.sale_id.value?.opportunity_id.value">
                <hr class=" flex-fill">

                <div class="card flex-fill">
                    <div class="card-body d-flex">
                        <div class="d-flex flex-column ms-2">
                            {{p.sale_id.value?.opportunity_id.value?.partner_id.name}}
                            <br>
                            {{p.sale_id.value?.opportunity_id.value?.name}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </div> -->


                <div class=" d-flex">

                    <ng-container *ngIf="p.group_id.value">
                        <!-- <hr class="w-25 flex-fill"> -->

                        <div class="card flex-fill">
                            <div class="card-body d-flex flex-column">
                                <div class="d-flex">
                                    <i class="fa fa-file" [ngClass]="{'text-success': p.sale_id.value?.state == 'sale', 'text-warning': p.sale_id.value?.state != 'sale'}"></i>

                                    <b>{{ p.sale_id.name }}  </b>
                                    <!-- <span>{{p.sale_id.value?.opportunity_id.value?.tracking_code}}
                                     {{p.sale_id.value?.opportunity_id.value?.name}}</span> -->
                                </div>
                                {{ p.sale_id.value.ga_title }}

                            </div>
                        </div>
                    </ng-container>
                </div>



                <!-- Trasferimenti -->
                <div class="align-middle flex-fill px-0 w-25">
                    <div class="d-flex">
                        <hr class="w-25 flex-fill">
                        <div class="card flex-fill w-100">
                            <div class="card-body border-bottom" *ngIf="getPurchasesFor(p.group_id.value).length > 0">
                                <div *ngFor="let po of getPurchasesFor(p.group_id.value)" class="text-nowrap">
                                    <i 
                                        class="fa fa-circle me-2"
                                        [ngClass]="{
                                            'text-success': p.state == 'assigned', 
                                            'text-warning': p.state == 'waiting', 
                                            'text-primary': p.state == 'done'  
                                            }"
                                    ></i>
                                    <b class="text-nowrap">{{po.name}}</b>
                                </div>
                            </div>

                            <div class="card-body">

                                <!-- {{p.sale_id.value.delivery_count }} trasferimenti -->
                                <div class="" >
                                    <div *ngFor="let p of getPickingsFor(p)" class="text-nowrap">
                                        <i 
                                            class="fa fa-circle me-2"
                                            [ngClass]="{
                                                'text-success': p.state == 'assigned', 
                                                'text-warning': p.state == 'waiting', 
                                                'text-primary': p.state == 'done'  
                                             }"
                                        
                                        ></i>
                                        <span>{{p.picking_type_id.name}}</span>
                                            </div>
                                        </div>
                            </div>
                        </div>
                        <hr class="w-25">
                    </div>
                </div>




                <div class="w-25  d-flex">
                        <hr style="min-width: 10px;width: auto;">
                        <div class="card flex-fill">
                            <div class="card-body">
                                <i class="fa fa-truck-fast" [ngClass]="{'text-success': p.state == 'assigned', 'text-warning': p.state == 'waiting'}"></i>
                                <a class="ms-2" href="">{{ p.name }}</a>
                                
                                <input class="form-control mt-3" type="datetime-local" [ngModel]="p.scheduled_date" (change)="onDateChange(p)">
                            </div>
                </div>
                </div>
                <!-- <td>{{ p.picking_type_id.name}} </td>

                <td>{{p.sale_id.name}}</td> -->
            </div>   



    </diV>
    
    
        

        </div>
</div>
