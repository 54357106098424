import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import {Chart, ChartData, ChartOptions  } from 'chart.js/auto';
import { Action, ActionParam, DashboardComponent } from '../dashboard.component';
import { HttpClient } from '@angular/common/http';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-action-plot',
  templateUrl: './action-plot.component.html',
  encapsulation: ViewEncapsulation.None // This is required for styles to apply
})


export class ActionPlotComponent implements OnInit, AfterViewInit {

  @Output() onDelete:EventEmitter<Action> = new EventEmitter<Action>()
  @Input() state : string   //"inspector" | "canvas" 
  @Input() action: Action
  results: any[];
  params: ActionParam[];
  @ViewChild('canvas') canvas: any
  debugQuery: string;
  parsed: any;
  constructor(private http: HttpClient, private odooRPC: OdoorpcService,private sanitizer: DomSanitizer, private renderer: Renderer2) { }

  ngAfterViewInit(): void {

    // if( this.state == "canvas")
      // this.draw()
  }
  
  ngOnInit(): void {


    var s =  `//dichiarazione variabili
    var ctx = document.getElementById('myChart').getContext('2d');
    
    //dati da visualizzare
    var data = {
        labels: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        datasets: [{
            label: 'Vendite',
            data: [12, 19, 3, 5, 2, 3, 20, 33, 23, 12, 3, 5],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255,99,132,1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    };
    
    //opzioni grafico
    var options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero:true
                }
            }]
        }
    };
    
    //creazione grafico
    var myChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: options
    }); `;

    this.draw(s)
  }


  delete() {
    this.onDelete.next(null)
  }
  
  draw(parsed) {
    
    
    const scriptElement = this.renderer.createElement('script');
    const scriptContent = this.renderer.createText(parsed);
    this.renderer.appendChild(scriptElement, scriptContent);

    var scriptCode = this.sanitizer.bypassSecurityTrustHtml(scriptElement.outerHTML);

    console.log("doc", scriptElement)
    // Esegui il codice JavaScript
    this.renderer.appendChild(document.body, scriptElement);

  }


  async execute() {
    this.action.busy = true
    let pr = ''
    // if (previous) {
    //   console.log("prev", previous.results)
    //   pr = pr + "il risultato della ricerca precedente sono i seguenti id " + previous.results.map(x => x.id).join(",")
    // }

    // pr = pr + 'var x = { "model": "purchase.order", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]], "fields": ["id","name"]}'
    // pr = pr + 'var x = { "model": "crm.lead", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]] }'

    // var x = {
    //   "model": "",
    //   "domain": [],
    //   "fields": [],
    //   "group_by": ""
    // }              
    // modificando le proprietà 'model' e 'domain' e 'fields' e 'group_by' .
    // per una query su odoo che usa il modello che rappresenta ${this.action.params[0].value} con questo criteri dominio  ${this.action.params[1].value}.
    // il campo "fields" e' un array delle properità degli oggetti ${this.action.params[0].value}  di questo tipo ${this.action.params[2].value}
    // rispondi solo con il codice. quando cerchi stringhe usa sempre l'operatore ilike.
    // quando si deve inserire un criterio chiamato o od oppure bisogna usare l'operatore "|"
    // se si chiede di raggruppare usa la colonna group_by
    // l'operatore "|" nella query del dominio va messo prima dei due criteri tra cui scegliere  quindi  "domain": ["|", criterio 1, criterio2]





    // pr = pr + 'i principali campi di un res.partner sono id, name, city,street'
    pr = pr + `codice js completo per disegnare un grafico lineare con chartjs,  ometti i tag aperta chiusura di script `

    this.debugQuery = pr
    // pr = 'write hello in an heading in an html file'

    var response = await this.http.post<any>(
      'https://api.openai.com/v1/completions',
      {
        prompt: [
          pr
        ],
        max_tokens: 600,
        n: 1,
        stop: null,
        temperature: 0.1,
        model: "text-davinci-003"
        // model: "curie:ft-personal-2023-05-09-19-32-34"
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + DashboardComponent.k
        }
      }
    ).toPromise();
    response = response.choices[0].text.trim();
    // this.action.debug = response



    try{

      this.action.parsed = response

      console.log(response)
      this.draw(this.action.parsed)

      // this.parsed.domain.forEach(p => {
      //   if (p[1] == '>')
      //     p[1] = 'gt;'
      // });

      // handle | or in odoo
    //   if (this.parsed.domain) {

    //     if (this.action.params[3] && this.action.params[3].value != '') {
    //       var x = await this.odooRPC.groupByRead({
    //         table: this.parsed.model,
    //         criteria: this.parsed.domain,
    //         fields: this.parsed.fields,
    //         limit: 10
    //       }, this.action.params[3].value)
    //     } else {
    //       var x = await this.odooRPC.searchRead({
    //         table: this.parsed.model,
    //         criteria: this.parsed.domain,
    //         fields: this.parsed.fields,
    //         limit: 20
    //       })
    //     }

    //   }

    //   this.action.results = x
    } catch (e) {
      this.action.error = e
      this.action.busy = false
    } finally {
      this.action.busy = false
    }
  }
  

  
}
