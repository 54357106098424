import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../models/product.model';
import { ProductTemplateAttributeValue } from '../models/product.template.attribute.value.model';
import { PurchaseOrderLine } from '../models/purchase-order-line.model';
import { PurchaseOrder } from '../models/purchase-order.model';
import { StockMove } from '../models/stock-move';
import { StockPicking } from '../models/stock-picking';
import { GapiService } from '../shared/services/g-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { PRINTS_PURCHASE_CFG } from 'src/app/models/deal';
import { AccountTax } from '../models/account-tax.model';
import { ProductSupplierinfo } from '../models/product.supplierinfo';
import { row2printable } from '../shared/print-util';

@Component({
  selector: 'app-purchase-print',
  templateUrl: './purchase-print.component.html'
})
export class PurchasePrintComponent implements OnInit {
  id: any;
  loading: boolean;
  pickings: StockPicking[];
  moves: StockMove[];
  order: PurchaseOrder;

  constructor(
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private gapiService: GapiService,
    private odooEm: OdooEntityManager,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.id = params['id']
      // available routes in sale
      await this.load()
      this.existCondition()
    })
  }

  existCondition() {

    this.loading = true
    var existCondition = setInterval(async () => {
      if (this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length && this.elementRef.nativeElement.querySelectorAll('[data-print-col]').length > 0) {
        clearInterval(existCondition);

        this.gapiService.$ready.subscribe( async (bool) => {
          if(bool){
            await this.print()
            this.loading = false
            this.cd.detectChanges();
          }
        })
      }
    }, 100); // check every 100ms
  }


  async load() {

    this.loading = true

    var order: PurchaseOrder = (await this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [["id", "=", this.id]]).toPromise())[0]
    // await this.odooEm.resolveSingle(new StockMove(), order.partner_id).toPromise()
    // await this.odooEm.resolveSingle(new StockPickingType(), order.picking_type_id).toPromise()
    console.log("oRDER", order)
    await this.odooEm.resolve(order.order_line).toPromise()
    await this.odooEm.resolveArray(new StockMove(), order.order_line.values, "move_ids").toPromise()

    var moveids = order.order_line.values.map(l => l.move_ids.values).reduce((acc, val) => acc.concat(val), []);
    await this.odooEm.resolveArrayOfSingle(new StockPicking(), moveids, "picking_id").toPromise()
    // order.notes =  order.notes ? "=\""+order.notes.replace(/<\/p>/mg,'"&CODICE.CARATT(10)&"').replace(/<[^>]*>?/gm, '') : ""


    // BAD FIX STAMPARE NOTE
    // order.notes =  order.notes ? order.notes.replace(/<[^>]*>?/gm, '\n') : ""
    this.pickings = await this.odooEm.search<StockPicking>(new StockPicking(), [["origin", "=", order.name]]).toPromise()
    this.moves = await this.odooEm.search<StockMove>(new StockMove(), [["picking_id", "in", this.pickings.map(p => p.id)]]).toPromise()
    await this.odooEm.resolveArrayOfSingle(new Product(), order.order_line.values, "product_id").toPromise()
    await this.odooEm.resolveArray(new AccountTax(), order.order_line.values, "taxes_id").toPromise()

    var products = []
    order.order_line.values.forEach(s => {

      if (s.product_id && s.product_id.value)
        products.push(s.product_id.value)
    })

    await this.odooEm.resolveArray(new ProductSupplierinfo(), products, "variant_seller_ids").toPromise()
    await this.odooEm.resolveArray(new ProductTemplateAttributeValue(), products, "product_template_attribute_value_ids").toPromise()
    this.order = order

    this.loading = false

  }

  isLineDoubleUM(line) {
    return true
  }


  innerText(s:string) {
    return s.replaceAll("<p>", "").replaceAll("</p>", "\n")
  }


  getPz(line:PurchaseOrderLine) {


    var l = Number(this.getVariantAttribute(line, 'Lunghezza')?.name)
    var h = Number(this.getVariantAttribute(line, 'Altezza')?.name)
    var w = Number(this.getVariantAttribute(line, 'Larghezza')?.name)

    if (line.product_id.value.uom_id.name.includes('²')) 
      var v = l  * w / 1000000
    else if (line.product_id.value.uom_id.name.includes('³'))
      var v = l * h * w / 1000000000  
    

    return line.product_qty / v
  }

  

  getVariantAttribute(line: PurchaseOrderLine, name: string):ProductTemplateAttributeValue|null{


    console.log("getva", line)
    if (!line.product_id)
      return

    if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
      return 
      console.log("getva2", line)

    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }
    console.log("getva3", f)

    return (f && f.length > 0) ? f[0] : null
  }




  isLineEditable(line: PurchaseOrderLine) {
    if (!line.product_id || !line.product_id.value)
      return false
    var a = line.product_id.value.product_template_attribute_value_ids.values.filter(x => x.name == "su misura")
    return a.length == 1
  }


  async print(): Promise<void> {


    this.loading = true;

    let headerRow; // just one

    // headerRow = ["Ordine di acquisto", "Fornitore", "Riferimento fornitore", "Valuta", "Data conferma", "Consegna a","Note","Termine di resa","Termini di pagamento" ]
    this.elementRef.nativeElement.querySelectorAll('[data-print-row-header]').forEach(element => {
      headerRow = (row2printable(element))
    });

    const headerDatas = []; // more than one
    this.elementRef.nativeElement.querySelectorAll('[data-print-row]').forEach(element => {
      var ret = row2printable(element)
      headerDatas.push(ret);
    });

    console.log("ERR3 headerRow    ",headerRow)
    console.log("headerRow    ",headerRow)

    var sheetid = await this.gapiService.printOrder(
      PRINTS_PURCHASE_CFG.template_sheet_id,
      PRINTS_PURCHASE_CFG.spool_folder_id,
      headerRow,
      headerDatas)
    window.location.href = 'https://docs.google.com/spreadsheets/d/' + sheetid, '_blank';
    this.loading = false;

  }



}
