<div 
  [ngClass]="{
    'bg-success': !action.attendance.check_out,
    'bg-dark': action.attendance.check_out
  }"
  class="h-100 d-flex align-items-center justify-content-center bg-success text-white"
>

    <div *ngIf="!action">
        Sto controllando
    </div>
    
    <div class="d-flex">

      <div *ngIf="action && !action.attendance.check_out">
        <h1>Benvenuta/o</h1>
        {{action.employee_name}}

        <p class="lead me-2 mt-1">
          {{formateTime(action.attendance.check_in)}}
        </p>

        {{formateDate(action.attendance.check_in)}}
      </div>
  
      <div *ngIf="action && action.attendance.check_out">
        
        <h1>Arriverderci</h1>
        
        {{action.employee_name}}
        <p class="lead me-2">
          {{formateTime(action.attendance.check_in)}}
          {{formateTime(action.attendance.check_out)}}
          
        </p>
        {{formateDate(action.attendance.check_out)}}
      </div>

    </div>
    

  </div>
  