import { Component, ElementRef, OnInit } from '@angular/core';
import Drawflow from 'drawflow'
import { PineconeClient } from 'pinecone-client';

@Component({
  selector: 'app-mindmap',
  template: '<div id="canva" class="w-100 h-100" style=" background-color: #DAE4E4;"></div>',
  styleUrls: ['./mindmap.component.scss']
})
export class MindmapComponent implements OnInit{
  
  constructor(private elref:ElementRef) {

  }

  ngOnInit(): void {

    
    const editor = new Drawflow(this.elref.nativeElement.querySelector('#canva'));
    editor.start();

    var html = document.createElement("div");
    html.innerHTML =  "Hello Drawflow!!";
    editor.registerNode('test', html);

    // editor.registerNode('test2', html);
    // Use
    editor.addNode('search', 0, 1, 150, 300, '', {'model':'s'}, 'test', false);
    editor.addNode('2', 1, 1, 110, 300, '', {}, 'test', false);
    editor.addNode('3', 1, 1, 110, 300, '', {}, 'test', false);
    // editor.addNode(name, inputs, outputs, posx, posy, class, data, html);




    console.log(editor)
  }
  
}
