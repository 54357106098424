import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
const NDEFReader = (window as any).NDEFReader;

@Component({
  selector: 'app-badge-write',
  templateUrl: './badge-write.component.html',
  styleUrls: ['./badge-write.component.scss']
})
export class BadgeWriteComponent implements OnInit {

  constructor(
    public route: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {


    this.route.params.subscribe(async params => {
    
      const ndef = new NDEFReader();
      await ndef.write({
        records: [
          { recordType:"url", data:"https://m3.galimberti.eu/badge/read/" + params.id },
        ]
      })
      
      document.write("Ok")
    })


    


  }

}
