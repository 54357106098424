<nav class="navbar navbar-expand bg-dark navbar-dark">

  <div class="container-fluid">
    <div class="collapse navbar-collapse px-0 " id="navbarSupportedContent">


      <button *ngIf="backroute" class="btn btn-link text-primary" [routerLink]="backroute ? backroute : '..'" queryParamsHandling="preserve">
        <i class="fas fa-chevron-left"></i>
      </button>


      <ng-content></ng-content>



      <!-- <button class="btn btn-link text-white"></button> -->

      <!-- New dropdown button -->
      <div class="dropdown">


        <!-- contactId is defined as input , conteact-detail set it , others pages didn't set -->
        <!-- <i id="dropdownMenuButtonC" *ngIf="contactId" data-bs-toggle="dropdown"
          class="fa-duotone fa-lg fa-comment-alt fa-lg text-white"></i>


        <div class="dropdown-menu dropdown-menu-end" *ngIf="contactId" style="width: 350px;"
          aria-labelledby="dropdownMenuButtonC"> -->
          <!-- Dropdown items here -->
          <!-- <app-message-widget [model]="'res.partner'" [res_id]="contactId"></app-message-widget>
        </div> -->

        <!-- contactId is defined as input , conteact-detail set it , others pages didn't set -->
        <!-- <i id="dropdownMenuButtonL" *ngIf="leadId" data-bs-toggle="dropdown"
          class="fa-duotone fa-lg fa-comment-alt fa-lg text-white"></i>

        <div class="dropdown-menu dropdown-menu-end" *ngIf="leadId" style="width: 350px;"
          aria-labelledby="dropdownMenuButtonL">
          <app-message-widget [model]="'crm.lead'" [res_id]="leadId"></app-message-widget>
        </div> -->


      </div>



      <a href="https://trello.com/b/W3EJtffy/910-odoo-manuale" target="_blank" class="btn btn-link d-none d-md-block">
        <i class="fa-solid fa-lg fa-circle-question fa-lg text-light"></i>
      </a>

    </div>
  </div>



</nav>
<bar-loader [loading]="loading"></bar-loader>