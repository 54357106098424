import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RestapiService, QuerySearchOptions, ApiEvent, EventType, QueryPostOptions, QueryCriteria } from 'src/app/shared/services/rest-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AREAS_CFG } from '../../models/deal'
import { ViewChild } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { Lead } from 'src/app/models/crm.lead.model';
import { CrmStage } from 'src/app/models/crm.lead.stage.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { OdooRelationship } from 'src/app/models/odoo-relationship.model';

@Component({
  selector: 'app-deals-dashboard',
  templateUrl: './deals-dashboard.component.html',
})
export class DealsDashboardComponent implements OnInit, AfterViewInit {
  stages: CrmStage[] = []
  cards: Lead[] = []
  filteredCards: Lead[] = []
  @Input() loading: boolean = true
  searchDealsInput: string = ''
  areas: { name: string; src: string; dst: string; }[]
  newDeal:Lead
  filter: BehaviorSubject<string> 
  justRecents = true
  @Input() title = "Fascicoli"
  @ViewChild('searchInput', {static:true}) searchInput: ElementRef
  @Input() embedded:boolean = false
  @Output() onSelect:EventEmitter<boolean> = new EventEmitter()
  lastSearch: number;

  constructor(
    public restapi : RestapiService,
    private router: Router,
    private route: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private odooEM: OdooEntityManager
  ) {
    // cache filter val
    var i = localStorage.getItem('deals-dashboard-filter')
    i = i ? i : ''

    this.filter = new BehaviorSubject(i)
    this.filter.subscribe((f) => {
      // sync new deal area default with active filter
      console.log("FILTER2", f)
      localStorage.setItem('deals-dashboard-filter', f)
      if (this.newDeal)
        this.newDeal.area = f
    })
  }

  async ngOnInit() {
    this.areas = AREAS_CFG
    this.newDeal = new Lead()
    //this.newDeal._isExpressSale = false
    this.newDeal.area = this.filter.value
    this.newDeal.partner_id = new OdooRelationship()
    this.stages = await this.odooEM.search<CrmStage>(new CrmStage(),[]).toPromise()

    this.route.queryParams.subscribe(params => {
      params.search ? this.searchDealsInput = params.search : this.searchDealsInput = ''
      this.refresh()
    })

  }


  async refresh() {

    this.loading = true

    let x= Math.random()
    this.lastSearch = x

    
    var d = new Date();
    d.setMonth(d.getMonth() - 3);

    var dom:any = [['company_id',"=",1]]
    
    if (this.justRecents)
      dom.push(['write_date','>=', d.toDateString()])

    if (this.searchDealsInput && this.searchDealsInput.match(/[vV][0-9][0-9][0-9][0-9]*/)) {
      dom.push(["name", "ilike", this.searchDealsInput])
      var ids = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), dom))

      this.cards = await this.odooEM.search<Lead>(
        new Lead(),
        [['order_ids', "in", ids.map(i => i.id)]], 1000, null, 'tracking_code asc').toPromise()
    } else if (this.searchInput) {
      dom.push('|')
      dom.push('|')
      dom.push('|')
      
      dom.push(['partner_id', 'ilike',this.searchDealsInput])
      dom.push(['name', 'ilike',this.searchDealsInput])
      dom.push(['tracking_code', 'ilike',this.searchDealsInput])
      dom.push(['street', 'ilike',this.searchDealsInput])

      const r = await this.odooEM.search<Lead>(
        new Lead(),
        dom, 500, null, 'tracking_code asc').toPromise()

      if (this.lastSearch != x) {
        return
      }
      this.cards = r
    }

    this.loading = false
  }

  ngAfterViewInit(): void {
    // debounce search input changes
    fromEvent(this.searchInput.nativeElement, 'input')
    .pipe(debounceTime(400))
    .subscribe(e => {
      this.searchDealsInput = (e as any).target.value
      // if (this.searchDealsInput)
        this.router.navigate([], { queryParams: { search: this.searchDealsInput } });

      this.refresh()
    });
  }

  onContact(c) {
    if (c == null)
      this.newDeal.partner_id = new OdooRelationship()
    else {
      this.newDeal.partner_id = new OdooRelationship(c.id, c.name)
    }
    // this.newDeal.partner_id = [c.id,''];
  }
  
  onCreate() {
    this.createDeal()
  }

  
  pickContact() {
    var w = window.open('/contact?mode=embedded','_blank','height=700,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes' );
    
    var h = event => {
      var d = JSON.parse(event.data)
      this.newDeal.partner_id = new OdooRelationship(d.id,d.name)
      w.close()
    }
    window.addEventListener("message", h, false);
    w.onbeforeunload = () => {
      window.removeEventListener("message", h)
    }
  }

  async createDeal() {

    this.loading = true
    // todo move in abstarct model
    var x = {

              "area": this.newDeal.area, 
              "partner_id": this.newDeal.partner_id.id,
              "name":"",
              "description":"",
              "city":"",
              "street":"",
              "contact_name":  this.newDeal.partner_id.name,
              "type": "opportunity"
            }

   // if (this.newDeal._isExpressSale) {
     // x['stage_id'] = 4 // da fatturare
     // x['name'] = "Vendita banco"
   // }

    // var postOptions : QueryPostOptions = {
    //   table : "crm.lead",
    //   json_fields : JSON.stringify(x)
    // }
    
    await this.odooEM.create<Lead>(new Lead(),x).subscribe(async (res) => {

      // if (this.newDeal._isExpressSale) {
      //   let s = {
      //     partner_id: this.newDeal.partner_id.id,
      //     opportunity_id: res.id
      //   }
      //   await firstValueFrom(this.odooEM.create<SaleOrder>(new SaleOrder(), s)) 
      // }

      this.loading = false
      // Add the new deal to the cards array
      // this.cards.unshift(JSON.parse(JSON.stringify(res)))
      this.redirectDeal(res.id)
    })

  }

  redirectDeal(id) {
    if (!this.embedded)
      // this.router.navigate([],  });

      this.router.navigate(['leads/'+id], { queryParams: { search: this.searchDealsInput }});
    else {
      this.onSelect.emit(id)
      // this.
    }
  }

  // filter cards by id and by searchbar substrings match (name and description)
  filterCards(stageid) {
    return this.cards.filter(c => {

      if (this.filter.getValue()  != "" && c.area != this.filter.getValue() )
        return false

      return (c.stage_id.id == stageid)
    
    })
  }

  // Calculate the total estimated value for stage type 
  calculateTotalRevenueValues(filteredCards) {
    for(let stage of this.stages) {
      stage.totalRevenueValue = 0;
      for(let card of filteredCards) {
        stage.totalRevenueValue = stage.totalRevenueValue + card.planned_revenue;    
      }
    }
  }

  toggleRecents() {
    this.justRecents = !this.justRecents
    this.refresh()
  }

}
