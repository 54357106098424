import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestapiService } from '../shared/services/rest-api.service';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { Subject, debounceTime, firstValueFrom } from 'rxjs';
import { SaleOrder } from '../models/sale-order.model';
import { StockPicking } from '../models/stock-picking';
import { ODOO_IDS } from '../models/deal';
import { ProcurementGroup } from '../models/procurement.group.model';
import { User } from '../models/user.model';
import { PurchaseOrder } from '../models/purchase-order.model';
import { StockMove } from '../models/stock-move';
import { CrmLeadPart } from '../models/crm.lead.part.model';
import { Lead } from '../models/crm.lead.model';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit{

  id: any;
  loading: boolean;
  pickings: StockPicking[];
  users: User[];
  filterUser: User;
  purchases: PurchaseOrder[] = []
  shipments: StockPicking[] = [];
  opportunities: Lead[];
deals: any;
  leads: Lead[];
  refresh$: any = new Subject();
  filterLead: Lead;

  constructor(
    private route: ActivatedRoute,
    private odooEm: OdooEntityManager,
    public router: Router
  ) { }

  
  async ngOnInit(): Promise<void> {
    let x = JSON.parse(localStorage.getItem('timeline_user'))
    if (x)
      this.filterUser = x

    this.users = await firstValueFrom(this.odooEm.search<User>(new User()))
    this.route.params.subscribe(async params => {
      
      this.id = params['order_id']
      // available routes in sale
      // this.selectableRoutes = await this.odooEm.search<StockLocationRoute>(new StockLocationRoute(),[["sale_selectable","=",true]]).toPromise()
      await this.load()
    }) 

    this.refresh$.pipe(debounceTime(250)).subscribe(s => {
      this.load()
    })
  }

  loadPurchases(p:ProcurementGroup) {
    this.purchases = []
    this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['origin','=',p.name]]).subscribe(purchases => {
      this.purchases = purchases
    })
  }


  

  getPurchasesFor(p:ProcurementGroup) {
    return this.purchases.filter(pu => pu.origin == p.name)
  }
  


  getPickingsFor(p:StockPicking) {
    return this.pickings.filter(pu => {
        return pu.group_id.id == p.group_id.id && pu.state != 'cancel' && !pu.name.includes('OUT')
      }
    ) 
  }


  async onUserChange($event: any) {
    localStorage.setItem('timeline_user', JSON.stringify($event))
    this.filterUser = $event

    await this.load()
  }


  onDateChange(data) {

  }

  uniqueByPicking(ms:StockMove[]) {
    // var pickings = []
    // ms.forEach(m => {
    //   let p = pickings.find(p => p.id == m.picking_id.id)
    //   if (!p) {
    //     pickings.push(m.picking_id.value)
    //   }
    // })

    // const unique = [...new Set(ms.map(item => item.picking_id))]; // [ 'A', 'B']
    // return unique
  }

  async load() {
    // search all saleorder confirmed
    this.loading = true;

    let criteria:any = [
      ['picking_type_id','in',ODOO_IDS.picking_type_shipments],
      '|',
      '|',
      ['state', '=', 'waiting'],
      ['state', '=', 'assigned'],
      ['state', '=', 'confirmed']
    ]

    if (this.filterUser) {
      criteria.push(['sale_id.user_id', '=', this.filterUser.id])
    }

    let ps = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), 
      criteria,
      100,
      null,
      "scheduled_date ASC"
    ))

    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new SaleOrder(), ps, 'sale_id'))
    let sales = ps.map(p => p.sale_id.value)
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Lead(), sales, 'opportunity_id'))
    
    // this.opportunities = await firstValueFrom(this.odooEm.search<Lead>(new Lead(), [['name','in',opportunities]]));

    // let fps = ps.filter(p => p.group_id.id ? p : false)
    // console.log(fps)
    
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new ProcurementGroup(), ps, 'group_id'))
    let groups = ps.map(p => p.group_id.id)


    // let groups = fps.map(p => p.group_id.value)
    // await firstValueFrom(this.odooEm.resolveArray(new StockMove(), groups, 'stock_move_ids'))
    this.pickings = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['group_id','in',groups]]));


    this.purchases = await firstValueFrom(this.odooEm.search<PurchaseOrder>(new PurchaseOrder(), [['group_id','in',groups]]));


    // leads select
    this.leads = sales.map(s => s.opportunity_id.value)

    this.shipments = ps


    if (this.filterLead) {


      this.shipments = this.shipments.filter(s => {
        console.log("SS",s)
        return s.sale_id.value.opportunity_id.value?.id == this.filterLead.id
    })
    }


    this.loading = false;
  }


  setFilterLead(l:Lead) {
    this.filterLead = l
    console.log("SET FILTER LEAD", l)
    this.refresh$.next()
  }

}
