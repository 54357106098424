import { Component, OnInit } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { StockPicking } from '../models/stock-picking';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../models/product.model';
import { SaleOrderLine } from '../models/sale-order-line.model';
import { SaleOrder } from '../models/sale-order.model';
import { Lead } from '../models/crm.lead.model';

@Component({
  selector: 'app-delivery-note-viewer',
  templateUrl: './delivery-note-viewer.component.html',
  styleUrls: ['./delivery-note-viewer.component.scss']
})
export class DeliveryNoteViewerComponent implements OnInit {
  picking: StockPicking;
  loading: boolean;
  origin: SaleOrder;

  constructor(
    private odooEM:OdooEntityManager,
    private router:Router,
    private activeRoute:ActivatedRoute
  ) {
  }


  async ngOnInit(): Promise<void> {

    this.activeRoute.params.subscribe(async params => {
      this.loading = true

      let r = await firstValueFrom(this.odooEM.search<StockPicking>(new StockPicking(),[['id','=', Number(params.id)]]))


      

      if (r.length == 0) 
        return 
      this.picking = r[0]

      await firstValueFrom(this.odooEM.resolve(this.picking.move_ids)) 
      await firstValueFrom(this.odooEM.resolveArrayOfSingle(new Product(), this.picking.move_ids.values,'product_id'))

      let ss = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(),[['name','=', r[0].origin]]))
      if (!ss.length)
        return

      await firstValueFrom(this.odooEM.resolveSingle(new Lead(), ss[0].opportunity_id));
      // load lines
      await firstValueFrom(this.odooEM.resolve(ss[0].order_line)) 
      await firstValueFrom(this.odooEM.resolveArrayOfSingle(new Product(), ss[0].order_line.values,'product_id'))

      this.origin = ss[0]
      this.loading = false
      // // load moves
      // let picking = r[0]
      // await firstValueFrom(this.odooEM.resolve(picking.move_ids)) 
      // await firstValueFrom(this.odooEM.resolveArrayOfSingle(new Product(), picking.move_ids.values,'product_id'))
      // this.picking = picking


      // // load origin
      // let ors = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), [['name','=', picking.origin]]))
      // if (ors.length>0)
      //  this.origin = ors[0]

    })
  }

  async confirm() {

    if (!confirm("Confermi l'operazione ? Verrà scaricato solo il materiale pronto"))
      return
    this.loading = true

    await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "action_assign", [[this.picking.id]]))
    await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "action_set_quantities_to_reservation", [[this.picking.id]]))
    await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[this.picking.id]]))

    this.router.navigate(["/delivery-note"]);

    // temp fix  - confirm them all
    // let ps = await firstValueFrom(this.odooEM.search<StockPicking>(
    //   new StockPicking(),
    //   [
    //     ['group_id','=', Number(this.picking.group_id.id)],
    //     "|", "|", 
    //     ['state', '=', 'waiting'], 
    //     ['state', '=', 'confirmed'],
    //     ['state', '=', 'assigned']
    //   ],200,null,"id ASC")
    // )
    
    
    // for (var x = 0; x < ps.length; x++) {
    //     await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "action_assign", [[ps[x].id]]))
    //     await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "action_set_quantities_to_reservation", [[ps[x].id]]))
    //     await this.check(this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[ps[x].id]]))
    //     // let await this.odooEM.call2(new StockPicking().ODOO_MODEL, "action_assign", [[ps[x].id]])
    //     // await )
    //     // await this.odooEM.call2(new StockPicking().ODOO_MODEL, "button_validate", [[ps[x].id]])
    // }
    
  }

  async check(f) {
    let r = await f
    if (r.error) {
      this.loading = false
      alert(r.error.data.message)
      throw (r.error.data.message)
    }
    return r
  }

}
