import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
import { Action, DashboardComponent } from '../dashboard.component';

@Component({
  selector: 'app-action-search',
  templateUrl: './action-search.component.html'
})
export class ActionSearchComponent implements OnInit {

  @Output() onDelete: EventEmitter<Action> = new EventEmitter<Action>()
  @Input() action: Action
  @Input() state: string   //"inspector" | "canvas" 
  debugQuery: string;
  parsed: any;
  error: any;
  busy: boolean;
  results: any[];



  constructor(private http: HttpClient, private odooRPC: OdoorpcService) { }

  ngOnInit(): void {

    this.refresh()
  }


  delete() {
    this.onDelete.next(this.action)
  }


  async refresh() {
    if (this.action.params[0])
    
      var x = await this.odooRPC.searchRead({
        table: this.action.parsed.model,
        criteria: this.action.parsed.domain,
        // fields: this.action.parsed.fields,
        limit: 10
      })

    this.action.results = x
  }
  async execute() {
    this.action.busy = true
    let pr = ''
    // if (previous) {
    //   console.log("prev", previous.results)
    //   pr = pr + "il risultato della ricerca precedente sono i seguenti id " + previous.results.map(x => x.id).join(",")
    // }

    // pr = pr + 'var x = { "model": "purchase.order", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]], "fields": ["id","name"]}'
    // pr = pr + 'var x = { "model": "crm.lead", "domain": [["partner_id", "in", [56903,39745,39749,39779,39780]]] }'

    // var x = {
    //   "model": "",
    //   "domain": [],
    //   "fields": [],
    //   "group_by": ""
    // }              
    // modificando le proprietà 'model' e 'domain' e 'fields' e 'group_by' .
    // per una query su odoo che usa il modello che rappresenta ${this.action.params[0].value} con questo criteri dominio  ${this.action.params[1].value}.
    // il campo "fields" e' un array delle properità degli oggetti ${this.action.params[0].value}  di questo tipo ${this.action.params[2].value}
    // rispondi solo con il codice. quando cerchi stringhe usa sempre l'operatore ilike.
    // quando si deve inserire un criterio chiamato o od oppure bisogna usare l'operatore "|"
    // se si chiede di raggruppare usa la colonna group_by
    // l'operatore "|" nella query del dominio va messo prima dei due criteri tra cui scegliere  quindi  "domain": ["|", criterio 1, criterio2]





    // pr = pr + 'i principali campi di un res.partner sono id, name, city,street'
    pr = pr + `riscrivi questo script json 
    var x = {
      "model": "",
      "domain": [],
      "fields": [],
      "group_by": ""
    }              
    con il campo model che il nome della classe che rappresenta ${this.action.params[0].value} in odoo
    con il campo "domain" che specifica i criteri di ricerca come nell'interfaccia xmlrpc di odoo secondo questi criteri ${this.action.params[1].value}
    il campo "fields" e' un array delle properità degli oggetti ${this.action.params[0].value}  di questo tipo ${this.action.params[2].value} ordinati secondo quanto specificato se specificato
    rispondi solo con il codice. 
    quando cerchi stringhe usa sempre l'operatore ilike.
    quando si deve inserire un criterio chiamato o od oppure bisogna usare l'operatore "|"
    se si chiede di raggruppare usa la colonna group_by
    l'operatore "|" nella query del dominio va messo prima dei due criteri tra cui scegliere  quindi  "domain": ["|", criterio 1, criterio2]
    `

    this.debugQuery = pr
    // pr = 'write hello in an heading in an html file'

    var response = await this.http.post<any>(
      'https://api.openai.com/v1/completions',
      {
        prompt: [
          pr
        ],
        max_tokens: 150,
        n: 1,
        stop: null,
        temperature: 0.1,
        model: "text-davinci-003"
        // model: "curie:ft-personal-2023-05-09-19-32-34"
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + DashboardComponent.k
        }
      }
    ).toPromise();
    response = response.choices[0].text.trim();
    // this.action.debug = response

    var q = response.substring(response.indexOf('=') + 1)
    if (q.endsWith(";"))
      q = q.slice(0, -1)
    try {
      this.parsed = JSON.parse(q)
      this.action.parsed = this.parsed

      // this.parsed.domain.forEach(p => {
      //   if (p[1] == '>')
      //     p[1] = 'gt;'
      // });

      // handle | or in odoo
      if (this.parsed.domain) {

        if (this.action.params[3] && this.action.params[3].value != '') {
          var x = await this.odooRPC.groupByRead({
            table: this.parsed.model,
            criteria: this.parsed.domain,
            fields: this.parsed.fields,
            limit: 10
          }, this.action.params[3].value)
        } else {
          var x = await this.odooRPC.searchRead({
            table: this.parsed.model,
            criteria: this.parsed.domain,
            fields: this.parsed.fields,
            limit: 20
          })
        }

      }

      this.action.results = x
    } catch (e) {
      this.action.error = e
      this.action.busy = false
    } finally {
      this.action.busy = false
    }
    this.action.results = x
  }





}
