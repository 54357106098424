<div class="card text-white" *ngIf="state == 'canvas'">

    <div class="card-header d-flex align-items-center " *ngIf="state == 'canvas'">
        <!-- Cerca -->
        <button class="btn btn-sm" (click)="refresh()"><i class="fa fa-refresh"></i></button>
        <i class="fa  ms-auto fa-trash" (click)="delete()"></i>
    </div>
    <div class="card-body p-0" *ngIf="state == 'canvas'">
        <table *ngIf="action.results" class="table flush mb-0 h-100">
            <thead class="">
                <tr>
                    <!-- <th>ID</th> -->
                    <!-- <th>NOME</th> -->
                    <th *ngFor="let item of action?.results[0] | keyvalue">
                        {{item.key}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let r of action.results">
                    <!-- <td>{{r.id}}</td>
                <td><a target="_new" href="https://o3.galimberti.eu/web#id={{r.id}}&model=&view_type=form">{{r.display_name}}</a></td> -->
                    <td *ngFor="let item of r | keyvalue">
                        {{item.value}}
                    </td>
                    <!-- <td *ngFor="let k of r | keyvalue">
                    {{k}}
                </td> -->
                </tr>
            </tbody>
        </table>
    </div>

</div>


<div class="d-flex card text-white flex-column" *ngIf="state == 'inspector'  ">
        <div class=" ">
            <form class="p-3 w-100">
                <div class="row">
                    <div class="col-4">
                        <label class="form-label text-muted">Modello</label>
                        <input class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="action.params[0].value">
                    </div>
                    <div class="col-8">
                        <label class="form-label text-muted">Criteri</label>
                        <input class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="action.params[1].value">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <label class="form-label text-muted">Colonne</label>
                        <input class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="action.params[2].value">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12">
                        <label class="form-label text-muted">Raggruppa per</label>
                        <input class="form-control" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="action.params[3].value">
                    </div>

                </div>
            </form>

        </div>

        <div class="d-flex p-3">
            <button class="btn btn-success w-100 text-white" [disabled]="action.busy" (click)="execute()">
                EXECUTE
            </button>
        </div>

</div>