import { Component, OnInit, ViewChild } from '@angular/core';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SaleOrder } from '../models/sale-order.model';
import { firstValueFrom } from 'rxjs';
import { Product } from '../models/product.model';
import { SaleOrderLine } from '../models/sale-order-line.model';
import { OrderInventoryComponent } from '../components/order-inventory/order-inventory.component';
import { state, style, trigger } from '@angular/animations';
import { ProductTag } from '../models/product.tag.model';
import { StockLocationRoute } from '../models/stock-location-route.model';
import { OdooRelationship } from '../models/odoo-relationship.model';
import { SaleOrderCancel } from '../models/sale-order-cancel.model';
import { StockPicking } from '../models/stock-picking';
import { Contact } from '../models/contact.model';
import { ODOO_IDS } from '../models/deal';
import { DeliveryCarrier } from '../models/delivery-carrier.model';

@Component({
  selector: 'app-flash-sale-order-editor',
  templateUrl: './flash-sale-order-editor.component.html',
  styleUrls: ['./flash-sale-order-editor.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      }))
  ])]})

  
export class FlashSaleOrderEditorComponent implements OnInit {
  loading: boolean = true;
  order: SaleOrder;
  keybuffer: string = "";
  masterLine: SaleOrderLine;
  descriptiveArray: any[];
  @ViewChild('inventory') inventory:OrderInventoryComponent;
  // tag: any;
  route?:StockLocationRoute;
  tempLine: SaleOrderLine;
  scanning: boolean;
  firstPicking: StockPicking;
  carriers: DeliveryCarrier[];
  notePicking: StockPicking;

  constructor(
    private odooEm:OdooEntityManager,
    private activeRoute:ActivatedRoute,
    private router:Router,
  ) {
    
  }

  ngOnInit(): void {
    this.loading = true
    this.activeRoute.params.subscribe(async params => {

      // tag diretto
      // let tag = await firstValueFrom(this.odooEm.search<ProductTag>(new ProductTag(),[['name','=', 'DIRETTO']])) 
      // if (!tag.length)
      //   return alert("manca tag DIRETTO")
      // else this.tag = tag[0]

      // percorso route di default
      let route = await firstValueFrom(this.odooEm.search<StockLocationRoute>(new StockLocationRoute(),[['id','=', ODOO_IDS.diretto_route_id]])) 
      if (!route.length)
        return alert("manca route per vendita immediata")
      else 
      
      this.route = route[0]


      let o = new SaleOrder(Number(params['order_id']))
      await this.refreshSale(o)
      console.log("OOOO", o)
      await this.resolveSale(o)
      this.order = o
      
      // var res = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(),[['id','=', Number(id)]])) 
      // console.log("SALE ", res[0])
      // if (res.length) {
      //   await this.resolveSale(res[0])
      //   this.order = res[0]
      // }
    })

    window.addEventListener("keypress", e => {
        if (e.key == "Enter") {
          // this.onScan(this.keybuffer)
          this.onBarcode(this.keybuffer)
          this.keybuffer = ""

        } else 
          this.keybuffer = this.keybuffer + e.key
      })
    this.loading = false
  }


  async onAddProduct(p:Product) {

    this.inventory.close()
    let l = this.order.order_line.values?.find(l => l.product_id.id  == p.id)
    if (l) {
      this.onLine(l)
    } else {
      // don't save until confirmed
      // this.tempLine = new SaleOrderLine()
      // this.tempLine.order_id = new OdooRelationship(this.order.id, this.order.name)
      // this.tempLine.product_id = new OdooRelationship(p.id, p.display_name, p);
      // this.tempLine.product_uom_qty = 0;
      // this.tempLine.route_id = new OdooRelationship(this.route.id,this.route.name)

      l = await firstValueFrom(this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), {
            order_id: this.order.id,
            product_id: p.id,
            product_uom_qty: 0,
            route_id: this.route.id
          }));
      
      if (!l) return alert("Errore durante il salvataggio")
      await this.refreshSale(this.order)
      await this.resolveSale(this.order)
      
      // l = (await firstValueFrom(this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), [['id', '=', l.id]])))[0];
      l = this.order.order_line.values.find(x => x.id == l.id)

      this.onLine(l)
      // this.updateMasterLine()
      // console.log("REMP;LIEN", this.tempLine)
      // this.inventory.close()
      // this.onLine(this.tempLine)
      // await this.resolveSale(this.order)
    }
  }

  updateSearch(val) {
    this.inventory.inputSearch.next(val)
    this.inventory.refresh$.next(true)
  }

  closeInventory() {
    this.inventory.close()
  }

 

  async share() {
    await navigator.share({
      title: "Vendita diretta " + this.order.name,
      text: "Vendita diretta " + this.order.name,
      url: window.location.origin + '/immediate-sale/s/' + this.order.id
    })
  }

  async onBarcode(code) {

    if (code == null)
      return
    let res = await firstValueFrom(this.odooEm.search<Product>(new Product(), [['barcode', '=', code]]))
    if (!res.length) return alert("Prodotto non trovato")

    console.log("resid" + res)
    // search in existing
    let l = this.order.order_line.values.find(l => l.product_id.id  == res[0].id)
    this.onAddProduct(res[0])

  }



  async refreshSale(s:SaleOrder) {
    let r = await firstValueFrom(this.odooEm.search<SaleOrder>(new SaleOrder(), [['id','=', s.id]]));
    
    if (r.length) {
      let res = r[0]
      await firstValueFrom(this.odooEm.resolve(res.order_line))
      await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(),res.order_line.values,"product_id"))

      for (var key in s) {
        if (s.hasOwnProperty(key)) {
          // if (s[key] == null) {
              s[key] = res[key];
          // }
        }
      }
    }
    // pickings
    let picks = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['sale_id', '=', s.id], "|", "|", ['state', '=', 'assigned'], ['state', '=', 'done'],['state', '=', 'confirmed']]))
    this.firstPicking = picks.reverse().find(p => p.location_id?.id == ODOO_IDS.stock_location_stock)
    this.notePicking = picks.find(p => p.picking_type_id.id == ODOO_IDS.picking_type_ddt)
    console.log("FIRST ", this.firstPicking)
  }


  async resolveSale(o:SaleOrder) {
    await firstValueFrom(this.odooEm.resolveSingle(new Contact(), o.partner_id))
    if (!this.carriers)
      this.carriers = await firstValueFrom(this.odooEm.search<DeliveryCarrier>(new DeliveryCarrier()))
  }

  openPicking(p:StockPicking) {
    this.router.navigate(['/pickings/internal/', p.id],{ queryParams: { back: document.location.pathname.toString() }} )
  }


  async onLine(l:SaleOrderLine) {
    await firstValueFrom(this.odooEm.resolve(l.product_id.value.packaging_ids))
    this.descriptiveArray = this.getDescriptiveArrayOr(l)
    this.masterLine = l
  } 

  async onCarrier(v:DeliveryCarrier|boolean) {
    this.loading = true

    let c:DeliveryCarrier
    if (v == true)
      c = this.carriers[0]
    else if (v == false)
      c = null
    else 
      c = v

    await firstValueFrom(this.odooEm.update<StockPicking>(this.firstPicking, {
      carrier_id : c?.id
    }))

    // avoid refresh
    this.firstPicking.carrier_id.id = v ? this.carriers[0].id : null

    // await this.resolveSale(this.order)
    this.loading = false
    // if(!v) {
  }

  async updateDescriptiveOr(master, d, value) {
    d[0] = value
    let uom_qty = value * d[1].qty 
    await this.updateMaster(master, "product_uom_qty", uom_qty)
  }

  async back() {
    this.loading = true
    await this.resolveSale(this.order)
    this.masterLine = null
    this.loading = false
  }

  public async updateMasterLine() {
    this.loading = true
    // let p = {}
    // p[prop] = val

    // if (this.tempLine) {
    //   var l = await firstValueFrom(this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), {
    //     order_id: this.order.id,
    //     product_id: this.tempLine.product_id.id,
    //     product_uom_qty: this.tempLine.product_uom_qty,
    //     route_id: this.route.id
    //   }));
    //   console.log("LLL ", l)
    //   if (!l) return alert("Errore durante il salvataggio")
    //   // l = (await firstValueFrom(this.odooEm.search<SaleOrderLine>(new SaleOrderLine(), [['id', '=', l.id]])))[0];
    //   // this.masterLine = l
    //   await this.refreshSale(this.order)
    //   // return

    // } else {
      var r = await firstValueFrom(this.odooEm.update<SaleOrderLine>(this.masterLine, {
        'product_uom_qty': this.masterLine.product_uom_qty,
        'price_unit' : this.masterLine.price_subtotal / this.masterLine.product_uom_qty
      }))
    // }

    await this.resolveSale(this.order)
    this.masterLine = null
    this.loading = false
  }

  public async draft(s:SaleOrder) {
    this.loading = true
    try {
      await this.odooEm.call(new SaleOrder(), "action_draft",s.id).toPromise()
    } catch(e) {
      this.loading = false
      alert(e.message)
    }
    
    await this.refreshSale(s)
    this.loading = false
  }


  public async cancel(s:SaleOrder) {
    this.loading = true

    var cancel = await firstValueFrom(this.odooEm.create<SaleOrderCancel>(new SaleOrderCancel(),{
      order_id: s.id
    }))

    try {
      await this.odooEm.call(new SaleOrderCancel(), "action_cancel", [cancel.id]).toPromise()
    } catch(e) {
      this.loading = false
      alert(e.message)
    }
    await this.refreshSale(s)
    this.loading = false
  }
  
  public async confirm(s:SaleOrder) {
    if (!confirm("I prodotti verranno rimossi dalla Giacenza. Confermi ?"))
      return
      
    // check every line with a product has a route
    var fs = s.order_line.values.filter(l => (l && l.product_id?.value)  && (l && l.product_type == 'product')  && !l.route_id.id)
    if (fs.length > 0)
      return alert("Tutti i prodotti devono avere un percorso")
    this.loading = true

    
    await this.odooEm.call(new SaleOrder(), "action_confirm",s.id).toPromise()

    // var res = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), [['origin', '=', s.name]]))
    // if (!res.length) {
    //   alert("Errore. Non sono stati trovati trasferimenti!")
    //   return
    // }
    
    // for (var x = 0; x < res.length; x++) {
    //   await firstValueFrom(this.odooEm.call(new StockPicking(), "action_assign", res[x].id))
    //   await firstValueFrom(this.odooEm.call(new StockPicking(), "button_validate", res[x].id))
    // }
    
    // // confirm picking
    
    // await firstValueFrom(this.odooEm.call(new StockPicking(), "action_assign", this.shipmentPicking.id))
    // await firstValueFrom(this.odooEm.call(new StockPicking(), "action_set_quantities_to_reservation", this.shipmentPicking.id))
    // await firstValueFrom(this.odooEm.call(new StockPicking(), "button_validate", this.shipmentPicking.id))
    
    // // find the firt picking that moves from Giacenza
    // console.log("PICKING ", p)
    // this.odooEm.call2("stock.picking", "")

    await this.refreshSale(s)
    this.loading = false
  }


  async toDeliveryNote() {
    await this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_assign", [[this.firstPicking.id]])
    await this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_set_quantities_to_reservation", [[this.firstPicking.id]])
    await this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [[this.firstPicking.id]])
    await this.refreshSale(this.order)
    await this.resolveSale(this.order)

  } 


  async updateMaster(master, prop, val ) {
    master[prop] = val
    master['price_subtotal'] = master.price_unit * this.masterLine.product_uom_qty
    this.descriptiveArray = this.getDescriptiveArrayOr(master)
    this.loading = false
  }

  scanWithCamera() {
    this.scanning = true
  }


  async ship() {
    // resolve if it's not
    if (!this.order.picking_ids.values) {
      await firstValueFrom(this.odooEm.resolve(this.order.picking_ids))
    }

    // find the shipment
    var ship = this.order.picking_ids.values.find(p => p.state == "draft" && p.picking_type_id.id == ODOO_IDS.picking_type_shipment)
    if (!ship) 
      return alert("Errore - Picking di consegna non trovato")
  }

  async deleteLine(line:SaleOrderLine) {
    if (!confirm("Confermi eliminazione ?"))
      return 

    this.loading = true    
    var r = await firstValueFrom(this.odooEm.delete(new SaleOrderLine(), [line.id])) 

    this.masterLine = null
    await this.refreshSale(this.order)
    await this.resolveSale(this.order)
    this.loading = false
  }
  
  getDescriptiveArrayOr(line:SaleOrderLine):any[] {
    if (!line.product_id.value)return []
    
    if (!line.product_id.value.packaging_ids.values) {
      return []
    }
      
    var ps = line.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.product_uom_qty
    
    var d = ""
    var a = []

    ps = ps.filter(x => x.sales)
    
    ps.forEach((p, i) => {
        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0
        a.push([parseFloat((q / p.qty).toFixed(4)) ,p,0])
    })

    return a
   }


  getDescriptiveArray(line:SaleOrderLine):any[] {
    if (!line.product_id.value)return []
    
    if (!line.product_id.value.packaging_ids.values) {
      alert("non risolto")
      return []
    }
      
    var ps = line.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.product_uom_qty
    
    var d = ""
    var a = []

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    ps.forEach((p, i) => {
        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0

        if ((ps.length - 1) == i) {
          d += parseFloat((q / p.qty).toFixed(4)) + " " + p.name + "\n"
          a.push([parseFloat((q / p.qty).toFixed(4)), p, 0])
          // q = q - (xx * p.qty)
        } else if (!isRound) {
          q = q - (x * p.qty)
          d += x + " " + p.name + "\n"
          a.push([x, p, 0])
        } else {
          q = q - (xx * p.qty)
          d += xx + " " + p.name + "\n"
          a.push([xx, p, 0])
        }
    })
    return a
   }
   
}