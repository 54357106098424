<style>



    #mainParent {
        height: 90vh;
        padding: 5px;
    }

    @media (min-width: 550px) {
        #mainParent {
            width: 550px;
        }
    }

    @media (max-width: 768px) {
        #mainParent {
            width: 300px;
        }
    }

    .gray {
        color: #6c757d
    }

    .alignLeft {
        left: 10px !important;
        right: auto !important;
    }

    .w-messages .icon-smile {
        display: none;
    }

    .w-messages:hover .icon-smile {
        display: block !important;
    }

    .suggestions-container {
        position: absolute;
        z-index: 1000;
    }

    .suggestion-item {
        cursor: pointer;
        text-align: start;
    }

    .scrollable-dropdown-content {

        height: 100%;
        overflow-y: auto;
    }

    .input-icon-container {
        position: relative;
    }

    .input-icon-container .form-control {
        padding-right: 30px;
        /* Adjust padding to ensure text does not overlap the icon */
    }

    .input-icon-container .icon {
        position: absolute;
        top: 5px;
        bottom: 0;
        right: 10px;
        /* Adjust based on preference */
        margin: auto 0;
        cursor: pointer;
        height: 20px;
        /* Adjust based on the size of your icon */
        /* pointer-events: none; */
        /* Makes the icon non-interactive */
    }

    .bg-gray {
        background-color: #eee;
    }

    .bg-green {
        background-color: #d4edda;
    }

    .w-messages {
        display: block;
    }

    .w-messages>div {
        max-width: 70%;
        position: relative;
        /* Make this a position reference for its children */
        margin-bottom: 20px;
    }

    .reactions {
        position: relative;
        bottom: -15px;
        z-index: 1;
    }

    .w-messages .smiles {
        position: relative;
        /* Adjust based on your layout */
        bottom: -20px
            /* Adjust based on your preference */
    }

    .smiles2 {
        bottom: 55px;
        right: 20px;
    }
</style>


<div id="mainParent" class="d-flex flex-column justify-content-between" (click)="$event.stopPropagation()">

    <div #scrollableContent class="scrollable-dropdown-content">

        <ng-container *ngFor="let g of groupMessage">


            <div class="d-flex justify-content-center mb-2 ">
                <div class="bg-gray  text-dark p-2 rounded-5 px-3">
                    {{ g.dateFromNow }}

                </div>
            </div>




            <div *ngFor="let m of g.messages" class="w-messages d-flex"
                [ngClass]="{'justify-content-end': m.author_id.id === userId}">


                        <!-- Button placed before the message if m.author_id.id does NOT match userId -->
                        <button *ngIf="m.author_id.id === userId"
                            (click)="$event.stopPropagation();toggleReactions(m.id)" type="button"
                            class="btn btn-link icon-smile">
                            <i class="fal fa-smile" title="Inserisci emoji"></i>
                        </button>

                        <div [ngClass]="{'bg-green': m.author_id.id === userId, 'bg-gray': m.author_id.id !== userId}"
                            class=" rounded-3 p-2">
                            <div [ngClass]="{'text-end': m.author_id.id === userId}">

                                <div class="d-flex flex-column justify-content-between" style="height: 100%;">
                                    <b *ngIf="m.author_id.id != userId"><small>{{ m.author_id.name }}</small><br></b>
                                    {{ m.body | removeHtml }}
                                    <div class="mt-3 text-end"><small class="fs-6">{{m.dateHour}}</small></div>
                                </div>



                                <div *ngIf="m.attachment_ids && m.attachment_ids.values">

                                    <div *ngFor="let attachment of m.attachment_ids.values" class="mt-2">
                                        <a [href]="'/api/web/content/ir.attachment/' + attachment.id + '/datas?download=true'"
                                            [title]="attachment.name" target="_blank" class="d-block">
                                            <i class="fa fa-download"></i> {{ attachment.name }}
                                        </a>
                                        <img *ngIf="isImage(attachment)" style="max-width: 19vw;"
                                            [src]="'/api/web/content/ir.attachment/' + attachment.id + '/datas'"
                                            class="img-preview mt-2">
                                    </div>
                                </div>



                                <div *ngIf="m.reaction_ids && m.reaction_ids.values" class="reactions"
                                    [ngClass]="{'text-end': m.author_id.id === userId}">
                                    <span *ngFor="let reaction of m.reaction_ids.values" class="p-1 bg-gray rounded-4 ">
                                        <span [attr.title]="reaction.partner_id.name" data-bs-toggle="tooltip"
                                            data-bs-placement="top">
                                            {{ reaction.content }}
                                        </span>
                                    </span>
                                </div>





                            </div>

                            <div *ngIf="currentReactionMessageId === m.id" class="smiles position-relative">
                                <div class="btn-group btn-group-sm" role="group">
                                    <button type="button" (click)="setReaction($event, m,'😀')"
                                        class="btn btn-light">😀</button>
                                    <button type="button" (click)="setReaction($event, m,'😍')"
                                        class="btn btn-light">😍</button>
                                    <button type="button" (click)="setReaction($event, m,'👍')"
                                        class="btn btn-light">👍</button>
                                    <button type="button" (click)="setReaction($event, m,'⭐')"
                                        class="btn btn-light">⭐</button>
                                    <button type="button" (click)="setReaction($event, m,'😲')"
                                        class="btn btn-light">😲</button>
                                </div>
                            </div>

                        </div>

                        <!-- Button placed after the message if m.author_id.id matches userId -->
                        <button *ngIf="m.author_id.id !== userId"
                            (click)="$event.stopPropagation();toggleReactions(m.id)" type="button"
                            class="btn btn-link icon-smile">
                            <i class="fal fa-smile" title="Inserisci emoji"></i>
                        </button>



                <!-- Button Group to be shown on hover [ngClass]="{'alignLeft': m.author_id.id === userId}" -->



            </div>
        </ng-container>





    </div>

    <div class="d-flex mt-2">


        <!-- Button on the left -->
        <button class="border-0 me-1" id="button-addon1" (click)="$event.stopPropagation()">

            <!-- Hidden file input -->
            <input type="file" id="fileInput" style="display: none" (change)="uploadFile($event)" multiple accept="*/*">
            <label for="fileInput" role="button">
                <i class="fa fa-plus gray fa-lg"></i>
            </label>

        </button>





        <div *ngIf="showReactions" class="smiles2  position-absolute">
            <div class="btn-group btn-group-sm" role="group">
                <button type="button" (click)="insertSmile($event, m,'😀')" class="btn btn-light">😀</button>
                <button type="button" (click)="insertSmile($event, m,'😍')" class="btn btn-light">😍</button>
                <button type="button" (click)="insertSmile($event, m,'👍')" class="btn btn-light">👍</button>
                <button type="button" (click)="insertSmile($event, m,'⭐')" class="btn btn-light">⭐</button>
                <button type="button" (click)="insertSmile($event, m,'😲')" class="btn btn-light">😲</button>
            </div>
        </div>




        <div class="input-icon-container w-100 me-2">
            <input type="text" class="form-control rounded-5" [(ngModel)]="message" (keyup)="onKeyup($event)"
                placeholder="Scrivi un messaggio" aria-label="Recipient's username" aria-describedby="button-addon2">





            <i (click)="$event.stopPropagation();showReactions = !showReactions " class="fal fa-smile gray icon"></i>

        </div>


        <!-- Suggestions container -->
        <div *ngIf="showSuggestions" class="suggestions-container mt-5 w-100">
            <ul class="list-group">
                <li class="list-group-item suggestion-item" *ngFor="let suggestion of suggestions; let i = index"
                    (click)="$event.stopPropagation() ;selectSuggestion(suggestion)"
                    [class.active]="i === selectedSuggestionIndex" (mouseover)="selectedSuggestionIndex = i">
                    {{ suggestion.name }} <!-- Adjust based on your suggestion object structure -->
                </li>
            </ul>
        </div>


        <!-- Button on the right -->

        <button class="border-0 me-1" id="button-addon1" *ngIf="message && message.trim() != ''"
            (click)="$event.stopPropagation();confirmMessage()">
            <i class="fad fa-2x fa-arrow-circle-right text-primary"></i>
        </button>

    </div>


    <div *ngIf="selectedFiles.length > 0" class="mt-2">
        <ul class="list-group list-group-flush">
            <li *ngFor="let file of selectedFiles"
                class="list-group-item list-group-item-action list-group-item-success py-1">
                <small>{{ file.name }}</small>
                <!-- Mostra anteprima immagine se il file è un'immagine -->
            </li>
        </ul>
    </div>



</div>
<!-- https://o3.galimberti.eu/web/content/ir.attachment/1427/datas?download=true -->