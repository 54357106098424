  

  <div class="card">

    <div class="card-header">
      Cliente
    </div>
    <div *ngIf="!contact" class="card-body">
        <form 
          autocomplete="off" 
          class="d-flex flex-fill overflow-hidden"
        >
          <div class="input-group">
            <input
              type="text"
              class="form-control border-info"
              placeholder="Cerca per nome o partita iva"
              [(ngModel)]="inputSearch"
              (input)="onSearchChange()"
              (focus)="onFocusInput(true)"
              (focusout)="onFocusInput(false)"
              name="cx">
            <button  
              *ngIf="this.mode !== 'embedded'"
              type="button" 
              [ngClass]="{'bg-primary': filterCompany$.value == false}"
              (click)="filterCompany$.next(false)" class="btn btn-dark border-info">
              <i class="fa fa-building"></i>
            </button>
            <button  
              *ngIf="this.mode !== 'embedded'"
              type="button"
              [ngClass]="{'bg-primary': filterCompany$.value == true}"
              (click)="filterCompany$.next(true)" class="btn btn-dark border-info">
              <i class="fa fa-user"></i>
            </button>
        </div>
      </form>

     

    </div>
    <ul class="list-group list-group-flush mt-2" *ngIf="!contact && contacts">
      <ng-container *ngFor="let c of contacts">
        <!-- *ngIf="contact.parent_id.id" -->
        <li 
          (click)="$event.stopPropagation(); onContact(c); contact = c"
          class="list-group-item lead">
            <!-- <i class="fa-duotone fa-building me-3 "></i> -->
            {{c?.name}} <span class="text-muted" >{{  c.city ? ' - ' + c.city : ''}}</span>
        </li>
      </ng-container>
    </ul>


    <div  *ngIf="contact" class="card-body d-flex align-items-center">
      <div class="w-100">
       <span>{{contact.name}}</span>
        <span *ngIf="contact?.vat">
          <br>
          {{contact.vat}}
        </span>
        <span *ngIf="contact?.street">
          <br>
          {{contact.street}} - {{contact.city}}
        </span>
      </div>
      <div>
        <button class="btn btn-link" (click)="$event.stopPropagation();onContact(null);"><i class="fa fa-close fa-lg"></i></button>
      </div>
    </div>
  
    <div class="card-header border-top" *ngIf="contact && showAddresses">
      Indirizzo spedizione
    </div>
    
    <ul class="list-group list-group-flush" *ngIf="contact && !address && addresses.length">
      <ng-container *ngFor="let a of addresses">
        <li class="list-group-item" (click)="$event.stopPropagation();address = a; onAddress(a)">
          {{a?.street}} 
          <span class="text-muted" *ngIf="a.parent_id.id == null"> - Sede</span>
        </li>
      </ng-container>
    </ul>

    <div class="card-body d-flex align-items-center" *ngIf="address">
      <div class="w-100 me-3">
        <span *ngIf="contact?.street">
          {{address.street}}
        </span>
      </div>
      <div>
        <button class="btn btn-link" (click)="$event.stopPropagation();onAddress(null)"><i class="fa fa-close fa-lg"></i></button>
      </div>
    </div>

    <div class="card-footer" *ngIf="contact && !address && showAddresses" (click)="isAddingAddress = true">
      <span *ngIf="!isAddingAddress ">
        <i class="fa fa-plus"></i>
        Nuovo
      </span>
      <div *ngIf="isAddingAddress">
        <input #placesRef="ngx-places"  ngx-google-places-autocomplete required class="form-control"
        (onAddressChange)="onAddressChange($event)" >
      </div>
    </div>



  </div>
  
  <ng-container *ngIf="contacts?.length == 0">
    <h5 class="text-center mt-4">Nessun risultato</h5>
  </ng-container>
