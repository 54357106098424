<app-navbar [loading]="loading" backroute="..">
    <a class="navbar-brand">
        Produzioni
    </a>

    <div class="dropdown ms-auto">

    </div>
</app-navbar>

<div class="navbar bg-light px-3">
    <div class="form d-flex flex-row align-items-center w-100">
        <span>Cerca</span>
        <input class="form-control mx-3 w-100" [ngModel]="searchInput | async"
            (ngModelChange)="searchInput.next($event);onSearchChange($event)">
        <select [compareWith]="compareByString" class="form-control" [(ngModel)]="activePickingOption"
            (ngModelChange)="persist()">
            <option *ngFor="let o of this.picking_search_options | keyvalue" [ngValue]="o.value">{{o.key}}</option>
        </select>
    </div>
</div>

<div class="table-wrapper h-100 overflow-scroll" style="overflow-y:scroll;-webkit-overflow-scrolling: touch;"
    [hidden]="loading">
    <table class="table table-bordered align-middle">
        <thead class="bg-light">
            <tr>
                <th>Origine</th>
                <th>Prodotto</th>
                <th>quantità</th>
                <th>Scadenza</th>
                <th>Completata</th>
            </tr>
        </thead>
        <tbody style="-webkit-overflow-scrolling: touch;">
            <tr *ngFor="let p of productions" queryParamsHandling="preserve">
                <td>
                    <div class="d-flex align-items-center">
                        <span class="fa  {{getProductionClass(p)}}">&nbsp;</span>
                        <div>
                            <span *ngIf="p.name">
                                {{p.name}}
                            </span>
                            <span *ngIf="getSalesForProduction(p) && p.id"> /
                                <!-- nome, partner e ga_title dell'ordine di vendita -->
                                <b *ngIf="getSalesForProduction(p)">
                                    {{getSalesForProduction(p).name}}
                                    <br>
                                    {{getSalesForProduction(p).partner_id.name}}
                                </b>
                                <br>
                                {{getSalesForProduction(p).ga_title}}
                            </span>

                        </div>
                    </div>
                </td>
                <td>
                    {{p.product_id.name}}
                </td>
                <td>
                    {{p.product_qty}} {{p.product_id.value.uom_id.name}}
                <td>
                    <!-- scadenza dell'attività legata al sale della produzione -->
                    <span *ngIf="getSalesForProduction(p)">
                        {{getSalesForProduction(p).activity_ids?.values[0]?.date_deadline | date: 'dd/MM/yyyy'}}
                    </span>
                </td>
                <td>
                    <div class="d-flex align-items-center">
                        <!-- bottone  che completa la produzione -->
                        <button class="btn btn-primary text-white" (click)="completeProd(p)">
                            Completa
                        </button>


                    </div>

            </tr>
        </tbody>
    </table>
</div>