import { Component, AfterViewInit,ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

const BarcodeDetector = window['BarcodeDetector']

const Dynamsoft = (window as any).Dynamsoft;
Dynamsoft.DBR.BarcodeScanner.organizationID = "367928";
Dynamsoft.DBR.BarcodeScanner.license = "DLS2eyJoYW5kc2hha2VDb2RlIjoiMzY3OTI4LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjM2NzkyOCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOjIxMjk2NzYyNDV9";

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})

export class ScannerComponent implements OnInit,AfterViewInit, OnDestroy {

  @ViewChild('video') video: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  @Input() canCreate:boolean = false;
  controls: any;
  @Output() onCode:EventEmitter<string|null> = new EventEmitter<string|null>()
  @Output() onPackage:EventEmitter<StockQuantPackage|null> = new EventEmitter<StockQuantPackage|null>()
  result: string = null;
  i: number = 0;
  // canvas: HTMLCanvasElement;
  scanner: any;
  needToCreate: boolean;
  resolution: any;
  facing: string;
  camera: string;
  detecting: boolean;
  devices: MediaDeviceInfo[];
  barcodes = []
  barcodeDetector: any;
  captureStream: MediaStream;
  videoSettings: MediaTrackSettings;
  candidateBarcode: any;
  manualBarcode: string;
  keyboard:boolean = false;
  withDetector: boolean | null = false;
  
  constructor(
    private odooEM: OdooEntityManager,
    private changeDetectorRef:ChangeDetectorRef
  ) { }


  async ngOnDestroy(): Promise<void> {
    this.detecting = false
    this.video.nativeElement.pause()
    try { 
      this.captureStream.getVideoTracks()[0].stop()
    } catch(e) {
      console.log("Error stopping video", e)
    }
  }

  async ngOnInit() {

    // check compatibility
    if (!("BarcodeDetector" in window)) {
      this.withDetector = false
      this.keyboard = true
    } else {
      this.withDetector = true
      this.barcodeDetector = new BarcodeDetector(
        //   { formats: ["code_39", "codabar", "ean_13"] }
      );
    }
  }

  ngAfterViewInit(): void {
    this.detect()
  }
  
  private async detect() {
    this.captureStream = 
      await navigator.mediaDevices.getUserMedia(
        {
          video:{facingMode:  "environment" }
        }
      )
    
    this.video.nativeElement.srcObject = this.captureStream;
    await this.video.nativeElement.play()

    this.videoSettings  = this.captureStream.getVideoTracks()[0].getSettings()
    this.canvas.nativeElement.style.width = this.video.nativeElement.offsetWidth + "px"
    this.canvas.nativeElement.width = this.video.nativeElement.offsetWidth 

    this.canvas.nativeElement.style.height = this.video.nativeElement.offsetHeight + "px"
    this.canvas.nativeElement.height = this.video.nativeElement.offsetHeight


    // let xx =this.video.nativeElement.offsetWidth / s.width
    // this.result = "--" + this.canvas.nativeElement.style.width + " " + this.canvas.nativeElement.height
    this.detecting = true

    let ctx = this.canvas.nativeElement.getContext("2d")
    while (this.detecting) {
      
      let barcodes = await this.barcodeDetector.detect(this.video.nativeElement)
      ctx.clearRect(0, 0, this.canvas.nativeElement.width , this.canvas.nativeElement.height);

      if (barcodes.length) {
        barcodes.forEach(b => {
          this.addRect(ctx, b)
        })
        if (this.candidateBarcode != barcodes[0].rawValue) 
          this.candidateBarcode = barcodes[0].rawValue
      } 

      this.addCross(ctx)
      await sleep(50)
    }
  }


  getWidthRatio() {
   return this.video.nativeElement.offsetWidth / this.videoSettings.width
  }

  getHeightRatio() {
    return this.video.nativeElement.offsetHeight / this.videoSettings.height
  }

  addCross(context) {
    
   
    context.fillStyle = 'white'
    context.beginPath()
    context.moveTo(this.videoSettings.width * this.getWidthRatio() / 2, 0)
    context.lineTo((this.videoSettings.width * this.getWidthRatio() )/ 2, this.videoSettings.height * this.getHeightRatio())
    context.stroke();

    context.beginPath()
    context.moveTo(0 , (this.videoSettings.height * this.getHeightRatio()) /2)
    context.lineTo(this.videoSettings.width * this.getWidthRatio() ,(this.videoSettings.height * this.getHeightRatio()) /2)
    context.stroke();

  }


  addRect(context, points) {
    console.log("POINTS ", points)
      let x1 = (points.cornerPoints[0].x * this.getWidthRatio()) 
      let y1 = points.cornerPoints[0].y * this.getHeightRatio()
      let x2 = points.cornerPoints[1].x *this.getWidthRatio()
      let y2 = points.cornerPoints[1].y * this.getHeightRatio()
      let x3 = points.cornerPoints[2].x *this.getWidthRatio()
      let y3 = points.cornerPoints[2].y * this.getHeightRatio()
      let x4 = points.cornerPoints[3].x *this.getWidthRatio()
      let y4 = points.cornerPoints[3].y * this.getHeightRatio()

      context.fillStyle = '#28a745'
      context.beginPath()
      context.moveTo(x1, y1)
      context.lineTo(x2, y2)
      context.lineTo(x3, y3)
      context.lineTo(x4, y4)
      context.fill();
  }


  async create() {
    // var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", this.selectCode]]).toPromise()
    // var p:StockQuantPackage
    // if (ps.length == 0 && confirm("Pacco non trovato, vuoi crearlo ?")) {
    var p = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
        // 'location_id': this.scanningPackageDest.location_dest_id.id,
        'name': this.result
      }).toPromise()
    // } else if (ps.length > 0) {
    //   p = ps[0]
    // } 
    this.onPackage.emit(p)
  }

  cancel() {
    this.scanner.hide()
    this.onCode.emit(null)
  }

  async selectCode(c:string) {
    // if (c && c.split("-").length > 1) { 
    //   c = c[0]
    // }
    this.onCode.emit(c)
  }

}

function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

