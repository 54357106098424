
<app-navbar [loading]="loading" backroute="..">

    <a class="navbar-brand">
        Vendite immediate
    </a>

    
    <ng-content>
     
    </ng-content>
    <!-- {{picking.state}} -->
    <!-- <div class="dropdown " style="z-index:200000">
      <button class="btn btn-link ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-bars text-white"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-end ms-auto">
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item" target="_blank" (click)="isPrinting = true">Stampa</a></li>
        <li><a class="dropdown-item" target="_blank" href="https://o3.galimberti.eu/web#id={{picking.id}}&cids=1&menu_id=256&action=375&model=stock.picking&view_type=form">Apri in Odoo</a></li>
      </ul>
    </div>
  
   -->
  </app-navbar>


  <div class="card m-3">
    <div class="card-body">
        <h5 class="card-title">Nuova vendita</h5>
        <form>
            <div class="my-3">
              <app-contact-picker2 
                [showAddresses]="true"
                class="embedded bg-white" [mode]="'embedded'" (onSelect)="onContact($event)">
              </app-contact-picker2>
              
              <div 
                *ngIf="false" 
                class="card p-2 d-flex flex-row align-items-center"
              >
              <div class="card-header"></div>
                <p class="w-100 mb-0" *ngIf="newSalePartner.parent_id.id">
                  {{newSalePartner.parent_id.name?.toUpperCase()}}
                -  {{newSalePartner.name?.toUpperCase()}}
                  <span *ngIf="newSalePartner.street">
                    <br>
                    {{newSalePartner.street}}
                  </span>
                  <span *ngIf="newSalePartner.city">
                    <br>
                    {{newSalePartner.city}}
                  </span>
                </p>

                <p class="w-100 mb-0" *ngIf="!newSalePartner.parent_id.id">
                  {{newSalePartner.name?.toUpperCase()}}
                  <span *ngIf="newSalePartner.street">
                    <br>
                    {{newSalePartner.street}}
                  </span>
                  <span *ngIf="newSalePartner.city">
                    <br>
                    {{newSalePartner.city}}
                  </span>
                </p>
                
                <button class="btn btn-sm btn-link ms-3 " (click)="newSalePartner = null"><i class="fa fa-close"></i></button>
              </div>
            </div>
            <button type="submit" [disabled]="!newSalePartner" class="btn btn-primary" (click)="newSale()">Crea</button>
          </form>
    </div>
  </div>


  <div class="p-3 mt-3">
    <h5>Recenti</h5>
    <table class="table m-0">
      <tbody>
        <tr *ngFor="let s of sales" [routerLink]="[s.id]">
          <!-- <td class="align-middle">{{s.display_name}}</td> -->
          <td class="text-muted  align-middle">
            <b>{{s.name}}</b>
          </td>
          <td>
            Per {{s.partner_invoice_id.name}}<br>
            <span class="text-muted">Di {{s.user_id.name}} </span>
          </td>
          <td class="text-muted  align-middle">
            {{s.create_date | Date4Humans}}
          </td>
        </tr>
      </tbody>
    </table>

  </div>