import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { HrEmployee } from 'src/app/models/hr-employee.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
const NDEFReader = (window as any).NDEFReader;

@Component({
  selector: 'app-badge-admin',
  templateUrl: './badge-admin.component.html'
})
export class BadgeAdminComponent implements OnInit {
  employees: HrEmployee[] = []

  constructor(
    private odooEM: OdooEntityManager,
    public route: ActivatedRoute,
    public router: Router,
    private odooRPC:OdoorpcService
  ) { }


  id ;

  async ngOnInit(): Promise<void> {

    this.employees = (await this.odooEM.search<HrEmployee>(new HrEmployee(), [] ).toPromise())
    this.employees.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    );

    this.route.params.subscribe(async params => {
      this.id = params.id

    })
  }     


  async write( e : HrEmployee ){

      alert(e.barcode)
   
      const ndef = new NDEFReader();
      await ndef.write({
        records: [
          { recordType:"url", data:"https://m3.galimberti.eu/badge/read/" +e.barcode },
        ]
      })

      alert("writed")

      document.write("Ok")

    
  }

}
