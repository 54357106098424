import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockPicking } from 'src/app/models/stock-picking';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { ODOO_IDS } from '../../models/deal';
import { ProcurementGroup } from '../../models/procurement.group.model';
import { MailActivity } from '../../models/mail.message';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MrpProduction } from 'src/app/models/mrp-production';
import { Product } from 'src/app/models/product.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockMoveLine } from 'src/app/models/stock-move-line';
import { get } from 'http';



@Component({
  selector: 'app-productions-search',
  templateUrl: './productions-search.component.html',
  styleUrls: ['./productions-search.component.scss']
})

export class ProductionsSearchComponent implements OnInit {


  loading: boolean
  pickings: StockPicking[];
  title = ""

  filters = {
    purchase: false,
    sale: false
  }

  searchInput: BehaviorSubject<string> = new BehaviorSubject("")
  params: any;
  keybuffer: string = "";
  picking_search_options: {};
  activePickingOption: any;
  productions: MrpProduction[];
  sales: SaleOrder[] = [];
  activities: MailActivity[] = [];

  constructor(
    public odooEM: OdooEntityManager,
    public router: Router,
    public elementRef: ElementRef,
    private activatedRoute: ActivatedRoute
  ) {
  }


  async ngOnInit(): Promise<void> {
    //this.picking_search_options = ODOO_IDS.picking_search_options


    //let s = localStorage.getItem("activePickingOption")
    //if (s != "undefined") 
    //  this.activePickingOption = JSON.parse(s)
    //else this.activePickingOption = ODOO_IDS.picking_search_options[0]

    //this.params = this.activatedRoute.snapshot.params


    this.title = "Produzioni"
    this.searchInput.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(s => {
        this.load()
      })
  }


  getProductionClass(p: MrpProduction) {

    let c = ""
    if (p.state == 'draft')
      c = "fa-circle text-muted"
    if (p.state == 'confirmed' && p.components_availability_state == 'available')
      c = "fa-circle text-success"
    if (p.state == 'confirmed' && p.components_availability_state !== 'available')
      c = "fa-circle text-warning"


    return c
  }


  onSearchChange($event: any) {
    this.router.navigate([], { queryParams: { search: $event } });
  }

  compareByString(a, b) {
    return a?.toString() == b?.toString()
  }

  toggleFilter(f: string) {
    this.filters[f] = !this.filters[f]

    this.load()
  }

  persist() {
    localStorage.setItem("activePickingOption", JSON.stringify(this.activePickingOption))
    this.load()
  }


  async load() {

    this.loading = true
    // carica in un array c le produzioni in stato draft, confirmed, progress, to close esolo produzioni originate da sale order con almeno una activity in stato todo

    var c: any[] = [['state', 'in', ['draft', 'confirmed', 'progress', 'to close']]]
    var ps = await this.odooEM.search<MrpProduction>(new MrpProduction(), c, 500, "").toPromise()

    // risolvo prodotti, sale orders e acrivities
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new Product(), ps, 'product_id'))
    this.sales = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), [['name','in', ps.map(p => p.origin)]]))

    //risolvi le activities
    await firstValueFrom(this.odooEM.resolveArray(new MailActivity(), this.sales, 'activity_ids'))


    this.productions = ps
    this.loading = false
  }

  getSalesForProduction(p: MrpProduction) {
    //SE TROVO UN SALE CON NOME = ORIGIN, LO RITORNO
    let sales = this.sales.find(s => s.name == p.origin)
    console.log("SALE FOUND", sales)
    return sales
  
  }

  getProdActivities(p: MrpProduction) {
    // cerca le activites legate al sale order
      let s = this.getSalesForProduction(p)
      if (s)
      console.log("ACTIVITIES", s?.activity_ids?.values)
        return s?.activity_ids?.values  

    
  }

  // getActivityDate(p: MrpProduction) {
  //   if (p && p.sale_id && p.sale_id.value) {
  //     let a = p.sale_id?.value?.activity_ids?.values?.find(a => a.activity_type_id.id == ODOO_IDS.activity_todo)
  //     console.log("aaaaaa", a)
  //     if (a)
  //       return a.date_deadline
  //   }
  // }

  async completeProd(p: MrpProduction) {

    // risolvi i procurement group
    await firstValueFrom(this.odooEM.resolveSingle(new ProcurementGroup(), p.procurement_group_id))

    // risolvi i stock moves
    let proc = []
    proc.push(p.procurement_group_id.value)

    await firstValueFrom(this.odooEM.resolveArray(new StockMove(), proc, "stock_move_ids"))

    //salva le moves

    let moves = proc[0].stock_move_ids.values
    console.log("MOVES",moves)

    //aggiorna quantuità done delle moves
    for (let m of moves) {
        await firstValueFrom(this.odooEM.update<StockMove>(m, {quantity_done: m.product_uom_qty}))
      }


    //odoo em update --> qty = qty_done produzione
     await firstValueFrom(this.odooEM.update<MrpProduction>(p, {qty_producing: p.product_qty}))

      await this.odooEM.call2(new MrpProduction().ODOO_MODEL, "button_mark_done", [[p.id]])
      await this.load();
    }
  
  }



