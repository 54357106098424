import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';

@Component({
  selector: 'app-package-chooser',
  templateUrl: './package-chooser.component.html',
  styleUrls: ['./package-chooser.component.scss']
})
export class PackageChooserComponent  implements OnInit {
  @Input() picking:StockPicking

  constructor(
    private odooEM:OdooEntityManager,
    private route:ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.this.picking.package_level_ids
  }

}
