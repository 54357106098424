
<div>
<app-navbar [loading]="loading2" backroute=".." class="sticky-top">
  <a class="navbar-brand">
      <span >
          Disponibilità e prezzi
      </span>&nbsp;
  </a>

<div class="d-flex flex-nowrap w-100" id="menu">

  

  <div class="input-group ">
    <button 
        type="button" 
        class="text-white btn btn-outline-primary   text-nowrap" 
        [ngClass]="{'bg-primary': activeTemplate?.id === selectedTemplate?.id}"
        (click)="toggleProductTemplate(selectedTemplate.id,selectedTemplate.name)">
        {{selectedTemplate.name}}
      </button>

    <!-- <button type="button" class="btn btn-outline-secondary">Action</button> -->
    <button type="button" class="btn  text-white btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
      <span class="visually-hidden">Toggle Dropdown</span>
    </button>
   <!-- here starts dropdown menu (tagged products with extensive search)-->
    <ul class="dropdown-menu p-0" >
      <div class="accordion border-0" id="accordionExample" style="width: 400px;">
        <div class="accordion-item border-0" *ngFor="let t of productTemplates | keyvalue">
            <button
              (click)="$event.stopPropagation()"
              class="accordion-header link-underline-opacity-0 accordion-button collapsed text-muted"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse' + t.key.replaceAll(' / ','')">
              {{t.key}}
          </button>
          <!-- {{t.key.replace(' / ','')}} -->
          <div 
          
            [id]="'collapse' + t.key.replaceAll(' / ','')"
            class="accordion-collapse collapse" 
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body p-0">
              <ul class="list-group">
                <li 
                  *ngFor="let p of t.value" 
                  class="list-group-item">
                  
                  <a 
                    (mouseup)="$event.stopPropagation(); toggleProductTemplate(p.id, p.name)"
                    type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                    {{p.name}}
              </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ul>
    <input class="form-control border-primary" autofocus autocomplete="off" placeHolder="Cerca per nome/lunghezza" id="search-input" (ngModelChange)="inputSearch.next($event)" [ngModel]="inputSearch | async" name="s1">
  </div>
  <ng-content></ng-content>
  <!-- <div class="button-group" >
    <div class="btn-group" style="z-index: 10000000;" *ngIf="!noTemplate"> 
      <button 
        type="button" 
        class="btn btn-dark text-white text-nowrap" 
        [ngClass]="{'bg-primary': activeTemplate?.id === selectedTemplate?.id}"
        (click)="toggleProductTemplate(selectedTemplate.id,selectedTemplate.name)">
        {{selectedTemplate.name}}
      </button>
      <button 
        type="button" 
        class="btn  btn-dark dropdown-toggle dropdown-toggle-split" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
        (mousedown)="$event.stopPropagation()"
      >
        <span class="visually-hidden">Toggle Dropdown</span>
      </button>

      <ul class="dropdown-menu p-0" *ngIf="productTemplates">
        <div class="accordion border-0" id="accordionExample" style="width: 400px;">
          <div class="accordion-item border-0" *ngFor="let t of productTemplates | keyvalue" >
              <button 
                (click)="$event.stopPropagation()" 
                class="accordion-header link-underline-opacity-0 accordion-button collapsed text-muted" 
                data-bs-toggle="collapse" 
                [attr.data-bs-target]="'#collapse' + t.key.replaceAll(' / ','')">
                {{t.key}}
            </button>
            <div 
            
              [id]="'collapse' + t.key.replaceAll(' / ','')"
              class="accordion-collapse collapse" 
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body p-0">


                <ul class="list-group">
                  <li 
                    *ngFor="let p of t.value" 
                    class="list-group-item">
                    
                    <a 
                      (mouseup)="$event.stopPropagation(); toggleProductTemplate(p.id, p.name)"
                      type="button" class="" data-bs-toggle="dropdown" aria-expanded="false">
                      {{p.name}}
                </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div> -->

  <!-- <input class="form-control" autofocus autocomplete="off" placeHolder="Cerca per nome" id="search-input" (ngModelChange)="inputSearch.next($event)" [ngModel]="inputSearch | async" name="s1"> -->
</div>
</app-navbar>


<!--- Search by attribute -->
<div id="inventory" class="card rounded-0" [hidden]="inventoryClosed">
  
  <div class="card-body p-0">
    <table class="table table-hover" data-test-id="tableInventory">
      
      <thead [hidden]="!activeTemplate" class="text-center bg-white">
        <tr>
          <th class=" border-0" colspan="9">
            <div class="w-100 d-flex align-items-start">

              <ng-container *ngFor="let a of activeTemplate?.attribute_line_ids.values">
                <div
                  *ngIf="a.value_ids.ids.length != 1 && !a.attribute_id.name.startsWith('Lunghezza')" 
                    (mousedown)="mousedown($event)" class="flex-wrap d-flex align-items-start border-end p-3">
                  <div
                    class="text-nowrap w-100 me-3 mb-2 "                    
                    *ngIf="a.value_ids.values.length"
                  >
                    {{a.display_name}} 
                  </div>

                  <ng-container *ngIf="a.value_ids.values.length" class="p-2">
                    <span
                      style=" user-select: none; "
                      *ngFor="let v of a.value_ids.values | sortAsNumber  " 
                      (click)="toggleCriteria({attributeLine: a, attributeValue: v});refresh$.next(true)"
                      [ngClass]="{'bg-primary': hasCriteria({attributeLine: a, attributeValue: v})}"
                      class="badge text-dark me-2" 
                      (mouseover)="mouseover($event)">
                        {{v?.name}} 
                    </span>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </th>
        </tr>
      </thead>
       <!-- intestazione della tabella risultati-->
       <thead class="sticky-top border-dark border-4 border-top-0 border-start-0 border-end-0 bg-white" style="top:54px">

      <tr>
        <th></th>
        <th>ID</th>
        <th title="Descrizione prodotto">Prodotto</th>
        <th class="text-center" title="Costo di acqusito del prodotto">Costo</th>
        <th class="text-center" title="Prezzo di vendita consigliato secondo listino standard">Vendita</th>
        <th class="text-center" title="">UdM</th>
        <th title="quantità attualmente a magazzino">Qtà</th>

        <!-- <th>Mag</th> -->
        <!-- <th>Mag</th> -->
        <th colspan="2" title="Quantità che puoi utilizzare, non impegnata da altri">Qtà libera</th>
      
      </tr>
      </thead>
    
      <tbody cdkDropList 
        id="inventoryList"
        [cdkDropListConnectedTo]="saleIds"
        (cdkDropListDropped)="drop($event)"
      >
          <!-- VARIANT 
          <tr *ngIf="canCreateVariant() && products.length == 0">
            <td colspan="9">
              <button class="btn bg-primary" (click)="createVariant()">Crea nuova variante</button>
            </td>
          </tr>  -->

          <tr
            *ngFor="let p of products"
            class="cursor-pointer text-nowrap"
            (click)="insert(p)"
          ><td class="text-wrap align-middle  text-center">  
            <i *ngIf="p.reordering_min_qty > 0" class="fa-solid fa-star text-success my-0" title="Prodotto standard a magazzino"></i>
            <i *ngIf="p.additional_product_tag_ids.ids?.includes(20)" class="fa-solid text-danger fa-trash-clock my-0" title="Fondo di magazzino. Consumare con priorità"></i>
            <i *ngIf="!(p.reordering_min_qty > 0) && !p.additional_product_tag_ids.ids?.includes(20) && !p.free_qty " class="fa-solid text-body-secondary fa-cart-shopping my-0" title="Prodotto fuori standard. Acqusitato su richiesta"></i>
            <i *ngIf="!(p.reordering_min_qty > 0) && !p.additional_product_tag_ids.ids?.includes(20) && p.free_qty" class="fa-solid text-warning fa-down-to-line my-0" title="Prodotto fuori standard disponibile. Vendere con priorità"></i>
          </td>
          <td class="text-wrap align-middle">
              {{p.id}}
            </td>
            <td class="text-nowrap align-middle">
              <!-- <i *ngIf="p.product_variant_count > 1" class="fa-solid fa-ruler text-info "></i> -->
              {{p.display_name.replaceAll(", -","")}}
              <a class="text-wrap btn btn-link ms-1" title="Apri in Odoo" href="https://o3.galimberti.eu/web#id={{p.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form" target="_blank">
                <i class="text-wrap fa-solid fa-magnifying-glass my-0"></i>
              </a>             
            </td>            
            <td class="text-nowrap align-middle text-center py-1">{{p.standard_price > 0 ? (p.standard_price | number : '1.2-2':'it-IT') : (p.lst_price | number : '1.2-2':'it-IT') }} €</td>
            <td class="text-nowrap align-middle text-center">{{p._lst_price ? (p._lst_price  | number : '1.2-2':'it-IT')  : '-'}} €</td>
            <td class="text-nowrap align-middle text-center">{{p.uom_id.name}} </td>
            <td class="text-nowrap align-middle text-body-tertiary">{{p.qty_available | number : '1.1-1':'it-IT'}}</td>
            <!-- <td>{{p.virtual_available | number : '1.0-2':'it-IT'}} {{p.uom_id.name}}</td> -->
             <!-- se consumabile, 9999+ | se riservata, mostra solo libera | se impegnata ma non riservata (free=available, periso, segati), mostra available - outgoing -->
            <td  class="text-nowrap align-middle fw-bold"> {{ getFree(p) | number : '1.1-1':'it-IT' }}</td>
            <td  class="text-nowrap align-middle fw-bold">{{getInPzFast(p)}}</td>
          </tr>
      </tbody>
    </table>
  </div>
</div>
