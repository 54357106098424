
<div class="dropdown me-2" *ngIf="!activity" >
                                                     
    <button class="btn btn-sm bg-muted  text-white dropdown-toggle" data-bs-auto-close="outside" type="button" data-bs-toggle="dropdown">
        Prepara
    </button>
    
    <div class="dropdown-menu px-3 py-3">
        <div class="mb-3">Prepara entro</div>
        <input class="form-control mb-3" type="date" [(ngModel)]="sale.date_deadline">
        <div>
            <button class="btn btn-primary text-white" (click)="scheduleActivity(sale, sale.date_deadline)">Conferma</button>
        </div>
    </div>
</div>

<div class="dropdown me-2" *ngIf="activity" >
 
    <button class="btn btn-sm bg-primary text-white dropdown-toggle " data-bs-auto-close="outside" type="button" data-bs-toggle="dropdown">
        {{activity.date_deadline | Date4Humans}}
    </button>
    
    <div class="dropdown-menu px-3 py-3" >
        <div class="mb-3">Da prepare entro</div>
        <input class="form-control mb-3" type="date" (ngModelChange)="date_deadline = $event" [ngModel]="activity.date_deadline.toString()">
        <button class="btn btn-primary text-white" (click)="rescheduleActivity(date_deadline)">Aggiorna</button>
        <button class="btn btn-link text-dark" (click)="unscheduleActivity(sale)">Non preparare</button>
    </div>
</div>