import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Product, ProductWithOnlyVariants } from '../../models/product.model';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { ProductTemplate } from 'src/app/models/product.template.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { SaleOrderEditorComponent } from 'src/app/sale-order/sale-order-editor/sale-order-editor.component';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { ProductTemplateAttributeValue } from 'src/app/models/product.template.attribute.value.model';
import { ProductAttributeValue } from 'src/app/models/product.attribute.value';
import { ProductTemplateAttributeLine } from 'src/app/models/product.template.attribute.line';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PriceList } from 'src/app/models/price.list.model';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';


@Pipe({ name: 'sortAsNumber' })
export class SortAsNumber implements PipeTransform {
  transform(value: any[], order = '', column: string = ''): any[] {
    return value.sort((a,b) => Number(a.name) - Number(b.name))
  }
}


@Component({
  selector: 'app-order-inventory-mini',
  templateUrl: './order-inventory-mini.component.html'
})

export class OrderInventoryMiniComponent implements OnInit, AfterViewInit {
  @Output() addOrderLine = new EventEmitter<Product>()
  @Input() sales: SaleOrder[]
  @Input() saleIds: string[]
  @Input() saleEditor: SaleOrderEditorComponent
  @Input() loading: boolean 
  inventoryClosed = true;
  @ViewChild(TemplateRef) template: TemplateRef<any>;
  @Input() noDrag:boolean = false
  @Input() noTemplate:boolean = false
  @Input() domain = []
  
  inputSearch:BehaviorSubject<string> = new BehaviorSubject<string>("");
  activeTemplate: ProductTemplate|null;

  products: ProductWithOnlyVariants[];
  variants: Product[] = []
  groups: any;
  // TODO bad way to init prefs
  criteria: {
    toCreate?: any;attributeLine:ProductTemplateAttributeLine, attributeValue:ProductTemplateAttributeValue
    }[] = [];
  isMouseDown: boolean;
  lastValues: ProductTemplateAttributeValue[];
  name: any;
  selectedTemplate: { id: number; name: any; } = {id:45643, name:'Lamellare'};
  runningSearch$: any;
  refresh$: BehaviorSubject<boolean>= new BehaviorSubject(false);
  
  constructor(
    private odooEm: OdooEntityManager,
    private elRef:ElementRef,
    private odoorpcService: OdoorpcService
  ) {}


  ngAfterViewInit(): void {
  }

 
  ngOnInit() {

    this.refresh$.pipe(debounceTime(400)).subscribe(() => {
      this.refresh()
    })

    window.addEventListener("mousedown",e => {
      if (
        this.elRef.nativeElement.querySelector("table").contains(e.target) ||
        this.elRef.nativeElement.querySelector("input") == e.target ||
        this.elRef.nativeElement.querySelector("button") == e.target
      ) {
          this.inventoryClosed = false
        } else {
          this.inventoryClosed = true
          this.activeTemplate = null
        this.isMouseDown = false
      }

    })

    this.inputSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(async x => {
      this.refresh$.next(true)
    });
  }


  async loadPrice(res:ProductWithOnlyVariants[]) {

    var kwargs = kwargs || {};
    kwargs.context = [];

    var params = {
      model: new PriceList().ODOO_MODEL,
      method: "get_products_price",
      args: [[2],res.map(x => x.id)],
      kwargs: {
        context: null
      },
    };

    var r: any = await this.odoorpcService.sendRequest('/api/web/dataset/call_kw/' + params.model + "/" + params.method, params)

    return r.result ? r.result : []
  }


  backFromVariants() {
    this.variants = []
  }
  
  browseVariants(t:ProductTemplate) {
    this.variants = t.product_variant_ids.values
  }

  dragstart(p:Product, ev) {
    console.log("overr1", ev)
    ev.dataTransfer.setData("app-product", "dassad")
    return true
  }

  async drop(el:CdkDragDrop<any>) {
  } 

  mouseup() {
    this.isMouseDown = false
  }

  mousedown(ev) {
    this.isMouseDown = true
  }

  mouseover(ev, a:ProductAttributeValue) {
  }

  async getProducts(input): Promise<ProductWithOnlyVariants[]> {
    let filters = [];
    
    if (this.activeTemplate)
      filters.push(['product_tmpl_id', "=", this.activeTemplate.id])
    
    var domain = []
    
    var cs = this.criteria.filter(c => {
      return (!c.toCreate || !c.attributeLine.display_name.startsWith('Lunghe')) 
    })

    // translate criteria in odoo query
    cs.forEach((x,i) => {
        domain.push('&')
        domain.push(['name', '=', x.attributeValue.name])
        domain.push('&')
        domain.push(['product_tmpl_id', '=', this.activeTemplate?.id])
        domain.push(['attribute_id', '=',x.attributeValue.attribute_id.id])
    });
    
    // odoo use logic operator 
    cs.slice(0,-1).forEach(x => {
      domain.unshift("|")
    })

    if (domain.length) {
      this.lastValues = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), domain))
      this.lastValues.map(x => x.id).forEach(v => {
          filters.push(['product_template_attribute_value_ids','in',v])
        })
    }
    
    if (input)
      input.split(' ').forEach(v => {
        filters.push(
          ['sale_ok', '=', 'true'],
          ['active', '=', 'true'],
          '|',
          ['name', 'ilike', v.replace(/[\s\*]/g, "%25")],
          ['product_template_attribute_value_ids', "ilike", v.replace(/[\s\*]/g, "%25")]
        );

        if (this.domain)
          filters = filters.concat(this.domain)
      });

      let products = await this.odooEm.search<ProductWithOnlyVariants>(new ProductWithOnlyVariants(), filters, 100,null,"name ASC").toPromise();

      return products
  }


  canCreateVariant() {
    if (!this.activeTemplate)
      return false
    // todo fix -4
    return (this.criteria.length == this.activeTemplate.attribute_line_ids.ids.length)
  }

  async createVariant() {
    var domain = []
    
    // translate criteria in odoo query
    this.criteria.forEach((x,i) => {
      domain.push('&')
      domain.push(['name', '=', x.attributeValue.name])
      domain.push('&')
      domain.push(['product_tmpl_id', '=', this.activeTemplate.id])
      domain.push(['attribute_id', '=',x.attributeValue.attribute_id.id])
    });

    // odoo use logic operator 
    this.criteria.slice(0,-1).forEach(x => {
      domain.unshift("|")
    })

    var val = await firstValueFrom(this.odooEm.search<ProductTemplateAttributeValue>(new ProductTemplateAttributeValue(), domain))
    var aa = val.map(v => v.id)

    // aa = aa.concat(3389,3401,3402)

    var kwargs = kwargs || {};
    kwargs.context = [];
    
    const params = {
        method: "call",
        params: {
          "product_template_attribute_value_ids": aa,
          "product_template_id": this.activeTemplate.id
        },
        kwargs: {},
    };

    var r:any = await this.odooEm.odoorpcService.sendRequest('/api/sale/create_product_variant', {
      "product_template_attribute_value_ids": JSON.stringify(aa),
      "product_template_id": this.activeTemplate.id
    });
    // todo PEZZO PACK
    await this.odooEm.run(666, r.result, "product.product" )
    this.refresh()
  }

  hasCriteria(c) {
    return this.criteria.find(x => {
      return x.attributeLine.id == c.attributeLine.id && x.attributeValue.id == c.attributeValue.id
    })
  }

  async toggleCriteria(c) {
    console.log("TOGGLE CRITER",c)
    
    var index = this.criteria.findIndex(
      x => x.attributeLine.id == c.attributeLine.id
        && x.attributeValue.id == c.attributeValue.id)    
    if (index === -1) {
      // clear last active
      this.criteria = this.criteria.filter(x => x.attributeLine.id != c.attributeLine.id)
      this.criteria.push(c);
    } else
      this.criteria.splice(index, 1)

    //persist
    console.log("PRE ", this.criteria)
    var x = []
    this.criteria.forEach(c => 
      x.push({a:c.attributeLine.id, v:c.attributeValue.id})
    )

    window.localStorage.setItem("order-inventory-criteria", JSON.stringify(x))
   
    // this.refresh$.next(true)

    // await this.refresh()
  }
  
  async toggleProductTemplate(id:number, name) {
    // if (this.activeTemplate && )
    console.log("TOGGLE ", this.activeTemplate?.id)
    if (this.activeTemplate?.id == id) {
      this.activeTemplate = null
      
      // await this.refresh()
      return
    }
    
    var lastActiveId = this.activeTemplate?.id
    
    this.activeTemplate = null
    this.selectedTemplate = {id:id,name:name}
    // load template
    var res = await firstValueFrom(this.odooEm.search<ProductTemplate>(new ProductTemplate(), [['id', '=', id]])) 
    this.activeTemplate = res[0]

    await this.odooEm.resolve(res[0].attribute_line_ids).toPromise()

    await this.odooEm.resolveArray(new ProductAttributeValue(), res[0].attribute_line_ids.values, "value_ids").toPromise()

    if (lastActiveId != this.activeTemplate.id) {
      this.criteria = []
      // prefill hidden criterias
      this.activeTemplate.attribute_line_ids.values.map(a => {
        if (a.value_ids.ids.length == 1 || a.attribute_id.name.startsWith('Lunghezza'))
          this.toggleCriteria({attributeLine: a, attributeValue: a.value_ids.values[0],toCreate: true})
        // return 
      })
    }

    this.inventoryClosed = false
    this.refresh$.next(true)
  }

  close() {
    this.inventoryClosed = true  
  }

  async refresh() {
    if (this.inputSearch.value == "") {
      this.products = []
      return
    }
   
    this.loading = true
    var products = await this.getProducts(this.inputSearch.value)
    products.sort((a,b) => b.free_qty - a.free_qty)


    
    
    var prices = await this.loadPrice(products)
    products.forEach(p => {
      p._lst_price = prices[p.id]
    })
    
    this.products = products;
      
    // this.inventoryClosed = this.products?.length == 0
    this.loading = false
  }

  

  async insert(p:Product) {


    if (!this.sales && this.addOrderLine) {
      this.addOrderLine.next(p)
      return
    }

    var s = this.sales.find(x => x._open)
    if (s) {
      
      if (s.state == 'sale') {
        if (!confirm("Stai per retificare un ordine confermato e relative prenotazioni. Confermi ?"))
          return
      }
      var fr = s.order_line.values.find(l => l.route_id)

      var init = {
        product_id: p.id,
        product_uom_qty:0,
        order_id: s.id,
        route_id: fr ? fr.route_id.id : null,
        sequence: 10000
      }
      
      if (p.packaging_ids.ids && p.packaging_ids.ids.length > 0) {
        init['product_packaging_id'] = p.packaging_ids.ids[0]
      }

      await firstValueFrom(this.odooEm.create<SaleOrderLine>(new SaleOrderLine(), init))
      await this.saleEditor.resolveSale(s)
    }
  }


}
