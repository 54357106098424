    <app-navbar [loading]="loading" backroute="../.." queryParamsHandling="preserve">
        <a class="navbar-brand">
          {{picking?.origin}} - {{picking?.partner_id.name}} - {{picking?.name}} 
        </a>
        <button 
          *ngIf="picking?.state == 'assigned'"
          class="ms-auto btn btn-primary text-white" (click)="confirm()">
          Conferma
        </button>

        <span 
          *ngIf="picking?.state == 'done'"
          class="ms-auto  text-white">
          Completato
        </span>
        
    </app-navbar>

    <!-- offcanvas -->
    <div
      #offcanvas
      id="offcanvas"
      style="min-width: 70%;max-width: 95%;"
      class="offcanvas offcanvas-start bg-white" 
      tabindex="-1">
      
      <div class="offcanvas-header">
        <!-- <h5 class="offcanvas-title" id="offcanvasExampleLabel">Trasferimento {{picking.picking_type_id.name}} {{picking.name}}</h5> -->
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body p-0">
        <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <ng-container *ngFor="let move of moves">
                  <tr class="bg-light">
                    <td colspan="100  align-middle ">{{move.product_id.value.name}}</td>
                  </tr>
                  <tr class="py-3" 
                    (click)="addLineTo(move)" 
                    [ngClass]="{'text-decoration-line-through': (move.product_uom_qty - move.quantity_done) <= 0}"
                  >
                    <td class="py-3 align-middle " >
                      <pre class="mb-0">{{getDescriptiveMissing(move)}}</pre>
                    </td>
                    <td class="text-nowrap my-3 py-3  align-middle" *ngFor="let va of move.product_id.value.product_template_variant_value_ids.values">
                      {{va.name}}
                    </td>
                  </tr>
                </ng-container>
            </tbody>
            </table>
        </div>
      </div>
    </div>

    <ng-container *ngIf="picking">
      <ng-container *ngFor="let p of packagesGroup | keyvalue">
        <div class="mt-3 " *ngIf="p.key != 'undefined'">
          <div class="ps-2 bg-light border-top m-0 pe-2 d-flex flex-row align-items-center">
            
            <div class="badge bg-primary"><h3 class="mb-0">{{(p.key) ? p.key : 'Liberi'}}</h3></div>

            <!-- <div class="ms-2 d-flex align-items-center">
              Fila&nbsp;
              <select class="form-select ms-auto" style="min-width: 3em" [(ngModel)]="rootLocation" >
                <option *ngFor="let r of rootLocations" [value]="r">{{r}}</option>
              </select>
              <select 
                *ngIf="rootLocation"
                class="form-select ms-auto" style="min-width: 10em">
                <option *ngFor="let l of getChildLocations(rootLocation)" value="">{{l.display_name}}</option>
            </select>

              
            </div> -->
          
            <button 
              class="btn ms-auto my-1 btn-dark text-white" 
              type="button" 
              (click)="addToExistingPackByName(p.key)"
              [disabled]="order?.state == 'sale'"
            >
          <i class="fa fa-plus"></i>
        </button>
      </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <ng-container *ngFor="let line of groupItemBy(p.value, 'product_id.value.product_tmpl_id.name') | keyvalue">
                  <tr class="bg-light">
                    <td colspan="100">
                      {{line.key}}
                    </td>
                </tr>
                <tr *ngFor="let l of line.value" (click)="onLine(l)">
                  <td class="text-nowrap">
                    {{getDescriptive(l.move_id.value, l.qty_done)}}
                    <!-- {{getDescriptiveMissing(l.move_id.value)}} -->
                  </td>
                  <td class="text-nowrap my-3" *ngFor="let va of l.product_id.value.product_template_variant_value_ids.values">
                      {{va.name}} 
                  </td>
              </tr>
              </ng-container>
            </tbody>
            </table>
        </div>
        </div>
      </ng-container>
      <!-- <app-package-chooser [picking]="picking"></app-package-chooser> -->
    </ng-container>

    <div class="navbar-footer  bg-dark fixed-bottom d-flex p-2" *ngIf="picking?.state != 'done'">
      <div class="button" class="btn btn-dark" (click)="addToExistingPack(null)">
        <i class="fa fa-list"></i>
      </div>
      
      <!-- <button class="me-2 btn w-100 btn-primary text-white" (click)="scanBarcode()">
        <i class="fa fa-package">
          
        </i>
      </button> -->

      <button class="btn me-2 btn-outline-primary text-white" (click)="onScanBarcode(noPackage)">
        <!-- <i class="fa fa-plus"></i>  -->
        Nessuno
      </button>

      <button class="btn w-100 btn-primary text-white" (click)="scanBarcode()">
        <!-- <i class="fa fa-plus"></i>  -->
        Nuovo pacco
      </button>

    </div>

    <app-scanner *ngIf="scanningBarcode" format="EAN-13" (onCode)="onScanBarcode($event)"></app-scanner>





<div *ngIf="masterLine" class="w-100 h-100 bg-white d-flex  justify-content-center flex-column"
style="position: fixed; top: 0px; left: 0;z-index:100001">
<bar-loader [loading]="loading"></bar-loader>

<div class="row">
  <div class="col">
    <p class="lead mb-0  mt-5 my-3 text-center">
      <b>{{masterLine.product_id.value.name}}</b>
    </p>
  </div>
</div>

<div class="row flex-fill align-items-center">
  <div class="col p-4">
    <table class="table bg-ligth table-bordered">
     
      <tbody>
       
          <tr *ngFor="let d of descriptiveArray;let i = index;">
            <td class=" align-items-center d-flex p-3">
              <input 
                type="number"
                lang="it-IT"
                class=" form-control d-inline pe-2" 
                [ngModelOptions]="{'updateOn':'blur'}"
                (ngModelChange)="updateDescriptiveOr(masterLine, d, $event )"
                [ngModel]="d[0]"
              >
              <span class="ps-2">{{d[1].name}}</span>
            </td>
          </tr>
          <tr>

          <td class="align-items-center d-flex p-3 bg-light text-nowrap">
            <input
              type="number"
              lang="it-IT"
              class="form-control d-inline pe-2" 
              [ngModel]="masterLine.qty_done "
              [ngModelOptions]="{'updateOn':'blur'}"
              (ngModelChange)="updateMaster(masterLine, 'qty_done', $event)"
            >
            <span class="ps-2 text-nowrap">{{masterLine.product_id.value.uom_id.name}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="d-flex flex-column my-3 animate__animated" [class.animate__fadeOutDown]="loading" >
    <div class="align-items-center d-flex mt-2 justify-content-center">
      
      <button class="btn btn-link  text-success ms-2 me-2"
        (click)="back()">
        <i class="fa-duotone fa-circle-chevron-left fa-4x text-dark"></i>
      </button>

      <button class="btn btn-link text-success ms-2 me-2"
        [disabled]="order?.state == 'sale'"
        (click)="save()">
          <i class="fa-duotone fa-circle-check fa-5x"></i>
      </button>
      
      <button *ngIf="masterLine.id != -1" class="btn btn-link  text-success text-warning ms-2"
        [disabled]="order?.state == 'sale'"
        (click)="deleteLine(masterLine)">
        <i class="fa-duotone fa-circle-trash fa-4x " ></i>
      </button>

    </div>
  </div>

  
</div>

