import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import { MailMessage, MailMessageReaction } from '../models/mail.message';
import * as moment from 'moment';
import 'moment/locale/it'; // Import the Italian locale
import { ActivatedRoute } from '@angular/router';
import { OdoorpcService } from '../shared/services/odoorpc.service';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Partner } from '../models/partner';

@Component({
  selector: 'app-message-widget',
  templateUrl: './message-widget.component.html',
  styleUrls: ['./message-widget.component.scss']
})
export class MessageWidgetComponent implements OnInit {
  message: string = ""
  attachmentIds: number[] = [];
  selectedFiles: File[] = [];

  suggestions: any[] = []; // You can define a more specific type for your suggestions
  showSuggestions: boolean = false;
  showReactions: boolean = false;
  currentReactionMessageId: number | null = null;

  selectedSuggestionIndex: number = -1;
  atSignDetected: boolean = false;
  csrfToken: any;
  @Input() model: string
  @Input() res_id?: number
  userId: any;
  groupMessage: { dateFromNow: string; messages: any; }[];

  constructor(
    private odooEm: OdooEntityManager,
    private http: HttpClient,
    public route: ActivatedRoute, public odoorpcService: OdoorpcService
  ) { }
  @ViewChild('scrollableContent') private myScrollContainer: ElementRef;

  async ngOnInit(): Promise<void> {

    var result: any = await this.odooEm.odoorpcService.getSessionInfo()
    this.userId = result.result.partner_id

    await this.load()
  }

  @HostListener('document:show.bs.dropdown', ['$event'])
  onShow(event: any): void {
    setTimeout(() => { this.scrollToBottom() }, 1);
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  // Method to toggle reactions visibility for a specific message
  toggleReactions(messageId: number): void {
    if (this.currentReactionMessageId === messageId) {
      this.currentReactionMessageId = null; // Hide if the same message's reactions are already visible
    } else {
      this.currentReactionMessageId = messageId; // Show for the clicked message
    }
  }


  
  customFromNow(date) {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const dateMoment = moment(date);
  
    if (dateMoment.isSame(today, 'd')) {
      return 'Oggi';
    } else if (dateMoment.isSame(yesterday, 'd')) {
      return 'Ieri';
    } else {
      const daysAgo = today.diff(dateMoment, 'days');
      return `${daysAgo} giorni fa`;
    }
  }


  async load() {
    var messages:any = await firstValueFrom(this.odooEm.search<MailMessage>(new MailMessage(), [["model", "=", this.model],["res_id", "=", this.res_id]],null,null ,"date asc"));
    // Create an object to hold groups
    const groupedMessages = {};

    for (const message of messages) {
      message.dateHour = moment(message.date).format('HH:mm');
      message.dateFromNow = this.customFromNow(message.date);



      await firstValueFrom(this.odooEm.resolve(message.reaction_ids));
      await firstValueFrom(this.odooEm.resolve(message.attachment_ids));

      // Check if the group already exists; if not, initialize it
      if (!groupedMessages[message.dateFromNow]) {
        groupedMessages[message.dateFromNow] = [];
      }

      // Add the message to the correct group
      groupedMessages[message.dateFromNow].push(message);
    }

    // Optional: Convert groupedMessages to an array if needed, or you can work with it as an object
    const groupedMessagesArray = Object.keys(groupedMessages).map(dateFromNow => {
      return {
        dateFromNow,
        messages: groupedMessages[dateFromNow]
      };
    });

    // Assuming you want to keep the grouped messages in this format
    this.groupMessage = groupedMessagesArray
  }


  async getSuggestion(key: string) {
    // Ensure initialization and parameter setup as before
    var params = {
      model: new Partner().ODOO_MODEL,
      method: "get_mention_suggestions",
      kwargs: {
        "search": key,
        "context": {
          "lang": "it_IT",
          "tz": "Europe/Rome",
          "uid": 59,
          "allowed_company_ids": [1]
        }
      },
      args: []
    };

    try {
      var r: any = await this.odoorpcService.sendRequest('/api/web/dataset/call_kw/' + params.model + "/" + params.method, params);
      console.log("Suggestions:", r);
      // Assuming the response has a property that is an array of suggestions
      if (r && Array.isArray(r.result)) {
        this.suggestions = r.result;
        this.showSuggestions = true;
      } else {
        // Handle unexpected formats or errors
        console.error('Unexpected response format', r);
        this.suggestions = [];
        this.showSuggestions = false;
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      this.suggestions = [];
      this.showSuggestions = false;
    }
  }

  selectSuggestion(suggestion: any) {
    const atPosition = this.message.lastIndexOf('@');
    if (atPosition !== -1) {
      this.message = `${this.message.substring(0, atPosition)}@${suggestion.name} `;
      this.suggestions = [];
      this.showSuggestions = false;
      this.atSignDetected = false; // Reset the '@' detection
    }
  }


  isImage(attachment: any): boolean {
    return /^image\//.test(attachment.mimetype);
  }

  onKeyup(event: KeyboardEvent) {
    const inputValue: string = (event.target as HTMLInputElement).value;
    const atPosition = inputValue.lastIndexOf('@'); // Use lastIndexOf to support multiple '@' characters

    // Check if '@' is present in the input
    if (atPosition !== -1) {
      this.atSignDetected = true;
      // Extract the query right after '@', even if it's an empty string
      const query = inputValue.substring(atPosition + 1);
      // Call getSuggestion with the extracted query (which might be an empty string)
      this.getSuggestion(query);
    } else {
      // Reset state if '@' is not found
      this.atSignDetected = false;
      this.suggestions = [];
      this.showSuggestions = false;
    }
  }

  async fetchCSRFToken() {
    const url = '/api/get_csrf_token';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Include other necessary headers like authentication tokens
    });
    try {
      const response: any = await this.http.post(url, {}, { headers: headers }).toPromise();
      return response.result.csrf_token;
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  }

  async confirmMessage() {

    var m: any = {};
    m.body = this.message;
    m.model = this.model;
    m.res_id = Number(this.res_id)
    m.message_type = "comment";
    m.attachment_ids = [[6, 0, this.attachmentIds]];
    var x = await firstValueFrom(this.odooEm.create<MailMessage>(new MailMessage(), m))

    this.attachmentIds = [];
    this.selectedFiles = [];
    this.message = "";



    await this.load()


    setTimeout(() => { this.scrollToBottom() }, 1);
  }

  async uploadFile(event: any) {

    if (!this.csrfToken)
      this.csrfToken = await this.fetchCSRFToken();

    const files: FileList = event.target.files;

    console.log("files", files)
    if (files) {
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        const formData = new FormData();
        formData.append('ufile', file, file.name);
        formData.append('thread_model', 'res.partner');
        formData.append('thread_id', this.res_id.toString());
        formData.append('name', file.name);
        formData.append('mimetype', file.type);
        formData.append('csrf_token', this.csrfToken);
        formData.append('type', 'binary');

        var response = await fetch('/api/mail/attachment/upload', {
          method: 'POST',
          body: formData
        });
        var responseData = await response.json();
        this.attachmentIds.push(responseData.id);
        this.selectedFiles.push(file);

      }
    }
  }

  async insertSmile($event, message: MailMessage, body: string) {

    $event.stopPropagation();
    this.message = this.message + body;
    this.showReactions = false;
  }

  async setReaction($event, message: MailMessage, body: string) {
    $event.stopPropagation();
    var result: any = await this.odooEm.odoorpcService.getSessionInfo()


    var reaction = {
      message_id: message.id,
      content: body,
      partner_id: result.result.partner_id
    }


    var r = await firstValueFrom(this.odooEm.create<MailMessageReaction>(new MailMessageReaction(), reaction))
    message.reaction_ids.values.push(r)


    this.toggleReactions(message.id)
  }
}