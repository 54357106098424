import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { OdooRelationship } from './odoo-relationship.model';
import { GenericOrderLine } from './generic-order-line.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { Product } from './product.model';
import { StockLocationRoute } from './stock-location-route.model';
import { StockMove } from './stock-move';
import { ProductPackaging } from './product.packaging.model';
import { AccountTax } from './account-tax.model';
import { PurchaseOrderLine } from './purchase-order-line.model';
import { OdooModel } from './odoo-model.model';
import { PurchaseOrder } from './order';

export class AccountMoveLine extends OdooModel implements OdooSerializableInterface<AccountMoveLine> {

  public ODOO_MODEL = 'account.move.line';

  purchase_line_id: OdooRelationship<PurchaseOrder> = new OdooRelationship<PurchaseOrder>();
  product_id: OdooRelationship<Product> = new OdooRelationship<Product>();
  price_unit: number = 0;
  price_subtotal: number = 0;
  quantity: number = 0;

  // virtual_available_at_date:number = 0;
  // forecast_expected_date:string = "";
  // is_mto:boolean = false;
  // product_packaging_id : OdooRelationship<ProductPackaging> = new OdooRelationship<ProductPackaging>()
  // tax_id : OdooMultiRelationship<AccountTax> = new OdooMultiRelationship<AccountTax>(AccountTax)
  // product_packaging_qty: number = 0;
  // qty_delivered:number = 0;
  // qty_to_deliver:number = 0;
  // purchase_price: number = 0;
  // _checked:boolean;
  // _dragtarget:boolean;
  // discount:number = 0;
  // margin:number// = 0;
  // margin_percent:number //= 0;
  // display_type:string = ""; // line_section"
  // purchase_line_ids:OdooMultiRelationship<PurchaseOrderLine> = new OdooMultiRelationship<PurchaseOrderLine>(PurchaseOrderLine);

  create(): AccountMoveLine {
    return new AccountMoveLine();
  }
}


