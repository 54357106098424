import { AfterViewInit, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { HrAttendance } from '../models/hr-attendance.model';
import { HrEmployee } from '../models/hr-employee.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import * as moment from 'moment';
import { firstValueFrom } from 'rxjs';
import { OdoorpcService } from '../shared/services/odoorpc.service';
import { ActivatedRoute } from '@angular/router';



enum Actions {
  Enter = "login",
  Exit = "logout"
}

@Component({
  selector: 'app-badge-kiosk',
  templateUrl: './badge-kiosk.component.html',
})
export class BadgeKioskComponent implements OnInit {
  result: any;

  constructor(
    private odooRPC: OdoorpcService,
    private route: ActivatedRoute
  ) { }
 
  async ngOnInit() {
    this.route.params.subscribe(async params => {

      console.log(" params.p ",  params)
      var res = await this.odooRPC.login({
        db:"galimberti16", 
        login:"b",
        password: params.p
      })

      this.result = res
      console.log(this.result)
    
    })
  }



}
