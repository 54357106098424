export function row2printable(r: HTMLElement): any {
    const row = [];
    r.querySelectorAll('[data-print-col]').forEach(element => {

      if (element.tagName === 'SELECT') {
        var s = element as HTMLSelectElement
        if (s.selectedIndex > -1) {
          row.push(s.options[s.selectedIndex].innerHTML);
        }
      } else if (['INPUT', 'TEXTAREA'].includes(element.tagName)) {
        var i = element as HTMLInputElement
        row.push(i.value)
      } else if (element.childElementCount > 0) {

        let t = '';
        element.querySelectorAll(':scope > *').forEach(e => {
          t += (((e as any).value ? (e as any).value : (e as any).innerText) + ' ');
        });
        row.push(t);
      } else {
        var value = (element as any).innerText
        row.push(value)
      }

      try {
        var n = Number(row[row.length - 1])
        if (!Number.isNaN(n))
          row[row.length - 1] = n
      } catch(e) {

      }
    });
    return row;
  }