<app-navbar *ngIf="picking" [loading]="loading" backroute="../..">

  <a class="navbar-brand  me-auto" style="text-overflow: ellipsis;overflow: hidden; max-width: calc(100%);">
    
      {{picking.origin}} - {{picking.picking_type_id.name.replace("LOMAGNA:","")}} 
      <!--{{picking.location_id.name.replace("LOM/","")}} ->-->  
      <!-- {{picking.location_dest_id.name.replace("LOM/","")}} -->
      <!-- {{picking.partner_id?.name}} -  -->
  </a>
  <button 
    class="btn btn-primary text-white me-2" 
    type="button" 
    (click)="closeWithBackorder()" 
    *ngIf="picking.state == 'assigned'"
  >
    Conferma
  </button>
  <!-- {{picking.state}} -->
  <div class="dropdown " style="z-index:200000">
    <button class="btn btn-link ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bars text-white"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end ms-auto">
      <!-- <li 
        class="dropdown-item"  >
        <button class="btn btn-primary text-white" type="button" (click)="confirm(s)" >
          Conferma
        </button>
      </li> -->
      

      <!-- <li class="dropdown-item" *ngIf="s.state == 'sale'">
        <button class="btn btn-primary text-white" type="button" (click)="cancel(s)" >
          Annulla
        </button>
      </li> -->

      <!-- <li  class="dropdown-item" >
        <a class="dropdown-item" (click)="newMoveLine()" >
          Nuova riga
        </a>
      </li> -->

      <!-- <li><hr class="dropdown-divider"></li> -->
      <li class="px-3 text-nowrap text-muted d-flex flex-column">
        <span>Per {{picking.partner_id?.name}} </span>
        <span>Da {{picking.location_id.name.replace("LOM/","")}}</span>
        <span>A {{picking.location_dest_id.name.replace("LOM/","")}}</span>
      </li>
      <li class="dropdown-divider"></li>
      <li><a class="dropdown-item" target="_blank" (click)="isPrinting = true">Stampa</a></li>
      <li><a class="dropdown-item" target="_blank" href="https://o3.galimberti.eu/web#id={{picking.id}}&cids=1&menu_id=256&action=375&model=stock.picking&view_type=form">Apri in Odoo</a></li>
    </ul>
  </div>


</app-navbar>


<app-scanner *ngIf="scanningBarcode" format="EAN-13" (onCode)="onScanBarcode($event)">

</app-scanner>

<div class="table-wrapper" *ngIf="picking && !masterMove" style="height: 100%;" >
  <table class="table align-middle table-bordered" >
    <thead>
      <tr>
        <th >Prodotto</th>
        <th>Qta</th>
      </tr>
    </thead>
    <tbody style="height: 100%;">
      <ng-container
        *ngFor="let m of picking?.move_ids_without_package.values"
      >
        <tr data-print-row>
          <td 
            data-print-col  
            class="text-muted w-100 align-middle"
            (click)="scanBarcode2(m)" 
          >
            <i *ngIf="m.quantity_done >= m.product_uom_qty " class="fa fa-circle me-2 text-success"></i>
            <i *ngIf="m.quantity_done > 0 && m.quantity_done < m.product_uom_qty " class="fa fa-circle me-2 text-warning"></i>
            <i *ngIf="m.quantity_done == 0" class="fa fa-circle me-2 text-primary"></i>
              <!-- <i class="fa fa-barcode-scan fa-lg me-2"></i> -->
              {{m.product_id.name}} 
              - {{m.product_id.value.barcode}}
          </td>

          <td class="">
            {{m.quantity_done | number : '1.0-3':'it-IT'}}&nbsp;{{m.product_id.value.uom_id.name}}&nbsp;/&nbsp;{{m.product_uom_qty | number : '1.0-3':'it-IT'}}
            <br>
            {{m.quantity_done / m.product_packaging_id.value?.qty | number : '1.0-3':'it-IT' }}&nbsp;{{m.product_packaging_id.name}}&nbsp;/&nbsp;{{getPackagingQty(m) | number : '1.0-3':'it-IT'}}
          </td>
          
        </tr>
      </ng-container>

    </tbody>  
  </table>
</div>


<div class="btn-group-scan">
  <button class="btn btn-primary">xxx</button>
</div>


<div 
  *ngIf="masterMove"
  class="w-100 h-100 bg-white d-flex align-items-center justify-content-center flex-column" 
  style="position: fixed; top: 0px; left: 0;z-index:1000"
>
<div class="row">
  <div class="col">
    <p class="lead text-center">
      Prelievo di<br>
      <b>{{masterMove.product_id.value.name}} {{masterMove.product_id.value.uom_id.name}}</b>
      <br><br>
    </p>
  </div>
</div>
  

<table class="table bg-ligth table-bordered">
  <thead>
    <tr>
      <td class="text-end align-middle">Richiesta</td>
      <td>Prelevata</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let d of descriptiveArray;let i = index;">
      <td class="text-end w-50 align-middle">
        {{d[0]}}
        {{d[1].name}}
      </td>
      <td class="w-50">
        <input class="form-control" [(ngModel)]="descriptiveArrayDoing[i][0]"> 
      </td>
    </tr>
  </tbody>
</table>
<!-- <div class="row">
  <div class="col">
    
      <h1><pre>{{getDescriptive(masterMove)}}</pre></h1>
    
  </div>
  <div class="col">
    <h1><pre>{{getDescriptive(masterMove)}}</pre></h1>
  </div>
  <div class="col">
    <h1><pre>{{getDescriptive(masterMove)}}</pre></h1>
  </div>
</div> -->


  <br>
  <!-- <h2><span class="text-muted">{{masterMove.product_uom_qty}} {{masterMove.product_id.value.uom_id.name}}</span></h2> -->
  <br>
  <br>

  <div class="d-flex flex-column">
    <!-- <button class="btn btn-lg btn-light mx-2" (click)="masterMove = null">
      X
    </button> -->
    <div class="d-flex align-items-center">
    <button class="btn btn-lg btn-light text-dark mx-2 w-50" (click)="zero()">
      Azzera
    </button>
    <button class="btn btn-lg btn-light text-dark mx-2 w-50" (click)="complete()">
      Completa
    </button>
  </div>
    <div class="align-items-center d-flex mt-5 justify-content-center" (click)="masterMove = null">
      <i class="fa-duotone fa-circle-xmark fa-4x text-dark"></i>
    </div>  
    <!-- <button class="btn btn-lg btn-primary mx-2 text-white">
      Ok
    </button> -->
  </div>
</div>



<!-- DETTAGLI MOVE LINES -->
<!-- 
<div *ngIf="masterMove" class="h-100" tabindex="-1" >
    <div class="w-100 h-100">
      <div class="d-flex align-items-center p-2 bg-dark ">
        
        <div class="dropdown" style="z-index:200000">
          <button class="btn btn-link ms-auto" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fa fa-bars text-white"></i>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
           
            <li  class="dropdown-item" >
              <a class="dropdown-item" (click)="newMoveLine()" >
                Nuova riga
              </a>
            </li>
          </ul>
        </div>


        <h5 class="modal-title  text-white ps-3">
          {{masterMove?.product_id.name}}
          {{masterMove?.product_id.name}}</h5>
        <button type="button" class="ms-auto btn btn-link text-primary" (click)="closeModal()" style="width: 40px;margin:0px;">
          <i class="fas fa-2x fa-close"></i>      
        </button>
      </div>
      <div class="p-0" style="overflow: scroll;position:relative;min-height: 100%;">
        
        
      <div class="table-responsive">
        <table 
          class="table mb-0">
          <thead class="sticky-top bg-light" >
            <tr>
              <th class="align-middle">
                <input 
                  type="checkbox"
                  [ngModel]="allLinesChecked"
                  (ngModelChange)="toggleAllLines($event)"
                >
              </th>
              <th class="text-end align-middle">{{masterMove.product_id.value.uom_id.name}}</th>
              <th class="text-end align-middle">
                <div class="dropdown d-inline-block">
                <button class="btn w-100 p-0  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" (mousedown)="updateSelectablePackaging(line)">
                  <b>{{masterMove.product_packaging_id.name}}</b>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let p of masterMove.product_id.value?.packaging_ids.values"><button class="dropdown-item" (click)="changeProductPackaging(p)">{{p.name}}</button></li>
                </ul>
              </div></th>
              <th 
                class="text-end align-middle"
                >Da collo</th>
              <th class="text-end">A collo</th>
            </tr>
          </thead>
          <tbody style="overflow-y:scroll;-webkit-overflow-scrolling: touch;">
            
            
            <tr *ngFor="let l of masterMove?.move_line_ids.values">
              
              <td class="align-middle">
                <input 
                  type="checkbox"
                  [ngModel]="l._checked"
                  (ngModelChange)="l._checked = !l._checked"
                  style="vertical-align: middle;"
                >
              </td>

              <td class="ps-3 editable text-nowrap align-middle">
                <app-virtual-keypad
                  class="d-block"
                  [ngModel]="l.qty_done"
                  (ngModelChange)="updateLine('qty_done', l, $event )"
                  ></app-virtual-keypad>

                
              </td>

              <td class="editable text-nowrap align-middle">
                <app-virtual-keypad
                  class="d-block"
                  [ngModel]="getPackagingQtyDone(l,masterMove) | number : '1.0-4':'it-IT' "
                  (ngModelChange)="setPackagingQtyDone(l,masterMove,$event)"
                  ></app-virtual-keypad>
                  
              </td>

              <td 
                class="editable text-nowrap" >

                <app-virtual-keypad
                class="d-block"
                [ngModel]="l.package_id.name"
                  [ngModelOptions]="{'updateOn':'blur'}"
                  (ngModelChange)="onPackage(l,$event)"
                ></app-virtual-keypad>
              </td>

              <td 
                class="editable text-nowrap" >
                <app-virtual-keypad
                class="d-block"
                [ngModel]="l.result_package_id.name"
                  [ngModelOptions]="{'updateOn':'blur'}"
                  (ngModelChange)="onPackageDest(l,$event)"
                ></app-virtual-keypad>

              </td>

            </tr>
          </tbody>
          <tfoot class="sticky-bottom bg-white w-100">
            <tr >
              <td class="fw-bold bg-light" colspan="5">
                <span *ngIf="!masterMove.product_packaging_id.id">
                  {{masterMove.quantity_done | number : '1.0-4':'it-IT' }} di 
                  {{masterMove.quantity_done | number : '1.0-4':'it-IT' }}
                  &nbsp;{{masterMove.product_id.value}}
                  &nbsp;di
                </span>

                <span *ngIf="masterMove.product_packaging_id.id">
                  {{masterMove.quantity_done / masterMove.product_packaging_id?.value.qty | number : '1.0-4':'it-IT' }}
                  &nbsp;{{masterMove.product_packaging_id.name}}
                  &nbsp;di
                  {{getPackagingQty(masterMove) | number : '1.0-4':'it-IT'}}
                </span>
                <span class="ms-auto d-inline-block text-muted">
                  - 
                  {{masterMove?.move_line_ids.values.length}} righe 
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
        </div>
      </div>
  </div>
</div> -->
<!-- 
<div class="btn-group-scan " *ngIf="picking">
  <button 
    *ngIf="getSelections() && getSelections().length && masterMove"
    (click)="deleteMoveLines(getSelections())" class="btn-scan bg-dark text-white me-3">
    <i class="fa fa-trash fa-2x"></i>
  </button>
  {{defaultPackageDest?.id}}
  <button 
    *ngIf="hasBarcodes()"
    (click)="scanBarcode()" class="btn-scan text-white">
    <i class="fa fa-barcode-scan fa-2x"></i>
  </button>

  <app-keyboard-scanner 
      class="ms-auto me-3 d-none"
      (onCode)="onScan($event)" 
      [invalid]="barcodeInvalid" 
  ></app-keyboard-scanner>

  <button 
    *ngIf="hasLabelling()"
    (click)="labelPackage()" class="btn-scan text-white ms-3">
    <i class="fa fa-box fa-2x"></i>
  </button>
  <app-scanner-zxing *ngIf="labeling" (onCode)="onLabelPackage($event)"></app-scanner-zxing>

  <button 
    *ngIf="hasPick()"
    (click)="scanPackage()" class="btn-scan  text-white ms-3">
    <i class="fa fa-inbox-out fa-2x"></i>
  </button>
  
  <app-scanner-zxing *ngIf="scanning" (onCode)="onScan($event)"></app-scanner-zxing>
        
  <button 
    *ngIf="hasDefaultPack()"
    (click)="scanDefaultPackageDest()" 
    class="btn btn-pill bg-primary text-white d-flex align-items-center" >

    <i class="fa fa-times me-2" *ngIf="defaultPackageDest"></i>
    <span class="mx-2" *ngIf="defaultPackageDest">{{defaultPackageDest?.name}}</span>
    <i class="fa fa-inbox-in fa-2x"></i>
  </button>
  
  <app-scanner-zxing *ngIf="scanningDefaultPackageDest" (onCode)="onScanDefaultPackageDest($event)"></app-scanner-zxing>

</div> -->