

<div *ngIf="product" class="w-100 h-100 bg-white d-flex justify-content-center flex-column"
  style="position: fixed; top: 0px; left: 0;z-index:10001">
<bar-loader [loading]="loading"></bar-loader>

<div class="row">
  <div class="col">
    <div class="lead mb-0 mt-5 my-3 text-center">
      <h3 class="mb-4">{{product.name}}</h3>
      <div class="d-flex justify-content-center w-100 mb-4">
        <div 
          *ngFor="let va of product.product_template_variant_value_ids.values"
          class="badge bg-muted mx-1" 
        >
          {{va.name}}
        </div>
      </div>

      <div class="mb-3">
        <!-- {{getDescriptive(masterLine.move_id.value,masterLine.move_id.value.product_uom_qty)}} -->
      </div>

       <div class="text-muted">
        <!-- <b class="badge bg-primary" (click)="changeFrom(l)"> -->
          
          <!-- {{masterLine.package_id.id ? masterLine.package_id.name : 'Nessun imballo'}}</b> -->
      </div>
    </div>
  </div>
</div>

<div class="row flex-fill align-items-center">
  <div class="col p-4">
    <table class="table bg-ligth table-bordered">
    
      <tbody>
          <tr *ngFor="let d of descriptiveArray;let i = index;">
            <td class=" align-items-center d-flex p-3">
              <input 
                type="number"
                lang="it-IT"
                class=" form-control d-inline pe-2" 
                [ngModelOptions]="{'updateOn':'blur'}"
                [ngModel]="d[0]"
                (ngModelChange)="updateDescriptiveOr(d, $event )"
              >
              <span class="ps-2">{{d[1].name}}</span>
            </td>
          </tr>
          <tr>

          <td class="align-items-center d-flex p-3 bg-light text-nowrap">
            <input
              type="number"
              lang="it-IT"
              class="form-control d-inline pe-2" 
              [ngModelOptions]="{'updateOn':'blur'}"
              [ngModel]="quantity"
              (ngModelChange)="updateQuantity($event )"
            >
            <span class="ps-2 text-nowrap">{{product.uom_id.name}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="d-flex flex-column my-3 animate__animated" [class.animate__fadeOutDown]="loading" >
  <div class="align-items-center d-flex mt-2 justify-content-center">
    
    <button class="btn btn-link  text-success ms-2 me-2"
      (click)="back()">
      <i class="fa-duotone fa-circle-chevron-left fa-4x text-dark"></i>
    </button>
    <!-- <button class="btn btn-link text-ms-2 me-2"
      [disabled]="order?.state == 'sale'"
      (click)="assignMoveLine(masterLine)">
        <i class="fa-duotone fa-list-check fa-4x"></i>
    </button> -->

    <button class="btn btn-link text-success ms-2 me-2"
      [disabled]="order?.state == 'sale'"
      (click)="save()">
        <i class="fa-duotone fa-circle-check fa-5x"></i>
    </button>
    
    <!-- <button *ngIf="masterLine.id != -1" class="btn btn-link  text-success text-warning ms-2"
      [disabled]="order?.state == 'sale'"
      (click)="deleteLine(masterLine)">
        <i class="fa-duotone fa-circle-trash fa-4x " ></i>
    </button> -->

  </div>
</div>


</div>
