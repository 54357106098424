<app-navbar [loading]="loading"
  backroute="..">

  <a class="navbar-brand">
    {{picking?.origin}} - {{picking?.name}}
  </a>

  <button class="btn btn-lg btn-primary text-white ms-auto"
    type="button"
    (click)="confirm()">Bollato</button>

</app-navbar>

<div class="bg-light p-3">
  <p class="mb-1">
    Per {{picking?.partner_id.name}}
    <br>
    Fascicolo: {{origin.opportunity_id.value?.tracking_code}}
    <br>
    Fatturazione: {{origin.pricelist_id?.name.replace("  (EUR)", "")}}
  
    <!-- <br> -->
    <!--  {{picking?.carrier_id?.id ? picking?.carrier_id?.name : 'Nessun corriere'}} -->
    <!-- <br> -->
    <!-- Origine <a target="_blank" [routerLink]="['/', 'immediate-sale', 's', origin?.id]">{{origin?.name}}</a>  -->
  </p>
</div>

<ul class="nav nav-tabs bg-light"
  id="myTab"
  role="tablist">
  <li class="nav-item ms-3"
    role="presentation">
    <button class="nav-link "
      id="home-tab"
      data-bs-toggle="tab"
      data-bs-target="#home-tab-pane"
      type="button"
      role="tab"
      aria-controls="home-tab-pane"
      aria-selected="true">Materiale pronto</button>
  </li>
  <li class="nav-item"
    role="presentation">
    <button class="nav-link active"
      id="profile-tab"
      data-bs-toggle="tab"
      data-bs-target="#profile-tab-pane"
      type="button"
      role="tab"
      aria-controls="profile-tab-pane"
      aria-selected="false">
      Ordine di vendita </button>
  </li>
</ul>
<div class="tab-content"
  id="myTabContent">
  <div class="tab-pane fade "
    id="home-tab-pane"
    tabindex="0">


    <table class="table  table-striped">
      <thead>
        <tr>
          <th>Prodotto</th>
          <th>Categoria</th>
          <th>Quantità</th>
        </tr>
      </thead>
      <tbody class="">

        <ng-container *ngFor="let l of picking?.move_ids.values">
          <tr>
            <td *ngIf="l.display_type"
              class="text-nowrap align-middle text-muted"
              colspan="5">
              {{l.name}}
            </td>
          </tr>

          <tr *ngIf="!l.display_type">
            <td class="text-nowrap align-middle">
              {{l.product_id.name}}
            </td>
            <td class="text-muted align-middle text-nowrap">
              {{l.product_id.value?.categ_id.name}}
            </td>
            <td class="text-nowrap">{{l.reserved_availability | number : '1.0-5':'it-IT'}} {{l.product_uom.name}}</td>
            <!-- <td class=" align-middle text-nowrap">
                {{l.price_unit | number : '1.0-5':'it-IT'}} € / {{l.product_uom.name}}
              </td>
              <td class=" align-middle text-nowrap">
                {{l.price_subtotal | number : '1.0-5':'it-IT'}} €
              </td> -->
          </tr>
        </ng-container>



      </tbody>
    </table>




  </div>
  <div class="tab-pane fade show active"
    id="profile-tab-pane"
    tabindex="0">

    <table class="table align-middle ">
      <thead>
        <tr>
          <th>Prodotto</th>
          <th>Categoria</th>
          <th>Quantità</th>
          <th>Prezzo</th>
          <th>Totale</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let l of origin?.order_line.values">
          <tr>
            <td *ngIf="l.display_type"
              class="text-nowrap align-middle text-muted"
              colspan="5">
              {{l.name}}
            </td>
          </tr>

          <tr *ngIf="!l.display_type">
            <td class="text-nowrap align-middle">
              {{l.product_id.name}}
            </td>
            <td class="text-muted align-middle text-nowrap">
              {{l.product_id.value?.categ_id.name}}
            </td>
            <td class="text-nowrap">{{l.product_uom_qty | number : '1.0-5':'it-IT'}} {{l.product_uom.name}}</td>
            <td class=" align-middle text-nowrap">
              {{l.price_unit | number : '1.0-5':'it-IT'}} € / {{l.product_uom.name}}
            </td>
            <td class=" align-middle text-nowrap">
              {{l.price_subtotal | number : '1.0-5':'it-IT'}} €
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>


  </div>
</div>