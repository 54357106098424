import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { HrEmployee } from 'src/app/models/hr-employee.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { OdoorpcService } from 'src/app/shared/services/odoorpc.service';
const NDEFReader = (window as any).NDEFReader;
import * as moment from 'moment';

@Component({
  selector: 'app-badge-read',
  templateUrl: './badge-read.component.html',
  styleUrls: ['./badge-read.component.scss']
})
export class BadgeReadComponent implements OnInit {
  action: any;
  timeout: NodeJS.Timeout;

  constructor(
    private odooEM: OdooEntityManager,
    public route: ActivatedRoute,
    public router: Router,
    private odooRPC:OdoorpcService
  ) { }

  async ngOnInit(): Promise<void> {

    
    this.route.params.subscribe(async params => {

      // await this.odooRPC.login({
      //   db:"galimberti16", 
      //   login:"badge",
      //   password: "DRb!N3Fm"
      // })

      // this.timeout = setTimeout(() => {

      // }, 5 * 1000);
      this.onCode(params.id)
    })
    
  }

  formateDate(date) {
    return moment(date).format('DD/MM/YYYY')
  }

  formateTime(date) {
    return moment(date).format('hh:mm')
  }
  
  
  async onCode(badgeId) {
    // var res = await this.odooRPC.callWithApiKey("attendance_scan", new HrEmployee().ODOO_MODEL,badgeId,null,{"allowed_company_ids": [1]},"2011f6a5e5d0425b933083151fd4959ce57554ee")
    var res = await firstValueFrom(this.odooEM.call(new HrEmployee(), "attendance_scan", badgeId, {"allowed_company_ids": [1]}))
    if (res.action) {
      this.action = res.action
      setTimeout(() => {
        this.router.navigate(["/badge"])
      }, 3 * 1000)
    } else {
      this.action = null
      alert("Error")
    }
  }



}
