import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PRINT_ATTENDANCE_CFG } from 'src/app/models/deal';
import { SaleOrder } from '../models/sale-order.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { HrAttendance } from '../models/hr-attendance.model';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  socket: any;

  constructor(private router: Router, public odooEm: OdooEntityManager) { }

  ngOnInit() {


    // (window as any).global = {}
    // var s = this.odoo.searchRead("res.partner")
    // console.log("SS", s)
    // check redirect uri , setted by auth.guard
    // if (localStorage.getItem("redirect_uri") !== null) {
    //   var path = localStorage.getItem("redirect_uri")
    //   localStorage.removeItem("redirect_uri")
    //   this.router.navigate([path]);
    // }

  }

  async printAttendances() {


    var rows = []
    var hr = await this.odooEm.search<HrAttendance>(new HrAttendance(), []).toPromise()
    for (let index = 0; index < hr.length; index++) {
      const element = hr[index];
      var row = []
      row.push(element.employee_id.name)
      row.push(element.check_in)
      row.push(element.check_out)
      row.push(element.worked_hours)
      rows.push(row)
    }

    const sheetName = 'DATI';
    const o = (await gapi.client.drive.files.copy({
        supportsAllDrives: true,
        fileId: PRINT_ATTENDANCE_CFG.template_sheet_id,
        resource: {
          parents: [PRINT_ATTENDANCE_CFG.spool_folder_id]
        },
        fields: 'id, parents'
      })
    )

    console.log(" PPPPPPRR o.result.id ", o.result.id)
    var spreadsheetId = o.result.id

    // Get the number of columns in the sheet
    const data: any = await gapi.client.sheets.spreadsheets.get({
      spreadsheetId,
      ranges: sheetName,
      includeGridData: false,
    });

    const columnCount = data.result.sheets[0].properties.gridProperties.columnCount;

    // Clear existing data in the sheet
    await gapi.client.sheets.spreadsheets.values.clear({
      spreadsheetId: spreadsheetId,
      range: `${sheetName}!A2:${String.fromCharCode(64 + columnCount)}`, // Exclude the first row
      resource: {
      },
    });

    await gapi.client.sheets.spreadsheets.values.append(
      {
        spreadsheetId: o.result.id,
        range: 'Dati',
        valueInputOption: 'RAW',
      }, {
      values: rows
    }
    )
    window.open('https://docs.google.com/spreadsheets/d/' + spreadsheetId, '_blank');
  }
}
