import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlowDashboardService } from '../services/flow_dashboard.service';

@Component({
  selector: 'app-flow-inspector',
  templateUrl: './inspector.component.html',
})

export class InspectorComponent {

  public action : any

  subscription: Subscription;

  constructor(private serivice: FlowDashboardService) { }

  ngOnInit() {

    this.subscription = this.serivice.eventSelection$.subscribe(action => {
      this.action = action
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
