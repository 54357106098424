import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Provider,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export const WRAPPER_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputNumberComponent),
  multi: true
};

// template: `<input
// [(ngModel)]="innerValue"
// (ngModelChange)="ngModelChange.emit($event)" 
// [ngModelOptions]="ngModelOptions" (change)="onChange()" 
// [disabled]="disabled"
// type="text" class="w-100"
// style="min-width:max-content;max-width:max-content;"
// >`,

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'input-number',
  template: `<p class="mb-0 pb-0 w-100 text-end"
                type="text"
                style="min-width:30px;"
                (keydown)="onFocus($event)"
                [attr.contenteditable]="!disabled"
                (blur)="onChange($event.target.textContent)"
                (click)="onmousedown($event)"
                >{{innerValue ? innerValue : '-'}}</p>`,
                // style="min-width:{{innerValue ? innerValue.toString().length : 2}}em;max-width:{{innerValue ? innerValue.toString().length : 2}}em"
  providers: [WRAPPER_CONTROL_VALUE_ACCESSOR]
})

export class InputNumberComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() ngModelOptions
  @Output() ngModelChange = new EventEmitter()
  public innerValue:string ;
  private _change: any;

  
  private _touched: any;
  public value = 'dsa';

  constructor(
    private elementRef:ElementRef
  ) {
  }


  onmousedown(e) {
    
    // we want to select dummy 0
    if (this.elementRef.nativeElement.querySelector("p").innerText == "0") {
      let range = document.createRange();
      range.selectNodeContents(this.elementRef.nativeElement.querySelector("p"));
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }

    // range.moveToElementText(div);
    // range.select();
    // this.elementRef.nativeElement.querySelector("p").
  }

  onFocus(e) {
    
    if (e.key == 'Enter') {
      e.preventDefault()
      this.elementRef.nativeElement.querySelector("p").blur()
      this.onChange(e.target.textContent)
      var td:Element = this.elementRef.nativeElement.closest("td")
      var tdi = Array.prototype.indexOf.call(td.parentElement.childNodes, td) + 1;
      (td.parentElement.nextElementSibling.querySelector('td:nth-child(' + tdi + ')').querySelector("input-number p") as any).focus()
    }
    if (this.innerValue == "0") {
      // this.elementRef.nativeElement.focus()
      // var range = document.createRange();
      // range.selectNodeContents(this.elementRef.nativeElement.querySelector("p"));
      // window.getSelection().removeAllRanges();
      // window.getSelection().addRange(range);


      return true
    }
  }

  writeValue(obj: any): void {
    console.log("XXX write 1 ", obj, obj?.toString())
    
    if (!obj) {
      this.innerValue = null
      return
    }
    if (!obj.replace)
    obj = obj.toString()
    var p = obj.replace(/,/g, '').replace(/\./, ',')
    
    // if (!obj.replace)
    // obj = obj.toString()
    // if (obj.replace) {
    //   console.log("XXX REPLACEALL", obj)
      
    // } else {
    //   console.log("XXX ELSEREPLACEALL", obj)
    //   var p = obj
    // }
    // console.log("XXX WRITE ", obj)
    this.innerValue = p
  }

  registerOnChange(fn: any): void {
    this._change = fn;
  }

  registerOnTouched(fn: any): void {
    this._touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {

  }

  onChange(value) {
    window.getSelection().removeAllRanges();
    console.log("ON CHANGE ", value)

    // var p = value.replace(/\./g, '').replace(/,/, '.');
    var p = value
    if (p != this.innerValue) {
      this.innerValue = p
      var v = value.replace(/\./g, '').replace(/,/, '.');
      this._change(v);
    }

    console.log("CHANE", value, this.innerValue,p)
  }
}
