import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { RestapiService } from '../../shared/services/rest-api.service';

import {debounceTime,filter,first,switchMap} from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, merge, Observable, of, ReplaySubject } from 'rxjs';
import { RecentCalls } from '../recents';
import { Contact } from 'src/app/models/contact.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ContactSearchService } from 'src/app/shared/services/contact-search.service';

@Component({
  selector: 'app-contact-picker2',
  templateUrl: './contact-picker2.component.html'
})
export class ContactPicker2Component implements OnInit {

  personQuery = true;
  companyQuery = true;
  showToCheck: boolean = true;
  contacts: Contact[];
  loading: boolean;
  input: any;
  recents:RecentCalls = new RecentCalls()
  inputSearch:string=""
  inputNew: string =""
  @Input() showAddresses:boolean = false
  @Input() mode = '';
  state: string;
  isNewEmbedded: boolean;

  @Output() onSelect:EventEmitter<any> = new EventEmitter();
  recentContacts: Contact[];
  $formChange: ReplaySubject<any> = new ReplaySubject<any>(1);
  showResults: boolean = true;
  filterCompany$:  BehaviorSubject<boolean> = new BehaviorSubject(false);
  addresses: Contact[] = [];
  address: Contact;
  contact: any;


  constructor(
    public restapi: RestapiService,
    private router: Router,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private odooEm: OdooEntityManager,
    private toastService: ToastService,
    private contactSearchService: ContactSearchService
  ) {
    // this.mode = route.snapshot.queryParams.mode;
  }

  ngOnInit() {
    // if (this.mode == 'embedded')
    //   this.showResults = false
    
      // this.recentContacts = this.recents.getRecents()

    // workaround to force 401 interceptor @giulio
    console.log(this.odooEm.search(new Contact(),[],1).pipe(first()).toPromise())
    const $routeChange = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd && e.url === '/contact')
    );

    // var s = this.route.snapshot.queryParamMap.get('search')
    // if (s) {
    //   this.inputSearch = s
    //   this.$formChange.next(s)
    // } 
    
    let searchString = '';
    merge(this.filterCompany$,this.$formChange.pipe(debounceTime(400)), $routeChange).pipe(switchMap(() => {
      this.loading = true;
      // this.router.navigate([], { queryParams: { search: this.inputSearch } })
      if (this.inputSearch === undefined || this.inputSearch === null) {
        searchString = '';
      } else {
        searchString = this.inputSearch;
      }
      this.updateSearchState(searchString);
      return this.getContacts(searchString);
    })).subscribe(contacts => {
      this.loading = false;
      if (!contacts) {
        this.toastService.show('Impossibile ottenere i contatti');
      }

      if(contacts)
        contacts = contacts.sort( (c) => {
          if( c.name && c.name.toLowerCase().indexOf(searchString.toLowerCase()) != -1) 
            return -1
          else
            return 1;
        })
      this.contacts = contacts;
    });

    this.input = this.elementRef.nativeElement.querySelector('nav input');
  }

  onFocusInput(focus:boolean) {
    if (this.mode != 'embedded')
      return

    if (!focus)  // hack to permit click from results, before to close
      setTimeout(x => {
        this.showResults = false
      },100)
    else
      this.showResults = true
  }
  
  async onContact(c) {
    this.contact = c
    this.address = null
    if (this.showAddresses) {
      this.addresses = await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [['parent_id','=', c.id,], ['type','=','delivery'],['active','=', true]]))
      this.addresses.unshift(c)
    } else
      this.onSelect.next(c)
  }

  onAddress(a) {
    this.address = a
    this.onSelect.next(a)
  }

  onSearchChange() {
    this.$formChange.next(true)
  }
  
  onAddressChange(a) {
    // create a new temp contact on the fly 
    // needs to be saved later!!
    this.address = new Contact()
    console.log("address ", a )
    this.address.parent_id.id = this.contact.id
    this.address.name = a.formatted_address
    this.address.street = a.formatted_address
    this.onAddress(this.address)
  }

  getContacts(inputSearch: string): Observable<Contact[]> {
    
    if (!inputSearch) {
      return of(null);
    }
    
    let states = 'quotation';
    if (this.showToCheck) {
      states += ',to_check';
    }

    // check if criteria is email
    var re = /\S+@\S+\.\S+/;
    var m = this.inputSearch.match(re);
    if (m && m.length) {
      var x:ReplaySubject<Contact[]> = new ReplaySubject(1)
      var criteria:any = [['phone', 'ilike', this.inputSearch]]
      // this.odooEm.search<ContactLink>(new ContactLink(), criteria).subscribe(l => {
      //   console.log("LINKS", l)
      //   var pids = l.map(x => x.partner_id.id)
      //   this.odooEm.search<Contact>(new Contact(), [['id', 'in', pids]]).subscribe(r => {
      //     x.next(r)
      //   })
      // })
      
      return x

    } else {
      console.log("filter company", this.filterCompany$.value)
      
      inputSearch = inputSearch 
      var c:any = [
        ['parent_id','=', null]
      ]
      var parts = inputSearch.split(" ")
      parts.forEach(p => {
        // criteria = [
        //     ['name','ilike', p.replace(/[\s\*]/g, "%25")],
        //     '|',
        //     ['vat','ilike', p.replace(/[\s\*]/g, "%25")],
        //     ['city','ilike', p.replace(/[\s\*]/g, "%25")]
        // ]
        criteria = [
          '|',
          '|',
          ['display_name','ilike', p.replace(/[\s\*]/g, "%25")],
          ['vat','ilike', p.replace(/[\s\*]/g, "%25")],
          ['city','ilike', p.replace(/[\s\*]/g, "%25")]
        ]
        c = c.concat(criteria)
      })

      // if (this.mode !== 'embedded' && this.filterCompany$.value == true) {
      //   c.unshift( ["parent_id", "!=", null] ) 
      // }

      // if (this.mode !== 'embedded' && !this.filterCompany$.value) {
      //   c.unshift( ["parent_id", "=", null] ) 
      // }
      // if (this.filterAchived$.value )
      //   c.push( ["active", "=", false] ) 
      // else
      c.push( ["active", "=", true] ) 

      if( this.mode === "embedded") {
        // c.unshift(['parent_id', '=', false])     
      } else {
        // if (this.companyQuery ) 
        //   c.unshift(['parent_id', '=', false])
        // else if(!this.companyQuery)
        //   c.unshift(['parent_id', '!=', false])
      }

      return this.odooEm.search<Contact>(
        new Contact(), c)
    }
  }

  async redirectContact(c) {
    this.contact = c
    this.addresses = await firstValueFrom(this.odooEm.search<Contact>(new Contact(), [['parent_id','=', c.id]]))
    this.inputSearch = c.name
    // this.onSelect.emit(c)      
  }

  resetInput() {
    this.input.value = '';
  }

  updateSearchState(newString: string) {
    // this.contactSearchService.searchString = newString;
  }
}
