import { Component, OnInit, ElementRef, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { PurchaseOrder } from 'src/app/models/purchase-order.model';
import { SaleOrder } from 'src/app/models/sale-order.model';
import { StockPicking } from 'src/app/models/stock-picking';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { ODOO_IDS } from '../models/deal';
import { ProcurementGroup } from '../models/procurement.group.model';
import { MailActivity } from '../models/mail.message';


@Pipe({ name: 'sortByActivityDate' })
export class SortByActivityDate implements PipeTransform {
  transform(value: StockPicking[], order ): any[] {
    if (!value) return []
    
    return value.sort((a, b) => {
      // if (!a || !b || !a.sale_id.value || !b.sale_id.value ||!a.sale_id.value.activity_ids.ids || !b.sale_id.value.activity_ids.ids)
      //   return 1

      try {
        let da:Date = new Date(a.sale_id.value.activity_ids.values.find(a => a.activity_type_id.id == ODOO_IDS.activity_todo)?.date_deadline)
        let db = new Date(b.sale_id.value.activity_ids.values.find(b => b.activity_type_id.id == ODOO_IDS.activity_todo)?.date_deadline)
        return  da.getTime() -  db.getTime()
      } catch(e) {
        return 1
      }
    })
  }
}

@Pipe({ name: 'Date4Humans' })
export class Date4Humans implements PipeTransform {

  transform(value: string, order = '', column: string = ''): string {
    // Get today's date
    var todaysDate = new Date();
    var inputDate = new Date(value)
    
    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
      return "Oggi"
    } else 
      return new Date(value).toLocaleDateString()
  }
}



@Component({
  selector: 'app-production-search',
  templateUrl: 'picking-search.component.html',
})
export class PickingSearchComponent implements OnInit {


  loading:boolean
  pickings: StockPicking[];
  title = ""

  filters = {
    purchase : false,
    sale : false
  }

  searchInput:BehaviorSubject<string> = new BehaviorSubject("")
  params: any;
  keybuffer: string = "";
  picking_search_options: {};
  activePickingOption: any;

  constructor(
    public odooEM : OdooEntityManager,
    public router: Router,
    public elementRef : ElementRef,
    private activatedRoute:ActivatedRoute
  ) { 
  }


  async ngOnInit(): Promise<void> {
    this.picking_search_options = ODOO_IDS.picking_search_options
    let s = localStorage.getItem("activePickingOption")
    if (s != "undefined") 
      this.activePickingOption = JSON.parse(s)
    else this.activePickingOption = ODOO_IDS.picking_search_options[0]

    this.params = this.activatedRoute.snapshot.params

    if (this.activatedRoute.snapshot.queryParamMap.get('search')) {
      this.activePickingOption = ODOO_IDS.picking_search_options.E
      this.searchInput.next(this.activatedRoute.snapshot.queryParamMap.get('search'))
    }

    this.title = this.params['type']
    this.searchInput.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(s => {
        this.load()
      });
  }


  onSearchChange($event: any) {
    this.router.navigate([], { queryParams: { search: $event } });
  }

  compareByString(a,b) {
    return a?.toString() == b?.toString()
  }

  toggleFilter(f:string) {
    this.filters[f] = !this.filters[f]
    console.log(f)
    this.load()
  }

  persist() {
    localStorage.setItem("activePickingOption", JSON.stringify(this.activePickingOption))
    this.load()
  }

  getPickingPage(p:StockPicking) {
    if (p.picking_type_id.id == ODOO_IDS.picking_type_receipt) {
      return "in" 
    }
    // if (ODOO_IDS.packaging_picking_type_ids.includes(p.picking_type_id.id))
    //   return 'pack'
    // else if (ODOO_IDS.pickpack_picking_type_ids.includes(p.picking_type_id.id))
    //   return 'pickpack'
    // else
      return 'internal'
  }

  
  async onScan(s:string) {
    // look for candidates
    // var sales = await this.odooEM.search<SaleOrder>(new SaleOrder(), [['partner_ref', '=', s]]).toPromise()
    // if (sales.length > 0) {
      
    // }

    var purchases = await this.odooEM.search<PurchaseOrder>(new PurchaseOrder(), [['partner_ref', '=', s]]).toPromise()
    if (purchases.length > 0) {
      this.searchInput.next(purchases[0].name)
    }
  }
    
  async load() {

    this.loading = true
    var c:any[] = []
    // [['state','in',['assigned', 'confirmed']], ]
    // todo temp
    // c.push(['picking_type_id', '=', ODOO_IDS.picking_type_receipt])
    
    // if (this.filters.sale) 
    //   c.push(['origin', 'ilike', 'V%'])
    // if (this.filters.purchase) 
    //   c.push(['origin', 'ilike', 'A%'])
    
      var f = this.searchInput.value
    if (f) {
      c.push("|")
      c.push("|")
      c.push("|")
      c.push(['purchase_id','ilike',f])
      c.push(['sale_id','ilike',f])
      // c.push(['origin','ilike',f])
      c.push(['name','ilike',f])
      c.push(['partner_id','ilike',f])
    }

    if (this.activePickingOption) {
      c = c.concat(this.activePickingOption)
    }
    var ps = await this.odooEM.search<StockPicking>(new StockPicking(),c,500,"","scheduled_date").toPromise()
    
    await this.odooEM.resolveArrayOfSingle(new ProcurementGroup(),ps,"group_id").toPromise()
    await firstValueFrom(this.odooEM.resolveArrayOfSingle(new SaleOrder(),ps,"sale_id"))
    
    let sales = ps.map(p => p.sale_id.value).filter(s => s).flat()
    if (sales.length > 0) {
      console.log("SALE IDS", sales)

      await firstValueFrom(this.odooEM.resolveArray(new MailActivity(),sales,"activity_ids"))
    }
    
    this.pickings = ps
    this.loading = false
  }


  getActivityDate(p:StockPicking) {
    if (p && p.sale_id && p.sale_id.value) {
      let a = p.sale_id?.value?.activity_ids?.values?.find(a => a.activity_type_id.id == ODOO_IDS.activity_todo)
      console.log("aaaaaa", a)
      if (a)
        return a.date_deadline
    }
  }


  getPickingClass(p:StockPicking) {
    let c = ""  
    if (p.backorder_id.id)
      c = "fa-circle-half-stroke "
    else
      c = "fa-circle "

    if (p.state == 'waiting') // backorder
      return c + 'text-muted'
    if (p.state == 'assigned'  || p.state == 'done')
      return c + 'text-success'
    if (p.state == 'confirmed' )
      return c + 'text-warning'
    
  }
    
  
}
