<!-- <app-order-header
  showInventory="true"
  [isSomethingChecked]="true"
  [order]="order"
  [canConfirm]="order?.state == 'draft'"
  [loading]="loading"
></app-order-header> -->
<div class="d-flex my-3 justify-content-between w-100 ps-3">
  <p class="lead mb-0  text-white text-nowrap">Preparo la stampa</p>
  <button class="btn m-0"><i class="fa fa-close text-white" (click)="done()"></i></button>
</div>
<!-- <div class="d-flex">
  <div class="row align-items-center text-center" style="min-height: 100vh">
    <div class="offset-md-2 col-md-8">
      <div class="fa-3x">
        <i class="fa-solid fa-spinner fa-spin-pulse fa-spin-reverse ms-3"></i>
      </div>
    </div>
  </div>
</div> -->



<div
  id="info"
  class="card side-card w-25 h-100 rounded-0 d-none"
  *ngIf="order"
>
  <div class="card-body" data-print-row-header class="">

    <div class="form-group" class="">
      <input data-print-col value="{{ order.name }} - {{ order.opportunity_id.value?.tracking_code }}" />
    </div>

    <div class="form-group" class="d-none">
      <input data-print-col value="{{ order.partner_id.name }}" />
    </div>

    <div class="form-group" class="d-none">
      <input data-print-col value="{{ order.date_order }}" />
    </div>

    <div class="form-group" class="d-none">
      <input data-print-col value="{{ order.validity_date }}" />
    </div>

    <div class="form-group" class="d-none">
      <textarea data-print-col class="form-control" [ngModel]="order.note ? innerText(order.note) : ''" placeholder="Note"></textarea>
    </div>

    <div class="form-group" class="d-none">
      <input data-print-col value="{{ order.pricelist_id.name }}" />
    </div>

    <div class="form-group" class="d-none">
      <input data-print-col value="{{ order.payment_term_id.name }}" />
    </div>

    <span data-print-col>
      {{order.incoterm.name}}
    </span>
    
    <span data-print-col>
      {{order.picking_policy}}
    </span>

    <span data-print-col>
      {{order.client_order_ref}}
    </span>

    <span data-print-col>
      {{order.state}}
    </span>

    
    <span data-print-col>
      {{order.amount_untaxed}}
    </span>
    <span data-print-col>
      {{order.amount_total}}
    </span>

    <span data-print-col>
      {{order.ga_amount_total_agreed}}
    </span>


    <span data-print-col>
      {{order.user_id.name}}
    </span>

    <span data-print-col>
      {{order.partner_id.value?.vat}}
    </span>

    <span data-print-col>
      {{order.partner_id.value?.city}}
    </span>


    <span data-print-col>
      {{order.partner_id.value?.city}}
    </span>

    <span data-print-col>
      {{order.ga_title}}
    </span>

    <span data-print-col>
      {{order.opportunity_id.value?.name}}
    </span>

    <span data-print-col>
      {{order.ga_address}}
    </span>

    <span data-print-col>
      {{order.opportunity_id.value?.tag_ids.values?.length > 0 ? order.opportunity_id.value.tag_ids.values[0].name : ''}}
    </span>


    <span data-print-col>
      {{order.partner_id.value?.ga_arca}}
    </span>
  </div>
</div>


<!--(actionFromHeaderEmitter)="handleHeaderEvent($event)"-->

<!-- <app-order-inventory 
[inventoryClosed]="inventoryClosed"
(toggleInventory)="inventoryClosed = !inventoryClosed"
(addOrderLine)="addOrderLine($event)"
>
</app-order-inventory> -->

<div class="table-container h-100 d-none">
  <table
    class="table table-bordered tableFixHead table-hover w-100"
    id="order"
    style="margin-top: 62px"
    *ngIf="order"
  >
    <thead>
      <tr>
        <th>Codice</th>
        <th>Prodotto</th>
        <th>Descrizione</th>
        <th>Quantità</th>
        <th>Unità di misura</th>
        <th>Quantità Confezione</th>
        <th>Imballaggi</th>
        <th>Prezzo unitario</th>
        <th>Imposte</th>
        <th>Sconto</th>
        <th>Totale parziale</th>
        <th>Altezza</th>
        <th>Larghezza</th>
        <th>Lunghezza</th>
      </tr>
    </thead>

    <tbody>
      <tr
        style="vertical-align: middle"
        *ngFor="let line of orderLines"
        data-print-row
      >
        <ng-container *ngIf="line.product_id.id">
          <td class="text-right" data-print-col>
            {{
              line.product_id.value?.id
            }}
          </td>
          <td class="text-right">
            line {{line.product_id}}
            <input data-print-col value="{{ line.product_id.name }}" />
          </td>
          <td>
            <input data-print-col value="{{ line.product_id.value?.categ_id.name }}" />
          </td>

          <input data-print-col value="{{ getPz(line) }}" />
          <input data-print-col value="Pz"/>

          <!-- <app-input-double-um
            [ngModel]="line.product_uom_qty"
            [product]="line.product_id.value"
            style="vertical-align: middle;"
          ></app-input-double-um> -->

          <!-- <input data-print-col value="{{ line.product_uom_qty }}" /> -->
          
          <input data-print-col type="number" value="{{ line.product_uom_qty }}" />

          <input data-print-col value="{{ line.product_uom.name }}" />

          <input data-print-col type="number" value="{{ line.product_packaging_qty }}" />

          <input
            data-print-col
            value="{{ line.product_packaging_id.name }}"
          />

          <!-- price unit-->          
          <input data-print-col type="number" value="{{ line.price_unit  }}">
          
          <td class="text-right">
            <span data-print-col *ngIf="line.tax_id.values && line.tax_id.values?.length > 0">{{
              line.tax_id.values[0]?.name
            }}</span>
            <span data-print-col *ngIf="line.tax_id.values?.length == 0"></span>
          </td>

          <input data-print-col type="number" value="{{line.discount}}">
          
          <input data-print-col type="number" value="{{ line.price_subtotal }}">

          <input data-print-col type="number" value="{{getVariantAttribute(line,'Altezza')?.name}}">

          <input data-print-col type="number" value="{{getVariantAttribute(line,'Larghezza')?.name}}">
            
          <input data-print-col type="number" value="{{getVariantAttribute(line,'Lunghezza')?.name}}">


          <div data-print-col>
            {{line.route_id.name}}
          </div>

         
          <div  data-print-col> 
            {{getDescriptive(line)}}
          </div>

          <!-- route -->
          <div *ngFor="let mo of getMoves(line)" data-print-col> 
            {{mo.rule_id.name}}
          </div>
          <!-- <input data-print-col type="number" value="{{}}"> -->

        
          
        </ng-container>


        

        <ng-container *ngIf="line.display_type == 'line_note'">
          <td data-print-col>
            line_note
          </td>
          <td data-print-col>
            <span>{{ line.name }}</span>
          </td>
        </ng-container>

        
        <ng-container *ngIf="line.display_type == 'line_section'">
          <td data-print-col>
            line_section
          </td>
          <td data-print-col>
            <span>{{ line.name }}</span>
          </td>
        </ng-container>


      </tr>
    </tbody>
  </table>
</div>

