
<div class=" p-4 text-white" tabindex="-1"  aria-labelledby="offcanvasDarkLabel">

    <h3>
        Dashboard
    </h3>

    <h3>Automations</h3>
    <ul class="text-nowrap list-unstyled">

        
        <ng-container  *ngFor="let f of flows" >
            <li [ngClass]="{'active':selectedFlow && selectedFlow.id == f.id}" >
                <span (click)="choose(f)">{{f.name}}</span>
                <i (click)="delete(f)" class="fa fa-trash"></i>
            </li>
        </ng-container>
        <li (click)="add()"><i class="fa fa-plus"></i> Nuova</li>
    </ul>
    
    
    <ng-content></ng-content>
</div>

