<app-navbar>


</app-navbar>

<div class="d-flex">

    <div class="list-group">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 fas fa-fw fa-building  text-muted"></i>
            Contatti
        </a>
    </div>


    <div class="list-group my-3">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 fas fa-fw fa-building  text-muted"></i>
            Contatti
        </a>
    </div>

    <div class="list-group">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 fas fa-fw fa-building  text-muted"></i>
            Contatti
        </a>
    </div>



</div>