
<!-- <h1>--{{result}}--</h1> -->
    
<canvas #canvas class="" width="{{width}}" height="{{height}}" style="max-width:100%"></canvas>
<video #video  width="{{width}}" height="{{height}}" class="d-none" ></video>

<div class="app-toolbar d-flex row align-items-center">

    <div class="col"></div>

    <a [hidden]="!result" (click)="selectCode(result.text)"  class="btn btn-lg ms-3 p-3 bg-success text-white col" >
        <span class="fs-2">{{result?.text}}</span>
    </a>
    
    <div class="col text-end">
        <a class="btn code btn-scan p-3 me-3 bg-dark text-white" (click)="selectCode('')">
            <i class="fa fa-times fa-2x"></i>
        </a>
    </div>
</div>