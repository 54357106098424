<div class="row" *ngIf="contact?.child_ids">
<!-- <ul  class="list-inline" > -->

  
  <ng-container *ngFor="let v of contact.child_ids.values">
    <div  class="col-md-4 ">
      <div class="card mb-3">
        <div class="card-body">

          <!-- contact name -->
          <div class="d-flex align-items-center justify-content-between mb-1 bg-white" (click)="editingContact = !editingContact; openContact = editingContact ? v : null ;">
            <span><b class="text-nowrap">{{v.name}}</b> <span class="text-muted" *ngIf="v.note"> - {{v.note}} </span>&nbsp;</span>
            <div class="d-flex">
              <i class="fa fa-trash text-info me-3" (click)="onDelete(v)" *ngIf="openContact == v && editingContact"  ></i>
              <i class="fa fa-bars text-info" ></i>
            </div>
            
          </div>

          <form class="w-100" *ngIf="openContact == v && editingContact" ngNoForm>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Nome</label>
              <input type="text" class="form-control" (change)="update(v, 'name')" [(ngModel)]="v.name">
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Note</label>
              <input type="text" class="form-control" (change)="update(v, 'ga_comment')" [(ngModel)]="v.ga_comment">
            </div>
             <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email</label>
              <input type="email" class="form-control" (change)="update(v, 'email')" [(ngModel)]="v.email">
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Mobile</label>
              <input type="tel" class="form-control" (change)="update(v, 'mobile')" [(ngModel)]="v.mobile">
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Telefono</label>
              <input type="tel" class="form-control" (change)="update(v, 'phone')" [(ngModel)]="v.phone">
            </div>

          </form>

          <ul class="list-unstyled mt-3 mb-0" *ngIf="openContact != v">
            <li *ngIf="v.ga_comment" class="">
                <p  class="mb-0 mb-3">{{v.ga_comment}}</p>
            </li>
            <li *ngIf="v.email" class="text-nowrap ">
              <i class="fa fa-envelope text-info mb-3"></i>
              <a class="ms-2" href="mailto:{{v.email}}">
                {{v.email}}
              </a>
            </li>
            <li *ngIf="v.mobile">
              <i class="fa fa-phone text-info mb-3"></i>
              <a class="ms-2" href="tel:{{v.mobile}}">
                {{v.mobile}}
              </a>
            </li>

            <li *ngIf="v.phone">
              <i class="fa fa-phone text-info mb-3"></i>
              <a class="ms-2" href="tel:{{v.phone}}">
                {{v.phone}}
              </a>
            </li>
            
          </ul>

        </div>
      </div>
      

      <!-- dettagli -->
      <!-- <div class="w-100 pb-4" > -->
     
      <!-- modifca dettagli -->
      <!-- <div class="w-100 " *ngIf="openContact == v && editingContact">
        <input class="form-control mt-2" placeholder="Note" [(ngModel)]="v.comment" (change)="onChangeContact(v,'comment')">

        <table class="my-3">
          <ng-container *ngFor="let l of v.link_ids.values">
            <tr>
              <td class="pt-4">
                <select class="form-control w-auto" [(ngModel)]="l.type" (change)="onChangeLink(l,'type')">
                  <option value="Tel">Tel</option>
                  <option value="Cell">Cell</option>
                  <option value="Email">Email</option>
                  <option value="PEC">PEC</option>
                  <option value="URL">URL</option>
                </select>
              </td>
              <td class="w-100 pt-4">
                <input class="form-control w-100" [(ngModel)]="l.value" (change)="onChangeLink(l,'value')">
              </td>
              <td rowspan="2">
                <button class="btn btn-link text-muted" (click)="onDeleteLink(v, l)">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
            <tr class="mt-4 mb-4">
              <td colspan="2" class="pt-2 w-100">
                <input class="w-100 form-control" placeholder="Note" [(ngModel)]="l.note" (change)="onChangeLink(l,'note')">
              </td>
            </tr>
          </ng-container>
        </table>

        <button class="btn btn-link d-block mx-auto" (click)="newLink(v)">  
          <span class="fa-stack">
            <i class="fas fa-circle text-muted fa-stack-2x"></i>
            <i class="fas fa-plus text-white fa-stack-1x fa-inverse"></i>
          </span>
        </button>
        
      </div>
       -->
    </div>
    </ng-container>
    <!-- add child contact -->
    <!-- <li class="list-inline-item rounded-0"  class="bg-white p-3 list-inline-item mb-5 m-2 align-items-center justify-content-center d-flex" >
      <button class="btn btn-link d-block mx-auto " (click)="newChild(v)">  
        <span class="fa-stack">
          <i class="fas fa-plus fa-stack-1x "></i>
        </span>
      </button>
    </li> -->


</div>