<app-navbar  [loading]="loading" backroute="..">
    <a class="navbar-brand">
      Movimenti
    </a>

    <div class="dropdown ms-auto">
        <button class="btn btn-link text-white" data-bs-toggle="dropdown">
          <i class="fa fa-bars"></i>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li><a class="dropdown-item" [routerLink]="['oddments']">Resto legno</a></li>
        </ul>
    </div>
</app-navbar>

<div class="navbar bg-light px-3">
    <div class="form d-flex flex-row align-items-center w-100">
        <span>Cerca</span>
        <input class="form-control mx-3 w-100" [ngModel]="searchInput | async" (ngModelChange)="searchInput.next($event);onSearchChange($event)">
        <select [compareWith]="compareByString" class="form-control" [(ngModel)]="activePickingOption" (ngModelChange)="persist()">
            <option *ngFor="let o of this.picking_search_options | keyvalue" [ngValue]="o.value">{{o.key}}</option>
        </select>
    </div>
</div>

  <div class="table-wrapper h-100 overflow-scroll" style="overflow-y:scroll;-webkit-overflow-scrolling: touch;" [hidden]="loading" >
    <table class="table table-bordered align-middle">
        <thead class="bg-light">
            <tr>
                <th>Origine</th>
                <th>Data</th>
                <th>Operazione</th>
                <!-- <th>Riferimento</th> -->
                <!-- <th>Nome</th> -->
            </tr>
        </thead>
        <tbody  style="-webkit-overflow-scrolling: touch;">
            <tr *ngFor="let p of pickings | sortByActivityDate" [routerLink]="[getPickingPage(p),p.id]" queryParamsHandling="preserve">
                <td>
                    <div class="d-flex align-items-center">
                        <span class="fa  {{getPickingClass(p)}}">&nbsp;</span>
                    
                    
                        <div>
                            <span *ngIf="p.purchase_id.name">
                                {{p.purchase_id.name}}
                            </span>
                            <span *ngIf="p.purchase_id.name && p.sale_id.name"> / </span>
                            <b *ngIf="p.sale_id.name">
                                {{p.sale_id.name}}
                            </b>
                            <span>
                                - {{p.partner_id?.name}}
                            </span>
                            <br>
                            {{p.sale_id.value?.ga_title}}
                        </div>
                    </div>
                </td>
                <td>
                    {{getActivityDate(p) | Date4Humans }}
                    <br>
                    {{p.sale_id.value?.user_id?.name}}
                </td>
                <td>
                    {{p.name}} <br>
                    {{p.picking_type_id.name.replace("LOMAGNA:","")}}
                </td>
              
            </tr>
        </tbody>
    </table>
  </div>
