import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Product } from 'src/app/models/product.model';
import { StockMove } from 'src/app/models/stock-move';
import { StockMoveLine } from 'src/app/models/stock-move-line';
import { StockPicking } from 'src/app/models/stock-picking';
import { StockQuant } from 'src/app/models/stock-quant';
import { StockQuantPackage } from 'src/app/models/stock-quant-package';
import { StockBackorderConfirmation, StockBackorderConfirmationLine } from 'src/app/models/stock.backorder.confirmation.model';
import { GapiService } from 'src/app/shared/services/g-api.service';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { AbstractPickingComponent } from '../abstract-picking/abstract-picking.component';
import { ProductPackaging } from 'src/app/models/product.packaging.model';
import { ODOO_IDS } from 'src/app/models/deal';
import { SaleOrderLine } from 'src/app/models/sale-order-line.model';
import { promise } from 'protractor';

@Component({
  selector: 'app-picking-editor-page',
  templateUrl: './picking-editor-page.component.html',
  styleUrls: ['./picking-editor-page.component.scss']
})
export class PickingEditorPage extends AbstractPickingComponent implements OnInit, AfterViewInit, OnDestroy {
  id: any;
  loading: boolean;
  picking: StockPicking;

  packages: StockQuantPackage[];
  activeMoveLine: StockMoveLine;
  quants: StockQuant[] = []
  scanning: boolean;
  labeling: boolean;

  scanningPackageDest: StockMoveLine;
  pickOrigin: StockMoveLine;
  pickDestination: StockMoveLine;
  keybuffer: string = ""
  contextPackage: StockQuantPackage;
  barcodeInvalid: boolean;
  barcodeValid: boolean;

  isPrinting:boolean = false
  scanningDestPackage:StockQuantPackage = null
  masterMove: StockMove;
  // detailModal: any;
  formModal: any;
  modalEl: any;
  masterInternalMove: StockMove;
  isInternal: boolean;
  scanningDefaultPackageDest: boolean;
  defaultPackageDest: StockQuantPackage;
  scanningBarcode: string;

  allLinesChecked:boolean = false
  descriptiveArray: any[];
  descriptiveArrayDoing: any[];

  constructor(
    protected odooEM:OdooEntityManager,
    private route: ActivatedRoute,
    public elementRef: ElementRef, 
    public gapiService : GapiService,
    private changeDetectorRef:ChangeDetectorRef
  ) {
    super( elementRef, gapiService);
  }
  ngOnDestroy(): void {
    // this.closeModal()
  }


  ngAfterViewInit(): void {
    
  }

  async ngOnInit() {
    this.route.params.subscribe(async params => {
      this.id = params['picking_id'];
      this.loading = true;
      await this.load()
      this.loading = false
    }) 
  }

  hasBarcodes() {
    return this.masterMove
  }

  hasLabelling() {
    return this.masterMove && this.picking?.picking_type_id.id == ODOO_IDS.picking_type_receipt
  }

  hasPick() {
    return this.masterMove && this.picking?.picking_type_id.id != ODOO_IDS.picking_type_receipt && !this.masterMove.product_id.value?.barcode
  }

  hasDefaultPack() {
    return this.picking?.picking_type_id.id != ODOO_IDS.picking_type_receipt
  }

  async resolveMove(move:StockMove) {

    let r = await firstValueFrom(this.odooEM.search<StockMove>(new StockMove(),[['id','=', move.id]]))
    if (!r.length) return

    move.quantity_done = r[0].quantity_done

     if (!move.product_id.value.packaging_ids.values)
      await firstValueFrom(this.odooEM.resolve(move.product_id.value.packaging_ids))
    
    this.descriptiveArrayDoing = this.getDescriptiveArray2(move.product_id.value, move.quantity_done)
    console.log("this.descriptiveArrayDoing", this.descriptiveArrayDoing)
    this.descriptiveArray = this.getDescriptiveArray(move)
    console.log("DESCRT ", this.descriptiveArray)
    await (this.odooEM.resolve(move.product_id.value.packaging_ids)).toPromise()
  }

  async scanBarcode2(move:StockMove) {
    await this.resolveMove(move)
    this.masterMove = move
    // this.scanningBarcode = move.product_id.value.barcode;
  }
  


  // ['fatti','da fare', 'attuale']

  getDescriptiveArray2(product:Product,quantity):any[] {
    if (!product)return
    
    if (!product.packaging_ids.values) {
      alert("non risolto")
      return []
    }
      
    var ps = product.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = quantity
    
    var d = ""
    var a = []

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    ps.forEach((p, i) => {
        
        console.log("XXX ", p,i)

        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0

        if ((ps.length - 1) == i) {
          d += parseFloat((q / p.qty).toFixed(4)) + " " + p.name + "\n"
          a.push([parseFloat((q / p.qty).toFixed(4)), p, 0])
          // q = q - (xx * p.qty)
        } else if (!isRound) {
          q = q - (x * p.qty)
          d += x + " " + p.name + "\n"
          a.push([x, p, 0])
        } else {
          q = q - (xx * p.qty)
          d += xx + " " + p.name + "\n"
          a.push([xx, p, 0])
        }
    })

    console.log("ZZZZ ", a)

    return a
   }


   getDescriptiveArray(line:StockMove):any[] {
    if (!line.product_id.value)return
    
    // if (!line.product_id.value.packaging_ids.values)
    //   await firstValueFrom(this.odooEM.resolve(line.product_id.value.packaging_ids))
    
    if (line.product_uom_qty == 0 || !line.product_id.value.packaging_ids.values) {
      alert("non risolto")
      return []
    }
      
    var ps = line.product_id.value.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = line.product_uom_qty
    
    var d = ""
    var a = []

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    ps.forEach((p, i) => {
        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0

        if ((ps.length - 1) == i) {
          d += parseFloat((q / p.qty).toFixed(4)) + " " + p.name + "\n"
          a.push([parseFloat((q / p.qty).toFixed(4)), p, 0])
          // q = q - (xx * p.qty)
        } else if (!isRound) {
          q = q - (x * p.qty)
          d += x + " " + p.name + "\n"
          a.push([x, p, 0])
        } else {
          q = q - (xx * p.qty)
          d += xx + " " + p.name + "\n"
          a.push([xx, p, 0])
        }
    })
    return a
   }

   getDescriptive(product:Product, quantity) {

    if (!product)return
    
    var ps = product.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var q = quantity
    
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    ps.forEach((p, i) => {
      
      // if (p.sales && !p.name.includes("netti")) {
        var x = Math.floor(q / p.qty)
        var xx = Number((q / Number(p.qty.toFixed(4))))
        var isRound = xx % 1 == 0
        console.log("XXXXXXXX2 ", p.name, i, x, xx, isRound)

        if ((ps.length - 1) == i) {
          d += parseFloat((q / p.qty).toFixed(4)) + " " + p.name + "\n"
          // q = q - (xx * p.qty)
        } else if (!isRound) {
          q = q - (x * p.qty)
          d += x + " " + p.name + "\n"
        } else {
          q = q - (xx * p.qty)
          d += xx + " " + p.name + "\n"
        }
    })
    return d
  }


  
  async onDelete(l:StockMoveLine) {
    this.loading = true
    await this.odooEM.delete(new StockMoveLine(), [l.id])
    await this.load()
    this.loading = false
  }

  async onScan(keybuffer: string) {
    
    if (keybuffer.length == 6 && this.masterMove) { // sto usando ns codice
      
      var nopack = this.masterMove.move_line_ids.values.find(x => !x.package_id.id)
      if (nopack) {
        await this.onPackage(nopack,keybuffer)
      } else {
        var l =  await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
          'move_id' : this.masterMove.id,
          'product_id': this.masterMove.product_id.id
        }))
        this.masterMove.move_line_ids.ids.push(l.id)
        this.masterMove.move_line_ids.values.push(l)
        await this.onPackage(l,keybuffer)
      }
    }

    this.scanning = false
    this.changeDetectorRef.detectChanges();
  }




  getSelections() {
    return this.masterMove?.move_line_ids.values?.filter(l => l._checked)
  }


  async zero() {
    var x = await firstValueFrom(this.odooEM.update<StockMove>(this.masterMove, {"quantity_done": 0}))
    await this.resolveMove(this.masterMove)
  }

  async complete() {  
    var x = await firstValueFrom(this.odooEM.update<StockMove>(this.masterMove, {"quantity_done": this.masterMove.product_uom_qty}))
    await this.resolveMove(this.masterMove)
  }


  async load() {
    var ps = await this.odooEM.search<StockPicking>(new StockPicking(),[["id","=",this.id]]).toPromise()
    if (ps.length == 0)
      throw "Error"

    
    await this.odooEM.resolve(ps[0].move_ids_without_package).toPromise()
    await this.odooEM.resolveArrayOfSingle(new Product(),ps[0].move_ids_without_package.values,"product_id").toPromise()
    await this.odooEM.resolveArrayOfSingle(new ProductPackaging(),ps[0].move_ids_without_package.values,"product_packaging_id").toPromise()
    // await this.odooEM.resolveArray(new StockMoveLine(),ps[0].move_ids_without_package.values,"move_line_ids").toPromise()
    var ml = []
    // ps[0].move_ids_without_package.values.forEach(m => {
    //   console.log("MMM", m.move_line_ids.ids)
    //   ml = ml.concat(m.move_line_ids.values)
    // })

    // console.log("MMMlll", ml)

    await this.odooEM.resolveArrayOfSingle(new Product(), ml, "product_id").toPromise()

    // if we are in a packaging picking group by dest package
    // if (ps[0].picking_type_id.id == ODOO_IDS.packaging_picking_type_id) {
      
    //   this.packages = ps[0].move_ids_without_package.values.map(m => {
    //     return m.move_line_ids.values.map(ml => ml.result_package_id.value).flat()
    //   }).flat()
    // }

    this.picking = ps[0];
    console.log("PICK", this.picking)
  }

  

  getPackagingQty(m:StockMove) {
    return  m?.product_uom_qty / m.product_packaging_id.value?.qty
  }

  async setPcsQtyDone(ml:StockMoveLine,m:StockMove,e) {
    ml.qty_done = e * m.product_packaging_id.value.qty
    // await this.updateLine('qty_done', ml, e )
  }


  // getPcsQtyDone(ml:StockMoveLine,m:StockMove) {
  //   var l = Number(this.getVariantAttribute(this.masterMove.product_id.value, 'Lunghezza'))
  //   var h = Number(this.getVariantAttribute(this.masterMove.product_id.value, 'Altezza'))
  //   var w = Number(this.getVariantAttribute(this.masterMove.product_id.value, 'Larghezza'))

  //   if (this.masterMove.product_id.value.uom_id.name.includes('²')) 
  //     var v = l  * w / 1000000
  //   else if (this.masterMove.product_id.value.uom_id.name.includes('³'))
  //     var v = l * h * w / 1000000  
    

  //   // var v = l * h * w / 1000000
  //   console.log("v", v , l * h * w / 1000000)
  //   return ml.qty_done / v
  //   // return this.getVariantAttribute(this.masterMove.product_id.value, 'Lunghezza')
  //   // return  ml.qty_done / m.product_packaging_id.value.qty
  // }


  async setPackagingQtyDone(ml:StockMoveLine,m:StockMove,e) {
    console.log("set pack ", e, m.product_packaging_id.value?.qty)
    // ml.qty_done = e * m.product_packaging_id.value?.qty
    await this.updateLine('qty_done', ml, e * m.product_packaging_id.value?.qty)
  }


  getPackagingQtyDone(ml:StockMoveLine,m:StockMove) {
    console.log("GET PACK ", ml, m,ml.qty_done , m.product_packaging_id.value?.qty)
    return  ml.qty_done / m.product_packaging_id.value?.qty
  }

  async updateLine(fieldName:string,line:StockMoveLine,e) {
    this.loading = true
    var n = {}
    n[fieldName] = e
    var lll = await this.odooEM.update(line, n).toPromise()
    if (lll) {
      line[fieldName] = e
    }
    
    this.loading = false
  }

  async closeModal() {
    this.masterMove = null
    await this.load()
  }

  getVariantAttribute(product:Product, name:string) {
    console.log("CCCC", product)
    // console.log("line ", line)
    if (product.product_template_attribute_value_ids.values) {
      var f = product.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }
    console.log("CCCC", f)
    return (f && f.length > 0) ? f[0].name : null
  }


  async newMoveLine() {
    this.loading = true
    await firstValueFrom(this.odooEM.create(new StockMoveLine(), {
      'move_id' : this.masterMove.id,
      'product_id': this.masterMove.product_id.id,
      'reserved_uom_qty': this.masterMove.product_packaging_id?.value.qty ? 1  * this.masterMove.product_packaging_id?.value.qty : 1,
      'qty_done': this.masterMove.product_packaging_id?.value.qty ? 1  * this.masterMove.product_packaging_id?.value.qty : 1
    }))

    await this.showMoveDetail(this.masterMove)
    this.loading = false
  }

  async showMoveDetail(m:StockMove) {
    // load a fresh copy 
    var s = await firstValueFrom(this.odooEM.search<StockMove>(new StockMove(), [['id','=',m.id]]))
    if (s.length != 1)
      return alert("Error nella stock move con id " + m?.id)
    m = s[0]
    
    // console.log("xxx ", this.picking)

    if (this.picking.picking_type_id.id == ODOO_IDS.picking_type_internal_id)
      this.isInternal = true
    else 
      this.isInternal = false
    
    await this.odooEM.resolveSingle(new Product(), m.product_id).toPromise()
    await this.odooEM.resolve(m.move_line_ids).toPromise()
    
    await firstValueFrom(this.odooEM.resolveSingle(new ProductPackaging(), m.product_packaging_id))
    await firstValueFrom(this.odooEM.resolve(m.product_id.value.product_template_attribute_value_ids))
    await firstValueFrom(this.odooEM.resolve(m.product_id.value.packaging_ids))

    // console.log("MASTER MOVE", m)
    this.masterMove = m
  }


  toggleAllLines(val:boolean) {
    this.masterMove?.move_line_ids.values?.forEach(x => {
      x._checked = val
    })
  }

  async completeLine(ml:StockMoveLine) {
    // check for multiple selection
    this.loading = true
    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)

    // if not checkboxs, use just the pressed one
    if (flat.length == 0 ) 
      flat.push(ml)
    
    // todo update multi
    for (var f of flat) {

      await this.odooEM.update(f, {
        qty_done : f.product_uom_qty
      }).toPromise()

      f.qty_done = f.product_uom_qty 

      console.log("FF", f)
    }
    
    // await this.load()
    this.loading = false
  }

  

  async uncompleteLine(ml:StockMoveLine) {
    // check for multiple selection
    
    var a = this.picking.move_ids_without_package.values.map(m => m.move_line_ids.values)
    var flat = [].concat(...a).filter(l => l._checked == true)
    if (flat.length == 0 ) 
      flat.push(ml)

    // todo update multi
    for (var f of flat) {
      await this.odooEM.update(f, {
        qty_done : 0
      }).toPromise()
    }
    
    this.load()
  }


  scanBarcode() {
    this.scanningBarcode = "a"
  }

  async onScanBarcode(code) {
    this.scanningBarcode = null
    if (code.length > 0 && this.masterMove) { // sto usando ns codice
      
      // var nopack = this.masterMove.move_line_ids.values.find(x => !x.package_id.id)
      // if (nopack) {
      //   await this.onPackage(nopack,keybuffer)
      // } else {
      if (this.masterMove.product_id.value.barcode && this.masterMove.product_id.value.barcode == code) {
        var l =  await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
          'move_id' : this.masterMove.id,
          'product_id': this.masterMove.product_id.id,
          'result_package_id': this.defaultPackageDest ? this.defaultPackageDest.id : null,
          'qty_done': 1
        }))
        this.showMoveDetail(this.masterMove)
      }  
    }
    this.scanning = false
  }

  scanPackage() {
    this.scanning = !this.scanning
  }

  async pickPackage(ml:StockMoveLine) {
    // this.pickOrigin = ml
    
    // return
    // this.loading = true
    // // use subselecor to make 1 query
    // this.activeMoveLine = ml
    // var q = await this.odooEM.search<StockQuant>(new StockQuant(), [["product_id", "=", ml.product_id.id]]).toPromise()
    // this.packages = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(), [["quant_ids", "in", q.map(x => x.id)]]).toPromise()
    // this.quants = await this.odooEM.search<StockQuant>(new StockQuant(), 
    //   [
    //     ["product_id", "=", ml.product_id.id],
    //     ["on_hand", "=", true],
    //   ]).toPromise()

    // this.modalService.open(this.templateRefOrig)
    // this.loading = false
  }



  async onQuantOrig(q:StockQuant) {
    await this.odooEM.update(this.activeMoveLine, {
      'package_id': q.package_id.id ? q.package_id.id : null,
      'location_id' : q.location_id.id
    }).toPromise()

    this.load()
  }

  async pickPackageDestination(ml:StockMoveLine) {
    this.pickDestination = ml
  }


  async onPackage(ml:StockMoveLine, barcode:string) {

    var p:StockQuantPackage
    
    var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", barcode]]).toPromise()
    if (ps.length > 0) {
      if (confirm('Pacco gia esistente. Sei sicuro di volerlo riusare ?'))
        p = ps[0]
      else 
        return 
    } else {
      p = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
        // 'location_id': this.scanningPackageDest.location_dest_id.id,
        'name': barcode
      }).toPromise()
    }

    console.log("MOVE LISNE ", this.defaultPackageDest)
    var x = await this.odooEM.update<StockMoveLine>(ml, {
          'package_id': p.id,
          'result_package_id': this.defaultPackageDest ? this.defaultPackageDest.id : null
    }).toPromise()

    ml.package_id.id = p.id
    ml.package_id.name = p.name
    ml.result_package_id.name = this.defaultPackageDest ? this.defaultPackageDest.name : null
    ml.result_package_id.id = this.defaultPackageDest ? this.defaultPackageDest.id : null
    ml.reserved_uom_qty = x.reserved_uom_qty
    await this.setPackagingQtyDone(ml,this.masterMove,1);
    window.navigator.vibrate(200)
  }


  async onLabelPackage(code) {
    this.labeling = false
    if (this.masterMove)
      return

    if (code.length == 6 && this.masterMove) { // sto usando ns codice
      
      var nopack = this.masterMove.move_line_ids.values.find(x => !x.result_package_id.id)
      if (nopack) {
        await this.onPackageDest(nopack,code)
      } else {
        
        var l =  await firstValueFrom(this.odooEM.create<StockMoveLine>(new StockMoveLine(), {
          'move_id' : this.masterMove.id,
          'product_id': this.masterMove.product_id.id
        }))
        
        this.masterMove.move_line_ids.ids.push(l.id)
        this.masterMove.move_line_ids.values.push(l)
        await this.onPackageDest(l,code)
      }
    }

    
    this.scanning = false
    this.changeDetectorRef.detectChanges();


  }

  labelPackage() {
    this.labeling = true
  }


  async onPackageDest(ml:StockMoveLine, barcode:string) {
    var p:StockQuantPackage
    
    var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", barcode]]).toPromise()
    if (ps.length > 0) {
      if (confirm('Pacco gia esistente. Sei sicuro di volerlo riusare ?'))
        p = ps[0]
      else 
        return 
    } else {
      p = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
        // 'location_id': this.scanningPackageDest.location_dest_id.id,
        'name': barcode
      }).toPromise()
    }

    await this.odooEM.update(ml, {
          'result_package_id': p.id
        }).toPromise()

    ml.result_package_id.id = p.id
    ml.result_package_id.name = p.name

    await this.setPackagingQtyDone(ml,this.masterMove,1);
    window.navigator.vibrate(200)
  }

  isFilled() {
    return this.masterMove.move_line_ids.values.find(ml => {
      return ml.qty_done > 0
    })
  }

  async changeProductPackaging(p:ProductPackaging) {
    this.masterMove.product_packaging_id.name = p.name
    this.masterMove.product_packaging_id.value = p
  }

  async closeWithBackorder() {

      var o = {
        pick_ids : [this.picking.id]
      }
      var bo = await firstValueFrom(this.odooEM.create<StockBackorderConfirmation>(new StockBackorderConfirmation(), o))

      var ol = {
        to_backorder: true,
        backorder_confirmation_id: bo.id,
        picking_id: this.picking.id
      }
      await firstValueFrom(this.odooEM.create(new StockBackorderConfirmationLine(),ol))

      await firstValueFrom(this.odooEM.call(new StockBackorderConfirmation(), 'process', [bo.id], {
        'button_validate_picking_ids': [this.picking.id]
      }))

      this.load()
    }

    async deleteMoveLines(ss:StockMoveLine[]) {
      this.loading = true
      await firstValueFrom(this.odooEM.delete(new StockMoveLine(), ss.map(s => s.id)))
      await firstValueFrom(this.odooEM.resolve(this.masterMove.move_line_ids))
      this.loading = false
    }

    async scanDefaultPackageDest() {
      if (this.defaultPackageDest) {
        this.defaultPackageDest = null
        return
      }
      this.scanningDefaultPackageDest = !this.scanningDefaultPackageDest
    }

    async onScanDefaultPackageDest(barcode) {
      this.scanningDefaultPackageDest = false
      this.defaultPackageDest = null
      if (!barcode) {
        return
      }
      
      var ps = await this.odooEM.search<StockQuantPackage>(new StockQuantPackage(),[["name", "=", barcode]]).toPromise()
      if (ps.length > 0) {
        this.defaultPackageDest = ps[0]
      } else {
        this.defaultPackageDest = await this.odooEM.create<StockQuantPackage>(new StockQuantPackage(), {
          // 'location_id': this.scanningPackageDest.location_dest_id.id,
          'name': barcode
        }).toPromise()
      }
    }
  

    newLine() {
      // this.odooEM.create(new SaleOrderLine)
    }
}
