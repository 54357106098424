import { Component, OnInit } from '@angular/core';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { MrpProduction } from '../models/mrp-production';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-production-editor',
  templateUrl: './production-editor.component.html',
  styleUrls: ['./production-editor.component.scss']
})
export class ProductionEditorComponent implements OnInit {
  id: any;
  loading: boolean;
  production: MrpProduction;
  

  constructor(
    private odooEm:OdooEntityManager,
    private route: ActivatedRoute
  ) { 

  }

  async ngOnInit(): Promise<void> {

    this.route.params.subscribe(async params => {
      this.id = params['id']
      this.loading = true

      let prs = await firstValueFrom(this.odooEm.search<MrpProduction>(
        new MrpProduction(),
        [['id', '=', this.id]]
      ))

      if (prs.length == 0) 
        return alert("Production not found")

      this.resolve(prs[0])
      this.production = prs[0]
      this.loading = false
    })
  
  }


  async resolve(p: MrpProduction) {
      await this.odooEm.check(
        firstValueFrom(this.odooEm.resolve(p.move_raw_ids))
      )
      
  }

}
