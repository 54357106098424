<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
      <span >
          Commesse
      </span>&nbsp;
  </a>
  
  <ng-container>
    <div class="ms-auto dropdown">
      <a class="btn text-white btn-primary dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Nuovo 
      </a>
    
      <ul class="dropdown-menu dropdown-menu-end">
        <form class="px-4 py-3" style="min-width: 440px;">
          <div class="card mb-3">
            <div class="card-header">
              Settore
            </div>
            <div class="card-body">
              <select 
              name="area" 
              class="form-control"
              [(ngModel)]="newDeal.area"
              (change)="filter.next(newDeal.area)"
              placeholder="Settore"
            >
              <option value="" disabled>Settore</option>
              <option *ngFor="let a of areas" [ngValue]="a.name">{{a.name}}</option>
            </select>
            </div>
            
          </div>          

          <app-contact-picker2
            [mode]="'embedded'" 
            class="embedded" 
            (onSelect)="onContact($event)"></app-contact-picker2>
          <button 
            [disabled]="!newDeal.partner_id.id"
            class="btn btn-primary text-white mt-3" (click)="onCreate()">Crea</button>
        </form>

      </ul>
    </div>

    
  </ng-container>

</app-navbar>



<div class="nav d-flex border p-2 pe-4 bg-light align-items-center" >
  <!-- <div class="btn btn-link"><i class="fa fa-calendar"></i> 3 mesi</div> / <div class="btn btn-link">Tutto</div> -->
  <!-- <a href="https://m.galimberti.eu/leads" target="_blank" class="btn btn-dark ms-2">Fascicoli &lt; 3000</a> -->
  <span class="ms-auto">Settore</span>
  <button (click)="filter.next('')" [ngClass]="(!filter.getValue() || filter.getValue() == '')  ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white"><i class="fa fa-asterisk"></i></button>
  <button (click)="filter.next('Aziendale')" [ngClass]="filter.getValue() == 'Aziendale' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Aziendale</button>
  <button (click)="filter.next('Case')" [ngClass]="filter.getValue()=='Case' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Case</button>
  <button (click)="filter.next('Facciate e Decking')" [ngClass]="filter.getValue() == 'Facciate e Decking' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Facciate e Decking</button>
  <button (click)="filter.next('Massello')" [ngClass]="filter.getValue()=='Massello' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Massello</button>
  <button (click)="filter.next('Pavimenti')" [ngClass]="filter.getValue()=='Pavimenti' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Pavimenti</button>
  <button (click)="filter.next('Tetti')" [ngClass]="filter.getValue()=='Tetti' ? 'btn-primary': 'btn-dark'" class="btn ms-2 text-white">Tetti</button>
  <div clas="form-inline">
    <input class="form-control ms-2" [(ngModel)]="searchDealsInput" #searchInput>
  </div>
  <button class="btn btn-link ms-3" data-toggle="tooltip" data-placement="bottom" title="Mostra solo recenti" label="Mostra solo recenti" (click)="toggleRecents()"><i class="fa fa-calendar" [ngClass]="{'text-primary': justRecents, 'text-muted': !justRecents}"></i></button>
  <!-- <select class="form-control ms-3 w-auto" [(ngModel)]="months">
    <option value="3">3</option>
  </select> -->
</div>

<div class="w-100 trello-like p-4" >
  <ul *ngFor="let stage of stages;">
    <li>
      <h3>
        {{stage.name}}
      </h3>
    </li>
    <li (click)="redirectDeal(card.id)" *ngFor="let card of filterCards(stage.id)" class="d-flex flex-column">
      <p class="lead mb-1">
        {{card.tracking_code}} - {{card.partner_id.name | uppercase}}</p>
      <p class="text-muted mb-0 ">{{card.name}}</p>
      <p class="text-muted mb-0 ">{{card.city}}</p>
      <div class=" align-items-end d-flex mt-2 ms-auto">
        <p *ngIf="!filter.getValue()" class="badge lead   mb-0 bg-info text-dark">{{card.area}}</p>
        <p class="badge lead ms-2 mb-0 bg-primary text-white">{{card.user_id.name}}</p>
      </div>
    </li>
  </ul>
</div>
