import { Component, ElementRef, OnInit } from '@angular/core';
import { PurchaseOrder } from '../models/purchase-order.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Product } from '../models/product.model';
import { PurchaseOrderLine } from '../models/purchase-order-line.model';
import { row2printable } from '../shared/print-util';
import { GapiService } from '../shared/services/g-api.service';
import { PRINTS_CFG } from '../models/deal';
import { SaleOrder } from '../models/sale-order.model';
import { Contact } from '../models/contact.model';
import { Lead } from '../models/crm.lead.model';


@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

  loading: boolean;
  purchase?: PurchaseOrder;
  sale: SaleOrder;
  contact: Contact | boolean = false
  
  
  lead: Lead;

  constructor(
    private odooEM: OdooEntityManager,
    private route: ActivatedRoute,
    private gapiService:GapiService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {

      let id = params['purchase_id'];
      let p = await firstValueFrom(this.odooEM.search<PurchaseOrder>(new PurchaseOrder(), [['id', '=', Number(id)]]));
      if (!p.length) return alert("Errore - l'acquisto non esiste" + id)
      
      this.purchase = p[0];
      if (p[0].origin) {
        let s = await firstValueFrom(this.odooEM.search<SaleOrder>(new SaleOrder(), [['name', '=', p[0].origin]]));
        if (s.length)
          this.sale = s[0];
          
        if (s.length && s[0].opportunity_id) {
          await firstValueFrom(this.odooEM.resolveSingle(new Lead(), s[0].opportunity_id))
        }
      }

      await firstValueFrom(this.odooEM.resolve(this.purchase.order_line))
      await this.odooEM.resolveArrayOfSingle(new Product(),this.purchase.order_line.values,"product_id").toPromise()
    });
  }

  delete(p) {
    
  }

  async onContact(p) {

    await this.updateOrder(this.purchase,p.id,"partner_id")
    // ugly way to avoid reload
    this.purchase.partner_id.name = p.name
    this.purchase.partner_id.id = p.id
    this.purchase.partner_id.value = p.value
    this.contact = false
  }

  innerText(s:string) {
    if (s)
      return s.replace("<p>", "").replace("</p>", "")
  }

  async print(p) {
    this.loading = true
    let header:string[] = [
      this.purchase.name,
      this.purchase.create_date,
      this.purchase.date_planned,
      this.purchase.partner_id.name,
      this.purchase.user_id.name,
      this.sale.name,
      this.sale.partner_id.name,
      this.sale?.opportunity_id.value?.tracking_code,
      this.sale?.opportunity_id.value?.name,
      this.sale?.opportunity_id.value?.street,
      this.innerText(this.purchase.notes)
    ]

    let datas = []
    this.purchase.order_line.values.forEach(l => {
      datas.push([l.product_id.value.display_name, l.product_qty,l.product_uom?.name,l.price_unit,l.price_total])
    })

    var sheetid = await this.gapiService.printOrder(
      PRINTS_CFG.template_purchase_embedded_sheet_id,
      PRINTS_CFG.spool_folder_id,
      header,
      datas)
    this.loading = false
    window.open('https://docs.google.com/spreadsheets/d/' + sheetid, '_blank');

  }

  getVariantAttribute(line:PurchaseOrderLine, name:string) {
    // console.log("line ", line)
    if (!line.product_id)
      return

    if (!line.product_id.value || !line.product_id.value.product_template_attribute_value_ids)
    return ""
    
    if (line.product_id.value.product_template_attribute_value_ids.values) {
      var f = line.product_id.value.product_template_attribute_value_ids.values.filter(value => {
        return value.attribute_id.name == name
      })
    }

    return (f && f.length > 0) ? f[0] : null
  }

  isLineEditable(p:PurchaseOrder) {
    return false
  }

  comfirm(p:PurchaseOrder) {

  }

  cancel(p:PurchaseOrder) {

  }

  draft(p:PurchaseOrder) {

  }


  async updateLine(line:PurchaseOrderLine, field, value) {
    var n = {}
    n[field] = value
    var lll = await firstValueFrom(this.odooEM.update<PurchaseOrderLine>(line, n))

  // // refresh the needed computed on line too 
  var fresh =(await this.odooEM.search<PurchaseOrderLine>(new PurchaseOrderLine(),[['id','=',line.id]]).toPromise())[0]
  line.product_qty = fresh.product_qty
  line.price_unit = fresh.price_unit
  line.price_total = fresh.price_total
  }

  async updateOrder(order:PurchaseOrder ,val:string, field, isNumber=false) {
    
    this.loading = true
    let j = {}
    if (isNumber)
      j[field] = Number(val)
    else
      j[field] = val
    await firstValueFrom(this.odooEM.update<PurchaseOrder>(order, j)) 
    // order[field] = val
    this.loading = false
  }
}
