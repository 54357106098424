<app-navbar 
  data-print-row-header
  [loading]="loading" 
  backroute="../.."
>
  <a class="navbar-brand">
      <span >
        {{purchase?.name}}
      </span>
        
      <!-- <span>
        -  {{opportunity_code}}
      </span> -->

      <!-- <span>
        - {{relatedSales[0]?.partner_id.name}}
      </span> -->
  </a>
  
  <div class="dropdown ms-auto" style="z-index:2000;">
    <button class="btn btn-link text-light" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="fa fa-bars"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <!-- <li 
        *ngIf="purchase?.state == 'draft'"
        class="dropdown-item"  >
        <button class="btn btn-primary text-white" type="button" (click)="confirm(purchase)">
          Conferma
        </button>
      </li> -->
    
      <!-- <li class="dropdown-item" *ngIf="purchase?.state == 'sale'">
        <button class="btn btn-primary text-white" type="button" (click)="cancel(purchase)">
          Annulla
        </button>
      </li>

      <li class="dropdown-item" *ngIf="purchase?.state == 'cancel'">
        <button class="btn btn-primary text-white" type="button" (click)="draft(purchase)">
          Imposta a bozza
        </button>
      </li> -->

      <!-- <li><hr class="dropdown-divider"></li> -->
      <li><a class="dropdown-item" target="_blank" (click)="print(purchase)">Stampa</a></li>
      <li><a class="dropdown-item" target="_blank" href="//o3.galimberti.eu/web#id={{purchase?.id}}&cids=1&menu_id=204&action=319&model=purchase.order&view_type=form">Apri in Odoo</a></li>
      <!-- <li><a class="dropdown-item" target="_blank" (click)="delete(purchase)">Elimina</a></li> -->
    </ul>
  </div>



</app-navbar>





<!----- TABLE ------>
<div class="table-container h-100 overflow-x-auto overflow-y-auto" *ngIf="purchase && !contact">
  <table  
    id="order" 
    class="table table-bordered tableFixHead table-hover mb-0 h-100" 
  >

    <thead class="border-info bg-info">
      <tr class="mt-3 border-top border-0 border-bottom-0">
        
        <td colspan="6" class="p-2 mt-3 border-0 border-bottom-0"  style="border-top: 1px solid #333 !important;">
          <div class="d-flex align-items-center">

            <div class="dropdown">
              <button class="btn btn-link px-1" type="button" id="dropdownMenu"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{purchase?.partner_id?.name}}
                  <i class="fal fa-bullseye"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-right p-0"
                  style="min-width: 300px;z-index:9999999">
                      <app-contact-picker2 [mode]="'embedded'" class="embedded"
                        (onSelect)="onContact($event)"></app-contact-picker2>
              </div>
            </div>

          
            <span class="ms-auto">Richiesto il:</span>
            <input 
              type="date" 
              class="text-muted ms-1 form-control w-auto" 
              [ngModel]="purchase.date_planned ? purchase.date_planned.slice(0,10): null" 
              (ngModelChange)="updateOrder(purchase, $event, 'date_planned')"
            >
          </div>
          
        </td>
      </tr>
      
    </thead>

    <thead class="sticky-top">
      <tr valign="middle" >
        <th class="bg-light"></th>
        <th class="bg-light ps-3">ID</th>
        <th class="bg-light">
          <div class="d-flex align-items-center">Nome
          </div>
        </th>

        <!-- <th class="bg-light">
          Percorso
        </th>
          -->
        <th class="bg-light text-end " colspan="1">UdM</th>
        <!-- <th class="bg-light text-end" colspan="1">Conf</th> -->
        <!-- <th class="bg-light" *ngIf="order.state == 'sale'">Consegnati</th> -->
        <!-- <th class="bg-light text-end">Larg</th>
        <th class="bg-light text-end">Spes</th>
        <th class="bg-light text-end">Lung</th> -->
        <th class="bg-light text-end">Cad €</th>
        <th class="bg-light text-end">Totale €</th>
        <!-- <th class="bg-light">Percorso</th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let line of purchase?.order_line.values" class="mw-100" id="tr{{line.id}}" > 
        <td class="align-middle">
          <i class="fa fa-bars fa-large me-2" ></i>
          {{line.checked}}
          <!-- <input type="checkbox" class="form-check-input" (mouseup)="selectLine($event, line, s)" [(ngModel)]="line._checked"  (ngModelChange)="updateOrderCheckbox(s)" name="x">
          <i class="fa fa-trash ms-3"  *ngIf="line._checked" (click)="deleteLine(s)"></i> -->
        </td>
        <ng-container 
          [ngTemplateOutlet]="lineProductTpl"
          [ngTemplateOutletContext]="{line: line, order: s}">
        </ng-container>
      </tr>
    </tbody>

    <tbody *ngIf="!purchase.order_line || purchase.order_line.values" class="h-100">
      <td colspan="14" class="p-0">
        <textarea 
          style="resize:none"
          class="form-control w-100 h-100 m-0" 
          [ngModelOptions]="{'updateOn':'blur'}"
          [ngModel]="purchase.notes ? innerText(purchase.notes) : ''" placeholder="Note" (ngModelChange)="updateOrder(purchase,$event, 'notes')"></textarea>
      </td>
    </tbody>

    <tfoot>
      <tr>
        <td colspan="6" *ngIf="sale?.opportunity_id.value">
          <i class="fa-duotone fa-location-dot"></i> {{sale.opportunity_id.value.street}}
        </td>
      </tr>
    </tfoot>
  </table>
</div>  

<ng-template #lineProductTpl let-line="line" let-order="order">
  <!-- id -->
  <td 
    style="vertical-align: middle; width: 30px;" 
    class="ps-3 text-start align-middle"
  >
    {{line.product_id.value?.id}}
  </td>
  
  <!-- name -->
  <td 
    class="text-start align-middle " style="text-wrap:balance;"
  >
    <a 
      href="https://o3.galimberti.eu/web?debug=1#id={{line.product_id.id}}&cids=1&menu_id=223&action=393&model=product.product&view_type=form" *ngIf="line.product_id" class="text-dark "  target="_blank" >
      {{line.product_id.value?.display_name}}
    </a>
  </td>

  <!-- cant' use plain select cause it flikers .. :-( )-->
  <!-- <td class="editable text-start align-middle" style="width: 50px;">
    <div class="dropdown">
      <button 
        [disabled]="order.state != 'draft'"
        class="btn w-100 p-0 btn-link dropdown-toggle" 
        type="button" 
        data-bs-toggle="dropdown" 
        aria-expanded="false"
        (mousedown)="updateSelectableRoutes(line)"
      >
        {{(line.route_id && line.route_id.name && line.route_id.name.split("-").length > 0) ? line.route_id.name.split("-")[0] : line.route_id.name}}
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let r of selectableRoutes"><button class="dropdown-item" (click)="updateLineRoute(order, line, r)">{{r.name}}</button></li>
      </ul>
    </div>
  </td> -->
  
  <!-- qty -->
  <td class="text-nowrap editable align-middle">
    <div class="d-flex align-items-center justify-content-end">

      <input-number
        [disabled]="purchase.state != 'draft'"
        class="me-2"
        (ngModelChange)="updateLine(line, 'product_qty', $event)"
        [ngModel]="line.product_qty | number : '1.0-5':'it-IT'"
        >
        <!-- [ngModelOptions]="{'updateOn':'blur'}" -->
      </input-number>
      <span>{{line.product_id.value?.uom_id?.name}}</span>
    </div>
  </td>


  <!-- qty packaging -->
  <!-- <td class="editable align-middle">
    <div class="d-flex align-items-center justify-content-end">
      
      <input-number
        [disabled]="purchase.state != 'draft'"
        class="me-2"
        [hidden]="!line.product_packaging_id?.id"
        [ngModel]="line.product_packaging_qty | number : '1.0-5':'en-EN'"
        (ngModelChange)="updateLine(order,line, 'product_packaging_qty', $event)"
      >
      </input-number>

      <div class="dropdown d-inline-block">
        <button class="btn w-100 p-0 btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" (mousedown)="updateSelectablePackaging(line)">
           {{line.product_packaging_id.name}}
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let r of selectablePackagings">
            <button class="dropdown-item" (click)="updateLinePackage(order, line, r)">{{r.name}}</button>
          </li>
        </ul>
      </div>
    </div>
  </td> -->


  <!-- quantità descrittiva -->
  <!-- <td class="align-middle fst-normal text-nowrap">
    
    <a
      (click)="onClickPurchase(line)"
      *ngIf="line.purchase_line_ids.ids.length"
      class="btn btn-sm bg-light text-dark border " 
    >ACQUISTO</a>
    
    <div *ngIf="!line.purchase_line_ids.ids.length" class="d-flex align-items-center justify-content-start">
      <ng-container *ngIf="line.product_uom_qty > 0 && line.product_id.value?.qty_available <= 0 ">
        <i class="fa fa-circle text-danger me-2 d-inline"></i>  
      </ng-container>

      <ng-container  *ngIf="line.product_uom_qty > 0 && line.product_id.value?.qty_available > 0 && line.product_id.value.qty_available < line.product_uom_qty">
        <i class="fa fa-circle text-warning me-2 "></i>  
      </ng-container>

      <pre class="mb-0">{{getDescriptive(line)}}</pre>    
    </div>
  </td> -->


  <!-- consegnati -->

  <!-- DIMENSIONI-->
  <!-- <td class="text-right align-middle">
    <input-number
      data-print-col
      [disabled]="true"
      [ngModel]="getVariantAttribute(line, 'Larghezza')?.name"></input-number>
  </td>

  <td class="text-right align-middle">
    <input-number
      data-print-col
      [disabled]="true"
      [ngModel]="getVariantAttribute(line, 'Altezza')?.name"></input-number>
  </td>
 
  <td class="text-right align-middle"  [ngClass]="{'editable': isLineEditable(line)}">
    <input-number
      [disabled]="!isLineEditable(line)"
      (ngModelChange)="updateVariant3(purchase, line, $event)"
      [ngModel]="getVariantAttribute(line, 'Lunghezza')?.name"
      >
    </input-number>
  </td>
  
  <td class="text-end align-middle editable" >
    <input-number
      [disabled]="purchase.state != 'draft'"
      [ngModel]="line.price_unit"
      (ngModelChange)="updateLine2(purchase, line, 'price_unit', $event)"
    ></input-number>
  </td> -->
  
  <td class="editable text-end align-middle">
    <input-number
      [disabled]="purchase.state != 'draft'"
      [ngModel]="line.price_unit"
      (ngModelChange)="updateLine(line, 'price_unit', $event)"
    ></input-number>
  </td>

  <!-- <td class="text-end align-middle">
    <input-number
    [disabled]="purchase.state != 'draft'"
    [ngModel]="line.discount"
    (ngModelChange)="updateLine(order, line, 'discount', $event)"
  ></input-number> -->
    <!-- {{line.price_subtotal}} -->
  <!-- </td> -->

<td class="text-end align-middle">
    {{line.price_total}}
  </td>
  <!-- <td>
    <ng-container *ngFor="let p of order._pickings">
      <button  *ngIf="order.state == 'sale' && p.state == 'assigned'"
        [ngClass]="{
          'bg-info': p.state == 'waiting',
          'bg-success': p.state == 'done',
          'bg-warning': p.state == 'assigned'
        }"
        class="btn btn-sm bg-info text-dark me-2"
        target="_blank"
        routerLink="./picking/{{p.id}}"
        > 
        {{p.picking_type_id.name.replace('LOMAGNA: ','')}} 
      </button>
    </ng-container> 
  </td> -->

</ng-template>

<ng-template #lineSectionTpl let-line="line" let-order="order">
  <!-- <td 
    class="ps-3" 
  >
    <input type="checkbox" [(ngModel)]="line._checked" name="x">
    <i class="fa fa-trash ms-3"  *ngIf="line._checked" (click)="deleteLine(order)"></i>
  </td> -->

  <td colspan="14"
    class=""
  >
    <input 
      class="text-muted fw-bold form-control"  
      [ngModelOptions]="{'updateOn':'blur'}"
      [ngModel]="line.name" 
      (ngModelChange)="updateLine(line, 'name',$event)"
    >
  </td>
</ng-template>





