<app-navbar [loading]="loading" backroute="..">

    <a class="navbar-brand">
      Avanzi
    </a>

    <!-- <button 
      class="btn btn-lg btn-primary text-white ms-auto" 
      type="button"
      (click)="confirm()"
    >Bollato</button> -->

    <!-- <button 
      class="btn btn-lg btn-primary text-white me-4" 
      type="button"
      [disabled]="order?.state == 'sale'"
      (click)="scanWithCamera()"
    >
      <i class="fa-duotone fa-camera fa-lg"></i>
    </button>
    <button 
        class="btn btn-lg btn-primary text-white" 
        type="button" 
        data-bs-toggle="offcanvas" 
        [disabled]="order?.state == 'sale'"
        data-bs-target="#offcanvasExample" 
        aria-controls="offcanvasExample"
    >
      <i class="fa-duotone fa-magnifying-glass fa-lg"></i>
    </button> -->

  </app-navbar>

  <table class="table align-middle table-bordered">
    <thead>
      <tr>
        <th>Prodotto</th>
      </tr>
    </thead>
    <tbody>
        <tr *ngFor="let p of products">
          <td class="py-4" (click)="newOddment(p)">{{p.name}}</td>
        </tr>
    </tbody>
  </table>


  <div 
    *ngIf="!activePicking" 
    class="w-100 h-100 bg-white d-flex justify-content-center flex-column align-items-center"
    style="position: fixed; top: 0px; left: 0;z-index:10001">

        <h2>Resto barre da vendita</h2>
        <label >Seleziona la vendita che ha generato il resto</label>
        <div class="mt-3 d-flex align-items-center">
            <span class="pe-1">V</span>
            
            <input class="form-control" type="string" [(ngModel)]="saleNumber">

            <button class="btn btn-primary text-white ms-2" (click)="loadPicking()">Ok</button>
        </div>
  </div>

    
<div 
  *ngIf="products" 
  class="w-100 h-100 bg-white d-flex flex-column"
  style="position: fixed; top: 0px; left: 0;z-index:10001">

  <app-navbar [loading]="loading" class="w-100" backroute="..">
    <a class="navbar-brand" >
      Resto barre V{{saleNumber}}
    </a>
  </app-navbar>

  <div class="table-responsive ">
    <table class="table">
        <tbody>
            <tr *ngFor="let p of products">
                <td class="py-3" (click)="onProduct(p)">
                    {{p.display_name}}
                </td>
            </tr>
        </tbody>
      </table>
    </div>
</div>

<!-- piece insert -->
<div 
  *ngIf="activeProduct" 
  class="w-100 px-5 h-100 bg-white d-flex justify-content-center flex-column align-items-center"
  style="position: fixed; top: 0px; left: 0;z-index:10002">

    <div class="d-flex flex-column flex-fill align-items-center justify-content-center">

        <h3>Da {{activeProduct.display_name}}</h3>
        <br><br>

        <div *ngFor="let p of pieces">
            <label>Lunghezza</label>
            <input type="number"   class="form-control" [(ngModel)]="p.length">

            <label class="mt-2">Collo</label>
            <input class="form-control" [(ngModel)]="p.package">
        </div>

    </div>

    <div class="align-items-center d-flex mt-2 justify-content-center">
        <button class="btn btn-link  text-success ms-2 me-2"
            (click)="back()">
            <i class="fa-duotone fa-circle-chevron-left fa-4x text-dark"></i>
        </button>

        <button class="btn btn-link  text-success ms-2 me-2"
            (click)="addPiece()">
            <i class="fa-duotone fa-circle-plus fa-4x text-dark"></i>
        </button>


        <button class="btn btn-link text-success ms-2 me-2"
            [disabled]="order?.state == 'sale'"
            (click)="save()">
            <i class="fa-duotone fa-circle-check fa-5x"></i>
        </button>

    </div>


    
</div>