<app-navbar>
    <a class="navbar-brand">
        Home
    </a>
    <app-user-info class="ms-auto"></app-user-info>
  </app-navbar>

<div class="p-0">

    <div class="list-group">
        <a routerLink="/contact" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 fas fa-fw fa-building  text-muted"></i>     
            Contatti
        </a>
    </div>
    
    <br>
    
    <div class="list-group">

        <a routerLink="/leads" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="me-2 text-muted fa-fw fas fa-filter"></i>      
            Commesse
        </a>
      
        <a routerLink="/immediate-sale" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-ballot fa-lg text-muted me-2"></i>
            Vendite su lista
        </a>
        <a routerLink="/flash-sale" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-barcode-read fa-lg text-muted me-2"></i>
            Vendite immediate
        </a>
        <a routerLink="/catalog" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-duotone fa-lg fa-tag me-2"></i>
            Disponibilità e prezzi
        </a>

    </div>
    <br>
    <div class="list-group">

        <a routerLink="/delivery-note2" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-ballot-check fa-lg  text-muted me-2"></i>
            Da bollare
        </a>

        <a routerLink="/pickings" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-box-check fa-lg  text-muted me-2"></i>
            Movimenti di magazzino
        </a>

        <a routerLink="/productions" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-hammer-brush fa-lg  text-muted me-2"></i>
            Produzioni
        </a>

        <a routerLink="/search" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-barcode-scan fa-lg  text-muted me-2"></i>
            Scansiona
        </a>

        <!-- <a routerLink="/ddt" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-truck fa-lg text-muted me-2"></i>
            Spedizioni
        </a> -->
    </div>  

    <br><br>

    <div class="list-group">
        <a href="//c2.galimberti.eu"  target="_blank" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-image fa-lg me-2"></i>Catpro
        </a>
        <a routerLink="/cantieri"  class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-shapes fa-lg me-2"></i>Cantieri
        </a>
        <a routerLink="/productions"  class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-shapes fa-lg me-2"></i>Produzioni
        </a>
        <!-- <a routerLink="/timeline" class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa fa-duotone fa-lg fa-calendar me-2"></i>
            Timeline
        </a> -->
    </div>
    <br><br>


    <div class="list-group">
        <a routerLink="/forklift/6"  class="list-group-item list-group-item-action rounded-0 py-3">
            <i class="fa-duotone fa-image fa-lg me-2"></i>Muletto 6
        </a>
    </div>
    <br><br>

</div>


<br>
<div class="text-center"><small class="text-muted ">Aggiornamento 10.09.24</small></div>
<br>
