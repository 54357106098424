import { OdooModel } from './odoo-model.model';
import { OdooSerializableInterface } from '../shared/interfaces/odoo-serializable-interface';
import { SaleOrder } from './sale-order.model';
import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { OdooRelationship } from './odoo-relationship.model';

import { StockQuant } from './stock-quant';
import { StockLocation } from './stock-location';


export class StockQuantPackage extends OdooModel implements OdooSerializableInterface<StockQuantPackage> {
  public readonly ODOO_MODEL = 'stock.quant.package';

  id: number;
  // unit: number;
  // packages: number;
  // barcodes: string[];
  // pacco_from: OdooRelationship = new OdooRelationship()
  // pacco_to: OdooRelationship = new OdooRelationship()
  // righe_from:OdooRelationship<NaPacchiProduct>
  // product_id:OdooRelationship = new OdooRelationship()
  // product_uom_qty
  // product_uom_qty_po
  // move_line_ids: OdooRelationship<StockMoveLine> = new OdooRelationship<StockMoveLine>()
  // result_package_id:OdooRelationship<StockQuantPackage>
  quant_ids:OdooMultiRelationship<StockQuant> = new OdooMultiRelationship(StockQuant)
  location_id:OdooRelationship<StockLocation> = new OdooRelationship<StockLocation>()
  // state = null
  // qty:number = 0
  name:string = ""
  // write_date:string
 

  create(): StockQuantPackage {
    return new StockQuantPackage();
  }
}
