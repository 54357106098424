<app-navbar backroute="..">
    <a class="navbar-brand">
        <span>
            &nbsp;<b>Home</b>
        </span>
    </a>
</app-navbar>

<div class="navbar px-3 bg-info">

    <form autocomplete="off" class="d-flex flex-fill overflow-hidden">
        <div class="input-group">
            <input type="text" class="form-control border-info" [(ngModel)]="inputSearch" name="cx">
            <div class="input-group-append" >
                <button class="btn btn-primary" (click)="search()">
                    <span *ngIf="!loading">Cerca</span>
                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Caricamento...</span>
                    </span>
                </button>
            </div>
        </div>

    </form>
</div>



<!-- <div *ngIf="isNewEmbedded">
    <button class="w-25">Annulla</button><button class="w-75">Crea</button>
  </div> -->

<div class="">
    <div class="card">
        <div class="card-body">
            <div [innerHTML]="answer"></div>
        </div>
    </div>
</div>


<!--  
    <ng-container *ngIf="contacts?.length == 0">
      <h5 class="text-center mt-4">Nessun risultato</h5>
    </ng-container> -->