import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../models/product.model';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { firstValueFrom } from 'rxjs';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-product-quantity-editor',
  templateUrl: './product-quantity-editor.component.html',
  styleUrls: ['./product-quantity-editor.component.scss']
})
export class ProductQuantityEditorComponent implements OnInit, AfterViewInit {

  @Input() product: Product
  @Input() quantity?: number = 0
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<Number>();
  descriptiveArray: any[];

  constructor(
    private odooEm:OdooEntityManager
  ) { }
  
  ngOnInit(): void {
    
  } 
  
  async ngAfterViewInit(): Promise<void> {
    this.descriptiveArray = await this.getDescriptiveArrayOr(this.product, this.quantity)  
  }


  async getDescriptiveArrayOr(product:Product,q:number):Promise<any[]> {
    
    
    if (!product.packaging_ids.values || product.packaging_ids.values.length != product.packaging_ids.ids.length)    // dont want to show 
    await firstValueFrom(this.odooEm.resolve(product.packaging_ids))
    
    console.log("getDescriptiveArrayOr", product, q) 
    if (!product.packaging_ids.values) {
      return []
    }
      
    var ps = product.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    var a = []

    ps = ps.filter(x => x.sales)
    
    ps.forEach((p, i) => {
        let x = Number.parseFloat(
          new Decimal(q).div(p.qty).toPrecision(5).toString()
          ).toString()
        a.push([x ,p,0])
    })
    return a
  }

  async updateDescriptiveOr(d, value) {
    d[0] = value
    let uom_qty = value * d[1].qty 

    // console.log("d",d, uom_qty)
    // await this.updateMaster(master, "qty_done", uom_qty)
    this.quantity = uom_qty
  }

  async updateQuantity(q) {
    this.quantity = q
    this.descriptiveArray = await this.getDescriptiveArrayOr(this.product, q)
  }

  getDescriptive(product:Product, q) {

    if (q == 0) return 0
    
    if (product.packaging_ids.values.length != product.packaging_ids.ids.length)    // dont want to show 
      this.odooEm.resolve(product.packaging_ids)

    if (!product.packaging_ids.values.length)    // dont want to show 
      return q +  " " + product.uom_id.name 
    
    
    var ps = product.packaging_ids.values.slice().sort((a,b) => b.qty - a.qty)
    // var q = line.move_id.value.product_uom_qty
    var d = ""

    ps = ps.filter(x => (!x.name.includes("netti") && x.sales == true))
    
    var totale = new Decimal(q)

    ps.forEach((p, i) => {

      if (!p.sales || p.name.includes("netti")) 
        return

      if (totale.toNumber() <= 0)
        return 

      let quo;
      //if last
      if (i == ps.length - 1)
        quo = totale.div(p.qty)
      else
        quo = totale.divToInt(p.qty)
      totale = totale.minus(quo.mul(p.qty))
      
      console.log("QUO", quo.toNumber())
      if (quo.toNumber() > 0)
        d = d + "" + Number.parseFloat(quo.toFixed(5)).toLocaleString("it-IT") + " " + p.name + "\n"
    })
    
    return d
  }




  back() {
    this.onCancel.emit()
  }

  assignMoveLine(){

  }

  save() {
    this.onSave.emit(this.quantity)
  }


  deleteLin() {

  }

}
