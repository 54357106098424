<div class="modal d-block ">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header" >
          <h5 class="modal-title">Carica pezzi</h5> <button type="button" class="close" data-dismiss="modal" (click)="onCancel()"> <span>×</span> </button>
        </div>
        <div class="modal-body">
          <p class="text-center">{{getTitle()}} 

          </p>
          <br>
          <div class="text-center biginput d-flex justify-content-center align-items-center">
            <input auto-length autofocus type="number" [(ngModel)]="loading" class="w-75"> 
            <select [(ngModel)]="mode">
                <!-- <option value="packages">{{getPackage()}}</option> -->
                <!-- <option value="unit">{{getUOM()}}</option> -->
                <option value="unit">Pz</option>
            </select>
            <sub class="text-nowrap" *ngIf="mode == 'packages'">&nbsp;</sub>
          </div>
          <br>
          <p class="lead text-success text-center" *ngIf="mode == 'packages'  && getMissing() == 0">
            Nessun {{getPackage()}} mancante
          </p>
          <p class="lead text-danger text-center" *ngIf="mode == 'packages'  && getMissing() > 0">
            {{getMissing()}} {{getPackage()}} mancanti
          </p>
          <p class="lead text-warning text-center" *ngIf="mode == 'packages' && getMissing() < 0">
            {{getMissing() * - 1}} {{getPackage()}} in piu'
          </p>
        </div>
        <div class="modal-footer"> 
          <button (click)="onOk()" type="button" class="btn btn-primary">Ok</button> 
          <button (click)="onCancel()" type="button" class="btn btn-warning" data-dismiss="modal">Annulla</button> </div>
      </div>
    </div>
  </div>

  <app-barcode-reader-dynamsoft *ngIf="isScanning" [title]="getTitle()" [barcodesNumber]="1" (onBarCodeSuccess)="onBarcodeScan($event)"></app-barcode-reader-dynamsoft>