import { Injectable, TemplateRef } from '@angular/core';
import { Flow } from '../../models/flow-flow.model';
import { OdooEntityManager } from '../../shared/services/odoo-entity-manager.service';
import { FlowDashboard } from '../../models/flow-dashboard.model';
import { Subject } from 'rxjs';
import { Action } from '../dashboard.component';

@Injectable({ providedIn: 'root' })
export class FlowDashboardService {
    flows: FlowDashboard[] = [];

    private eventSubject = new Subject<Action>();
    eventSelection$ = this.eventSubject.asObservable();

    constructor(
        private odooEM: OdooEntityManager
    ) {
    }

    async addSelection(action:Action){
        console.log("addSelection", action)
        this.eventSubject.next(action);
    }

    async save(flow: FlowDashboard, actions: any , styles : any) {


        try {
            await this.odooEM.update(flow, {
                "actions": JSON.stringify( actions ),
                "stylesCards" : JSON.stringify( styles )
            }).toPromise()

            // flow.actions = JSON.parse(flow.actions)\


        } catch (error) {
            
            console.log("eror ", error,actions )
        }
   
    }

    async list(): Promise<FlowDashboard[]> {
        return await this.odooEM.search<FlowDashboard>(new FlowDashboard(), []).toPromise()
    }

    async get(id) : Promise<FlowDashboard> {
        var xx = await this.odooEM.search<FlowDashboard>(new FlowDashboard(),[["id","=",Number(id)]]).toPromise()
        return xx[0]
    }

    async remove(id) {
        await this.odooEM.delete(new FlowDashboard(), [id]).toPromise()

    }


}
