<router-outlet>
</router-outlet>


<app-navbar backroute=".." *ngIf="contact"  >

  <a href="" class="navbar-brand">{{contact?.name}}</a>

  <ng-container>
    
    <div class="dropdown ms-auto" *ngIf="contact?.id">
      <i id="dropdownMenuButtonC" data-bs-toggle="dropdown"
        class="fa-duotone fa-lg fa-comment fa-lg text-white"></i>

      <div class="dropdown-menu dropdown-menu-end" 
        aria-labelledby="dropdownMenuButtonC"> 
        <!-- Dropdown items here -->
        <app-message-widget  [model]="'res.partner'" [res_id]="contact.id"></app-message-widget>
      </div> 
    </div>

    <span *ngIf="!contact.active" class="badge bg-primary">In archivio</span>
    <div class="dropdown ms-2" >
      <button class="btn btn-link text-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-bars"></i>
      </button>

      <ul class="dropdown-menu dropdown-menu-end">
        <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Download vcard</a></li>
        <li *ngIf="contact.active"><a class="dropdown-item" (click)="onDelete()">Archivia</a></li>
        <li *ngIf="!contact.active"><a class="dropdown-item" (click)="restore()">Ripristina</a></li>
      </ul>
    </div>
  </ng-container>


</app-navbar>

<!-- <div *ngIf="contact">
  <div *ngIf="contact && contact.state == 'cancel'" class="d-flex bg-muted p-2 align-items-center full-width" >
    Contatto archiviato
    <button class="ms-auto btn btn-success me-2" (click)="restore()">Ripristina</button>
  </div>

  <div *ngIf="contact.state == 'to_check'" class="d-flex bg-muted p-2 align-items-center full-width">
    Contatto non confermato
    <button class="ms-auto btn btn-danger me-2" (click)="obsolete()">Archivia</button>
    <button class="btn btn-success text-white" (click)="confirm()">Conferma</button>
  </div>
</div> -->

<div *ngIf="contact" class="container mt-3">
  <!-- form -->
  <div class="row py-1 mt-2">
    <div class="col-4 align-items-center d-flex">Tipo</div>
    <div class="col d-flex text-nowrap">

      <div class="form-check">
        <input class="form-check-input" type="radio" name="type" id="flexRadioDefault2" checked [value]="true"
          [(ngModel)]="contact.is_company" (ngModelChange)="update('is_company')">
        <label class=" form-check-label " for="flexRadioDefault2">
          Azienda
        </label>
      </div>

      <div class="form-check ms-2 mb-2 ">
        <input class="form-check-input" type="radio" name="type" id="flexRadioDefault1" [value]="false"
          [(ngModel)]="contact.is_company" (ngModelChange)="update('is_company')">
        <label class="form-check-label" for="flexRadioDefault1">
          Persona
        </label>
      </div>
    </div>
  </div>

  <div class="row py-1 mt-2">
    <div class="col-4 align-items-center  d-flex">Nome</div>
    <div class="col">
      <input name="name" [(ngModel)]="contact.name" (change)="update('name')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center  d-flex">IVA</div>
    <div class="col">
      <div class="input-group">
        <input class="form-control" name="vat" [(ngModel)]="contact.vat" (change)="update('vat')">
        <div class="input-group-append" *ngIf="contact.vat">
          <button class="btn btn-muted text-white" type="button" (click)="onVAT(contact.vat)">Controlla</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center  d-flex">Via</div>
    <div class="col">
      <input name="street" [(ngModel)]="contact.street" (change)="update('street')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center  d-flex">Città</div>
    <div class="col">
      <input name="city" [(ngModel)]="contact.city" (change)="update('city')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center  d-flex">CAP</div>
    <div class="col">
      <input name="zip" [(ngModel)]="contact.zip" (change)="update('zip')" class="form-control uppercase">
    </div>
  </div>

  <div class="row py-1">
    <div class="col-4 align-items-center  d-flex">Codice ARCA</div>
    <div class="col">
      <input name="arca" [(ngModel)]="contact.ga_arca" (change)="update('ga_arca')" class="form-control uppercase">
    </div>
  </div>
</div>

<div class="navbar bg-info mt-4 " *ngIf="contact">
  <div class="container ">

    <ul *ngIf="!isViewingChild" class="nav nav-pills" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#tabcontatti"
          type="button" role="tab" aria-controls="pills-home" aria-selected="true"><i
            class="fa fa-vcard"></i>&nbsp;Recapiti</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
          type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Commesse</button>
      </li>

      <!-- <li class="nav-item" role="presentation">
        <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#tabmessaggi"
          type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Messaggi</button>
      </li> -->

    </ul>
    <!-- <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">aaaaa</div>
      <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">bbbb</div>
      <div class="tab-pane fade" id="pills-messaggi" role="tabpanel" aria-labelledby="pills-profile-tab">ccc</div>
    </div> -->

    <!--     
    <ul *ngIf="!isViewingChild" class="nav nav-pills">
      <li class="nav-item"><a class="active nav-link" data-toggle="tab" data-target="#tabcontatti">
        <i class="fa fa-vcard"></i>&nbsp;Recapiti</a>
      </li>
      <li class="nav-item"><a  class="nav-link" data-toggle="tab" data-target="#tabassociati">Commesse</a></li>
      <li class="nav-item"><a  class="nav-link" data-toggle="tab" data-target="#tabmessaggi">Messaggi</a></li>
    </ul> -->
    <div class="d-flex">
      <!-- <input class="form-control w-auto me-2"> -->
      <button class="btn btn-dark" (click)="newPerson()"><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>


<div *ngIf="!isViewingChild" class="container tab-content  h-100">

  <!-- contatti 2 -->
  <div class="tab-pane fade show active my-3 " id="tabcontatti" role="tabpanel">
    <app-contact-picker *ngIf="contact" #picker (loading)="loading = $event" [contact]="contact"></app-contact-picker>
  </div>

  <!-- associati -->
  <div class="tab-pane fade" id="tabassociati" role="tabpanel">
    <div *ngIf="contact?.contatti_ids?.values?.length" class="list-group">
      <a (click)="redirectContact(c.id)" *ngFor="let c of contact.contatti_ids.values"
        class="list-group-item list-group-item-action rounded-0">
        {{c.name}} <span *ngIf="c.comment" class="text-muted"> - {{c.comment}}</span>
      </a>
    </div>
    <div *ngIf="!contact?.contatti_ids?.values?.length" class="list-group ">
      <a class="list-group-item rounded-0">
        Nessun associato
      </a>
    </div>
  </div>

  <!-- <div class="tab-pane fade" id="tabmessaggi" role="tabpanel">
    <app-message-widget [model]="'res.partner'" [res_id]="id"></app-message-widget>
  </div> -->


  <div class="tab-pane fade" id="tabvendite" role="tabpanel">
    <div class="list-group">
      <a (click)='createSaleOrder()' data-test-id="createSaleOrderButton"
        class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
        Nuovo ordine di vendita
      </a>
      <a (click)="redirectSale(s.id)" *ngFor="let s of sales"
        class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
        <div class="mb-1 lead me-auto">{{s.name}}
        </div>
        <div class="badge badge-muted bg-muted">{{localizeOrderState(s.state)}}</div>
      </a>
    </div>
    <div *ngIf="!sales || sales.length == 0" class="list-group ">
      <a class="list-group-item rounded-0">
        Nessun ordine di vendita
      </a>
    </div>
  </div>
  <div class="tab-pane fade" id="tabaquisti" role="tabpanel">
    <div class="list-group">
      <a (click)='createPurchaseOrder()' data-test-id="createPurchasesOrderButton"
        class="d-flex align-items-center list-group-item list-group-item-action rounded-0">
        Nuovo ordine d'acquisto
      </a>
      <a (click)="redirectPurchase(p.id)" *ngFor="let p of purchases"
        class="list-group-item list-group-item-action rounded-0">
        {{p.name}}
      </a>
    </div>
    <div *ngIf="!purchases || purchases.length == 0 " class="list-group ">
      <a class="list-group-item rounded-0">
        Nessun ordine di acquisto
      </a>
    </div>
  </div>
</div>