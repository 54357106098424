<div class="h-100  align-items-center justify-content-center">



    <ul class="list-group">
        <li *ngFor="let e of employees" (click)="write(e)" class="list-group-item"> {{e.name}}</li>
    </ul>


</div>
  