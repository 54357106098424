import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OdoorpcService } from '../shared/services/odoorpc.service';
import { ActionOdooSearch, ActionPlot } from './prompts.action.search';
import { FlowDashboardService } from './services/flow_dashboard.service';
import { FlowDashboard } from '../models/flow-dashboard.model';

export class ActionParam {

  public value: string = "ads"

  constructor(public name: string, value: string) {
    this.name = name
    this.value = value
  }
}
export interface Action {
  parsed: any;
  debug: any;
  type: string
  results: any[]
  params: ActionParam[]
  busy: boolean
  error: string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {


  actions: Action[] = []
  stylesCard: string[] = []

  queryResult = '';
  public static k = localStorage.getItem('aik')

  loading = false
  model = 'prodotti'
  domain = 'ordinati per nome'
  results: any[];
  lastQuery: string;
  prompts = [{
    model: "fascicoli",
    domain: "creati dopo il 2 maggio 2023"
  },
  {
    model: "prodotti",
    domain: "della categoria ferramenta"
  },
  {
    model: "prodotti",
    domain: "disponibili a magazzino della categoria velux"
  },
  {
    model: "prodotti",
    domain: "con un codice a barre e creati dopo il primo maggio 2023"
  },
  {
    model: "contatti",
    domain: "nella città di milano"
  },
  {
    model: "trasferimenti",
    domain: "con ubicazione di destinazione Giacenza/E"
  },
  {
    model: "righe degli oridini di vendita",
    domain: "con un product_id della categoria Velux, dei soli ordini del 2023 creati da paganini"
  }


  ]
  pr: any;
  parsed: any;
  flows: FlowDashboard[];
  selectedFlow: FlowDashboard;

  constructor(
    private http: HttpClient,
    private flowDService: FlowDashboardService,
    private odoorpc: OdoorpcService
  ) { }
  async ngOnInit(): Promise<void> {

    DashboardComponent.k = localStorage.getItem('aik')

    if (typeof (localStorage.getItem("lastFlowD")) !== "undefined" && localStorage.getItem("lastFlowD")) {

      console.log("qui")
      await this.restore()
    }
    this.refreshFlow()
  }

  async refreshFlow() {
    this.flows = await this.flowDService.list()
  }

  getPrevious(a: Action) {
    var i = this.actions.indexOf(a)
    if (i > 0)
      return this.actions[i - 1]
    return null
  }

  clickCard(action: Action) {
    this.flowDService.addSelection(action);
  }

  newActionSearch() {
    this.actions.push(new ActionOdooSearch(this.http, this.odoorpc))
    this.stylesCard.push("")
  }
  newActionPlot() {
    this.actions.push(new ActionPlot())
    this.stylesCard.push("")
  }

  insertPrompt(p: { model: string, domain: any }) {
    this.model = p.model;
    this.domain = p.domain;
  }


  _restore(as: any, stylesArray: string[]) {
    console.log("pre_restore", typeof (as))

    try {



      console.log("restore ", as)

      var index = 0
      JSON.parse(as).forEach(a => {

        console.log("RESTùore type", a.type)

        if (a.type == 'search') {
          var x = new ActionOdooSearch(this.http, this.odoorpc)
          // x.results = a.results
          x.params[0] = a.params[0]
          x.params[1] = a.params[1]
          x.params[2] = a.params[2]

          x.parsed = a.parsed
          this.actions.push(x)


          console.log("actions---->", this.actions)
          this.stylesCard.push(stylesArray[index])

        } else if (a.type == 'plot') {
          var p = new ActionPlot()

          if (a.params[0])
            p.params[0] = a.params[0]
          if (a.params[1])
            p.params[1] = a.params[1]
          if (a.params[2])
            p.params[2] = a.params[2]
          p.parsed = a.parsed
          this.actions.push(p)
          this.stylesCard.push(stylesArray[index])
        }

        index = index + 1

      });
    } catch (error) {

      console.log("error ", error)

    }

  }

  async restore() {
    var f = await this.flowDService.get(localStorage.getItem('lastFlowD'))
    console.log("lastFlowD--->", localStorage.getItem('lastFlowD'), f)
    if (f && f.actions)
      this._restore(JSON.parse(f.actions), JSON.parse(f.stylesCards))
    this.selectedFlow = f
  }

  handleFlowSelected(f: FlowDashboard) {

    this.selectedFlow = f

    this.actions = []

    if (f && f.actions)
      var as = JSON.parse(f.actions)

    if (f && f.stylesCards)
      var s = JSON.parse(f.stylesCards)

    if (as)
      this._restore(as, s)
  }


  delete(a: Action) {
    this.actions = this.actions.filter(x => x != a)
  }


  addPlot() {
    this.actions.push(new ActionPlot())
  }


  // uso l'array styles , che avrà la stessa size di actions , quindi ...
  styleChange(style, action) {
    console.log("style", style)
    var indexArray
    for (let index = 0; index < this.actions.length; index++) {
      const element = this.actions[index];
      if (element == action)
        indexArray = index
    }
    this.stylesCard[indexArray] = style

  }
  async persist() {


    var j = JSON.stringify(this.actions, function (key, value) {
      var ret = false
      if (key == 'domain')
        ret = false


      if (key == 'type' || key == 'parsed' || key == 'id' || key == 'results' || key == 'name' || key == 'display_name' || key == 'value' || key == 'params' || key == 'instan2ce' || value instanceof Array || value instanceof ActionParam || typeof value === 'boolean' || typeof value === 'number' || typeof value === 'string' || value instanceof ActionOdooSearch || value instanceof ActionPlot || value.id || value.value) {
        ret = value;
      }
      // if (typeof myVar === 'string' || myVar instanceof String)

      return ret
    })

    //    localStorage.setItem('actions',j)


    if (typeof (localStorage.getItem("lastFlowD")) !== "undefined" && localStorage.getItem("lastFlowD")) {
      var f: FlowDashboard = await this.flowDService.get(localStorage.getItem("lastFlowD"))
      await this.flowDService.save(f, j, this.stylesCard)
      await this.refreshFlow()
    }

  }

  persistkey(event) {
    localStorage.setItem('aik', event.target.value)
  }

}
