import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Flow } from 'src/app/models/flow-flow.model';
import { OdooEntityManager } from 'src/app/shared/services/odoo-entity-manager.service';
import { FlowService } from '../services/flow.service';
import { FlowDashboard } from 'src/app/models/flow-dashboard.model';
import { FlowDashboardService } from '../services/flow_dashboard.service';

@Component({
  selector: 'app-flow-dashboard-sidebar',
  templateUrl: './dashboard-sidebar.component.html',
})
export class FlowDashboardSidebarComponent implements OnInit,AfterViewInit {


  @Input() flows:FlowDashboard[]
  @Input() selectedFlow:Flow
  @Output() choosedFlow = new EventEmitter<FlowDashboard>();

  constructor(
    private odooEM: OdooEntityManager, private flowDashboardService : FlowDashboardService
  ) { }

  ngAfterViewInit(): void {
  }
  async ngOnInit(): Promise<void> {
  }

  async choose (f){
    this.selectedFlow = f
    this.choosedFlow.emit(f);

    localStorage.setItem('lastFlowD', JSON.stringify(f.id))
  }

  async add(){

    let name = prompt("Insert a name:");
    if(!name) return

    var f = {
      "name" : name,
      "date" : Math.floor(Date.now() / 1000) , 
      "actions" : ""
      // "actions_ids": [
      //   [0, 0, {
      //       parsed: 'parsed_data',
      //       debug: 'debug_data',
      //       action_type: 'type_data',
      //       busy: false,
      //       error: 'error_data',
      //       results: 'results_data',
      //       params_ids: [
      //           [0, 0, {
      //               key: 'key_data',
      //               value: 'value_data',
      //           }],
      //           // Add more arrays for more params
      //       ]
      //   }]
      // ]
    } 

    console.log("ffff", f)

    var s = await this.odooEM.create<FlowDashboard>(new FlowDashboard(), f).toPromise()
    if(s.id && this.flows)
    this.flows.push(s)
    this.choose(s)
  }

  async delete(f : FlowDashboard){
    this.flows = this.flows.filter(obj => obj.id !== f.id);
    await this.flowDashboardService.remove(f.id)
    this.choosedFlow.emit();

  }

}
