<div class="d-flex w-100 h-100">
  <!-- <app-navbar 
      [loading]="loading" backroute="../..">
      <a class="navbar-brand">
       OdooBot
      </a>
    
    </app-navbar> -->
  <app-flow-dashboard-sidebar *ngIf="flows" [flows]="flows" class="w-10" [selectedFlow]="selectedFlow"
    (choosedFlow)="handleFlowSelected($event)">
    <div class="mt-5 d-flex flex-column w-100">
      <span class="text-nowrap">GPT API KEY</span>
      <input class="form-control w-100 " type="password" (change)="persistkey($event)">
    </div>
  </app-flow-dashboard-sidebar>


  <div class="h-100 d-flex flex-column w-65 overflow-y-scroll" data-bs-theme="dark" *ngIf="selectedFlow">

    <div class="dropdown-center ms-auto me-auto mt-4 ">
      <button class="btn btn-lg btn-success text-white " type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-lg fa-plus"></i>
      </button>
      <ul class="dropdown-menu shadow" style="position: relative; left:-20px;">
        <li>
          <a class="dropdown-item" (click)="newActionSearch()">
            <h2 class="mb-0 text-white">Tabella</h2>
            <p>Cerca e trova quello che ti serve combinando criteri, raggupamenti</p>
          </a>
        </li>
        <li>
          <a class="dropdown-item " (click)="newActionPlot()">
            <h2 class="mb-0 text-white">Plotta</h2>
            <p>Genera grafici da allegare ai tuoi report</p>
          </a>
        </li>

      </ul>
    </div>

    <div class="d-flex flex-column h-100 p-5" #containerElement>

      <ng-container *ngFor="let a of actions;index as i">
        <app-action-card (click)="clickCard(a)"  [action]="a" [style]="stylesCard[i]" (styleChange)="styleChange($event,a)"
          (onDelete)="delete(a)" [parentRef]="containerElement"></app-action-card>
      </ng-container>

    </div>

    <button class="btn btn-dark btn-lg text-white ms-auto me-auto" (click)="persist()">
      <i class="fa fa-save"></i>
    </button>
  </div>

  <app-flow-inspector  data-bs-theme="dark" class="w-25"></app-flow-inspector>

</div>