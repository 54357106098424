import { OdooMultiRelationship } from './odoo-multi-relationship.model';
import { IConnectable, TrelloCardEntry2 } from './trello-card';
import { OdooModel } from './odoo-model.model';
import { StockMoveLine } from './stock-move-line';
import { StockMove } from './stock-move';
import { DriveFolder } from './drive.folder';
import { OdooRelationship } from './odoo-relationship.model';
import { Product } from './product.model';
import { SaleOrder } from './sale-order.model';
import { UomUom } from './uom-uom.model';
import { ProcurementGroup } from './procurement.group.model';

export class MrpProduction extends OdooModel implements IConnectable<MrpProduction> {
  public readonly ODOO_MODEL = 'mrp.production';
  
  // public readonly ODOO_MODEL = 'sale.order';
  // // public readonly ORDER_TYPE = SaleOrder;
     readonly ORDER_TYPE_NAME = 'Production';
  // public readonly ORDER_LINE_TYPE = SaleOrderLine;
   product_id: OdooRelationship<Product> = new OdooRelationship<Product>();
  // id: number;
  // confirmation_date: string;
  // create_ddt: boolean;
  // ddt_ids: OdooMultiRelationship<any>;
  // incoterm: OdooRelationship;
  // order_line: OdooMultiRelationship<SaleOrderLine>;
  // parcels: number;
  // payment_note: string;
  // reference: string;
  // signature: boolean;
  // volume: number;
  // weight: number;
  // drive_link_docs: string = ""
  // drive_link_project: string = "";
  move_finished_ids:OdooMultiRelationship<StockMove>= new OdooMultiRelationship<StockMove>(StockMove);
  state:string = ""
  move_raw_ids: OdooMultiRelationship<StockMove> = new OdooMultiRelationship<StockMove>(StockMove);
  name: string = ""
  drive_ids: OdooMultiRelationship<DriveFolder>;
  drive_folder_ids: OdooMultiRelationship<DriveFolder>;
  trello_ids: OdooMultiRelationship<TrelloCardEntry2> ;
  trello_card_ids: OdooMultiRelationship<TrelloCardEntry2> ;
  create_date: string = ""
  product_qty: number = 0 //quantità da produrre
  product_uom_id: OdooRelationship<UomUom> = new OdooRelationship<UomUom>();
  qty_producing: number = 0 //quantità prodotta
  procurement_group_id: OdooRelationship<ProcurementGroup> =  new OdooRelationship<ProcurementGroup>();
  sale_order_count: number = 0

  
  origin:string = ""
  // title:string = "" // custom
  components_availability_state: string;
  date_planned_start:string =""
  create() {
    return new MrpProduction()
  }


}
