<form (ngSubmit)="insert()" #form="ngForm">

  <div class="form-group row mb-4">
    <label  class="col-sm-4 col-form-label">{{trelloDateLabel ? trelloDateLabel : 'Scadenza'}}</label>
    <div class="col-sm-8">
      <input required type="date" name="due" class="form-control"  [(ngModel)]="due">
    </div>
  </div>

  <ng-container *ngFor="let field of fields; index as i;">
      
      <div class="form-group row mb-4" *ngIf="(field.type == 'text' || field.type == 'number') && !field.name.endsWith('*')">
          <label for="inputEmail3" class="col-sm-4 col-form-label">{{field.name}}</label>
          <div class="col-sm-8">
            <input required [name]="field.id" class="form-control" [(ngModel)]="field.value">
          </div>
      </div>

      <div class="form-group row mb-4" *ngIf="field.type == 'list' && !field.name.endsWith('*')">
        <label class="col-sm-4 col-form-label">{{field.name}}</label>
        <div class="col-sm-8">
          <select required class="form-control" [name]="field.id" [(ngModel)]="field.value">
            <option *ngFor="let o of field.options" [value]="o.id">{{o.value.text}}</option>
          </select>
        </div>
      </div>

      <div class="form-group row mb-4" *ngIf="field.type == 'checkbox' && !field.name.endsWith('*')">
        <label class="col-sm-4 col-form-label">{{field.name}}</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="checkbox"  [name]="field.id" [(ngModel)]="field.value">
          <!-- <select required class="form-control" [name]="field.id" [(ngModel)]="field.value">
            <option *ngFor="let o of field.options" [value]="o.id">{{o.value.text}}</option>
          </select> -->
        </div>
      </div>

      <!-- <div class="form-group row mb-4" *ngIf="field.type == 'checkbox' && !field.name.endsWith('*')">
        <label class="col-sm-4 col-form-label">{{field.name}}</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="checkbox" [name]="field.id" [(ngModel)]="field.value">
        </div>
      </div> -->

      <div class="form-group row mb-4"  *ngIf="field.type == 'date' && !field.name.endsWith('*')">
        <label class="col-sm-4 col-form-label">{{field.name}}</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="date" required class="form-control" [name]="field.id" [(ngModel)]="field.value">
          <!-- <select required class="form-control" [name]="field.id" [(ngModel)]="field.value">
            <option *ngFor="let o of field.options" [value]="o.id">{{o.value.text}}</option>
          </select> -->
        </div>
      </div>

      <!-- <div class="form-group row mb-4" *ngIf="field.type == 'date' && !field.name.endsWith('*')">
        <label class="col-sm-4 col-form-label">{{field.name}}</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="date" class="form-control" [name]="field.id" [(ngModel)]="field.value">
        </div>
      </div> -->


      <!-- <div class="form-group row">
        <label class="col-sm-4 col-form-label">field.name</label>
        <div class="col-sm-8 d-flex align-items-center">
          <input type="date" >
         
        </div>
      </div> -->

  </ng-container>
    

    
  <button [disabled]="!form.form.valid || isLoading" type="submit" class="btn btn-primary w-100 text-white" >Crea</button>
</form>
