<div class="d-flex flex-column h-100">
  <app-navbar [loading]="loading" backroute="..">

    <a class="navbar-brand  me-auto" style="text-overflow: ellipsis;overflow: hidden; max-width: calc(100%);">
      {{order?.name}}

      <!-- {{firstPicking.name}} 
      {{firstPicking.state}}  -->
    </a>

<!--     
    <button 
      class="btn btn-lg btn-primary text-white me-4" 
      type="button"
      [disabled]="order?.state == 'sale'"
      (click)="scanWithCamera()"
    >
      <i class="fa-duotone fa-camera fa-lg"></i>
    </button> -->


    <div class="dropdown" *ngIf="order">
      <button class="btn btn-dark me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-bars"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-end">
        <!-- <li 
          *ngIf="order.state == 'draft'"
          class="dropdown-item"  >
          <button class="btn btn-primary text-white" type="button" (click)="confirm(s)">
            Conferma
          </button>
        </li>
      
        

        <li  *ngIf="s.state == 'cancel'">
          <button class="btn btn-primary text-white ms-3" type="button" (click)="draft(order)">
            Imposta a bozza
          </button>
        </li> -->
        <!-- <li><hr class="dropdown-divider"></li> -->
        <!-- <li><a class="dropdown-item" target="_blank" (click)="print(s)">Stampa</a></li> -->

        <li class="dropdown-item" *ngIf="order.state == 'sale'">
         
          <button class="btn btn-primary text-white" type="button" (click)="cancel(order)">
            Annulla
          </button>
        </li>

        <li><a class="dropdown-item" target="_blank" href="//o3.galimberti.eu/web#id={{order.id}}&cids=1&menu_id=178&action=296&model=sale.order&view_type=form">Apri in Odoo</a></li>
        <li><a class="dropdown-item" target="_blank" (click)="print(order)">Stampa</a></li>
        <li><a class="dropdown-item" target="_blank" (click)="delete(order)">Elimina</a></li>
        <li><hr class="dropdown-divider"></li>
        <li class="dropdown-item disabled">
          Creato il {{order.date_order | date}} <br> di {{order.user_id.name}} 
      </li>
      </ul>
    </div>


    <button 
        class="btn btn-lg btn-primary text-white" 
        type="button" 
        data-bs-toggle="offcanvas" 
        [disabled]="order?.state == 'sale'"
        data-bs-target="#offcanvasExample" 
        aria-controls="offcanvasExample"
    >
      <i class="fa-duotone fa-magnifying-glass fa-lg"></i>
    </button>

  </app-navbar>

  <app-order-inventory-mini
    #inventory
    class="w-100 pe-1" 
    (addOrderLine)="onAddProduct($event)" 
    [noDrag]="true"
    [noTemplate]="true"
  ></app-order-inventory-mini>

  <div class="table-wrapper flex-fill h-100"  style="height: 100%;">

    <div class="mb-0 p-3 bg-light d-flex flex-column">
      <div class="d-flex align-items-center">
        <span>
          {{order?.partner_id.name}}
        </span>
        <!-- <button class="btn btn-link ms-auto p-0" data-bs-toggle="collapse" href="#collapsediv">
          <i class="fa fa-lg fa-caret-down"></i>
        </button> -->
      </div>

      <div class="collapse" id="collapsediv">
        <span *ngIf="order?.partner_id.value?.vat">
          PIVA {{order?.partner_id.value?.vat}}
        </span>
        <span *ngIf="order?.partner_id.value?.street">
          <br>
          {{order?.partner_id.value?.street}}
        </span>
        <span *ngIf="order?.partner_id.value?.city">
          <br>
          {{order?.partner_id.value?.city}}
        </span>
      </div>
    </div>

    <table class="table align-middle table-bordered">
      <thead>
        <tr>
          <th>Prodotto</th>
          <th>Qta</th>
          <th class="text-nowrap">€ / UdM</th>
          <th class="text-nowrap">Tot</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let l of order?.order_line.values">
          <tr >
            <td class="text-muted w-100 align-middle"  (click)="onLine(l)">
              {{l.product_id.value?.name}}
            </td>

            <td class="py-3  text-nowrap">
              {{l.product_uom_qty | number : '1.0-3':'it-IT'}} {{l.product_uom.name}}
              <br>
            </td>

            <td class="text-nowrap">
              {{l.price_unit}} €
            </td>

            <td class="text-nowrap">
              {{l.price_subtotal}} €
            </td>

          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>

  
  <div class="d-flex flex-column border-top p-2 pt-2">
      <!-- <div
      class="d-flex flex-column w-100 p-3"
      *ngIf="order?.state == 'sale' && firstPicking && (firstPicking.state == 'confirmed' || firstPicking.state == 'assigned')"
    > -->
    <!-- <form class="form mb-2">
 
      <div class="form-check form-switch mb-3">
        <input 
          [ngModel]="firstPicking?.carrier_id.id"
          (ngModelChange)="onCarrier($event)"
          name="carrier"
          class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Con corriere</label>
      </div>

      <ng-container *ngIf="firstPicking?.carrier_id.id">
        
        <div class="form-check"  *ngFor="let c of carriers">
          <input class="form-check-input"
            [value]="c.id"
            type="radio" name="flexRadioDefault" id="flexRadioDefault1"
            [ngModel]="firstPicking?.carrier_id.id"
            (ngModelChange)="onCarrier(c)"
            >
          <label class="form-check-label" for="flexRadioDefault1">
            {{c.name}}
          </label>
        </div>
      </ng-container>
     
    </form> -->

      <!-- <button 
        class="btn btn-primary btn-lg text-white btn-lg me-3 w-100"
        (click)="toDeliveryNote()"
      >
        Segna da bollare
      </button> -->

    <!-- </div> -->

    <div 
      class="d-flex w-100 p-3"
      *ngIf="order?.state == 'sale'"
    >
      <button
        class="btn btn-success btn-lg text-white btn-lg me-3 w-100 "
        (click)="openPicking(firstPicking)"
        >
        <!-- [routerLink]="['/pickings','internal', firstPicking.id]" -->
        <!-- (click)="toDeliveryNote()" -->
        Vai al trasferimento  
      </button>
    </div>
      
      <!-- <div 
        class="d-flex w-100 p-3"
        *ngIf="order?.state == 'sale' && firstPicking && firstPicking.state == 'done' && notePicking?.state != 'done'"
      >
        <button
          class="btn btn-link text-dark btn-lg disabled btn-lg me-3 w-100 "
        >
          In attesa di bolla
        </button>
      </div> -->

      <!-- <div
        *ngIf="order?.state == 'sale' && firstPicking"
      >

        <button *ngIf="order?.state == 'draft'" class="btn btn-primary btn-lg text-white w-100" type="button" (click)="confirm(order)">
          Conferma
        </button>
      </div> -->
    
        
    <!--         
        <div class="d-flex mb-2">
          <button  class="btn w-100  btn-muted text-white me-2" type="button" (click)="cancel(order)">
            Annulla
          </button>

          <button class="btn  btn-primary text-white" type="button" (click)="share(order)">
            <i class="fa fa-share"></i>
          </button>
        </div> -->

        
    <button *ngIf="order?.state == 'draft' " class="btn btn-primary btn-lg text-white w-100" type="button" (click)="confirm(order)">
      Conferma
    </button>

    <button (click)="draft(order)"  *ngIf="order?.state == 'cancel'" class="btn btn-lg btn-primary text-white w-100" type="button" >
      Reimposta a bozza
    </button>
    
  </div>
</div>



<div *ngIf="masterLine" class=" animate__animated  w-100 h-100 bg-white d-flex  justify-content-center flex-column"
  style="position: fixed; top: 0px; left: 0;z-index:1000">
  
  <bar-loader [loading]="loading"></bar-loader>
  
  <div class="row">
    <div class="col">
      <p class="lead mb-0 my-3 text-center">
        <b>{{masterLine.product_id.value.display_name}}</b>
      </p>
    </div>
  </div>

  <div class="row flex-fill align-items-center">
    <div class="col p-4">
      <table class="table bg-ligth table-bordered">
        <!-- <thead>
          <tr>
            <td>Prelevata</td>
          </tr>
        </thead> -->
        <tbody>
          <tr *ngFor="let d of descriptiveArray;let i = index;">
            <td class=" align-items-center d-flex p-3">
              <input 
                type="number"
                lang="it-IT"
                class=" form-control d-inline pe-2" 
                [ngModelOptions]="{'updateOn':'blur'}"
                (ngModelChange)="updateDescriptiveOr(masterLine, d, $event )"
                [ngModel]="d[0]"
              >
              <span class="ps-2">{{d[1].name}}</span>
            </td>
          </tr>
          <tr>
            <td class="align-items-center d-flex p-3 bg-light text-nowrap">
              <input
                type="number"
                lang="it-IT"
                class="form-control d-inline pe-2" 
                [ngModel]="masterLine.product_uom_qty"
                [ngModelOptions]="{'updateOn':'blur'}"
                (ngModelChange)="updateMaster(masterLine, 'product_uom_qty', $event)"
                >
              <span class="ps-2 text-nowrap">{{masterLine.product_uom.name}}</span>
            </td>
          </tr>
        <!-- <tr>
          <td class="align-items-center d-flex p-3 text-nowrap">
            <input
              type="number"
              lang="it-IT"
              class="form-control d-inline pe-2" 
              [ngModel]="masterLine.product_uom_qty"
              [ngModelOptions]="{'updateOn':'blur'}"
              (ngModelChange)="updateMaster(masterLine, 'product_uom_qty', $event)"
              >
            <span class="ps-2 text-nowrap">Prezzo listino €</span>
            </td>
          </tr> -->
          <tr>
            <td class="align-items-center d-flex p-3 bg-light text-nowrap">
              <input
                type="number"
                lang="it-IT"
                class="form-control d-inline pe-2" 
                [ngModel]="masterLine.price_unit"
                [ngModelOptions]="{'updateOn':'blur'}"
                (ngModelChange)="updateMaster(masterLine, 'price_unit', $event)"
              >
              <span class="ps-2 text-nowrap">€ / {{masterLine.product_uom.name}}</span>
            </td>
          </tr>
          <tr>
            <td class="align-items-center d-flex p-3 bg-light text-nowrap">
              <input
                type="number"
                lang="it-IT"
                disabled
                class="form-control d-inline pe-2 bg-transparent text-dark"
                [ngModel]="masterLine.price_subtotal"
                [ngModelOptions]="{'updateOn':'blur'}"
                (ngModelChange)="updateMaster(masterLine, 'price_subtotal', $event)"
              >
              <span class="ms-auto ps-2 text-nowrap">Totale €</span>
            </td>
          </tr>


        </tbody>
      </table>
    </div>
  </div>
  
  <div class="d-flex flex-column my-3 animate__animated" [class.animate__fadeOutDown]="loading" >
    <div class="align-items-center d-flex mt-2 justify-content-center">
      
      <button class="btn btn-link  text-success ms-2 me-2"
        (click)="back()">
        <i class="fa fa-circle-chevron-left fa-4x text-dark"></i>
      </button>

      <button class="btn btn-link text-success ms-2 me-2"
        [disabled]="order?.state == 'sale'"
        (click)="updateMasterLine()">
          <i class="fa fa-circle-check fa-5x"></i>
      </button>
      
      <button class="btn btn-link  text-success text-warning ms-2"
        [disabled]="order?.state == 'sale'"
        (click)="deleteLine(masterLine)">
        <i class="fa-solid fa-circle-minus fa-4x"></i>
      </button>

    </div>
  </div>
</div>

<app-scanner *ngIf="scanning" (onCode)="scanning = false;onBarcode($event)">

</app-scanner>