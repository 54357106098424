import { Component, OnInit } from '@angular/core';
import { debounceTime, firstValueFrom, Observable, Subject } from 'rxjs';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StockPicking } from '../models/stock-picking';
import { SaleOrder } from '../models/sale-order.model';
import { Lead } from '../models/crm.lead.model';
import { ODOO_IDS } from '../models/deal';
import { Product } from '../models/product.model';
import { StockBackorderConfirmation, StockBackorderConfirmationLine } from '../models/stock.backorder.confirmation.model';
import { CrmTag } from '../models/crm.tag.model';
import { Contact } from '../models/contact.model';
import { StockMove } from '../models/stock-move';

interface Move {
  product_id: [number, string];
  product_uom_qty: number;
  quantity_done: number;
  product_uom: { id: number, name: string };
  category: string;
  price_unit?: number;
  discount?: number;
}

interface GroupedMove {
  category: string;
  uom: string;
  moves: Move[];
  expanded: boolean;
}

interface GroupedPicks {
  opportunity?: Lead;
  picks: StockPicking[];
  allSelected: boolean;
  showLeadDropdown?: boolean;
}

@Component({
  selector: 'app-delivery-note-viewer2',
  template: `  
<app-navbar [loading]="loading" backroute="..">
  <a class="navbar-brand">
    Da bollare
  </a>
</app-navbar>

<div class="container-fluid mt-3">
  <div class="row align-items-center mb-3 pb-3 border-bottom">
    <div class="col-md-6">
      <div class="input-group">
        <span class="input-group-text bg-light border-end-0">
          <i class="fas fa-search text-muted"></i>
        </span>
        <input type="text" class="form-control border-start-0" [(ngModel)]="searchString" (ngModelChange)="load()" placeholder="Cerca...">
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex justify-content-end align-items-center">
        <div class="btn-group me-3" role="group" aria-label="View selection">
          <button type="button" class="btn" [class.btn-primary]="!filterListino" [class.btn-outline-primary]="filterListino" (click)="toggleFilterCommesse()">Commesse</button>
          <button type="button" class="btn" [class.btn-primary]="filterListino" [class.btn-outline-primary]="!filterListino" (click)="toggleFilterListino()">A listino</button>
        </div>
        <div class="form-check form-switch d-flex align-items-center">
          <input class="form-check-input me-2" type="checkbox" id="filterJustReady" [(ngModel)]="filterJustReady" (change)="load()">
          <label class="form-check-label mb-0" for="filterJustReady">Solo pronti</label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showMultipleOpportunityAlert" class="alert alert-warning mt-3" role="alert">
    Attenzione: Hai selezionato ordini di diverse commesse!
  </div>

  <div class="row mt-3">
    <div class="col-md-6 border-end">
      <h2 class="mb-4">Selezione ordini</h2>
      <ul class="list-group list-group-flush" *ngIf="!loading">
        <ng-container *ngIf="!filterListino">
  <table class="table table-hover">
    <tbody>
      <ng-container *ngFor="let group of groupedPicks; let i = index">
        <tr [class.border-top]="i > 0" class="bg-light">
          <td colspan="6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" 
                     [id]="group.opportunity ? 'opportunity_' + group.opportunity.id : 'no_opportunity'"
                     [(ngModel)]="group.allSelected" 
                     (change)="onOpportunitySelect(group)">
              <label class="form-check-label fw-bold" [for]="group.opportunity ? 'opportunity_' + group.opportunity.id : 'no_opportunity'">
                <a href="#" (click)="$event.preventDefault(); toggleLeadDropdown(group)" class="text-decoration-none text-dark">
                  {{ group.opportunity ? 
                    (group.opportunity.tracking_code || 'N/A') + ' - ' + 
                    (group.opportunity.partner_id.name || 'N/A') + ' - ' + 
                    (group.opportunity.name || 'N/A') : 
                    'No Opportunity' }}
                </a>
                <span class="badge ms-2" [ngClass]="getLeadBadgeClass(group.opportunity)">
                  {{ group.opportunity?.area || 'N/A' }}
                </span>
              </label>
            </div>
          </td>
        </tr>
        <tr *ngIf="group.showLeadDropdown">
          <td colspan="6">
            <div class="p-3 bg-light">
              <div class="row">
                <div class="col-md-6"><strong>Cliente:</strong> {{group.opportunity?.partner_id.value?.ga_arca}} - {{ group.opportunity?.partner_id.name }}</div>
                <div class="col-md-6"><strong>Indirizzo:</strong> {{ group.opportunity?.street }}</div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6"><strong>Fornitura:</strong> {{ getLeadTags(group.opportunity) }}</div>
                <div class="col-md-6"><strong>Responsabile:</strong> {{ group.opportunity?.user_id.name }}</div>
              </div>
            </div>
          </td>
        </tr>
        <ng-container *ngFor="let pick of group.picks">
          <tr>
            <td style="width: 40px;">
              <input class="form-check-input" type="checkbox" 
                     [id]="pick.id" 
                     [(ngModel)]="pick._selected" 
                     (change)="onPickingSelect(pick, group)">
            </td>
            <td style="width: 30px;">
              <i class="fa fa-circle" [ngClass]="pick.state === 'assigned' ? 'text-success' : 'text-warning'"></i>
            </td>
            <td>{{ pick.sale_id?.value.name || 'N/A' }}</td>
            <td colspan="3">{{ pick.sale_id?.value.ga_title || 'N/A' }}</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</ng-container>
        <ng-container *ngIf="filterListino">
  <table class="table table-hover">
    <thead>
      <tr>
        <th style="width: 40px;"></th>
        <th style="width: 30px;"></th>
        <th>Ordine</th>
        <th>Cliente</th>
        <th>Titolo</th>
        <th>Listino</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let pick of picks; let i = index">
        <tr [class.border-top]="i > 0">
          <td>
            <input class="form-check-input" type="checkbox" 
                   [id]="pick.id" 
                   [(ngModel)]="pick._selected" 
                   (change)="onPickingSelect(pick, null)">
          </td>
          <td>
            <i class="fa fa-circle" [ngClass]="pick.state === 'assigned' ? 'text-success' : 'text-warning'"></i>
          </td>
          <td>
            <a href="#" (click)="$event.preventDefault(); toggleSaleDropdown(pick)" class="text-decoration-none text-dark">
              {{ pick.sale_id?.value.name || 'N/A' }}
            </a>
          </td>
          <td>{{ pick.sale_id?.value?.partner_id.name || 'N/A' }}</td>
          <td>{{ pick.sale_id?.value?.ga_title || '' }}</td>
          <td>{{ pick.sale_id?.value?.pricelist_id?.name || 'N/A' }}</td>
        </tr>
        <tr *ngIf="pick._showSaleDropdown" class="bg-light">
          <td colspan="6">
            <div class="p-3">
              <div class="row">
                <div class="col-md-4"><strong>Cliente:</strong> {{ pick.partner_id.name }}</div>
                <div class="col-md-4"><strong>Indirizzo:</strong> da sistemare!!</div>
                <div class="col-md-4"><strong>Responsabile:</strong> {{ pick.sale_id?.value.user_id.name }}</div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>
      </ul>
    </div>

    <div class="col-md-6">
  <div class="d-flex justify-content-between align-items-center mb-4">
  <h2>Materiale preparato</h2>
  <button class="btn btn-primary text-nowrap" (click)="completePicks()" [disabled]="loading"> 
    <span *ngIf="!loading">Bolla</span>
    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
</div>
  <div *ngIf="updatingMoves" class="text-center mb-3">
    <div class="fa fa-spinner fa-spin" role="status">
      <span class="visually-hidden">Updating...</span>
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th>Prodotto</th>
        <th>UdM</th>
        <th>Caricato / Richiesto</th>
        <th *ngIf="filterListino">Prezzo</th>
        <th *ngIf="filterListino">Sconto</th>
        <th *ngIf="filterListino">Totale</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let group of groupedMoves">
        <tr (click)="group.expanded = !group.expanded" style="cursor: pointer;">
          <td class="fw-bold">
            <i [class]="group.expanded ? 'fa-solid fa-caret-down' : 'fa-solid fa-caret-right'"></i>
            {{ group.category }}
          </td>
          <td>{{ group.uom }}</td>
          <td *ngIf="!filterListino" [ngClass]="getQuantityClass(getTotalPrepared(group), getTotalQuantity(group))">
            {{ getTotalPrepared(group) | number : '1.0-5':'it-IT' }} / {{ getTotalQuantity(group) | number : '1.0-5':'it-IT' }}
          </td>
          <td *ngIf="filterListino" colspan="3"></td>
          <td *ngIf="filterListino" class="text-end">
            <strong>{{ calculateGroupTotal(group) | number : '1.2-2':'it-IT' }} €</strong>
          </td>
        </tr>
        <ng-container *ngIf="group.expanded">
          <tr *ngFor="let move of group.moves">
            <td class="ps-4">{{ move.product_id[1] }}</td>
            <td>{{ move.product_uom.name }}</td>
            <td [ngClass]="getQuantityClass(move.quantity_done, move.product_uom_qty)">
              {{ move.quantity_done | number : '1.0-5':'it-IT' }} / {{ move.product_uom_qty | number : '1.0-5':'it-IT' }}
            </td>
            <td *ngIf="filterListino">{{ move.price_unit | number : '1.2-2':'it-IT' }} €</td>
            <td *ngIf="filterListino">{{ move.discount | number : '1.2-2':'it-IT' }}%</td>
            <td *ngIf="filterListino" class="text-end">{{ calculateTotal(move) | number : '1.2-2':'it-IT' }} €</td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
    <tfoot *ngIf="filterListino">
      <tr>
        <td colspan="5" class="text-end"><strong>Totale Complessivo</strong></td>
        <td class="text-end"><strong>{{ calculateGrandTotal() | number : '1.2-2':'it-IT' }} €</strong></td>
      </tr>
    </tfoot>
  </table>
</div>
  </div>
</div>
  `,
  styleUrl: './delivery-note-viewer2.component.scss'
})

export class DeliveryNoteViewer2Component implements OnInit {
  picks: StockPicking[];
  groupedPicks: GroupedPicks[] = [];
  selectedPicking: StockPicking | null = null;
  combinedMoves: Move[] = [];
  loading: boolean = false;
  updatingMoves: boolean = false;
  searchString: string = '';
  filterJustReady: boolean = true;
  filterCommesse: boolean = true;
  filterListino: boolean = false;
  origin: SaleOrder;
  showMultipleOpportunityAlert: boolean = false;
  groupedMoves: GroupedMove[] = [];
  saleOrders: SaleOrder[] = [];
  pickingSelect$: Subject<void>  = new Subject<void>();
  lastCallId: number;

  constructor(
    private odooEm: OdooEntityManager,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  async ngOnInit(): Promise<void> {


    this.pickingSelect$.pipe(debounceTime(700)).subscribe(() => {
      this.onPickingSelectDebounced()
    })


    await this.load();
    if (this.filterListino) {
      this.expandAllGroups();
    }

  }


  toggleFilterCommesse() {
    this.filterCommesse = true;
    this.filterListino = false;
    this.deselectAllPicks();
    this.load();
  }

  async toggleFilterListino() {
    this.loading = true;
    this.filterListino = true;
    this.filterCommesse = false;
    this.deselectAllPicks();
    this.showMultipleOpportunityAlert = false;
    await this.load();
    this.expandAllGroups();
    this.loading = false;
  }

  toggleLeadDropdown(group: GroupedPicks) {
    group.showLeadDropdown = !group.showLeadDropdown;
  }

  toggleSaleDropdown(pick: StockPicking) {
    pick._showSaleDropdown = !pick._showSaleDropdown;
  }

  getLeadBadgeClass(lead: Lead | undefined): string {
    if (!lead || !lead.area) return 'bg-dark';
    switch (lead.area.toLowerCase()) {
      case 'tetti':
        return 'bg-success';
      case 'case':
        return 'bg-danger';
      case 'facciate & decking':
        return 'bg-secondary';
      default:
        return 'bg-warning';
    }
  }

  getLeadTags(lead: Lead | undefined): string {
    if (!lead || !lead.tag_ids || lead.tag_ids.values?.length === 0) return 'N/A';
    return lead.tag_ids.values?.map(tag => tag.name).join(', ');
  }


  async load() {
    this.loading = true;
    try {
      let criterias = [
        ['state', '!=', 'draft'],
        ['state', '!=', 'cancel'],
        ['state', '!=', 'done'],
        ['picking_type_id', '=', ODOO_IDS.picking_type_ddt]
      ];
  
      if (this.searchString) {
        criterias.push('|' as any);
        criterias.push('|' as any);
        criterias.push('|' as any);
        criterias.push(['origin', 'ilike', this.searchString]);
        criterias.push(['name', 'ilike', this.searchString]);
        criterias.push(['partner_id', 'ilike', this.searchString]);
        criterias.push(['group_id.sale_id.ga_title', 'ilike', this.searchString]);
      }
  
      if (this.filterJustReady) {
        criterias.push(['state', '=', 'assigned']);
      }
  
      // Add criteria for pricelist filtering
      if (this.filterListino) {
        criterias.push(['group_id.sale_id.pricelist_id', '!=', ODOO_IDS.pricelist_commessa]);
      } else {
        criterias.push(['group_id.sale_id.pricelist_id', '=', ODOO_IDS.pricelist_commessa]);
      }
  
      this.picks = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(), criterias, 200, "", "origin DESC"));
  
      this.picks = this.picks.filter(p => p.group_id.id != null);
  
      await firstValueFrom(this.odooEm.resolveArrayOfSingle(new SaleOrder(), this.picks, "sale_id"));
      
  
      
      //if commesse, resolve further data (leads, partners, tags)
      if (!this.filterListino) {
        let sales = this.picks.map(g => g.sale_id.value).filter(sale => sale && sale.opportunity_id && sale.opportunity_id.id);
        if (sales.length > 0) {
          await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Lead(), sales, "opportunity_id"));
        }
  
        // Save leads
        let leads = this.picks.map(p => p.sale_id.value?.opportunity_id.value).filter(lead => lead && lead.id);
  
        // Resolve lead partners
        await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Contact(), leads, "partner_id"));
  
        // Resolve lead tags
        await firstValueFrom(this.odooEm.resolveArray(new CrmTag(), leads, "tag_ids"));
      } else {
        // Resolve data specific to "A listino" view: only need contacts info cause sale is alreasy resolved
        await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Contact(), this.picks, "partner_id"));
      }

      console.log(this.picks);
      this.groupPicks();
  
      this.deselectAllPicks();
    } catch (error) {
      console.error('Error loading data:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      this.loading = false;
    }
  }


  groupPicks() {
    const groupMap = new Map<number | string, GroupedPicks>();
  
    this.picks.forEach(pick => {
      const opportunityId = pick.sale_id?.value.opportunity_id?.value?.id;
      const key = opportunityId || 'no_opportunity';
      if (!groupMap.has(key)) {
        groupMap.set(key, {
          opportunity: opportunityId ? pick.sale_id.value.opportunity_id.value : undefined,
          picks: [],
          allSelected: false
        });
      }
      groupMap.get(key)!.picks.push(pick);
    });
  
    this.groupedPicks = Array.from(groupMap.values());
  }


  onOpportunitySelect(group: GroupedPicks) {
    group.picks.forEach(pick => pick._selected = group.allSelected);
    this.updateCombinedMoves();
    this.checkMultipleOpportunitySelection();
  }


  async onPickingSelect(pick: StockPicking, group: GroupedPicks | null) {
    this.pickingSelect$.next();  
    if (group) {
      group.allSelected = group.picks.every(p => p._selected);
    }
  
    if (this.filterListino) {
      // Deselect all other picks
      this.picks.forEach(p => {
        if (p !== pick) {
          p._selected = false;
        }
      });
      // Resolve sale order lines of selected pick
      if (pick._selected && pick.sale_id.value) {
        await firstValueFrom(this.odooEm.resolve(pick.sale_id.value.order_line));
      }
      //store selected sale orders
      this.saleOrders = this.picks.filter(p => p._selected).map(p => p.sale_id.value);
    }
    
  }

  async onPickingSelectDebounced() {
   
    await this.updateCombinedMoves();
    this.checkMultipleOpportunitySelection();
  }

  private processServiceProducts() {

    
    console.log("PROCESSING SERVICE PRODUCTS FOR ORDERS", this.saleOrders); 
    for (const sale of this.saleOrders) {
      const serviceLines = sale.order_line.values.filter(line => line.product_type === 'service');
      for (const line of serviceLines) {
        this.combinedMoves.push({
          product_id: [line.product_id.id, line.product_id.name || ''],
          product_uom_qty: line.product_uom_qty,
          quantity_done: line.product_uom_qty, // For services, we consider them as fully "prepared"
          product_uom: line.product_uom,
          price_unit: line.price_unit,
          discount: line.discount,
          //product is not resolved yet so we push the category manually
          category: "Servizi e lavorazioni"
        });
        console.log("FOND SERVICES IN ORDER!!", line);
      }
    }
  }






  async updateCombinedMoves() {
    this.updatingMoves = true;
    try {
      if (this.filterListino) {
        await this.updateCombinedMovesListino();
      } else {
        await this.updateCombinedMovesCommesse();
      }
      // Process service products after updating combined moves
      this.processServiceProducts();
      // Regroup moves after adding service products
      await this.groupMovesByCategoryAndUom();
    } finally {
      this.updatingMoves = false;
    }
  }


  expandAllGroups() {
    this.groupedMoves.forEach(group => group.expanded = true);
  }

  private async updateCombinedMovesListino() {
    this.combinedMoves = [];
    const selectedPicks = this.picks.filter(p => p._selected);
    await firstValueFrom(this.odooEm.resolveArray(new StockMove(), selectedPicks, "move_ids"));

    var moves = selectedPicks.map(p =>  p.move_ids.values).flat()
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), moves, 'product_id'));


    for (const selectedPick of selectedPicks) {
      if (selectedPick && selectedPick.move_ids) {
        // await firstValueFrom(this.odooEm.resolve(selectedPick.move_ids));
        // await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), selectedPick.move_ids.values, 'product_id'));
        let moves = selectedPick.move_ids.values;
        for (const move of moves) {
          const saleOrderLine = selectedPick.sale_id?.value.order_line.values?.find(
            line => line.product_id.id === move.product_id.id
          );
          this.combinedMoves.push({
            product_id: move.product_id.id ? [move.product_id.id, move.product_id.name || ''] : [0, ''],
            product_uom_qty: move.product_uom_qty,
            quantity_done: move.reserved_availability,
            product_uom: move.product_uom,
            price_unit: saleOrderLine ? saleOrderLine.price_unit : 0,
            discount: saleOrderLine ? saleOrderLine.discount : 0,
            category: move.product_id.value?.categ_id.name || 'Uncategorized'
          });
        }
      }
    }
    
    // Group the moves
    await this.groupMovesByCategoryAndUom();
    
    // Expand all groups for "A listino" view
    this.expandAllGroups();
  }

  private async updateCombinedMovesCommesse() {

    let x = Math.random()
    this.lastCallId = x

    this.combinedMoves = [];
    const selectedPicks = this.picks.filter(p => p._selected);
    await firstValueFrom(this.odooEm.resolveArray(new StockMove(), selectedPicks, "move_ids"));
    var movesflat = selectedPicks.map(p =>  p.move_ids.values).flat()
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), movesflat, 'product_id'));

    for (const selectedPick of selectedPicks) {
      if (selectedPick && selectedPick.move_ids) {
        // await firstValueFrom(this.odooEm.resolve(selectedPick.move_ids));
        // await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Product(), selectedPick.move_ids.values, 'product_id'));
        let moves = selectedPick.move_ids.values;
        for (const move of moves) {
          if (this.lastCallId != x) return

          this.combinedMoves.push({
            product_id: move.product_id.id ? [move.product_id.id, move.product_id.name || ''] : [0, ''],
            product_uom_qty: move.product_uom_qty,
            quantity_done: move.reserved_availability,
            product_uom: move.product_uom,
            category: move.product_id.value?.categ_id.name || 'Uncategorized'
          });
        }
      }
    }

   
    await this.groupMovesByCategoryAndUom();
  }

  getQuantityClass(reserved: number, quantity: number): string {
    if (reserved === quantity) {
      return 'text-success';
    } else if (reserved < quantity) {
      return 'text-warning';
    } else {
      return 'text-danger';
    }
  }

  private groupMovesByCategoryAndUom() {
    const groupedMap = new Map<string, GroupedMove>();
  
    this.combinedMoves.forEach(move => {
      const key = `${move.category}_${move.product_uom.name}`;
      if (!groupedMap.has(key)) {
        groupedMap.set(key, {
          category: move.category,
          uom: move.product_uom.name,
          moves: [],
          expanded: this.filterListino // Automatically expand for "A listino" mode
        });
      }
      groupedMap.get(key)!.moves.push(move);
    });
  
    // Convert the Map to an array and sort it
    this.groupedMoves = Array.from(groupedMap.values()).sort((a, b) => {
      // First, sort by category
      const categoryComparison = a.category.localeCompare(b.category, 'it', { sensitivity: 'base' });
      if (categoryComparison !== 0) {
        return categoryComparison;
      }
      // If categories are the same, sort by UoM
      return a.uom.localeCompare(b.uom, 'it', { sensitivity: 'base' });
    });
  }

  deselectAllPicks() {
    this.groupedPicks.forEach(group => {
      group.allSelected = false;
      group.picks.forEach(pick => pick._selected = false);
    });
    this.updateCombinedMoves();
  }

  calculateTotal(move: Move): number {
    return move.quantity_done * move.price_unit * (1 - move.discount / 100);
  }

  calculateGroupTotal(group: GroupedMove): number {
    return group.moves.reduce((total, move) => total + this.calculateTotal(move), 0);
  }

  calculateGrandTotal(): number {
    return this.combinedMoves.reduce((total, move) => total + this.calculateTotal(move), 0);
  }

  getTotalQuantity(group: GroupedMove): number {
    return group.moves.reduce((total, move) => total + move.product_uom_qty, 0);
  }

  getTotalPrepared(group: GroupedMove): number {
    return group.moves.reduce((total, move) => total + move.quantity_done, 0);
  }

  private aggregateMoves(moves: Move[]): Move[] {
    const aggregatedMoves: { [key: string]: Move } = {};

    moves.forEach(move => {
      const key = move.product_id[0].toString();
      if (aggregatedMoves[key]) {
        aggregatedMoves[key].product_uom_qty += move.product_uom_qty;
        aggregatedMoves[key].quantity_done += move.quantity_done;
      } else {
        aggregatedMoves[key] = { ...move };
      }
    });

    return Object.values(aggregatedMoves);
  }

  private checkMultipleOpportunitySelection() {
    const selectedOpportunities = new Set<number | string>();
    this.groupedPicks.forEach(group => {
      if (group.picks.some(pick => pick._selected)) {
        selectedOpportunities.add(group.opportunity?.id || 'no_opportunity');
      }
    });
    this.showMultipleOpportunityAlert = selectedOpportunities.size > 1;
  }

  getSelectedCount(): number {
    return this.groupedPicks.reduce((count, group) =>
      count + group.picks.filter(pick => pick._selected).length, 0);
  }

  async completePicks() {
    const selectedPicks = this.picks.filter(p => p._selected);
    
    if (selectedPicks.length === 0) {
      alert("Nessun ordine selezionato. Seleziona almeno un ordine per procedere.");
      return;
    }
  
    if (!confirm("Confermi l'operazione? Verrà scaricato solo il materiale pronto"))
      return;
  
    this.loading = true;
  
    try {
      for (const pick of selectedPicks) {
        // Assign and set quantities
        await this.check(this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_assign", [[pick.id]]));
        await this.check(this.odooEm.call2(new StockPicking().ODOO_MODEL, "action_set_quantities_to_reservation", [[pick.id]]));
        
        // Attempt to validate
        const validationResult = await this.check(this.odooEm.call2(new StockPicking().ODOO_MODEL, "button_validate", [[pick.id]]));
        
        // Handle potential wizard
        if (validationResult && validationResult.res_model === 'stock.backorder.confirmation') {
          // Create backorder using the provided logic
          const backorderConfirmation = await firstValueFrom(this.odooEm.create<StockBackorderConfirmation>(new StockBackorderConfirmation(), {
            pick_ids: [pick.id]
          }));
  
          await firstValueFrom(this.odooEm.create(new StockBackorderConfirmationLine(), {
            to_backorder: true,
            backorder_confirmation_id: backorderConfirmation.id,
            picking_id: pick.id
          }));
  
          await firstValueFrom(this.odooEm.call(new StockBackorderConfirmation(), 'process', [backorderConfirmation.id], {
            'button_validate_picking_ids': [pick.id]
          }));
        } else if (validationResult && validationResult.res_model === 'stock.immediate.transfer') {
          // Confirm the immediate transfer
          await this.check(this.odooEm.call2('stock.immediate.transfer', 'process', [[validationResult.res_id]]));
        }
        
      }
  
      alert("Operazione completata con successo!");
      this.deselectAllPicks();
      await this.load(); // Reload the data to reflect the changes
    } catch (error) {
      console.error("Errore durante il completamento dei pick:", error);
      alert("Si è verificato un errore durante l'operazione. Controlla la console per i dettagli.");
    } finally {
      this.loading = false;

    }
  }

  async check(f: Promise<any>): Promise<any> {
    let r = await f;
    if (r.error) {
      throw new Error(r.error.data.message);
    }
    return r.result || r; // Return the result if it exists, otherwise return the whole response
  }
}