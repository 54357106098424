import { Component, OnInit } from '@angular/core';
import { StockPicking } from '../models/stock-picking';
import { Subject, debounceTime, firstValueFrom } from 'rxjs';
import { OdooEntityManager } from '../shared/services/odoo-entity-manager.service';
import { ODOO_IDS } from '../models/deal';
import { SaleOrder, SaleOrderMini } from '../models/sale-order.model';
import { ProcurementGroup } from '../models/procurement.group.model';
import { Lead } from '../models/crm.lead.model';

@Component({
  selector: 'app-delivery-note-search',
  templateUrl: './delivery-note-search.component.html',
  styleUrls: ['./delivery-note-search.component.scss']
})
export class DeliveryNoteSearchComponent implements OnInit {
  
  pickings:StockPicking[]
  loading:boolean
  searchString: any;
  refresh$= new Subject<void>();
  filterJustReady: boolean = true;
  
  constructor(
    private odooEm:OdooEntityManager
  ) {}

  async ngOnInit(): Promise<void> {

    await this.load()
    this.refresh$
    .pipe(
      debounceTime(300))
    .subscribe(() => {
      this.load()
    })

  }

  refresh() {
    this.refresh$.next()
  }


  async load() {

    this.loading = true
    let criterias = [
      ['state', '!=', 'draft'],
      ['state', '!=', 'cancel'], 
      ['state', '!=', 'done'],
      ['picking_type_id','=', ODOO_IDS.picking_type_ddt]]


    if (this.searchString) {
      criterias.push('|' as any)
      criterias.push('|' as any)
      criterias.push('|' as any)
      criterias.push(['origin', 'ilike', this.searchString])
      criterias.push(['name', 'ilike', this.searchString])
      criterias.push(['partner_id', 'ilike', this.searchString])
      criterias.push(['group_id.sale_id.ga_title', 'ilike', this.searchString])
    }

    if (this.filterJustReady) {
      criterias.push(['state', '=', 'assigned'])
    }

    let picks = await firstValueFrom(this.odooEm.search<StockPicking>(new StockPicking(),criterias ,200, "", "origin DESC"))
    
    picks = picks.filter(p => p.group_id.id != null)
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new ProcurementGroup(), picks, "group_id"))
    
    
    let gs = []
    picks.forEach(p => {
      gs.push(p.group_id.value)
    })
    console.log(gs)
    
    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new SaleOrderMini(), gs, "sale_id"))

    let sales = []
    gs.forEach(g => {
      sales.push(g.sale_id.value)
    })

    await firstValueFrom(this.odooEm.resolveArrayOfSingle(new Lead(), sales, "opportunity_id"))




    this.pickings = picks

    this.loading = false
  }



}
